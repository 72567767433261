import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {UserContext} from '../../services/userContext';
import BN from 'bignumber.js';
import ReactFlagsSelect from 'react-flags-select'
import { constants } from 'consts/utils';
import pin from 'img/icons/icon-mappin.png';
import transfer from 'img/icons/icon-transfer.png';
import flyWallet from 'img/illustrations/fly-wallet.png';
import defaultprofile from 'img/icons/defaultprofile.png';
import { DeliverSidebar, PasswordSidebar, RegisterTransferSidebar, TransferRegisteredSidebar } from 'components/Sidebars/Sidebars';
import style from './MyTasks.module.scss';
import dai from 'img/icons/Dai/Logos/PNGs/Mark_Dai.png';
var lang = constants.getTranslations

class MyTasks extends Component {

    constructor(props, context){
        super(props);
        this.state = {
            acceptedDeliveries: [],
            incoming: [],
            deliversidebar: false,
            show_completed_tasks: false,
            complete_tasks: [],
            chosen_task: {},
            passwordsidebar: false,
            password_task: {},
            registertransfersidebar: false,
            register_task: {},
            transferregisteredsidebar: false,
            transfered_task: {}
        };
        this.handler = this.handler.bind(this);
        this.handlerOpenRegisterSidebar = this.handlerOpenRegisterSidebar.bind(this);
        this.handlerOpenRegistered = this.handlerOpenRegistered.bind(this);
    }

    handler(state){
        this.setState({[state]: false})
    }

    handlerOpenRegisterSidebar(task){
        this.setState({
            deliversidebar: false,
            registertransfersidebar: true,
            register_task: task
        })
    }

    handlerOpenRegistered(task){
        const index = this.state.acceptedDeliveries.findIndex(d => d.id === task.id)
        var states = {registertransfersidebar: false, transferregisteredsidebar: true, transfered_task: task}
        if(index != -1){
            var orders = this.state.acceptedDeliveries
            orders[index].status = 'Transfer registered'
            states.acceptedDeliveries = orders
        }

        this.setState(states)
    }

    componentDidMount(){
        if(Object.keys(this.props.context.user).length === 0){
            location.reload()
        } else{
            if(this.props.context.user.dafnir_purposes.includes(2) && this.props.context.user.verified){
                this.loadTasks();
            }
        }
    }

    loadTasks = async () => {
        try {
            const response = await axios.get('/api/order/list/user/active/tasks', {
            })
            const incoming = response.data.filter(o => o.status === 'Requested' || o.status === 'Frozen')
            const acceptedDeliveries = response.data.filter(o => o.status === 'Accepted' || o.status === 'Transfer registered')
            this.setState({ acceptedDeliveries: acceptedDeliveries, incoming: incoming });
        } catch (err) {
            console.error(err)
        }
    }

    test_purpose_deliverer(user){
        if(user){
            return user.includes(2);
        }
    }

    showCompleteTasks = async () => {
        const flag = !this.state.show_completed_tasks
        if(flag){
            try {
                const response = await axios.get('/api/order/list/user/history/tasks', {
                })
                this.setState({ complete_tasks: response.data});
            } catch (err) {
                console.error(err)
            }
        }
        this.setState({show_completed_tasks: flag})
    }


    makeTasks = (tasks) => {
        if(!tasks) return <Fragment/>
        var items = []
        for(var i = 0; i < tasks.length; i++){
            const task = tasks[i]
            const amount = new BN(task.amount["$numberDecimal"])
            const payment = new BN(task.payment["$numberDecimal"])
            const received = constants.get_agent_payment(payment, task.max_fee, task.delivery_fee, false)
            const reward = constants.get_agent_reward(payment, task.delivery_fee)
            const completed = (task.status === 'Publicly completed' || task.status === 'Privately completed' || task.status === 'Failed' || task.status === 'Cancelled')
            items.push(
                <div key={i} className={`${style['Task']}`}>
                    <div className={`${style['Task__top']}`}>
                        <div className={`${style['Task__top--left']}`}>
                            <div className={`${style['Task__top--left-recipient']}`}>
                                <img alt="Recipient avatar" src={task.customer_info.avatar ? `/api/${task.customer_info.avatar.prefix}/avatar/${task.customer_info.avatar.avatar}` : defaultprofile} className={`${style['Task__top--left-recipient__avatar']}`} />
                                <div className={`${style['Task__top--left-recipient__container']}`}>
                                    <p className={`${style['Task__top--left-recipient__container--header']}`}>{completed ? lang.deliverflow_private_order_my_tasks.main_window.active_orders.delivered_to_fat_grey : lang.deliverflow_private_order_my_tasks.main_window.active_orders.deliver_to_fat_grey}</p>
                                    <p className={`${style['Task__top--left-recipient__container--text']}`}>{task.customer_info.name}</p>
                                </div>
                            </div>
                            <div className={`${style['Task__top--left-cashtodeliver']}`}>
                                <p className={`${style['Task__top--left-cashtodeliver__text']}`}>{completed ? lang.deliverflow_private_order_my_tasks.main_window.active_orders.cash_delivered : lang.deliverflow_private_order_my_tasks.main_window.active_orders.cash_to_deliver_fat_grey}</p>
                                <div className={`${style['Task__top--left-cashtodeliver__container']}`}>
                                    <ReactFlagsSelect
                                        className={'inline-flag'}
                                        selected={constants.shorthand_curr_to_flag[task.currency]}
                                        showSelectedLabel={false}
                                        placeholder=""
                                        disabled={true}
                                        selectedSize={14}
                                        optionsSize={20}
                                    />
                                    <p className={`${style['Task__top--left-cashtodeliver__container--amount']}`}>{amount.toFixed(2)}</p>
                                    <p className={`${style['Task__top--left-deposited__container--currency']}`}>{`${task.currency}`}</p>
                                </div>
                            </div>
                            <div className={`${style['Task__top--left-deposited']}`}>
                                <p className={`${style['Task__top--left-deposited__text']}`}>{completed ? lang.deliverflow_private_order_my_tasks.main_window.active_orders.received_fat_grey : lang.deliverflow_private_order_my_tasks.main_window.active_orders.receive_fat_grey}</p>
                                <div className={`${style['Task__top--left-deposited__container']}`}>
                                    <img src={dai} className={`${style['Task__bottom--left-container__img']}`} />
                                    <p className={`${style['Task__top--left-deposited__container--amount']}`}>{received}</p>
                                    <p className={`${style['Task__top--left-deposited__container--currency']}`}>{`${task.paymentcurrency}`}</p>
                                </div>
                            </div>
                            <div className={`${style['Task__top--left-deposited']}`}>
                                <p className={`${style['Task__top--left-deposited__text']}`}>{completed ? lang.deliverflow_private_order_my_tasks.main_window.active_orders.you_earned_fat_grey : lang.deliverflow_private_order_my_tasks.main_window.active_orders.you_earn_fat_grey}</p>
                                <div className={`${style['Task__top--left-deposited__container']}`}>
                                    <img src={dai} className={`${style['Task__bottom--left-container__img']}`} />
                                    <p className={`${style['Task__top--left-deposited__container--amount']}`}>{reward}</p>
                                    <p className={`${style['Task__top--left-deposited__container--currency']}`}>{`${task.paymentcurrency}`}</p>
                                </div>
                            </div>
                        </div>
                            <div className={`${style['Task__top--right']}`}>
                            {!completed && (
                                <Fragment>
                                    {task.status === 'Requested' && (
                                        <input type="button" onClick={() => this.setState({deliversidebar: true, chosen_task: task})} className={`${style['Task__top--right-button']}`} value={lang.deliverflow_private_order_my_tasks.main_window.incoming_orders.password_red_button}/> /* View to accept */
                                    )}
                                    {(task.status === 'Accepted' || task.status === 'Transfer registered' || task.status === 'Frozen') && (
                                        <Fragment>
                                            <input type="button" onClick={() => this.setState({deliversidebar: true, chosen_task: task})} className={`${style['Task__top--right-detailsbutton']}`} value={lang.orderflow_myself_find_agents_near_you_agent_overview.white_box.red_button_view_details}/>
                                            {(task.method === 'In person' && task.status !== 'Frozen')
                                                ? <input type="button" onClick={() => this.setState({passwordsidebar: true, password_task: task})} className={`${style['Task__top--right-button']}`} value={lang.deliverflow_private_order_overview.password_or_cancel.red_button_accept}/>
                                                :
                                                    task.status === 'Accepted' && (
                                                        <input type="button" onClick={() => this.setState({registertransfersidebar: true, register_task: task})} className={`${style['Task__top--right-button']}`} value={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.register_transfer}/>
                                                    )
                                            }
                                        </Fragment>
                                    )}
                                </Fragment>
                            )}
                            </div>
                    </div>
                    <div className={`${style['Task__bottom']}`}>
                        <div className={`${style['Task__bottom--left']}`}>
                            <div className={`${style['Task__bottom--left-methodcontainer']}`}>
                                <img src={transfer} className={`${style['Task__bottom--left-methodcontainer__img']}`} />
                                <p className={`${style['Task__bottom--left-methodcontainer__text']}`}>{`${constants.makeMethods(task.method, task)}`}</p>
                            </div>
                            {task.method === 'In person' && (
                                <div className={`${style['Task__bottom--left-container']}`}>
                                    <img src={pin} className={`${style['Task__bottom--left-container__img']}`} />
                                    <p className={`${style['Task__bottom--left-container__text']}`}>{`${lang.deliverflow_private_order_view_order_view_to_accept.accept_or_decline.near_the_area} ${task.city ? `${task.city}, ` : ''}${task.country ? `${task.country}` : ''}`}</p>
                                </div>
                            )}
                        </div>
                        <div className={`${style['Task__bottom--right']}`}>
                            <p className={`${style['Task__bottom--right-text']}`}>{constants.translateStatus(task.status)}</p>
                            {
                                task.status == 'Publicly completed'
                                ? <div className={`${style['Task__bottom--right-status']} ${style['green']}`}/>
                                : task.status == 'Privately completed'
                                ? <div className={`${style['Task__bottom--right-status']} ${style['green']}`}/>
                                : task.status == 'Accepted'
                                ? <div className={`${style['Task__bottom--right-status']} ${style['green']}`}/>
                                : task.status == 'Requested'
                                ? <div className={`${style['Task__bottom--right-status']} ${style['yellow']}`}/>
                                : task.status == 'Cancelled'
                                ? <div className={`${style['Task__bottom--right-status']} ${style['yellow']}`}/>
                                : task.status == 'Failed'
                                ? <div className={`${style['Task__bottom--right-status']} ${style['primred']}`}/>
                                : task.status == 'Solved'
                                ? <div className={`${style['Task__bottom--right-status']} ${style['blue']}`}/>
                                : task.status == 'Transfer registered'
                                ? <div className={`${style['Task__bottom--right-status']} ${style['green']}`}/>
                                : task.status == 'Frozen'
                                ? <div className={`${style['Task__bottom--right-status']} ${style['primred']}`}/>
                                : <div className={`${style['Task__bottom--right-status']} ${style['primred']}`}/>
                            }
                        </div>
                    </div>
                </div>
            )
        }
        return items
    }

    render(){
        return(
            <Fragment>
                <DeliverSidebar
                    handlerOpenRegister={this.handlerOpenRegisterSidebar}
                    handler={this.handler}
                    isOpen={this.state.deliversidebar}
                    task={this.state.chosen_task}
                />
                <PasswordSidebar
                    handler={this.handler}
                    isOpen={this.state.passwordsidebar}
                    task={this.state.password_task}
                />
                <RegisterTransferSidebar
                    handler={this.handler}
                    isOpen={this.state.registertransfersidebar}
                    task={this.state.register_task}
                    handlerOpenRegistered={this.handlerOpenRegistered}
                />
                <TransferRegisteredSidebar
                    handler={this.handler}
                    isOpen={this.state.transferregisteredsidebar}
                    task={this.state.transfered_task}
                />
                <div className={style['MyTasks']}>
                    <div className={style['MyTasks__container']}>
                        <div className={style['MyTasks__container--headercontainer']}>
                            <h1 className={style['MyTasks__container--headercontainer-header']}>{lang.deliverflow_private_order_my_tasks.top_menu.my_tasks}</h1>
                            <div className={style['MyTasks__container--headercontainer-right']}>
                                <Link to="#" onClick={() => this.showCompleteTasks()} className={style['MyTasks__container--headercontainer-right__link']}>{this.state.show_completed_tasks ? lang.deliverflow_private_order_my_tasks.main_window.active_orders.hide_tasks : lang.deliverflow_private_order_my_tasks.main_window.active_orders.completed_tasks}</Link>
                            </div>
                        </div>
                        {this.props.context.user.complete ?
                            <Fragment>
                            {this.props.context.user.verified ?
                                <Fragment>
                                {this.test_purpose_deliverer(this.props.context.user.dafnir_purposes)
                                    ?
                                        (this.state.acceptedDeliveries.length === 0 && this.state.incoming.length === 0 && !this.state.show_completed_tasks)
                                            ?
                                                <div className={`${style['MyTasks__container--empty']}`}>
                                                    <img src={flyWallet} alt="Wallet" className={`${style['MyTasks__container--empty-illustration']}`}/>
                                                    <p className={`${style['MyTasks__container--empty-text']}`}>{lang.deliverflow_private_order_my_tasks.main_window.active_orders.no_tasks}</p>
                                                    <input type="button" className={style['MyTasks__container--empty-button']} value={lang.deliverflow_private_order_my_tasks.main_window.active_orders.find_tasks} onClick={e => this.props.history.push('/orders/public')}/>
                                                    <div className={`${style['MyTasks__container--empty-linkcontainer']}`}>
                                                        <Link to="#" onClick={() => this.showCompleteTasks()} className={`${style['MyTasks__container--empty-linkcontainer__link']}`}>{lang.deliverflow_private_order_my_tasks.main_window.active_orders.completed_tasks}</Link>
                                                    </div>
                                                {/* Recruit a friend here */}
                                                </div>
                                            :
                                                <Fragment>
                                                    {this.state.show_completed_tasks && (
                                                        <Fragment>
                                                            <h2 className={`${style['MyTasks__container--subheader']}`}>{lang.deliverflow_private_order_my_tasks.main_window.incoming_orders.completed}</h2>
                                                            {this.makeTasks(this.state.complete_tasks)}
                                                        </Fragment>
                                                    )}
                                                    {this.state.acceptedDeliveries.length > 0 && (
                                                        <Fragment>
                                                            <h2 className={`${style['MyTasks__container--subheader']}`}>{lang.deliverflow_private_order_my_tasks.main_window.incoming_orders.accepted}</h2>
                                                            {this.makeTasks(this.state.acceptedDeliveries)}
                                                        </Fragment>
                                                    )}
                                                    {this.state.incoming.length > 0 && (
                                                        <Fragment>
                                                            <h2 className={`${style['MyTasks__container--subheader']}`}>{lang.deliverflow_private_order_my_tasks.main_window.incoming_orders.subheader}</h2>
                                                            {this.makeTasks(this.state.incoming)}
                                                        </Fragment>
                                                    )}
                                                </Fragment>
                                    : <center><h2><i>Please set your purpose as a recipient in the account settings</i></h2></center>
                                }
                                </Fragment>
                            : <center><h2><i>Please wait for your profile to be verified</i></h2></center>
                        }
                            </Fragment>
                    : <center><h2><i>Please complete your profile</i></h2></center>
                    }

                    </div>
                </div>
            </Fragment>
        )
    }
}

export default React.forwardRef((props, ref) => (
  <UserContext.Consumer>
    {user => <MyTasks {...props} context={user} ref={ref} />}
  </UserContext.Consumer>
));