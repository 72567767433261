import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './App';
import { Provider } from 'mobx-react';
import reportWebVitals from './reportWebVitals';
import registerServiceWorker from './registerServiceWorker';
import './main.module.scss';


// Stores
import orderStore from 'stores/OrderStore';
import basicKYCStore from 'stores/BasicKYCStore';
import extendedKYCStore from 'stores/ExtendedKYCStore';

const stores = {
    orderStore: orderStore,
    basicKYCStore: basicKYCStore,
    extendedKYCStore: extendedKYCStore
}


ReactDOM.render(
  <Provider {...stores}>
      <App />
    </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

registerServiceWorker();
reportWebVitals();