import React, { Component } from 'react';
import style from './Slide4.module.scss';
import dai from 'img/icons/Dai/Logos/PNGs/Mark_Dai.png';
import chooserecipient from './img/choose-recipient.png';
import chooseagent from './img/choose-agent.png';
import agreetime from './img/time-and-place.png';
import getcash from './img/get-the-cash.png';
import { constants } from 'consts/utils'
var lang = constants.getTranslations

export default class extends Component {
    render(){
        return(
            <div className={style['FourthSlide']}>
                <div className={style['FourthSlide__container']}>
                    <p className={style['FourthSlide__container--header']}>{lang.orderflow_customer_landingpage.slide_3_how_it_works.header}</p>
                    <div className={style['FourthSlide__container--centercontainer']}>
                        <div className={style['FourthSlide__container--centercontainer-itemcontainer']}>
                            <div className={style['FourthSlide__container--centercontainer-itemcontainer__item--iconcontainer']}>
                                <img className={style['FourthSlide__container--centercontainer-itemcontainer__item--iconcontainer-icon']} src={chooserecipient} alt='Choose recipient' />
                            </div>
                            <div className={style['FourthSlide__container--centercontainer-itemcontainer__item--textcontainer']}>
                                <div className={style['FourthSlide__container--centercontainer-itemcontainer__item--textcontainer-headercontainer']}>
                                    <div className={style['FourthSlide__container--centercontainer-itemcontainer__item--textcontainer-headercontainer__stepnocontainer']}>
                                        <p className={style['FourthSlide__container--centercontainer-itemcontainer__item--textcontainer-headercontainer__stepnocontainer--step']}>1</p>
                                    </div>
                                    <p className={style['FourthSlide__container--centercontainer-itemcontainer__item--textcontainer-headercontainer__header']}>{lang.agent_landingpage.slide_4_is_inflation_affecting_you.choose_recpient.header}</p>
                                </div>
                                <p className={style['FourthSlide__container--centercontainer-itemcontainer__item--textcontainer-body']}>{lang.agent_landingpage.slide_4_is_inflation_affecting_you.choose_recpient.text}</p>
                            </div>
                        </div>
                        <div className={style['FourthSlide__container--centercontainer-itemcontainer']}>
                            <div className={style['FourthSlide__container--centercontainer-itemcontainer__item--iconcontainer']}>
                                <img className={style['FourthSlide__container--centercontainer-itemcontainer__item--iconcontainer-icon']} src={chooseagent} alt='Trust' />
                            </div>
                            <div className={style['FourthSlide__container--centercontainer-itemcontainer__item--textcontainer']}>
                                <div className={style['FourthSlide__container--centercontainer-itemcontainer__item--textcontainer-headercontainer']}>
                                    <div className={style['FourthSlide__container--centercontainer-itemcontainer__item--textcontainer-headercontainer__stepnocontainer']}>
                                        <p className={style['FourthSlide__container--centercontainer-itemcontainer__item--textcontainer-headercontainer__stepnocontainer--step']}>2</p>
                                    </div>
                                    <p className={style['FourthSlide__container--centercontainer-itemcontainer__item--textcontainer-headercontainer__header']}>{lang.agent_landingpage.slide_4_is_inflation_affecting_you.recipient_choose_agent.header}</p>
                                </div>
                                <p className={style['FourthSlide__container--centercontainer-itemcontainer__item--textcontainer-body']}>{lang.agent_landingpage.slide_4_is_inflation_affecting_you.recipient_choose_agent.text}</p>
                            </div>
                        </div>
                        <div className={style['FourthSlide__container--centercontainer-itemcontainer']}>
                            <div className={style['FourthSlide__container--centercontainer-itemcontainer__item--iconcontainer']}>
                                <img className={style['FourthSlide__container--centercontainer-itemcontainer__item--iconcontainer-icon']} src={agreetime} alt='Security' />
                            </div>
                            <div className={style['FourthSlide__container--centercontainer-itemcontainer__item--textcontainer']}>
                                <div className={style['FourthSlide__container--centercontainer-itemcontainer__item--textcontainer-headercontainer']}>
                                    <div className={style['FourthSlide__container--centercontainer-itemcontainer__item--textcontainer-headercontainer__stepnocontainer']}>
                                        <p className={style['FourthSlide__container--centercontainer-itemcontainer__item--textcontainer-headercontainer__stepnocontainer--step']}>3</p>
                                    </div>
                                    <p className={style['FourthSlide__container--centercontainer-itemcontainer__item--textcontainer-headercontainer__header']}>{lang.agent_landingpage.slide_4_is_inflation_affecting_you.agree_time_and_place.header}</p>
                                </div>
                                <p className={style['FourthSlide__container--centercontainer-itemcontainer__item--textcontainer-body']}>{lang.agent_landingpage.slide_4_is_inflation_affecting_you.agree_time_and_place.text}</p>
                            </div>
                        </div>
                        <div className={style['FourthSlide__container--centercontainer-itemcontainer']}>
                            <div className={style['FourthSlide__container--centercontainer-itemcontainer__item--iconcontainer']}>
                                <img className={style['FourthSlide__container--centercontainer-itemcontainer__item--iconcontainer-icon']} src={getcash} alt='Privacy' />
                            </div>
                            <div className={style['FourthSlide__container--centercontainer-itemcontainer__item--textcontainer']}>
                                <div className={style['FourthSlide__container--centercontainer-itemcontainer__item--textcontainer-headercontainer']}>
                                    <div className={style['FourthSlide__container--centercontainer-itemcontainer__item--textcontainer-headercontainer__stepnocontainer']}>
                                        <p className={style['FourthSlide__container--centercontainer-itemcontainer__item--textcontainer-headercontainer__stepnocontainer--step']}>4</p>
                                    </div>
                                    <p className={style['FourthSlide__container--centercontainer-itemcontainer__item--textcontainer-headercontainer__header']}>{lang.agent_landingpage.slide_4_is_inflation_affecting_you.get_cash.header}</p>
                                </div>
                                <p className={style['FourthSlide__container--centercontainer-itemcontainer__item--textcontainer-body']}>{lang.agent_landingpage.slide_4_is_inflation_affecting_you.get_cash.text}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}