import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import iconGuarantee from 'img/icons/Icon-guarantee.png'
import ssn from '../../../img/ssn.png'
import style from '../../../Onboarding.module.scss'
import { inject, observer } from 'mobx-react';
import axios from 'axios';
import { constants } from 'consts/utils';
var lang = constants.getTranslations

@inject('extendedKYCStore')
@observer
class Step3 extends Component{

    constructor(props){
        super(props)
    }

    next = (e) => {
        e.preventDefault()
        if(!this.props.extendedKYCStore.ssn){
            alert("Please enter your social security number")
        }
        if(!this.props.extendedKYCStore.nationality){
            alert("Please enter your nationality")
        }
        this.props.extendedKYCStore.step = 4
    }

    render(){
        return(
            <Fragment>
                <div className={style['Onboarding']}>
                    <form>
                        <div className={style['Onboarding__container']}>
                            <p className={style['Onboarding__container--header3']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.thanks}</p>
                            <p className={style['Onboarding__container--subheader']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.confirm}</p>
                            <div className={style['Onboarding__container--flexcontainer']} style={{width: '522px', margin: 'auto', maxWidth: '100%'}}>
                                <img src={ssn} alt="Social Security" style={{marginRight: '20px'}}/>
                                <div style={{maxWidth: '350px'}}>
                                    <div className={style['Onboarding__container--inputcontainer']}>
                                        <p className={style['Onboarding__container--inputcontainer-label']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.ssn}</p>
                                        <input placeholder={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.ssn_filler} className={style['Onboarding__container--inputcontainer-input']} type="text" value={this.props.extendedKYCStore.ssn} onChange={(e) => this.props.extendedKYCStore.ssn = e.target.value}/>
                                    </div>
                                    <div className={style['Onboarding__container--inputcontainer']}>
                                        <p className={style['Onboarding__container--inputcontainer-label']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.nationality}</p>
                                        <input placeholder={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.nationality_filler} className={style['Onboarding__container--inputcontainer-input']} type="text" value={this.props.extendedKYCStore.nationality} onChange={(e) => this.props.extendedKYCStore.nationality = e.target.value}/>
                                    </div>
                                </div>
                            </div>
                            <div className={style['Onboarding__container--buttoncontainer']}>
                                <input type="submit" onClick={(e) => this.next(e)} className={style['Onboarding__container--buttoncontainer-button']} value={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.continue}/>
                                <Link to="#" onClick={() => this.props.extendedKYCStore.step = 2} className={style['Onboarding__container--buttoncontainer-link']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.previous}</Link>
                            </div>
                            <div className={style['Onboarding__container--textcontainer']}>
                                <img src={iconGuarantee} className={style['Onboarding__container--textcontainer-icon']} alt="Secure" />
                                <p className={style['Onboarding__container--textcontainer-text']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.storage}</p>
                            </div>
                        </div>
                    </form>
                </div>
            </Fragment>
        )
    }
}
export default Step3