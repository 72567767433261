import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import style from './Slide2.module.scss';
import daidollar from 'img/icons/dai-dollar.png';
import nobanks from './img/Icon-no-banks.png'
import avoid from './img/Icon-avoid-inflation.png'
import trust from './img/Icon-trust1.png'
import trust2 from './img/Icon-trust2.png'
import privacy from './img/Icon-privacy.png'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DaiEqualsDollarSidebar } from 'components/Sidebars/Sidebars';
import { constants } from 'consts/utils'
var lang = constants.getTranslations

export default class extends Component {
    constructor(){
        super();
        this.state = {
            DaiEqualsDollarSidebar: false

        }
    }

    handler = () => {
        this.setState({DaiEqualsDollarSidebar: false})
    }
	render(){
		return(
            <Fragment>
            <DaiEqualsDollarSidebar handler={this.handler} isOpen={this.state.DaiEqualsDollarSidebar}/>
            <div className={style['SecondSlide']}>
                <Link to="#" onClick={() => this.setState({DaiEqualsDollarSidebar: true})}>
                    <div className={style['SecondSlide__learncontainer']}>
                        <div className={style['SecondSlide__learncontainer--box']}>
                            <div className={style['SecondSlide__learncontainer--box-left']}>
                                <div className={style['SecondSlide__learncontainer--box-left__imagecontainer']}>
                                    <img className={style['SecondSlide__learncontainer--box-left__imagecontainer--img']} src={daidollar}/>
                                </div>
                            </div>
                            <div className={style['SecondSlide__learncontainer--box-right']}>
                                <div className={style['SecondSlide__learncontainer--box-right__content']}>
                                    <p className={style['SecondSlide__learncontainer--box-right__content--learn']}>{lang.agent_landingpage.get_help.get_help_red_text}</p>
                                    <p className={style['SecondSlide__learncontainer--box-right__content--header']}>{lang.agent_landingpage.get_help.subheader}</p>
                                    <p className={style['SecondSlide__learncontainer--box-right__content--body']}>{lang.agent_landingpage.get_help.text_under_subheader}</p>
                                </div>
                                <div className={style['SecondSlide__learncontainer--box-right__arrowcontainer']}>
                                    <FontAwesomeIcon icon={faArrowRight} className={style['SecondSlide__learncontainer--box-right__arrowcontainer--arrow']} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
                <div className={style['SecondSlide__container']}>
                    <div className={style['SecondSlide__container--itemcontainer']}>
                        <div className={style['SecondSlide__container--itemcontainer-item']}>
                            <div className={style['SecondSlide__container--itemcontainer-item__iconcontainer']}>
                                <img className={style['SecondSlide__container--itemcontainer-item__iconcontainer--icon']} src={avoid} alt='Avoid local inflation' />
                            </div>
                            <div className={style['SecondSlide__container--itemcontainer-item__textcontainer']}>
                                <p className={style['SecondSlide__container--itemcontainer-item__textcontainer--header']}>{lang.agent_landingpage.slide_2_overview_of_valueprop.no_local_inflation.header}</p>
                                <p className={style['SecondSlide__container--itemcontainer-item__textcontainer--body']}>{lang.agent_landingpage.slide_2_overview_of_valueprop.no_local_inflation.text}</p>
                            </div>
                        </div>
                        <div className={style['SecondSlide__container--itemcontainer-item']}>
                            <div className={style['SecondSlide__container--itemcontainer-item__iconcontainer']}>
                                <img className={style['SecondSlide__container--itemcontainer-item__iconcontainer--icon']} src={nobanks} alt='No banks needed' />
                            </div>
                            <div className={style['SecondSlide__container--itemcontainer-item__textcontainer']}>
                                <p className={style['SecondSlide__container--itemcontainer-item__textcontainer--header']}>{lang.agent_landingpage.slide_2_overview_of_valueprop.no_bank_account.header}</p>
                                <p className={style['SecondSlide__container--itemcontainer-item__textcontainer--body']}>{lang.agent_landingpage.slide_2_overview_of_valueprop.no_bank_account.text}</p>
                            </div>
                        </div>
                        <div className={style['SecondSlide__container--itemcontainer-item']}>
                            <div className={style['SecondSlide__container--itemcontainer-item__iconcontainer']}>
                                <img className={style['SecondSlide__container--itemcontainer-item__iconcontainer--icon']} src={trust} alt='Trust' />
                                <img className={style['SecondSlide__container--itemcontainer-item__iconcontainer--icon']} src={trust2} alt='Trust' />
                            </div>
                            <div className={style['SecondSlide__container--itemcontainer-item__textcontainer']}>
                                <p className={style['SecondSlide__container--itemcontainer-item__textcontainer--header']}>{lang.orderflow_customer_landingpage.slide_2_overview_of_valueprop.trust.header}</p>
                                <p className={style['SecondSlide__container--itemcontainer-item__textcontainer--body']}>{lang.orderflow_customer_landingpage.slide_2_overview_of_valueprop.trust.text}</p>
                            </div>
                        </div>
                        <div className={style['SecondSlide__container--itemcontainer-item']}>
                            <div className={style['SecondSlide__container--itemcontainer-item__iconcontainer']}>
                                <img className={style['SecondSlide__container--itemcontainer-item__iconcontainer--icon']} src={privacy} alt='Security' />
                            </div>
                            <div className={style['SecondSlide__container--itemcontainer-item__textcontainer']}>
                                <p className={style['SecondSlide__container--itemcontainer-item__textcontainer--header']}>{lang.orderflow_customer_landingpage.slide_2_overview_of_valueprop.privacy.header}</p>
                                <p className={style['SecondSlide__container--itemcontainer-item__textcontainer--body']}>{lang.orderflow_customer_landingpage.slide_2_overview_of_valueprop.privacy.text}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
		)
	}
}