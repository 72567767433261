import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AdminNav from '../AdminNav/AdminNav';
import BN from 'bignumber.js';
import style from '../../Admin.module.scss';
import ReactFlagsSelect from 'react-flags-select'
import { constants } from 'consts/utils';
import Checkbox from 'components/Forms/Checkbox/Checkbox.js';
import dai from 'img/icons/Dai/Logos/PNGs/Mark_Dai.png';
import transfer from 'img/icons/icon-transfer.png';
import defaultprofile from 'img/icons/defaultprofile.png';
import iconPhone from 'img/icons/icon-phone.png';
import iconBank from 'img/icons/icon-bankaccount-black.png';
import iconMail from 'img/icons/icon-mail.png';
import iconZoom from 'img/icons/icon-zoom.png'

class Orders extends Component {
    constructor(props){
        super(props)
        this.state = {
            orders: [],
            // activemenuitem: 'all',
            search: props.match.params.search || '',
            filter_active: true,
            filter_failed: true,
            filter_cancelled: true,
            filter_completed: true,
            filter_frozen: true,
            from: '',
            to: '',
            lower: '',
            higher: '',
            fromcountry: '',
            tocountry: '',
            refund_status: false,
            release_status: false,
            freeze_status: false,
        }
    }
    componentDidMount(){
        axios.get('/api/admin/orders')
        .then(response => {
            for (var ord of response.data){
                ord.unfolded = false
            }
            this.setState({ orders: response.data })
        })
        .catch(err => {
        })
    }

    toggleOrder = async e => {
        var copy = this.state.orders
        var order = copy[e.target.getAttribute("data-index")]
        order.unfolded = !order.unfolded
        this.setState({orders: copy})
    }

    isValidDate = (d) => {
      return d instanceof Date && !isNaN(d);
    }

    setDate = (strdate, state) => {
        var x = new Date(strdate)
        if(!this.isValidDate(x)){
            alert("Not a valid date")
            return strdate
        } else{
            this.setState({[state]: strdate})
        }
    }

    freeze = (id) => {
        this.setState({
            freeze_status: true
        })
        axios.post('/api/admin/freeze/', {id: id})
        .then(resp => {
            window.location.reload();
        }).catch(err => {
            alert(err.response.data.message);
        }).finally(() => {
            this.setState({
                freeze_status: false
            })
        })
    }

    release = (id) => {
        this.setState({
            release_status: true
        })
        axios.post('/api/admin/release/', {id: id})
        .then(resp => {
            window.location.reload();
        }).catch(err => {
            alert(err.response.data.message);
        }).finally(() => {
            this.setState({
                freeze_status: false
            })
        })
    }

    refund = (id) => {
        this.setState({
            refund_status: true
        })
        axios.post('/api/admin/return/', {id: id})
        .then(resp => {
            window.location.reload();
        }).catch(err => {
            alert(err.response.data.message);
        }).finally(() => {
            this.setState({
                refund_status: false
            })
        })
    }

    MakeOrders = () => {
        var items = []

        var orderCount = 0
        for(var i = 0; i < this.state.orders.length; i++){
            const order = this.state.orders[i]

            // Filters
/*            if(this.state.activemenuitem === 'failed'){
                if(order.status !== 'Failed'){
                    continue
                }
            }

            if(this.state.activemenuitem === 'cancelled'){
                if(order.status !== 'Cancelled'){
                    continue
                }
            }

            if(this.state.activemenuitem === 'completed'){
                if(order.status !== 'Publicly completed' && order.status !== 'Privately completed'){
                    continue
            }
            }*/

            if(
                (this.state.filter_active && ((order.status === 'Publicly available') || (order.status === 'Privately available') || (order.status === 'Publicly taken') || (order.status === 'Privately taken') || (order.status === 'Publicly registered') || (order.status === 'Privately registered') || (order.status === 'Missing info'))) ||
                (this.state.filter_failed && order.status === 'Failed') ||
                (this.state.filter_cancelled && (order.status === 'Cancelled' || order.status === 'Refunded')) ||
                (this.state.filter_completed && (order.status === 'Privately completed' || order.status === 'Publicly completed' || order.status === 'Solved')) ||
                (this.state.filter_frozen && (order.status === 'Frozen'))
                ){
            } else{
                continue
            }


            // Date filter
            if(this.state.from){
                var order_created = new Date(order.createdAt)
                var search_created = new Date(this.state.from)
                if(order_created <= search_created.getTime()){
                    continue
                }
            }

            if(this.state.to){
                var order_created = new Date(order.createdAt)
                var search_created = new Date(this.state.to)
                if(order_created > search_created.getTime()){
                    continue
                }
            }



            // Value filters
            const deposited = new BN(order.send_amount["$numberDecimal"])
            if(this.state.lower){
                if(new BN(this.state.lower).gt(deposited)){
                    continue
                }
            }

            if(this.state.higher){
                if(new BN(this.state.higher).lt(deposited)){
                    continue
                }
            }



            // Country filters
            if(this.state.fromcountry){
                if(order.sender.country !== this.state.fromcountry){
                    continue
                }
            }
            if(this.state.tocountry){
                if(order.recipient.country){
                    if(order.recipient.country !== this.state.tocountry){
                        continue
                    }
                }
            }


            // Search filters
            if(this.state.search){
                const search = this.state.search.toLowerCase()
                var found = false
                var ks = Object.keys(order)
                for (const k of ks){
                    var val = order[k]
                    if(val){
                        if(k === 'sender' || k === 'recipient' || k === 'agent' || k === 'payment_info'){
                            var found_x = false
                            for (const k_x of Object.keys(val)){
                                var val_x = val[k_x]
                                if(val_x){
                                    if(typeof(val_x) != 'string'){
                                        val_x = val_x.toString()
                                    }
                                    val_x = val_x.toLowerCase()
                                    if(val_x.includes(search)){
                                        found = true
                                        found_x = true
                                        break
                                    }
                                }
                            }
                            if(found_x){
                                break
                            }
                        } else{
                            const type = typeof(val)
                            if(type != 'string'){
                                if(type === 'object' && Object.keys(val).includes("$numberDecimal")){
                                    val = new BN(val["$numberDecimal"]).toFixed(2)
                                } else{
                                    val = val.toString()
                                }
                            }
                            val = val.toLowerCase()
                            if(val.includes(search)){
                                found = true
                                break
                            }
                        }
                    }
                }
                if(!found){
                    continue
                }
            }

            // make order
            orderCount += 1
            const deposited_pretty = deposited.toFixed(2)
            const receive = new BN(order.receive_amount["$numberDecimal"]).toFixed(2)
            var payment = 0
            var fee = 0
            if(order.agent && order.agent.fee){
                payment = deposited.minus(deposited.multipliedBy(order.agent.fee)).toFixed(2)
                fee = deposited.multipliedBy(order.agent.fee).toFixed(2)
            }
            items.push(
                    <div key={i} className={style['orderContainer']}>
                        <Link to={`#`} data-index={i} onClick={(e) => this.toggleOrder(e)} className={`${style['orderIndex']}`}>{orderCount}.</Link>
                        {order.unfolded ?

                            /*Unfolded */
                            <div className={`${style['Uorder']}`}>
                                {/* Sender */}
                                <div className={`${style['Uorder__sender']}`}>
                                    {/* Top */}
                                    <div className={`${style['Uorder__sender--top']}`}>
                                        {/*Picture and name */}

                                        <div className={`${style['Uorder__sender--top-container']}`}>
                                            <img src={defaultprofile} className={`${style['Uorder__sender--top-container__avatar']}`} />
                                            <div className={`${style['Uorder__sender--top-container__subcontainer']}`}>
                                                <p className={`${style['Uorder__sender--top-container__subcontainer--header']}`}>{'Sender'}</p>
                                                <p className={`${style['Uorder__sender--top-container__subcontainer--text']}`}>{order.sender.name}</p>
                                            </div>
                                        </div>

                                        {/* Country */}
                                        {(order.lat && order.long)
                                            ?
                                                <div className={`${style['Uorder__sender--top-container']}`}>
                                                    <div className={`${style['Uorder__sender--top-container__subcontainer']}`}>
                                                        <p className={`${style['Uorder__sender--top-container__subcontainer--header']}`}>{'Country'}</p>
                                                        <a href={`//google.com/maps?q=${order.lat},${order.long}`} className={`${style['Uorder__sender--top-container__subcontainer--text']}`}>{order.sender.country}</a>
                                                    </div>
                                                </div>
                                            :
                                            <div className={`${style['Uorder__sender--top-container']}`}>
                                                <div className={`${style['Uorder__sender--top-container__subcontainer']}`}>
                                                    <p className={`${style['Uorder__sender--top-container__subcontainer--header']}`}>{'Country'}</p>
                                                    <p className={`${style['Uorder__sender--top-container__subcontainer--text']}`}>{order.sender.country}</p>
                                                </div>
                                            </div>
                                        }

                                        {/* Deposited */}
                                        <div className={`${style['Uorder__sender--top-container']}`}>
                                            <div className={`${style['Uorder__sender--top-container__subcontainer']}`}>
                                                <p className={`${style['Uorder__sender--top-container__subcontainer--header']}`}>{'Deposited'}</p>
                                                <div className={`${style['Uorder__sender--top-container__subcontainer--flexcontainer']}`}>
                                                    <img src={dai} className={`${style['Uorder__sender--top-container__subcontainer--flexcontainer-img']}`} />
                                                    <p className={`${style['Uorder__sender--top-container__subcontainer--flexcontainer-amount']}`}>{deposited_pretty}</p>
                                                    <p className={`${style['Uorder__sender--top-container__subcontainer--flexcontainer-currency']}`}>{`${order.send_currency}`}</p>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Link to transaction id */}
                                        <div className={`${style['Uorder__sender--top-container']}`}>
                                            <div className={`${style['Uorder__sender--top-container__subcontainer']}`}>
                                                <p className={`${style['Uorder__sender--top-container__subcontainer--header']}`}>{'TX-id of deposit'}</p>
                                                <a href={`//etherscan.io/tx/${order.xid}`} className={`${style['Uorder__sender--top-container__subcontainer--text']}`}>{'Link to TX-id'}</a>
                                            </div>
                                        </div>

                                        {/* Order id */}
                                        <div className={`${style['Uorder__sender--top-container']}`}>
                                            <div className={`${style['Uorder__sender--top-container__subcontainer']}`}>
                                                <Link to="#" data-index={i} onClick={(e) => this.toggleOrder(e)} className={`${style['Uorder__sender--top-container__subcontainer--orderid']}`}>{order.id}</Link>
                                            </div>
                                        </div>
                                    </div> {/*Sender - top*/}
                                    <div className={`${style['Uorder__sender--bottom']}`}>
                                        <div className={`${style['Uorder__sender--bottom-left']}`}>
                                            <div className={`${style['Uorder__sender--bottom-left__linkcontainer']}`}>
                                                <img src={iconMail} className={`${style['Uorder__sender--bottom-left__linkcontainer--icon1']}`}/>
                                                <a href={`mailto:${order.sender.email}`} className={`${style['Uorder__sender--bottom-left__linkcontainer--link']}`}>{order.sender.email}</a>
                                            </div>
                                            <div className={`${style['Uorder__sender--bottom-left__linkcontainer']}`}>
                                                <img src={iconPhone} className={`${style['Uorder__sender--bottom-left__linkcontainer--icon2']}`}/>
                                                <a href={`tel:+${order.sender.phone_cc}${order.sender.phone_number}`} className={`${style['Uorder__sender--bottom-left__linkcontainer--link']}`}>{`+${order.sender.phone_cc} ${order.sender.phone_number}`}</a>
                                            </div>
                                        </div> {/* Left */}
                                        {/* Right */}
                                        <div className={`${style['Uorder__sender--bottom-right']}`}>
                                            <div className={`${style['Uorder__sender--bottom-right__container']}`}>
                                                {order.status != 'Publicly completed' && order.status != 'Privately completed' && order.status != 'Cancelled' && order.status != 'Refunded' && (
                                                    <div className={`${style['Uorder__sender--bottom-right__container--subcontainer']}`}>
                                                        <div className={`${style['Uorder__sender--bottom-right__container--subcontainer-left']}`}>
                                                            <p className={`${style['Uorder__sender--bottom-right__container--subcontainer-left__header']}`}>Sender gets returned in Dai</p>
                                                            <p className={`${style['Uorder__sender--bottom-right__container--subcontainer-left__amount']}`}>{deposited_pretty}</p>
                                                        </div>
                                                        <div className={`${style['Uorder__sender--bottom-right__container--subcontainer-right']}`}>
                                                            <input disabled={this.state.refund_status ? "disabled" : ''} onClick={() => this.refund(order.id)} type="button" className={`${style['Uorder__sender--bottom-right__container--subcontainer-right__button']}`} value="Return" />
                                                        </div>
                                                    </div>
                                                )}
                                                <div className={`${style['Uorder__sender--bottom-right__container--walletcontainer']}`}>
                                                    <p className={`${style['Uorder__sender--bottom-right__container--walletcontainer-header']}`}>Ether wallet address</p>
                                                    <p className={`${style['Uorder__sender--bottom-right__container--walletcontainer-text']}`}>{order.sender.wallet}</p>
                                                </div>
                                            </div>
                                        </div> {/* Right */}
                                    </div>
                                </div> {/* Sender */}


                                 {/* Recipient*/}
                                 { order.status != 'Missing info' && (
                                    <div className={`${style['Uorder__recipient']}`}>
                                        <div className={`${style['Uorder__recipient--top']}`}>
                                            <div className={`${style['Uorder__recipient--top-container']}`}>
                                                <img src={defaultprofile} className={`${style['Uorder__recipient--top-container__avatar']}`} />
                                                <div className={`${style['Uorder__recipient--top-container__subcontainer']}`}>
                                                    <p className={`${style['Uorder__recipient--top-container__subcontainer--header']}`}>{'Recipient'}</p>
                                                    <p className={`${style['Uorder__recipient--top-container__subcontainer--text']}`}>{order.recipient.name}</p>
                                                </div>
                                            </div>

                                            <div className={`${style['Uorder__recipient--top-container']}`}>
                                                <div className={`${style['Uorder__recipient--top-container__subcontainer']}`}>
                                                    <p className={`${style['Uorder__recipient--top-container__subcontainer--header']}`}>{'Country'}</p>
                                                    <a href={`//google.com/maps?q=${order.lat},${order.long}`} className={`${style['Uorder__recipient--top-container__subcontainer--text']}`}>{order.recipient.country}</a>
                                                </div>
                                            </div>

                                            {order.payment_method && (
                                                <div className={`${style['Uorder__recipient--top-container']}`}>
                                                    <div className={`${style['Uorder__recipient--top-container__subcontainer']}`}>
                                                        <p className={`${style['Uorder__recipient--top-container__subcontainer--header']}`}>{'Delivery method'}</p>
                                                        <p className={`${style['Uorder__recipient--top-container__subcontainer--text']}`}>{order.payment_method}</p>
                                                    </div>
                                                </div>
                                            )}

                                            {order.payment_method && order.payment_method == 'Mobile payment' && order.payment_info && order.payment_info.mobile_application && (
                                                <div className={`${style['Uorder__recipient--top-container']}`}>
                                                    <div className={`${style['Uorder__recipient--top-container__subcontainer']}`}>
                                                        <p className={`${style['Uorder__recipient--top-container__subcontainer--header']}`}>{'Payment solution'}</p>
                                                        <p className={`${style['Uorder__recipient--top-container__subcontainer--text']}`}>{order.payment_info.mobile_application}</p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div className={`${style['Uorder__recipient--bottom']}`}>
                                            <div className={`${style['Uorder__recipient--bottom-left']}`}>
                                                {(order.agent && order.recipient.agentmessage) && (
                                                    <div className={`${style['Uorder__recipient--bottom-left__message']}`}>
                                                        <p className={`${style['Uorder__recipient--bottom-left__message--text']}`}>{order.recipient.agentmessage}</p>
                                                    </div>
                                                )}
                                                <div className={`${style['Uorder__recipient--bottom-left__linkcontainer']}`}>
                                                    <img src={iconMail} className={`${style['Uorder__recipient--bottom-left__linkcontainer--icon1']}`}/>
                                                    <a href={`mailto:${order.recipient.email}`} className={`${style['Uorder__recipient--bottom-left__linkcontainer--link']}`}>{order.recipient.email}</a>
                                                </div>
                                                <div className={`${style['Uorder__recipient--bottom-left__linkcontainer']}`}>
                                                    <img src={iconPhone} className={`${style['Uorder__recipient--bottom-left__linkcontainer--icon2']}`}/>
                                                    <a href={`tel:+${order.recipient.phone_cc}${order.recipient.phone_number}`} className={`${style['Uorder__recipient--bottom-left__linkcontainer--link']}`}>{`+${order.recipient.phone_cc} ${order.recipient.phone_number}`}</a>
                                                </div>
                                            </div>
                                            <div className={`${style['Uorder__recipient--bottom-right']}`}>

                                                {/*Status: Registered*/}
                                                { (order.status === 'Publicly registered' || order.status === 'Privately registered') && order.payment_method === 'Mobile payment' && order.payment_info && (
                                                    <div className={`${style['Uorder__recipient--bottom-right__transfer']}`}>
                                                        <p className={`${style['Uorder__recipient--bottom-right__transfer--header']}`}>Transfer money to</p>
                                                        <div className={`${style['Uorder__recipient--bottom-right__transfer--flexcontainer']}`}>
                                                            <div className={`${style['Uorder__recipient--bottom-right__transfer--flexcontainer-iconcontainer']}`}>
                                                                <img src={iconPhone} alt="Phone" className={`${style['Uorder__recipient--bottom-right__transfer--flexcontainer-iconcontainer__icon']}`}/>
                                                            </div>
                                                            <div className={`${style['Uorder__recipient--bottom-right__transfer--flexcontainer-textcontainer']}`}>
                                                                <p className={`${style['Uorder__recipient--bottom-right__transfer--flexcontainer-textcontainer__text']}`}>{order.payment_info.mobile_identifier}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                { (order.status === 'Publicly registered' || order.status === 'Privately registered') && order.payment_method === 'Bank transfer' && (
                                                    <div className={`${style['Uorder__recipient--bottom-right__transfer']}`}>
                                                        <p className={`${style['Uorder__recipient--bottom-right__transfer--header']}`}>Transfer money to</p>
                                                        <div className={`${style['Uorder__recipient--bottom-right__transfer--flexcontainer']}`}>
                                                            <div className={`${style['Uorder__recipient--bottom-right__transfer--flexcontainer-iconcontainer']}`}>
                                                                <img src={iconBank} alt="Bank" className={`${style['Uorder__recipient--bottom-right__transfer--flexcontainer-iconcontainer__icon']}`}/>
                                                            </div>
                                                            <div className={`${style['Uorder__recipient--bottom-right__transfer--flexcontainer-textcontainer']}`}>
                                                                <p className={`${style['Uorder__recipient--bottom-right__transfer--flexcontainer-textcontainer__text']}`}>{order.payment_info.bankcode}</p>
                                                                <p className={`${style['Uorder__recipient--bottom-right__transfer--flexcontainer-textcontainer__text']}`}>{order.payment_info.accountnum}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                 )}

                                {/* Agent */}
                                {order.agent && (
                                    <div className={`${style['Uorder__agent']}`}>
                                        <div className={`${style['Uorder__agent--top']}`}>
                                            <div className={`${style['Uorder__agent--top-container']}`}>
                                                <img src={defaultprofile} className={`${style['Uorder__agent--top-container__avatar']}`} />
                                                <div className={`${style['Uorder__agent--top-container__subcontainer']}`}>
                                                    <p className={`${style['Uorder__agent--top-container__subcontainer--header']}`}>{'Agent'}</p>
                                                    <p className={`${style['Uorder__agent--top-container__subcontainer--text']}`}>{order.agent.name}</p>
                                                </div>
                                            </div>


                                            <div className={`${style['Uorder__agent--top-container']}`}>
                                                <div className={`${style['Uorder__agent--top-container__subcontainer']}`}>
                                                    <p className={`${style['Uorder__agent--top-container__subcontainer--header']}`}>{'Country'}</p>
                                                    <p className={`${style['Uorder__agent--top-container__subcontainer--text']}`}>{order.agent.country}</p>
                                                </div>
                                            </div>


                                            <div className={`${style['Uorder__agent--top-container']}`}>
                                                <div className={`${style['Uorder__agent--top-container__subcontainer']}`}>
                                                    <p className={`${style['Uorder__agent--top-container__subcontainer--header']}`}>{'Money to deliver'}</p>
                                                    <div className={`${style['Uorder__agent--top-container__subcontainer--flexcontainer']}`}>
                                                        <ReactFlagsSelect
                                                            className={'inline-flag'}
                                                            selected={constants.shorthand_curr_to_flag[order.receive_currency]}
                                                            showSelectedLabel={false}
                                                            placeholder=""
                                                            disabled={true}
                                                            selectedSize={14}
                                                            optionsSize={20}
                                                        />
                                                        <p className={`${style['Uorder__agent--top-container__subcontainer--flexcontainer-amount']}`}>{receive}</p>
                                                        <p className={`${style['Uorder__agent--top-container__subcontainer--flexcontainer-currency']}`}>{`${order.receive_currency}`}</p>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className={`${style['Uorder__agent--top-container']}`}>
                                                <div className={`${style['Uorder__agent--top-container__subcontainer']}`}>
                                                    <p className={`${style['Uorder__agent--top-container__subcontainer--header']}`}>{`Fee (${order.agent.fee*100}%)`}</p>
                                                    <div className={`${style['Uorder__agent--top-container__subcontainer--flexcontainer']}`}>
                                                        <img src={dai} className={`${style['Uorder__agent--top-container__subcontainer--flexcontainer-img']}`} />
                                                        <p className={`${style['Uorder__agent--top-container__subcontainer--flexcontainer-amount']}`}>{fee}</p>
                                                        <p className={`${style['Uorder__agent--top-container__subcontainer--flexcontainer-currency']}`}>{`${order.send_currency}`}</p>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className={`${style['Uorder__agent--top-container']}`}>
                                                <div className={`${style['Uorder__agent--top-container__subcontainer']}`}>
                                                    <p className={`${style['Uorder__agent--top-container__subcontainer--header']}`}>{'Receive'}</p>
                                                    <div className={`${style['Uorder__agent--top-container__subcontainer--flexcontainer']}`}>
                                                        <img src={dai} className={`${style['Uorder__agent--top-container__subcontainer--flexcontainer-img']}`} />
                                                        <p className={`${style['Uorder__agent--top-container__subcontainer--flexcontainer-amount']}`}>{payment}</p>
                                                        <p className={`${style['Uorder__agent--top-container__subcontainer--flexcontainer-currency']}`}>{`${order.send_currency}`}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${style['Uorder__agent--bottom']}`}>
                                            <div className={`${style['Uorder__agent--bottom-left']}`}>
                                                <div className={`${style['Uorder__agent--bottom-left__linkcontainer']}`}>
                                                    <img src={iconMail} className={`${style['Uorder__agent--bottom-left__linkcontainer--icon1']}`}/>
                                                    <a href={`mailto:${order.agent.email}`} className={`${style['Uorder__agent--bottom-left__linkcontainer--link']}`}>{order.agent.email}</a>
                                                </div>
                                                <div className={`${style['Uorder__agent--bottom-left__linkcontainer']}`}>
                                                    <img src={iconPhone} className={`${style['Uorder__agent--bottom-left__linkcontainer--icon2']}`}/>
                                                    <a href={`tel:+${order.agent.phone_cc}${order.agent.phone_number}`} className={`${style['Uorder__agent--bottom-left__linkcontainer--link']}`}>{`+${order.agent.phone_cc} ${order.agent.phone_number}`}</a>
                                                </div>
                                                <div className={`${style['Uorder__agent--bottom-left__profiletextcontainer']}`}>
                                                    <p className={`${style['Uorder__agent--bottom-left__profiletextcontainer--header']}`}>Profile text</p>
                                                    <p className={`${style['Uorder__agent--bottom-left__profiletextcontainer--text']}`}>{order.agent.profiletext}</p>
                                                </div>
                                            </div>
                                            <div className={`${style['Uorder__agent--bottom-right']}`}>
                                                <div className={`${style['Uorder__sender--bottom-right__container']}`}>
                                                    {order.status != 'Publicly completed' && order.status != 'Privately completed' && order.status != 'Refunded' && order.status != 'Cancelled' &&  (
                                                        <div className={`${style['Uorder__sender--bottom-right__container--subcontainer']}`}>
                                                            <div className={`${style['Uorder__sender--bottom-right__container--subcontainer-left']}`}>
                                                                <p className={`${style['Uorder__sender--bottom-right__container--subcontainer-left__header']}`}>Recipient gets paid Dai</p>
                                                                <p className={`${style['Uorder__sender--bottom-right__container--subcontainer-left__amount']}`}>{payment}</p>
                                                            </div>
                                                            <div className={`${style['Uorder__sender--bottom-right__container--subcontainer-right']}`}>
                                                                <input disabled={this.state.release_status ? "disabled" : ''} onClick={() => this.release(order.id)} type="button" className={`${style['Uorder__sender--bottom-right__container--subcontainer-right__button']}`} value="Release" />
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className={`${style['Uorder__sender--bottom-right__container--walletcontainer']}`}>
                                                        <p className={`${style['Uorder__sender--bottom-right__container--walletcontainer-header']}`}>Ether wallet address</p>
                                                        <p className={`${style['Uorder__sender--bottom-right__container--walletcontainer-text']}`}>{order.agent.wallet}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}


                                <div className={`${style['Uorder__timeline']}`}>
                                    <div className={`${style['Uorder__timeline--bottom']}`}>
                                        <div className={`${style['Uorder__timeline--bottom-left']}`}>
                                        </div>
                                        <div className={`${style['Uorder__timeline--bottom-right']}`}>
                                            {order.status != 'Publicly completed' && order.status != 'Privately completed' && order.status != 'Cancelled' && order.status != 'Frozen' && order.status != 'Refunded' && (
                                                <div className={`${style['Uorder__timeline--bottom-right__container']}`}>
                                                    <input disabled={this.state.freeze_status ? "disabled" : ''} onClick={() => this.freeze(order.id)} type="button" className={`${style['Uorder__timeline--bottom-right__container--button']}`} value="Freeze" />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        :

                            // Folded
                            <div className={`${style['Order']}`}>
                                <div className={`${style['Order__top']}`}>
                                    {/* Left */}
                                    <div className={`${style['Order__top--left']}`}>

                                        {/* Sender */}
                                        <div className={`${style['Order__top--left-recipient']}`}>
                                            <img src={defaultprofile} className={`${style['Order__top--left-recipient__avatar']}`} />
                                            <div className={`${style['Order__top--left-recipient__container']}`}>
                                                <p className={`${style['Order__top--left-recipient__container--header']}`}>{'Sender'}</p>
                                                <p className={`${style['Order__top--left-recipient__container--text']}`}>{order.sender.name}</p>
                                                <a href={`mailto:${order.sender.email}`} className={`${style['Order__top--left-recipient__container--email']}`}>{order.sender.email}</a>
                                            </div>
                                        </div>

                                        {/* Recipient */}
                                        <div className={`${style['Order__top--left-recipient']}`}>
                                            <img src={order.avatar ? `/api/order/avatar/${order.avatar}` : defaultprofile} className={`${style['Order__top--left-recipient__avatar']}`} />
                                            <div className={`${style['Order__top--left-recipient__container']}`}>
                                                <p className={`${style['Order__top--left-recipient__container--header']}`}>{'Recipient'}</p>
                                                <p className={`${style['Order__top--left-recipient__container--text']}`}>{order.recipient.name}</p>
                                                <a href={`mailto:${order.recipient.email}`} className={`${style['Order__top--left-recipient__container--email']}`}>{order.recipient.email}</a>
                                            </div>
                                        </div>

                                        {/* Agent */}
                                        {order.agent && (
                                            <div className={`${style['Order__top--left-recipient']}`}>
                                                <img src={defaultprofile} className={`${style['Order__top--left-recipient__avatar']}`} />
                                                <div className={`${style['Order__top--left-recipient__container']}`}>
                                                    <p className={`${style['Order__top--left-recipient__container--header']}`}>{'Agent'}</p>
                                                    <p className={`${style['Order__top--left-recipient__container--text']}`}>{order.agent.name}</p>
                                                    <a href={`mailto:${order.agentemail}`} className={`${style['Order__top--left-recipient__container--email']}`}>{order.agent.email}</a>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {/* Right */}
                                    <div className={`${style['Order__top--right']}`}>
                                        {/* Order id */}
                                        <Link to="#" data-index={i} onClick={(e) => this.toggleOrder(e)} className={`${style['Order__top--right-orderid']}`}>{order.id}</Link>
                                    </div>
                                </div>

                                {/* Bottom */}

                                <div className={`${style['Order__bottom']}`}>
                                    {/* Left */}
                                    <div className={`${style['Order__bottom--left']}`}>
                                        {/* Deposited */}
                                        <div className={`${style['Order__bottom--left-container']}`}>
                                            <p className={`${style['Order__bottom--left-container__label']}`}>{'Deposited'}</p>
                                            <div className={`${style['Order__bottom--left-container__flexcontainer']}`}>
                                                <img src={dai} className={`${style['Order__bottom--left-container__flexcontainer--img']}`} />
                                                <p className={`${style['Order__bottom--left-container__flexcontainer--amount']}`}>{deposited_pretty}</p>
                                                <p className={`${style['Order__bottom--left-container__flexcontainer--currency']}`}>{`${order.send_currency}`}</p>
                                            </div>
                                        </div>
                                        {/* To deliver */}
                                        <div className={`${style['Order__bottom--left-container']}`}>
                                            <p className={`${style['Order__bottom--left-container__label']}`}>{(order.status === 'Publicly completed' || order.status === 'Privately completed') ? 'Received' : 'Receive'}</p>
                                            <div className={`${style['Order__bottom--left-container__flexcontainer']}`}>
                                                <ReactFlagsSelect
                                                    className={'inline-flag'}
                                                    selected={constants.shorthand_curr_to_flag[order.receive_currency]}
                                                    showSelectedLabel={false}
                                                    placeholder=""
                                                    disabled={true}
                                                    selectedSize={14}
                                                    optionsSize={20}
                                                />
                                                <p className={`${style['Order__bottom--left-container__flexcontainer--amount']}`}>{receive}</p>
                                                <p className={`${style['Order__bottom--left-container__flexcontainer--currency']}`}>{`${order.receive_currency}`}</p>
                                            </div>
                                        </div>
                                        {/* Country */}
                                        {order.lat && order.long && (
                                            <div className={`${style['Order__bottom--left-container']}`}>
                                                <p className={`${style['Order__bottom--left-container__label']}`}>{'Location'}</p>
                                                <a href={`//google.com/maps?q=${order.lat},${order.long}`} className={`${style['Order__bottom--left-container__flexcontainer--location']}`}>{`${order.lat}, ${order.long}`}</a>
                                            </div>
                                        )}
                                    </div>
                                    <div className={`${style['Order__bottom--right']}`}>
                                        <div className={`${style['Order__bottom--right-container']}`}>
                                            <p className={`${style['Order__bottom--right-container__label']}`}>Status</p>
                                            <div className={`${style['Order__bottom--right-container__flexcontainer']}`}>
                                                <p className={`${style['Order__bottom--right-container__flexcontainer--text']}`}>{order.status}</p>
                                                    {
                                                    order.status == 'Privately available'
                                                    ? <div className={`${style['Order__bottom--right-status']} ${style['blue']}`}/>
                                                    : order.status == 'Publicly available'
                                                    ? <div className={`${style['Order__bottom--right-status']} ${style['blue']}`}/>
                                                    : order.status == 'Privately taken'
                                                    ? <div className={`${style['Order__bottom--right-status']} ${style['green']}`}/>
                                                    : order.status == 'Publicly taken'
                                                    ? <div className={`${style['Order__bottom--right-status']} ${style['green']}`}/>
                                                    : order.status == 'Transfer registered'
                                                    ? <div className={`${style['Order__bottom--right-status']} ${style['yellow']}`}/>
                                                    : order.status == 'Privately completed'
                                                    ? <div className={`${style['Order__bottom--right-status']} ${style['green']}`}/>
                                                    : order.status == 'Publicly completed'
                                                    ? <div className={`${style['Order__bottom--right-status']} ${style['green']}`}/>
                                                    : order.status == 'Requested'
                                                    ? <div className={`${style['Order__bottom--right-status']} ${style['yellow']}`}/>
                                                    : order.status == 'Failed'
                                                    ? <div className={`${style['Order__bottom--right-status']} ${style['primred']}`}/>
                                                    : order.status == 'Cancelled'
                                                    ? <div className={`${style['Order__bottom--right-status']} ${style['primred']}`}/>
                                                    : order.status == 'Refunded'
                                                    ? <div className={`${style['Order__bottom--right-status']} ${style['green']}`}/>
                                                    : order.status == 'Frozen'
                                                    ? <div className={`${style['Order__bottom--right-status']} ${style['primred']}`}/>
                                                    : <div className={`${style['Order__bottom--right-status']} ${style['primred']}`}/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                    </div>
            )
        }
        return items
    }


    render(){
        return(
            <Fragment>
                <AdminNav/>
                <div className={`${style['Admin']}`}>
                    <div className={`${style['Admin__container']}`}>
                        <div className={`${style['Admin__container--centercontainer']}`}>
                            <h2 className={`${style['Admin__container--header']}`}>Transactions</h2>
                            <div className={`${style['Admin__container--filters']}`}>
                                <div className={`${style['Admin__container--filters-fullflex']}`}>
                                    <div className={`${style['Admin__container--filters-fullflex']}`}>
                                        <div className={`${style['Admin__container--filters-fullflex__search']}`}>
                                            <span className={`${style['Admin__container--filters-fullflex__search--icon']}`}><img src={iconZoom}/></span>
                                            <input type="text" placeholder="Search" className={`${style['Admin__container--filters-fullflex__search--input']}`} value={this.state.search} onChange={(e) => this.setState({search: e.target.value})} />
                                        </div>
                                        <div className={`${style['Admin__container--filters-fullflex__statuscontainer']}`}>
                                            <div className={`${style['Admin__container--filters-fullflex__statuscontainer--item']}`}>
                                                <Checkbox
                                                    extendspanclass={`${style['Admin__container--filters-fullflex__statuscontainer--item-checkspan']}`}
                                                    extendinputclass={`${style['Admin__container--filters-fullflex__statuscontainer--item-checkinput']}`}
                                                    defaultChecked={this.state.filter_active}
                                                    onClick={() => this.setState({filter_active: !this.state.filter_active})}
                                                />
                                                <p className={`${style['Admin__container--filters-fullflex__statuscontainer--item-text']}`}>Active</p>
                                            </div>
                                            <div className={`${style['Admin__container--filters-fullflex__statuscontainer--item']}`}>
                                                <Checkbox
                                                    extendspanclass={`${style['Admin__container--filters-fullflex__statuscontainer--item-checkspan']}`}
                                                    extendinputclass={`${style['Admin__container--filters-fullflex__statuscontainer--item-checkinput']}`}
                                                    defaultChecked={this.state.filter_failed}
                                                    onClick={() => this.setState({filter_failed: !this.state.filter_failed})}
                                                />
                                                <p className={`${style['Admin__container--filters-fullflex__statuscontainer--item-text']}`}>Failed</p>
                                            </div>
                                            <div className={`${style['Admin__container--filters-fullflex__statuscontainer--item']}`}>
                                                <Checkbox
                                                    extendspanclass={`${style['Admin__container--filters-fullflex__statuscontainer--item-checkspan']}`}
                                                    extendinputclass={`${style['Admin__container--filters-fullflex__statuscontainer--item-checkinput']}`}
                                                    defaultChecked={this.state.filter_cancelled}
                                                    onClick={() => this.setState({filter_cancelled: !this.state.filter_cancelled})}
                                                />
                                                <p className={`${style['Admin__container--filters-fullflex__statuscontainer--item-text']}`}>Cancelled</p>
                                            </div>
                                            <div className={`${style['Admin__container--filters-fullflex__statuscontainer--item']}`}>
                                                <Checkbox
                                                    extendspanclass={`${style['Admin__container--filters-fullflex__statuscontainer--item-checkspan']}`}
                                                    extendinputclass={`${style['Admin__container--filters-fullflex__statuscontainer--item-checkinput']}`}
                                                    defaultChecked={this.state.filter_completed}
                                                    onClick={() => this.setState({filter_completed: !this.state.filter_completed})}
                                                />
                                                <p className={`${style['Admin__container--filters-fullflex__statuscontainer--item-text']}`}>Completed</p>
                                            </div>
                                            <div className={`${style['Admin__container--filters-fullflex__statuscontainer--item']}`}>
                                                <Checkbox
                                                    extendspanclass={`${style['Admin__container--filters-fullflex__statuscontainer--item-checkspan']}`}
                                                    extendinputclass={`${style['Admin__container--filters-fullflex__statuscontainer--item-checkinput']}`}
                                                    defaultChecked={this.state.filter_frozen}
                                                    onClick={() => this.setState({filter_frozen: !this.state.filter_frozen})}
                                                />
                                                <p className={`${style['Admin__container--filters-fullflex__statuscontainer--item-text']}`}>Frozen</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${style['Admin__container--filters-smallflex']}`}>
                                    <p className={`${style['Admin__container--filters-smallflex__label']}`}>From</p>
                                    <input type="date" placeholder="mm-dd-yyyy" className={`${style['Admin__container--filters-smallflex__input']}`} onChange={(e) => this.setDate(e.target.value,'from')} value={this.state.from}/>
                                    <p className={`${style['Admin__container--filters-smallflex__label2']}`}>To</p>
                                    <input type="date" placeholder="mm-dd-yyyy" className={`${style['Admin__container--filters-smallflex__input']}`} onChange={(e) => this.setDate(e.target.value, 'to')} value={this.state.to}/>
                                </div>
                                <div className={`${style['Admin__container--filters-smallflex']}`}>
                                    <p className={`${style['Admin__container--filters-smallflex__label']}`}>Between</p>
                                    <input type="number" placeholder="Lower amount" className={`${style['Admin__container--filters-smallflex__input']}`} onChange={(e) => this.setState({lower: e.target.value})} value={this.state.lower}/>
                                    <p className={`${style['Admin__container--filters-smallflex__label2']}`}>and</p>
                                    <input type="number" placeholder="Higher amount" className={`${style['Admin__container--filters-smallflex__input']}`} onChange={(e) => this.setState({higher: e.target.value})} value={this.state.higher}/>
                                    <input type="text" disabled className={`${style['Admin__container--filters-smallflex__inputdai']}`} value={'DAI'}/>
                                </div>
                                <div className={`${style['Admin__container--filters-smallflex']}`}>
                                    <p className={`${style['Admin__container--filters-smallflex__label']}`}>From</p>
                                    <select value={this.state.fromcountry} className={style['Admin__container--filters-smallflex__select']} onChange={(e) => this.setState({fromcountry: e.target.value})}>
                                        <option key={0} disabled value="">Choose country</option>
                                        {[...new Set(constants.countries.map(c => c.country))].sort(function(a,b){return a-b}).map((c,i) => <option key={i} value={c}>{`${c}`}</option>)}
                                    </select>
                                    <p className={`${style['Admin__container--filters-smallflex__label2']}`}>To</p>
                                    <select value={this.state.tocountry} className={style['Admin__container--filters-smallflex__select']} onChange={(e) => this.setState({tocountry: e.target.value})}>
                                        <option key={0} disabled value="">Choose country</option>
                                        {[...new Set(constants.countries.map(c => c.country))].sort(function(a,b){return a-b}).map((c,i) => <option key={i} value={c}>{`${c}`}</option>)}
                                    </select>
                                </div>
                            </div>
{/*                            <ul className={`${style['Admin__container--centercontainer-filtersmenu']}`}>
                                <Link to="#" className={`${style['Admin__container--centercontainer-filtersmenu__link']}`} onClick={() => this.setState({activemenuitem: 'all'})}><li className={`${this.state.activemenuitem === 'all' && style['activetransaction']}`}>Transactions</li></Link>
                                <Link to="#" className={`${style['Admin__container--centercontainer-filtersmenu__link']}`} onClick={() => this.setState({activemenuitem: 'failed'})}><li className={`${this.state.activemenuitem === 'failed' && style['activetransaction']}`}>Show failed transactions</li></Link>
                                <Link to="#" className={`${style['Admin__container--centercontainer-filtersmenu__link']}`} onClick={() => this.setState({activemenuitem: 'cancelled'})}><li className={`${this.state.activemenuitem === 'cancelled' && style['activetransaction']}`}>Show cancelled transactions</li></Link>
                                <Link to="#" className={`${style['Admin__container--centercontainer-filtersmenu__link']}`} onClick={() => this.setState({activemenuitem: 'completed'})}><li className={`${this.state.activemenuitem === 'completed' && style['activetransaction']}`}>Show completed transactions</li></Link>
                            </ul>*/}
                            <this.MakeOrders/>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default Orders;
