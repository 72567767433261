import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import iconMailSuccess from '../../../img/icon-mail-success.png'
import style from '../../../Onboarding.module.scss'
import { inject, observer } from 'mobx-react';
import axios from 'axios';
import { constants } from 'consts/utils';
var lang = constants.getTranslations

@inject('basicKYCStore')
@observer
class Step1 extends Component{

    constructor(props){
        super(props)
        this.state = {
            response: '',
        }
    }

    componentDidMount(){
        this.test_token()
    }

    test_token = () => {
        axios.post('/api/user/test_confirmtoken', {token: this.props.match.params.token})
        .then(resp => {
            this.props.basicKYCStore.setEmail(resp.data.email)
        })
        .catch(err => {
            console.error(err)
            this.setState({response: err.response.data.message})
        })
    }

    render(){
        return(
            this.state.response
                ? <div className={style['Onboarding']}>
                      <div className={style['Onboarding__container']}>
                          <p className={style['Onboarding__container--header']}>{this.state.response}</p>
                      </div>
                  </div>
                :
                    <div className={style['Onboarding']}>
                        <div className={style['Onboarding__container']}>
                            <p className={style['Onboarding__container--header']}>{lang.kys_kyr_kya.customer_due_diligence.email_verified.header}</p>
                            <div className={style['Onboarding__container--imagecontainer']}>
                                <img src={iconMailSuccess} alt="Email verified" className={style['Onboarding__container--imagecontainer-image']}/>
                            </div>
                            <div className={style['Onboarding__container--textcontainer']}>
                                <p className={style['Onboarding__container--textcontainer-center']}>{lang.kys_kyr_kya.customer_due_diligence.email_verified.your_address} ({this.props.basicKYCStore.email}) {lang.kys_kyr_kya.customer_due_diligence.email_verified.is_now_verified}</p>
                            </div>
                            <div className={style['Onboarding__container--buttoncontainer']}>
                                <input type="button" onClick={() => this.props.basicKYCStore.step = 2} className={style['Onboarding__container--buttoncontainer-button']} value={lang.kys_kyr_kya.customer_due_diligence.email_verified.continue}/>
                            </div>
                        </div>
                    </div>
        )
    }
}
export default withRouter(Step1)