import React, { Component, Fragment } from 'react'
import { Link, withRouter } from 'react-router-dom'
import axios from 'axios'
import BN from 'bignumber.js';
import Modal from 'react-modal';
import style from './Slide1.module.scss'
import styleBAA from '../../BecomeAnAgent.module.scss'
import dai from 'img/icons/Dai/Logos/PNGs/Mark_Dai.png'
import rightbg from 'img/background/agentright.png'
import ReactFlagsSelect from 'react-flags-select'
import { constants } from 'consts/utils'
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {UserContext} from 'services/userContext';

var lang = constants.getTranslations

const defaultCountry = 'AR'
const defaultCurrency = 'ARS'

Modal.setAppElement('body');

class Slide1 extends Component {

    constructor(props) {
        super(props)
        this.state =
            { videoModal: false,
              exchange_rates: {},
              cashvalue: new BN(5000),
              feevalue: new BN(5),
              getBack: new BN(0),
              inCurr: new BN(0),
              exchangeRate: new BN(0),
              curr: defaultCountry
            }
        this.sliderRef = React.createRef()
        this.sliderRef2 = React.createRef()
    }

    componentDidMount() {
        axios.get('/api/fiat/all')
        .then(
              response => {
                  const exchangeRate = new BN(response.data[defaultCurrency])
                  const fee = new BN(0.05)
                  const val = new BN(5000)
                  const incurr = val.multipliedBy(fee).plus(val)
                  this.setState({
                      exchange_rates: response.data,
                      exchangeRate: response.data[defaultCurrency],
                      inCurr: incurr,
                      getBack: incurr/exchangeRate

                  })
        })
        .catch(err => {
        })
    }


    chooseCurrency = (e) => {
        this.setState({curr: e})
        const exchangeRate = new BN(this.state.exchange_rates[constants.shorthand[e]])
        const incurr = (this.state.cashvalue.multipliedBy(this.state.feevalue.dividedBy(100)).plus(this.state.cashvalue))
        this.setState({
            exchangeRate: exchangeRate,
            getBack: incurr.dividedBy(exchangeRate),
            inCurr: incurr,
        })
    }

    updateCashValue = (e) => {
        const cashval = new BN(e.target.value)
        const incurr = (cashval.multipliedBy(this.state.feevalue.dividedBy(100))).plus(cashval)
        this.setState({
            cashvalue: cashval,
            inCurr: incurr,
            getBack: incurr.dividedBy(this.state.exchangeRate),
        })
    }

    updateFee = (e) => {
        const fee = new BN(e.target.value)
        const incurr = (this.state.cashvalue.multipliedBy(fee.dividedBy(100))).plus(this.state.cashvalue)
        this.setState({
            feevalue: fee,
            inCurr: incurr,
            getBack: incurr.dividedBy(this.state.exchangeRate),
        })
    }



    render(){

        return(
            <Fragment>
                    <Modal
                      isOpen={this.state.videoModal}
                      onRequestClose={() => this.setState({videoModal: false})}
                      className={`videoModal`}
                      style={{overlay: {zIndex: 10, margin: 'auto'}}}
                    >
                    <iframe src="https://player.vimeo.com/video/366319512?autoplay=1" width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                </Modal>
                <div className={style['FirstSlide']}>
                    <div className={`${style['FirstSlide__left']}`}>
                        <div className={`${style['FirstSlide__left--headercontainer']}`}>
                            <p className={`${style['FirstSlide__left--headercontainer-header']}`}>{lang.agent_landingpage.slide_1_amount.header}</p>
                            <p className={`${style['FirstSlide__left--headercontainer-body']}`}>{lang.agent_landingpage.slide_1_amount.subheader}</p>
                        </div>
                        <div className={style['FirstSlide__left--container']}>
                            <div className={style['FirstSlide__left--container-box']}>
                                <div className={style['FirstSlide__left--container-box__flexcontainer']}>
                                    <b className={style['FirstSlide__left--container-box__flexcontainer--header']}>{lang.agent_landingpage.slide_1_amount.deliver_label}: {this.state.cashvalue.toFixed(2)}</b>
                                    <ReactFlagsSelect
                                            searchable={true}
                                            className={'inline-flag2'}
                                             onSelect={this.chooseCurrency}
                                             countries={constants.country_labels}
                                             customLabels={constants.shorthand}
                                             selected={this.state.curr || defaultCountry}
                                             selectedSize={22.31} // 18 px
                                             optionsSize={20}
                                     />
                                </div>
                                <div ref={this.sliderRef} className={styleBAA['slidecontainer']}>
                                    <input type="range" min="1" max="25000" value={this.state.cashvalue} onChange={this.updateCashValue} className={styleBAA['slidecontainer__slider']} />
                                    <div className={styleBAA['slidecontainer__trail']} style={{width: `${((this.state.cashvalue) / 25000)* (this.sliderRef.current ? this.sliderRef.current.offsetWidth : 0)}px`}} />
                                </div>
                            </div>
                            <div className={style['FirstSlide__left--container-box']}>
                                <div className={style['FirstSlide__left--container-box__flexcontainer']}>
                                    <b className={style['FirstSlide__left--container-box__flexcontainer--header']}>{lang.agent_landingpage.slide_1_amount.fee_label}: {this.state.feevalue.toFixed(0)}%</b>
                                </div>
                                <div ref={this.sliderRef2} className={styleBAA['slidecontainer']}>
                                    <input type="range" min={`${constants.min_fee}`} max={`${constants.max_fee_num}`} value={this.state.feevalue} onChange={this.updateFee} className={styleBAA['slidecontainer__slider']} />
                                    <div className={styleBAA['slidecontainer__trail']} style={{width: `${((this.state.feevalue) / 6)* (this.sliderRef2.current ? this.sliderRef2.current.offsetWidth : 0)}px`}} />
                                </div>
                            </div>
                            <div className={style['FirstSlide__left--container-box']}>
                                <div className={style['FirstSlide__left--container-box__flexcontainer']}>
                                    <div className={style['FirstSlide__left--container-box__flexcontainer--left']}>
                                        <b className={style['FirstSlide__left--container-box__flexcontainer--left-header']}>{lang.agent_landingpage.slide_1_amount.fat_red_you_will_get}</b>
                                        <div className={style['FirstSlide__left--container-box__flexcontainer--left-details']}>
                                            <img src={dai} alt="Dai" className={style['FirstSlide__left--container-box__flexcontainer--left-details__icon']}/>
                                            <div className={style['FirstSlide__left--container-box__flexcontainer--left-details__valuecontainer']}>
                                                <b className={style['FirstSlide__left--container-box__flexcontainer--left-details__valuecontainer--amount']}>{this.state.getBack.toFixed(2)}</b>
                                                <p className={style['FirstSlide__left--container-box__flexcontainer--left-details__valuecontainer--currency']}>Dai</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style['FirstSlide__left--container-box__flexcontainer--right']}>
                                        <b className={style['FirstSlide__left--container-box__flexcontainer--right-header']}>{lang.agent_landingpage.slide_1_amount.fat_red_in_value}</b>
                                        <div className={style['FirstSlide__left--container-box__flexcontainer--right-details']}>
                                        <ReactFlagsSelect
                                            className={'only-flag'}
                                            selected={this.state.curr}
                                            showSelectedLabel={false}
                                            placeholder=""
                                            disabled={true}
                                            selectedSize={22.31}
                                            optionsSize={20}
                                         />
                                            <div className={style['FirstSlide__left--container-box__flexcontainer--right-details__valuecontainer']}>
                                                <b className={style['FirstSlide__left--container-box__flexcontainer--right-details__valuecontainer--amount']}>{this.state.inCurr.toFixed(0)}</b>
                                                <p className={style['FirstSlide__left--container-box__flexcontainer--right-details__valuecontainer--currency']}>{constants.shorthand[this.state.curr]}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className={style['FirstSlide__left--container-box__disclaimer']}></p>
                            </div>
                            {!this.props.context.isLoggedIn && (
                                <div className={style['FirstSlide__left--container-buttoncontainer']}>
                                     <input type='submit'  onClick={() => this.props.history.push('?signup')} value={lang.agent_landingpage.slide_1_amount.main_button} className={`${style['FirstSlide__left--container-buttoncontainer__signup']}`}/>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={`${style['FirstSlide__right']}`}>
                        {/*<div className={`${style['FirstSlide__right--cut']}`}/>*/}
                        <div className={`${style['FirstSlide__right--overlay']}`}/>
                        <div className={`${style['FirstSlide__right--imagecontainer']}`}>
                            <img className={`${style['FirstSlide__right--imagecontainer-image']}`} src={rightbg}/>
                        </div>
                        <div className={`${style['FirstSlide__right--playcontainer']}`}>
                            <Link to="#" onClick={() => this.setState({videoModal: true})}>
                                <div className={`${style['FirstSlide__right--playcontainer-circle']}`}>
                                    <FontAwesomeIcon icon={faPlay} className={`${style['FirstSlide__right--playcontainer-circle__play']}`} />
                                </div>
                            </Link>
                            <Link to="#" onClick={() => this.setState({videoModal: true})}>
                                <p className={`${style['FirstSlide__right--playcontainer-text']}`}>{lang.agent_landingpage.slide_1_amount.video_text}</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(React.forwardRef((props, ref) => (
  <UserContext.Consumer>
    {(user, setStatus, setUser, isLoggedIn) => <Slide1 {...props} context={user} isLoggedIn={isLoggedIn} ref={ref} />}
  </UserContext.Consumer>
)));