import React, { Component, Fragment } from "react";
import { faPencilAlt, faArrowRight, faArrowLeft, faTimes, faLock, faEnvelope, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter, Link } from 'react-router-dom';
import Checkbox from 'components/Forms/Checkbox/Checkbox.js';
import MakeStars from 'components/MakeStars/MakeStars.js';
import Sidebar from 'react-sidebar';
import defaultprofile from 'img/icons/defaultprofile.png';
import iconPhone from 'img/icons/icon-phone.png';
import iconMail from 'img/icons/icon-mail.png';
import iconPin from 'img/icons/icon-mappin.png';
import iconLock from 'img/icons/icon-lock.png';
import iconOther from 'img/icons/other.png';
import iconBaccount from 'img/icons/baccount.png';
import iconAccountno from 'img/icons/accountno.png'
import iconBankcode from 'img/icons/bankcode.png'
import iconAccountnonew from 'img/icons/accountnonew.png'
import iconBanktransfer from 'img/icons/banktransfer.png';
import iconTransfer from 'img/icons/icon-transfer.png';
import ponderEmoji from 'img/icons/ponderemoji.png';
import celebrateEmoji from 'img/icons/celebrateemoji.png';
import { constants } from 'consts/utils';
import BN from 'bignumber.js';
import { ReactComponent as Checkmark } from 'img/icons/icon-checkmark.react.svg'
import { ReactComponent as RoundCheckmark } from 'img/icons/icon-checkmark-round.react.svg'
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { ReactComponent as Zoom } from 'img/icons/icon-zoom.react.svg'
import ReactFlagsSelect from 'react-flags-select'
import style from './Sidebar.module.scss';
import dai from 'img/icons/Dai/Logos/PNGs/Mark_Dai.png'
import handover from 'img/illustrations/handover-nobg.png';
import axios from 'axios';
import Spinner from 'components/Spinner/Spinner'
import recrstyle from 'components/Recruit/Recruit.module.scss';
import { inject, observer } from 'mobx-react';
import Modal from 'react-modal';
import Recruit from 'components/Recruit/Recruit';

var lang = constants.getTranslations


function format_date(date){
    const d1 = new Date(date)
    const d2 = new Date()
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth() + 1;
    months += d2.getMonth();
    if(months < 1){
        const days = d2.getDay() - d1.getDay()
        if(days < 1){
            return `${lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.today}`
        } else{

            return `${days} ${lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.days_ago}`
        }
    }
    else if(months > 12){
        const years = d2.getFullYear() - d1.getFullYear()
        return `${years} ${lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.years_ago}`
    }
    const s = months === 1 ? '' : 's'
    return `${months} ${lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.month_ago}`
}



function preventScrolling(prev, now){
    if(!prev && now){
        document.body.style.height = '100vh'
        document.body.style.overflowY = 'hidden'
    } else if(prev && !now){
        document.body.style.height = 'auto'
        document.body.style.overflowY = 'auto'
    }
}

class HowToSidebar extends Component {
    constructor(props){
        super(props);
    }

    componentDidUpdate(prevprops){
        preventScrolling(prevprops.isOpen, this.props.isOpen)
    }


    componentWillUnmount(){
        document.body.style.height = 'auto'
        document.body.style.overflowY = 'auto'
    }


    render() {
        return (

            <Sidebar
                sidebar=
                    {
                        <Fragment>
                            <div className={`${style['sidebar__top']}`}>
                                <Link to="#" onClick={() => this.props.handler("howToGetDaiSidebar")}><FontAwesomeIcon icon={faTimes} className={style['sidebar__top--close']} /></Link>
                                <p className={`${style['sidebar__top--title']}`}>{lang.sidebars.get.how_to_get}</p>
                            </div>
                            <div className={`${style['sidebar__body']}`}>
                                <p className={`${style['sidebar__body--header']}`}>{lang.sidebars.get.why_get}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.get.the_stablecoin} <a href="//makerdao.com/en/dai" className={`${style['sidebar__body--link']}`}>https://makerdao.com/en/dai</a></p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header']}`}>
                                    {lang.sidebars.get.you_have}
                                </p>
                                <p className={`${style['sidebar__body--text']}`}>
                                    {lang.sidebars.get.one+' '}
                                    <Link to="/agent" className={`${style['sidebar__body--link']}`}>https://dafnir.com/agent</Link>
                                </p>
                                <br/>
                                <p className={`${style['sidebar__body--text']}`}>
                                    {lang.sidebars.get.two+' '}
                                    <a href="//stablepay.io/" className={`${style['sidebar__body--link']}`}>https://stablepay.io/</a>
                                </p>
                                <br/>
                                <p className={`${style['sidebar__body--text']}`}>
                                    {lang.sidebars.get.three+' '}
                                    <a href="//coinbase.com/" className={`${style['sidebar__body--link']}`}>https://coinbase.com/</a>
                                    <br/>
                                    <a href="//ripio.com/" className={`${style['sidebar__body--link']}`}>https://www.ripio.com/</a>
                                </p>
                                <br/><br/>
                                <p className={`${style['sidebar__body--text']}`}>
                                    {lang.sidebars.get.four+' '}
                                    <a href="//uniswap.exchange/swap" className={`${style['sidebar__body--link']}`}>https://uniswap.exchange/swap</a>
                                    <br/>
                                    <a href="//kyberswap.com/swap/" className={`${style['sidebar__body--link']}`}>https://kyberswap.com/swap/</a>
                                    <br/>
                                    <a href="//shapeshift.io/" className={`${style['sidebar__body--link']}`}>https://shapeshift.io/</a>
                                </p>
                            </div>
                        </Fragment>
                    }
                children={<Fragment/>}
                touchHandleWidth={0}
                open={this.props.isOpen}
                pullRight={true}
                rootClassName={style['sidebarbg']}
                sidebarClassName={style['sidebar']}
                onSetOpen={() => this.props.handler("howToGetDaiSidebar")}
                styles={ !this.props.isOpen ?
                    {root:{
                        pointerEvents: 'none'
                    }}
                    :
                    {}
                }
            />
        )
    };
}

class SignInSidebar extends Component {
    constructor(props){
        super(props);
        this.state = {
            loginEmail: '',
            loginPassword: '',
            errcode: 0
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidUpdate(prevprops){
        preventScrolling(prevprops.isOpen, this.props.isOpen)
    }

    handleInputChange(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            [name]: value
        });
    }

    checkCredentials = (e, setStatus, setUser) => {
        e.preventDefault()
        axios.post('/api/auth/login', {
            email: this.state.loginEmail,
            password: this.state.loginPassword
        }).then(resp => {
            setStatus(true);
            setUser(resp.data);
        }).catch(err => {
            if(err.response.data.errcode){
                this.setState({errcode: err.response.data.errcode})
            }
            alert(err.response.data.message);
        })
    }

    resend = () => {
        axios.post('/api/user/resend_token', {
            email: this.state.loginEmail,
        }).then(resp => {
            this.setState({errcode: 0})
            alert("Great! now please check your email for a verification link")
        }).catch(err => {
            alert(err.response.data.message);
        })
    }

    openSignup = () =>{
        this.props.handler("signinSidebar")
        this.props.handlerOpen("signupSidebar")
    }



    componentWillUnmount(){
        document.body.style.height = 'auto'
        document.body.style.overflowY = 'auto'
    }


    render() {
        return (
            <Sidebar
                sidebar=
                    {
                        <Fragment>
                            <div className={`${style['sidebar__top']}`}>
                                <Link to="#" onClick={() => this.props.handler("signinSidebar")}><FontAwesomeIcon icon={faTimes} className={style['sidebar__top--close']} /></Link>
                                <p className={`${style['sidebar__top--title']}`}>{lang.sign_in.header}</p>
                            </div>
                            <div className={`${style['sidebar__body']}`}>
                                <form>
                                    <div className={`${style['sidebar__body--inputcontainer']}`}>
                                        <FontAwesomeIcon icon={faEnvelope} className={`${style['sidebar__body--inputcontainer-icon']}`} />
                                        <input type="email" name="loginEmail" autoComplete="username" onChange={this.handleInputChange} className={`${style['sidebar__body--inputcontainer-input']}`} value={this.state.loginEmail} placeholder={lang.sign_in.filler_email} />
                                    </div>
                                    <div className={`${style['sidebar__body--inputcontainer']}`}>
                                        <FontAwesomeIcon icon={faLock} className={`${style['sidebar__body--inputcontainer-icon']}`} />
                                        <input type="password" name="loginPassword" autoComplete="current-password" onChange={this.handleInputChange} className={`${style['sidebar__body--inputcontainer-input']}`} value={this.state.loginPassword} placeholder={lang.sign_in.filler_password} />
                                    </div>
                                    {this.state.errcode === 1 && (
                                        <p className={`${style['sidebar__body--resend']}`}><a href="#" onClick={() => this.resend()}>{'Resend verification email'}</a></p>
                                    )}
                                    <p className={`${style['sidebar__body--forgotpassword']}`}><Link to="/support/forgotpassword" onClick={() => this.props.handler("signinSidebar")}>{lang.sign_in.red_link_forgot_password}</Link></p>
                                    <input type="submit" onClick={e => this.checkCredentials(e, this.props.setStatus, this.props.setUser)} className={`${style['sidebar__body--button']}`} value={lang.sign_in.red_button}/>
                                    <p className={`${style['sidebar__body--noaccount']}`}>{lang.sign_in.no_account} <Link to="#" onClick={() => this.openSignup()} className={`${style['sidebar__body--noaccount-signup']}`}>{lang.sign_in.red_link_sign_up}</Link></p>
                                </form>
                            </div>
                        </Fragment>
                    }
                children={<Fragment/>}
                touchHandleWidth={0}
                open={this.props.isOpen}
                pullRight={true}
                rootClassName={style['sidebarbg']}
                sidebarClassName={style['sidebar']}
                onSetOpen={() => this.props.handler("signinSidebar")}
                styles={ !this.props.isOpen ?
                    {root:{
                        pointerEvents: 'none'
                    }}
                    :
                    {}
                }
            />
        );
    };
}

@inject('basicKYCStore')
@observer
class SignUpSidebar extends Component {
    constructor(props){
        super(props);
        this.state = {
            signupEmail: '',
            signupPassword: '',
            terms: false,
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidUpdate(prevprops){
        preventScrolling(prevprops.isOpen, this.props.isOpen)
    }

    handleInputChange(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            [name]: value
        });
    }

    register = async (e, handler) => {
        e.preventDefault()
        await axios.post('/api/auth/register', {
            terms: this.state.terms,
            email: this.state.signupEmail,
            password: this.state.signupPassword
        }).then(resp => {
            handler("signupSidebar")
            this.props.basicKYCStore.email = this.state.signupEmail
            this.props.history.push('/signedup');
        }).catch(err => {
            try{
                alert(err.response.data.message);
            } catch(e){
                alert(err)
            }
        })
    }

    openSignin = () =>{
        this.props.handler("signupSidebar")
        this.props.handlerOpen("signinSidebar")
    }

    openTermsOfBusiness = () =>{
        this.props.handler("signupSidebar")
        this.props.handlerOpen("termsBusinessSidebar")
    }

    openPrivacyPolicy = () =>{
        this.props.handler("signupSidebar")
        this.props.handlerOpen("termsPrivacySidebar")
    }

    componentWillUnmount(){
        document.body.style.height = 'auto'
        document.body.style.overflowY = 'auto'
    }


    render() {
        return (

            <Sidebar
                sidebar=
                    {
                        <Fragment>
                            <div className={`${style['sidebar__top']}`}>
                                <Link to="#" onClick={() => this.props.handler("signupSidebar")}><FontAwesomeIcon icon={faTimes} className={style['sidebar__top--close']} /></Link>
                                <p className={`${style['sidebar__top--title']}`}>{lang.sign_up.header}</p>
                            </div>
                            <div className={`${style['sidebar__body']}`}>
                                <form>
                                    <div className={`${style['sidebar__body--inputcontainer']}`}>
                                        <FontAwesomeIcon icon={faEnvelope} className={`${style['sidebar__body--inputcontainer-icon']}`} />
                                        <input type="email" name="signupEmail" autoComplete="username" onChange={this.handleInputChange} className={`${style['sidebar__body--inputcontainer-input']}`} value={this.state.signupEmail} placeholder={lang.sign_up.filler_email} />
                                    </div>
                                    <div className={`${style['sidebar__body--inputcontainer']}`}>
                                        <FontAwesomeIcon icon={faLock} className={`${style['sidebar__body--inputcontainer-icon']}`} />
                                        <input type="password" autoComplete="new-password" name="signupPassword" onChange={this.handleInputChange} className={`${style['sidebar__body--inputcontainer-input']}`} value={this.state.signupPassword} placeholder={lang.sign_up.filler_password} />
                                    </div>
                                    <div className={`${style['sidebar__body--flexcontainer2']}`}>
                                        <Checkbox
                                            extendinputclass={'col-check-red'}
                                            spanstyle={{backgroundColor: 'var(--primgray8)'}}
                                            defaultChecked={this.state.terms}
                                            onClick={() => this.setState({terms: !this.state.terms})}
                                        />
                                        <p className={`${style['sidebar__body--flexcontainer-text']}`}>{lang.sign_up.accept_terms} <Link to="#" onClick={() => this.openPrivacyPolicy()} className={`${style['sidebar__body--link']}`}>{lang.sign_up.privacy_policy}</Link> {lang.sign_up.and_the} <Link to="#" onClick={() => this.openTermsOfBusiness()} className={`${style['sidebar__body--link']}`}>{lang.sign_up.terms_of_business}</Link></p>
                                    </div>

                                    <div className={style['sidebar__body--spacer4']}/>
                                    <input type="submit" onClick={(e) => this.register(e, this.props.handler)} className={`${style['sidebar__body--button']}`} value={lang.sign_up.red_button}/>
                                    <p className={`${style['sidebar__body--noaccount']}`}>
                                        {lang.sign_up.have_account+' '} <Link to="" onClick={() => this.openSignin()} className={`${style['sidebar__body--noaccount-signup']}`}>{lang.sign_up.red_link_sign_in}</Link>
                                    </p>
                                    <div className={`${style['sidebar__body--bonuscontainer']}`}>
                                        <p className={`${style['sidebar__body--bonuscontainer-header']}`}>{lang.sign_up.sub_header}</p>
                                        <div className={`${style['sidebar__body--bonuscontainer-container']}`}>
                                            <Checkmark width="20px" height="14.24px" viewBox="0 0 70 45" className={`${style['sidebar__body--bonuscontainer-container__left']}`}/>
                                            <p className={`${style['sidebar__body--bonuscontainer-container__right']}`}>{lang.sign_up.benefit_send}</p>
                                        </div>
                                        <div className={`${style['sidebar__body--bonuscontainer-container']}`}>
                                            <Checkmark width="20px" height="14.24px" viewBox="0 0 70 45" className={`${style['sidebar__body--bonuscontainer-container__left']}`}/>
                                            <p className={`${style['sidebar__body--bonuscontainer-container__right']}`}>{lang.sign_up.benefit_exchange_cash}</p>
                                        </div>
                                        <div className={`${style['sidebar__body--bonuscontainer-container']}`}>
                                            <Checkmark width="20px" height="14.24px" viewBox="0 0 70 45" className={`${style['sidebar__body--bonuscontainer-container__left']}`}/>
                                            <p className={`${style['sidebar__body--bonuscontainer-container__right']}`}>{lang.sign_up.benefit_exchange_dai}</p>
                                        </div>
                                        <div className={`${style['sidebar__body--bonuscontainer-container']}`}>
                                            <Checkmark width="20px" height="14.24px" viewBox="0 0 70 45" className={`${style['sidebar__body--bonuscontainer-container__left']}`}/>
                                            <p className={`${style['sidebar__body--bonuscontainer-container__right']}`}>{lang.sign_up.benefit_make_money}</p>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </Fragment>
                    }
                children={<Fragment/>}
                touchHandleWidth={0}
                open={this.props.isOpen}
                pullRight={true}
                rootClassName={style['sidebarbg']}
                sidebarClassName={style['sidebar']}
                onSetOpen={() => this.props.handler("signupSidebar")}
                styles={ !this.props.isOpen ?
                    {root:{
                        pointerEvents: 'none'
                    }}
                    :
                    {}
                }
            />
        );
    };
}

class AgentSidebar extends Component {
    constructor(props){
        super(props);
        this.state = {
            reviewCount: 2
        }
    }


    Make_Reviews = (props) => {
        const reviews = props.reviews
        var maxCount = 0
        if(this.state.reviewCount == 0){
            maxCount = reviews.length
        } else{
            maxCount = Math.min(reviews.length, this.state.reviewCount) // either 1 or 2 reviews
        }
        var ret_reviews = [];
        for (var i = 0; i < maxCount; i++) {
            const review = reviews[i]
            if(review){
                ret_reviews.push(
                    <div key={i} className={`${style['sidebar__agent--ratingscontainer-reviewcontainer']}`}>
                        <div className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__top']}`}>
                            <div className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__top--agent']}`}>
                                <img src={review.avatar ? `/api/user/avatar/${review.avatar}` : defaultprofile} alt="Recipient avatar" className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__top--agent-avatar']}`}/>
                                <div className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__top--agent-info']}`}>
                                    <p className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__top--agent-info__header']}`}>{review.name}</p>
                                    <p className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__top--agent-info__body']}`}>{format_date(review.rated_when)}</p>
                                </div>
                            </div>
                            <div className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__top--starcontainer']}`}>
                                <MakeStars ratings={review.rating}/>
                            </div>
                        </div>
                        <div className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__bottom']}`}>
                            <p className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__bottom--review']}`}>{review.review}</p>
                            {review.response && (
                                <div className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__bottom--responsecontainer']}`}>
                                    <div className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__bottom--responsecontainer-infocontainer']}`}>
                                        <p className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__bottom--responsecontainer-infocontainer__from']}`}>{this.props.agent.firstname.length > 15 ? this.props.agent.firstname.substring(0,12)+"..." : this.props.agent.firstname.substr(this.props.agent.firstname.length-1) === 's' ? this.props.agent.firstname+"'" : this.props.agent.firstname+"s"} {lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.response}</p>
                                        <p className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__bottom--responsecontainer-infocontainer__when']}`}>{format_date(review.response.response_when)}</p>
                                    </div>
                                    <div className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__bottom--responsecontainer-body']}`}>
                                        <p className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__bottom--responsecontainer-body__text']}`}>{review.response.answer}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )
            }
        }

        return ret_reviews
    }

    componentDidUpdate(prevprops){
        preventScrolling(prevprops.isOpen, this.props.isOpen)
    }
    componentWillUnmount(){
        document.body.style.height = 'auto'
        document.body.style.overflowY = 'auto'
    }


    render() {
        return (
                <Sidebar
                    sidebar=
                        {
                            Object.keys(this.props.agent).length > 0 && (
                                <Fragment>
                                    <div className={`${style['sidebar__top']}`}>
                                        <Link to="#" onClick={() => this.props.handler('agentsidebar')}><FontAwesomeIcon icon={faTimes} className={style['sidebar__top--close']} /></Link>
                                        <div className={`${style['sidebar__top--userheader']}`}>
                                            <div className={`${style['sidebar__top--userheader-imagecontainer']}`}>
                                                <img src={this.props.agent.avatar ? `/api/user/avatar/${this.props.agent.avatar}` : defaultprofile} alt="Agent avatar" className={`${style['sidebar__top--userheader-imagecontainer__image']}`} />
                                            </div>
                                            <div className={`${style['sidebar__top--userheader-infocontainer']}`}>
                                                <p className={`${style['sidebar__top--userheader-infocontainer__header']}`}>{lang.orderflow_myself_agent_details_before_accept.view_agent_details_sidebar.header}</p>
                                                <p className={`${style['sidebar__top--userheader-infocontainer__name']}`}>{this.props.agent.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${style['sidebar__agent']}`}>

                                        {/* Rating and fee */}
                                        <div className={`${style['sidebar__agent--firstcontainer']}`}>
                                            <div className={`${style['sidebar__agent--firstcontainer-ratingcontainer']}`}>
                                                <p className={`${style['sidebar__agent--firstcontainer-ratingcontainer__header']}`}>{lang.orderflow_myself_agent_details_before_accept.view_agent_details_sidebar.user_rating}</p>
                                                <MakeStars ratings={this.props.agent.ratings ? this.props.agent.ratings.map(r => r.rating) : []}/>
                                            </div>
                                            <div className={`${style['sidebar__agent--firstcontainer-feecontainer']}`}>
                                                <p className={`${style['sidebar__agent--firstcontainer-feecontainer__header']}`}>{lang.orderflow_amount.white_box.delivery_fee}</p>
                                                <p className={`${style['sidebar__agent--firstcontainer-feecontainer__body']}`}>{this.props.agent.fee}%</p>
                                            </div>
                                        </div>

                                        {/* Profile text */}
                                        <div className={`${style['sidebar__agent--firstcontainer']}`}>
                                            <div className={`${style['sidebar__agent--firstcontainer-feecontainer']}`}>
                                                <p className={`${style['sidebar__agent--firstcontainer-feecontainer__header']}`}>{`Profile text`}</p>
                                                <p className={`${style['sidebar__agent--firstcontainer-feecontainer__body']}`}>{this.props.agent.profile_text}</p>
                                            </div>
                                        </div>

                                        {/* Delivery method */}
                                        {this.props.agent.method && (
                                            <div className={`${style['sidebar__agent--firstcontainer']}`}>
                                                <div className={`${style['sidebar__agent--firstcontainer-feecontainer']}`}>
                                                    <p className={`${style['sidebar__agent--firstcontainer-feecontainer__header']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.delivery_method}</p>
                                                    <p className={`${style['sidebar__agent--firstcontainer-feecontainer__body']}`}>{constants.translateMethod(this.props.agent.method)}</p>
                                                </div>
                                                <div className={`${style['sidebar__agent--firstcontainer-feecontainer']}`}>
                                                    <p className={`${style['sidebar__agent--firstcontainer-feecontainer__header']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.payment_solution}</p>
                                                    <p className={`${style['sidebar__agent--firstcontainer-feecontainer__body']}`}>{constants.makeMethods(this.props.agent.method, this.props.order.payment_info)}</p>
                                                </div>
                                            </div>
                                        )}
                                        {/* Delivery options */}
                                        {this.props.agent.deliver_methods && (
                                            <div className={`${style['sidebar__agent--firstcontainer']}`}>
                                                <div className={`${style['sidebar__agent--firstcontainer-ratingcontainer']}`}>
                                                    <p className={`${style['sidebar__agent--firstcontainer-ratingcontainer__header']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.delivery_options}</p>
                                                    <p className={`${style['sidebar__agent--firstcontainer-feecontainer__body']}`}>{constants.makeMethods(this.props.agent.deliver_methods, this.props.agent.deliver_mobile_applications)}</p>
                                                </div>
                                            </div>
                                        )}
                                        {this.props.agent.ratings && this.props.agent.ratings.length > 0 && (
                                            <div className={`${style['sidebar__agent--ratingscontainer']}`}>
                                                <div className={`${style['sidebar__agent--ratingscontainer-infocontainer']}`}>
                                                    <p className={`${style['sidebar__agent--ratingscontainer-infocontainer__reviewsheader']}`}>{lang.orderflow_myself_agent_details_before_accept.view_agent_details_sidebar.reviews} ({this.props.agent.ratings.length})</p>
                                                    {(this.props.agent.ratings.length > 2 && this.state.reviewCount != 0 && this.state.reviewCount != 2) && (
                                                        <Link to="#" onClick={() => this.setState({reviewCount: 0})} className={`${style['sidebar__agent--ratingscontainer-infocontainer__allheader']}`}>{lang.orderflow_myself_agent_details_before_accept.view_agent_details_sidebar.all_reviews}</Link>
                                                    )}
                                                    {(this.state.reviewCount === 0 || this.state.reviewCount === 2) && (
                                                        <Link to="#" onClick={() => this.setState({reviewCount: 2})} className={`${style['sidebar__agent--ratingscontainer-infocontainer__allheader']}`}>{lang.orderflow_myself_agent_details_before_accept.view_agent_details_sidebar.hide_reviews} ({this.props.agent.ratings.length - 2})</Link>
                                                    )}
                                                </div>
                                                {<this.Make_Reviews reviews={this.props.agent.ratings}/>}
                                            </div>
                                        )}
                                        <div className={`${style['sidebar__agent--delivercontainer']}`}>
                                            <div className={`${style['sidebar__agent--delivercontainer-headercontainer']}`}>
                                                <img src={iconPin} alt="map pin" className={`${style['sidebar__agent--delivercontainer-headercontainer__icon']}`}/>
                                                <p className={`${style['sidebar__agent--delivercontainer-headercontainer__text']}`}>{lang.orderflow_myself_agent_details_before_accept.view_agent_details_sidebar.near} {this.props.agent.city}, {this.props.agent.country}</p>
                                            </div>
                                            {this.props.agent.opening_hours && (
                                                <div className={`${style['sidebar__agent--delivercontainer-availablecontainer']}`}>
                                                    <p className={`${style['sidebar__agent--delivercontainer-availablecontainer__header']}`}>{lang.orderflow_myself_agent_details_before_accept.view_agent_details_sidebar.available}</p>
                                                    {this.props.agent.opening_hours.start_mon && (
                                                        <div className={`${style['sidebar__agent--delivercontainer-availablecontainer__timecontainer']}`}>
                                                            <p className={`${style['sidebar__agent--delivercontainer-availablecontainer__timecontainer--label']}`}>{lang.orderflow_myself_agent_details_before_accept.view_agent_details_sidebar.monday}</p>
                                                            <p className={`${style['sidebar__agent--delivercontainer-availablecontainer__timecontainer--time']}`}>{this.props.agent.opening_hours.start_mon} - {this.props.agent.opening_hours.end_mon}</p>
                                                        </div>
                                                    )}
                                                    {this.props.agent.opening_hours.start_tue && (
                                                        <div className={`${style['sidebar__agent--delivercontainer-availablecontainer__timecontainer']}`}>
                                                            <p className={`${style['sidebar__agent--delivercontainer-availablecontainer__timecontainer--label']}`}>{lang.orderflow_myself_agent_details_before_accept.view_agent_details_sidebar.tuesday}</p>
                                                            <p className={`${style['sidebar__agent--delivercontainer-availablecontainer__timecontainer--time']}`}>{this.props.agent.opening_hours.start_tue} - {this.props.agent.opening_hours.end_tue}</p>
                                                        </div>
                                                    )}
                                                    {this.props.agent.opening_hours.start_wed && (
                                                        <div className={`${style['sidebar__agent--delivercontainer-availablecontainer__timecontainer']}`}>
                                                            <p className={`${style['sidebar__agent--delivercontainer-availablecontainer__timecontainer--label']}`}>{lang.orderflow_myself_agent_details_before_accept.view_agent_details_sidebar.wednesday}</p>
                                                            <p className={`${style['sidebar__agent--delivercontainer-availablecontainer__timecontainer--time']}`}>{this.props.agent.opening_hours.start_wed} - {this.props.agent.opening_hours.end_wed}</p>
                                                        </div>
                                                    )}
                                                    {this.props.agent.opening_hours.start_thur && (
                                                        <div className={`${style['sidebar__agent--delivercontainer-availablecontainer__timecontainer']}`}>
                                                            <p className={`${style['sidebar__agent--delivercontainer-availablecontainer__timecontainer--label']}`}>{lang.orderflow_myself_agent_details_before_accept.view_agent_details_sidebar.thursday}</p>
                                                            <p className={`${style['sidebar__agent--delivercontainer-availablecontainer__timecontainer--time']}`}>{this.props.agent.opening_hours.start_thur} - {this.props.agent.opening_hours.end_thur}</p>
                                                        </div>
                                                    )}
                                                    {this.props.agent.opening_hours.start_fri && (
                                                        <div className={`${style['sidebar__agent--delivercontainer-availablecontainer__timecontainer']}`}>
                                                            <p className={`${style['sidebar__agent--delivercontainer-availablecontainer__timecontainer--label']}`}>{lang.orderflow_myself_agent_details_before_accept.view_agent_details_sidebar.friday}</p>
                                                            <p className={`${style['sidebar__agent--delivercontainer-availablecontainer__timecontainer--time']}`}>{this.props.agent.opening_hours.start_fri} - {this.props.agent.opening_hours.end_fri}</p>
                                                        </div>
                                                    )}
                                                    {this.props.agent.opening_hours.start_sat && (
                                                        <div className={`${style['sidebar__agent--delivercontainer-availablecontainer__timecontainer']}`}>
                                                            <p className={`${style['sidebar__agent--delivercontainer-availablecontainer__timecontainer--label']}`}>{lang.orderflow_myself_agent_details_before_accept.view_agent_details_sidebar.saturday}</p>
                                                            <p className={`${style['sidebar__agent--delivercontainer-availablecontainer__timecontainer--time']}`}>{this.props.agent.opening_hours.start_sat} - {this.props.agent.opening_hours.end_sat}</p>
                                                        </div>
                                                    )}
                                                    {this.props.agent.opening_hours.start_sun && (
                                                        <div className={`${style['sidebar__agent--delivercontainer-availablecontainer__timecontainer']}`}>
                                                            <p className={`${style['sidebar__agent--delivercontainer-availablecontainer__timecontainer--label']}`}>{lang.orderflow_myself_agent_details_before_accept.view_agent_details_sidebar.sunday}</p>
                                                            <p className={`${style['sidebar__agent--delivercontainer-availablecontainer__timecontainer--time']}`}>{this.props.agent.opening_hours.start_sun} - {this.props.agent.opening_hours.end_sun}</p>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={`${style['sidebar__bottom']}`}>
                                        {
                                            this.props.request
                                                ? this.props.agent.selected
                                                    ? <Link to="#"><input type="button" className={`${style['sidebar__bottom--requested']}`} value={lang.deliverflow_public_order_public_tasks.main_window.requested_white_button}/></Link>
                                                    : <Link to="#" onClick={() => this.props.continue()}><input type="button" className={`${style['sidebar__bottom--button']}`} value={lang.orderflow_myself_agent_details_before_accept.view_agent_details_sidebar.red_button_request_delivery}/></Link>
                                                :
                                                    <Fragment>
                                                        <Link to="#" onClick={() => this.props.acceptAgent(this.props.agent.id)}><input type="button" className={`${style['sidebar__bottom--acceptbutton']}`} value={lang.deliverflow_private_order_view_order_view_to_accept.accept_or_decline.red_button_accept}/></Link>
                                                        <Link to="#" onClick={() => this.props.declineAgent(this.props.agent.id)}><input type="button" className={`${style['sidebar__bottom--declinebutton']}`} value={lang.deliverflow_private_order_view_order_view_to_accept.accept_or_decline.white_button_decline}/></Link>
                                                    </Fragment>
                                        }
                                    </div>
                                </Fragment>
                            )
                        }
                    children={<Fragment/>}
                    touchHandleWidth={0}
                    open={this.props.isOpen}
                    pullRight={true}
                    rootClassName={style['sidebarbg']}
                    sidebarClassName={style['sidebar']}
                    onSetOpen={() => this.props.handler('agentsidebar')}
                    styles={ !this.props.isOpen ?
                        {root:{
                            pointerEvents: 'none'
                        }}
                        :
                        {}
                    }
                    />
        )
    };
}

/* Choose order type */
class PrivateOrderStep2 extends Component {
    constructor(props){
        super(props)
    }

    componentDidUpdate(prevprops){
        preventScrolling(prevprops.isOpen, this.props.isOpen)
    }
    componentWillUnmount(){
        document.body.style.height = 'auto'
        document.body.style.overflowY = 'auto'
    }


    render() {
        return (
            <Sidebar
                sidebar=
                    {
                        this.props.agent && (
                            <Fragment>
                                <div className={`${style['sidebar__top']}`}>
                                    <Link to="#" onClick={() => this.props.goBack()}><FontAwesomeIcon icon={faArrowLeft} className={style['sidebar__top--closearrow']} /></Link>
                                    <div className={`${style['sidebar__top--userheader']}`}>
                                        <div className={`${style['sidebar__top--userheader-imagecontainer']}`}>
                                            <img src={this.props.agent.avatar ? `/api/user/avatar/${this.props.agent.avatar}` : defaultprofile} alt="Agent avatar" className={`${style['sidebar__top--userheader-imagecontainer__image']}`} />
                                        </div>
                                        <div className={`${style['sidebar__top--userheader-infocontainer']}`}>
                                            <p className={`${style['sidebar__top--userheader-infocontainer__header']}`}>{lang.orderflow_myself_agent_details_before_accept.wiew_agent_personal_message_sidebar.header}</p>
                                            <p className={`${style['sidebar__top--userheader-infocontainer__name']}`}>{this.props.agent.name}</p>
                                        </div>
                                    </div>
                                </div>

                            {/*Martin, jeg har et gennemgående problem, jeg kan ikke få in person og bank transfer bundet op på oversættelserne*/}
                            {/* Svar: Gik da ellers fint? Tror jeg? Ved ikke hvad der er galt? */}
                                <div className={`${style['sidebar__body']}`}>
                                    <p className={`${style['sidebar__body--header3']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.select_method}</p>
                                    {/* Cash delivery */}
                                    {this.props.agent.requestable_in_person && (
                                        this.props.agent.deliver_methods && this.props.agent.deliver_methods.includes("In person") && (
                                            <Link to="#" style={{textDecoration: 'none'}} onClick={() => this.props.next("privateSidebar3_inPerson")}>
                                                <div className={`${style['sidebar__body--spacer6']} ${style['sidebar__body--deliverymethod']}`}>
                                                    <div className={`${style['sidebar__body--deliverymethod-left']}`}>
                                                        <p className={`${style['sidebar__body--deliverymethod-left__header']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.receive_cash_header}</p>
                                                        <p className={`${style['sidebar__body--deliverymethod-left__body']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.receive_cash_subtext}</p>
                                                    </div>
                                                    <div className={`${style['sidebar__body--deliverymethod-right']}`}>
                                                        <FontAwesomeIcon icon={faArrowRight} className={style['sidebar__body--deliverymethod-right__arrow']} />
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                    )}

                                    {/* Bank transfer */}
                                    { this.props.agent.deliver_methods && this.props.agent.deliver_methods.includes("Bank transfer") && (
                                        <Link to="#" style={{textDecoration: 'none'}} onClick={() => this.props.next("privateSidebar3_bankTransfer")}>
                                            <div className={`${style['sidebar__body--deliverymethod']}`}>
                                                <div className={`${style['sidebar__body--deliverymethod-left']}`}>
                                                    <p className={`${style['sidebar__body--deliverymethod-left__header']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.receive_transfer_header}</p>
                                                    <p className={`${style['sidebar__body--deliverymethod-left__body']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.receive_transfer_subtext}</p>
                                                </div>
                                                <div className={`${style['sidebar__body--deliverymethod-right']}`}>
                                                    <FontAwesomeIcon icon={faArrowRight} className={style['sidebar__body--deliverymethod-right__arrow']} />
                                                </div>
                                            </div>
                                        </Link>
                                    )}

                                    {/* Other */}
                                    { this.props.agent.deliver_methods && this.props.agent.deliver_methods.includes("Mobile payment") && (
                                        <Link to="#" style={{textDecoration: 'none'}} onClick={() => this.props.next("privateSidebar3_mobilePayment")}>
                                            <div className={`${style['sidebar__body--deliverymethod']}`}>
                                                <div className={`${style['sidebar__body--deliverymethod-left']}`}>
                                                    <p className={`${style['sidebar__body--deliverymethod-left__header']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.receive_other_header}</p>
                                                    <p className={`${style['sidebar__body--deliverymethod-left__body']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.receive_other_subtext}</p>
                                                </div>
                                                <div className={`${style['sidebar__body--deliverymethod-right']}`}>
                                                    <FontAwesomeIcon icon={faArrowRight} className={style['sidebar__body--deliverymethod-right__arrow']} />
                                                </div>
                                            </div>
                                        </Link>
                                    )}

                                </div>
                            </Fragment>
                        )
                    }
                children={<Fragment/>}
                touchHandleWidth={0}
                open={this.props.isOpen}
                pullRight={true}
                rootClassName={style['sidebarbg']}
                sidebarClassName={style['sidebar']}
                onSetOpen={() => this.props.goBack()}
                styles={ !this.props.isOpen ?
                    {root:{
                        pointerEvents: 'none'
                    }}
                    :
                    {}
                }
            />
        )
    }
}

class PrivateOrderStep3_InPerson extends Component {
    constructor(props){
        super(props)
        this.state = {
            message: ''
        }
    }

    componentDidUpdate(prevprops){
        preventScrolling(prevprops.isOpen, this.props.isOpen)
    }
    componentWillUnmount(){
        document.body.style.height = 'auto'
        document.body.style.overflowY = 'auto'
    }


    render() {
        return (
            <Sidebar
                sidebar=
                    {
                        this.props.agent && (
                            <Fragment>
                                <div className={`${style['sidebar__top']}`}>
                                    <Link to="#" onClick={() => this.props.goBack('privateSidebar3_inPerson')}><FontAwesomeIcon icon={faArrowLeft} className={style['sidebar__top--closearrow']} /></Link>
                                    <div className={`${style['sidebar__top--userheader']}`}>
                                        <div className={`${style['sidebar__top--userheader-imagecontainer']}`}>
                                            <img src={this.props.agent.avatar ? `/api/user/avatar/${this.props.agent.avatar}` : defaultprofile} alt="Agent avatar" className={`${style['sidebar__top--userheader-imagecontainer__image']}`} />
                                        </div>
                                        <div className={`${style['sidebar__top--userheader-infocontainer']}`}>
                                            <p className={`${style['sidebar__top--userheader-infocontainer__header']}`}>{lang.orderflow_myself_agent_details_before_accept.wiew_agent_personal_message_sidebar.header}</p>
                                            <p className={`${style['sidebar__top--userheader-infocontainer__name']}`}>{this.props.agent.name}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${style['sidebar__body']}`}>
                                    <p className={`${style['sidebar__body--header3']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.message}</p>
                                    <div className={`${style['sidebar__body--inputcontainer']}`}>
                                        <textarea value={this.state.message} onChange={(e) => this.setState({message: e.target.value})} className={`${style['sidebar__body--inputcontainer-textarea']}`} placeholder={`${lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.personal_message_filler_cash_one} ${this.props.agent.firstname} ${lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.personal_message_filler_cash_two}`}/>
                                    </div>
                                    <div className={`${style['sidebar__body--line']}`}/>
                                    <div className={`${style['sidebar__body--flexcontainer2']}`}>
                                        <span className={`${style['sidebar__body--flexcontainer2-left']}`}/>
                                        <p className={`${style['sidebar__body--flexcontainer2-right']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.cash_benefit_one}</p>
                                    </div>
                                    <div className={`${style['sidebar__body--flexcontainer2']}`}>
                                        <span className={`${style['sidebar__body--flexcontainer2-left']}`}/>
                                        <p className={`${style['sidebar__body--flexcontainer2-right']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.cash_benefit_two}</p>
                                    </div>

                                </div>
                                <div className={`${style['sidebar__bottom']}`}>
                                    <Link to="#" onClick={() => this.props.selectFunc(this.props.agent.id, "In person", this.state.message, null)}><input type="button" className={`${style['sidebar__bottom--button']}`} value={lang.orderflow_myself_agent_details_before_accept.view_agent_details_sidebar.red_button_request_delivery}/></Link>
                                </div>
                            </Fragment>
                        )
                    }
                children={<Fragment/>}
                touchHandleWidth={0}
                open={this.props.isOpen}
                pullRight={true}
                rootClassName={style['sidebarbg']}
                sidebarClassName={style['sidebar']}
                onSetOpen={() => this.props.goBack('privateSidebar3_inPerson')}
                styles={ !this.props.isOpen ?
                    {root:{
                        pointerEvents: 'none'
                    }}
                    :
                    {}
                }
            />
        )
    }
}



class PrivateOrderStep3_MobilePayment extends Component {
    constructor(props){
        super(props)
        this.state = {
            mobile_application: '',
            mobile_identifier: '',
            message: ''

        }
    }

    componentDidUpdate(prevprops){
        preventScrolling(prevprops.isOpen, this.props.isOpen)
        if(prevprops.agent != this.props.agent){
            this.setState({mobile_application: '', mobile_identifier: '', message: ''})
        }
    }
    componentWillUnmount(){
        document.body.style.height = 'auto'
        document.body.style.overflowY = 'auto'
    }


    render() {
        return (
            <Sidebar
                sidebar=
                    {
                        this.props.agent && (
                            <Fragment>
                                <div className={`${style['sidebar__top']}`}>
                                    <Link to="#" onClick={() => this.props.goBack('privateSidebar3_mobilePayment')}><FontAwesomeIcon icon={faArrowLeft} className={style['sidebar__top--closearrow']} /></Link>
                                    <div className={`${style['sidebar__top--userheader']}`}>
                                        <div className={`${style['sidebar__top--userheader-imagecontainer']}`}>
                                            <img src={this.props.agent.avatar ? `/api/user/avatar/${this.props.agent.avatar}` : defaultprofile} alt="Agent avatar" className={`${style['sidebar__top--userheader-imagecontainer__image']}`} />
                                        </div>
                                        <div className={`${style['sidebar__top--userheader-infocontainer']}`}>
                                            <p className={`${style['sidebar__top--userheader-infocontainer__header']}`}>{lang.orderflow_myself_agent_details_before_accept.wiew_agent_personal_message_sidebar.header}</p>
                                            <p className={`${style['sidebar__top--userheader-infocontainer__name']}`}>{this.props.agent.name}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${style['sidebar__body']}`}>
                                    {/* Mobile payment */}
                                    <p className={`${style['sidebar__body--header']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.receive_other_subtext}</p>
                                    <p className={`${style['sidebar__body--text']}`}>{this.props.agent.firstname} {lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.other_offers} {constants.makeMethods(['Mobile payment'], this.props.agent.deliver_mobile_applications)}.</p>
                                    <div className={`${style['sidebar__body--inputcontainer']} ${style['sidebar__body--spacer4']}`}>

                                        {/* preferred mobile payment solution */}
                                        <p className={`${style['sidebar__body--header3']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.receive_other_select}</p>
                                        <div className={`${style['sidebar__body--inputcontainer']}`}>
                                            <select defaultValue={this.state.mobile_application} className={style['sidebar__body--inputcontainer-select']} onChange={(e) => this.setState({mobile_application: e.target.value})}>
                                                <option key={0} value="" disabled>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.receive_other_click}</option>
                                                {this.props.agent.deliver_mobile_applications && this.props.agent.deliver_mobile_applications.map((e,i) => <option key={i} value={e}>{e}</option>)}
                                            </select>
                                        </div>
                                        {/* Recipient details */}
                                        <p className={`${style['sidebar__body--header3']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.other_address}</p>
                                        <div className={`${style['sidebar__body--inputcontainer']}`}>
                                            <img src={iconAccountno} className={`${style['sidebar__body--inputcontainer-icon']}`}/>
                                            <input type="text" value={this.state.mobile_identifier} onChange={(e) => this.setState({mobile_identifier: e.target.value})} className={`${style['sidebar__body--inputcontainer-input']}`} placeholder={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.other_address_filler}/>
                                        </div>


                                        {/* Personal message - <div className={`${style['sidebar__body--line']}`}/> - <p className={`${style['sidebar__body--inputcontainer-helpertext']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.other_help_text}</p> */}
                                        <p className={`${style['sidebar__body--header3']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.message}</p>
                                        <div className={`${style['sidebar__body--inputcontainer']}`}>
                                            <textarea value={this.state.message} onChange={(e) => this.setState({message: e.target.value})} className={`${style['sidebar__body--inputcontainer-textarea']}`} placeholder={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.personal_message_transfer_filler}/>
                                        </div>
                                        <div className={`${style['sidebar__body--line']}`}/>
                                        <div className={`${style['sidebar__body--flexcontainer2']}`}>
                                            <span className={`${style['sidebar__body--flexcontainer2-left']}`}/>
                                            <p className={`${style['sidebar__body--flexcontainer2-right']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.other_benefit_one}</p>
                                        </div>
                                        <div className={`${style['sidebar__body--flexcontainer2']}`}>
                                            <span className={`${style['sidebar__body--flexcontainer2-left']}`}/>
                                            <p className={`${style['sidebar__body--flexcontainer2-right']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.other_benefit_two}</p>
                                        </div>
                                        <div className={`${style['sidebar__body--flexcontainer2']}`}>
                                            <span className={`${style['sidebar__body--flexcontainer2-left']}`}/>
                                            <p className={`${style['sidebar__body--flexcontainer2-right']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.other_benefit_three}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${style['sidebar__bottom']}`}>
                                    <Link to="#" onClick={() => this.props.selectFunc(this.props.agent.id, "Mobile payment", this.state.message, {mobile_application: this.state.mobile_application, mobile_identifier: this.state.mobile_identifier})}>
                                        <input type="button" className={`${style['sidebar__bottom--button']}`} value={lang.orderflow_myself_agent_details_before_accept.view_agent_details_sidebar.red_button_request_delivery}/>
                                    </Link>
                                </div>
                            </Fragment>
                        )
                    }
                children={<Fragment/>}
                touchHandleWidth={0}
                open={this.props.isOpen}
                pullRight={true}
                rootClassName={style['sidebarbg']}
                sidebarClassName={style['sidebar']}
                onSetOpen={() => this.props.goBack('privateSidebar3_mobilePayment')}
                styles={ !this.props.isOpen ?
                    {root:{
                        pointerEvents: 'none'
                    }}
                    :
                    {}
                }
            />
        )
    }
}

class PrivateOrderStep3_BankTransfer extends Component {
    constructor(props){
        super(props)
        this.state = {
            bankcode: '',
            accountnum: '',
            message: ''

        }
    }

    componentDidUpdate(prevprops){
        preventScrolling(prevprops.isOpen, this.props.isOpen)
        if(prevprops.agent != this.props.agent){
            this.setState({message: ''})
        }
    }
    componentWillUnmount(){
        document.body.style.height = 'auto'
        document.body.style.overflowY = 'auto'
    }


    render() {
        return (
            <Sidebar
                sidebar=
                    {
                        this.props.agent && (
                            <Fragment>
                                <div className={`${style['sidebar__top']}`}>
                                    <Link to="#" onClick={() => this.props.goBack('privateSidebar3_bankTransfer')}><FontAwesomeIcon icon={faArrowLeft} className={style['sidebar__top--closearrow']} /></Link>
                                    <div className={`${style['sidebar__top--userheader']}`}>
                                        <div className={`${style['sidebar__top--userheader-imagecontainer']}`}>
                                            <img src={this.props.agent.avatar ? `/api/user/avatar/${this.props.agent.avatar}` : defaultprofile} alt="Agent avatar" className={`${style['sidebar__top--userheader-imagecontainer__image']}`} />
                                        </div>
                                        <div className={`${style['sidebar__top--userheader-infocontainer']}`}>
                                            <p className={`${style['sidebar__top--userheader-infocontainer__header']}`}>{lang.orderflow_myself_agent_details_before_accept.wiew_agent_personal_message_sidebar.header}</p>
                                            <p className={`${style['sidebar__top--userheader-infocontainer__name']}`}>{this.props.agent.name}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${style['sidebar__body']}`}>
                                    {/* bank transfer */}
                                    <p className={`${style['sidebar__body--header']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.receive_transfer_header}</p>
                                    <p className={`${style['sidebar__body--text']}`}>{this.props.agent.firstname} {lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.send_by_transfer}</p>
                                    <div className={`${style['sidebar__body--inputcontainer']} ${style['sidebar__body--spacer4']}`}>
                                        {/* preferred mobile payment solution */}
                                        <p className={`${style['sidebar__body--header3']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.bank_extra_subheader}</p>
                                        <div className={`${style['sidebar__body--inputcontainer']}`}>
                                            <img src={iconBankcode} className={`${style['sidebar__body--inputcontainer-icon']}`}/>
                                            <input type="text" value={this.state.mobile_application} onChange={(e) => this.setState({bankcode: e.target.value})} className={`${style['sidebar__body--inputcontainer-input']}`} placeholder={""}/>
                                        </div>
                                        {/* Recipient details */}
                                        <p className={`${style['sidebar__body--header3']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.bank_extra_subheader_two}</p>
                                        <div className={`${style['sidebar__body--inputcontainer']}`}>
                                            <img src={iconAccountnonew} className={`${style['sidebar__body--inputcontainer-icon']}`}/>
                                            <input type="text" value={this.state.mobile_identifier} onChange={(e) => this.setState({accountnum: e.target.value})} className={`${style['sidebar__body--inputcontainer-input']}`} placeholder={""}/>
                                        </div>
                                        <div className={`${style['sidebar__body--line']}`}/>
                                        {/* Personal message */}
                                        <p className={`${style['sidebar__body--header3']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.message}</p>
                                        <div className={`${style['sidebar__body--inputcontainer']}`}>
                                            <textarea value={this.state.message} onChange={(e) => this.setState({message: e.target.value})} className={`${style['sidebar__body--inputcontainer-textarea']}`} placeholder={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.personal_message_transfer_filler}/>
                                        </div>
                                        <div className={`${style['sidebar__body--flexcontainer2']}`}>
                                            <span className={`${style['sidebar__body--flexcontainer2-left']}`}/>
                                            <p className={`${style['sidebar__body--flexcontainer2-right']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.transfer_benefit_one}</p>
                                        </div>
                                        <div className={`${style['sidebar__body--flexcontainer2']}`}>
                                            <span className={`${style['sidebar__body--flexcontainer2-left']}`}/>
                                            <p className={`${style['sidebar__body--flexcontainer2-right']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.transfer_benefit_two}</p>
                                        </div>
                                        <div className={`${style['sidebar__body--flexcontainer2']}`}>
                                            <span className={`${style['sidebar__body--flexcontainer2-left']}`}/>
                                            <p className={`${style['sidebar__body--flexcontainer2-right']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.transfer_benefit_three}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${style['sidebar__bottom']}`}>
                                    <Link to="#" onClick={() => this.props.selectFunc(this.props.agent.id, "Bank transfer", this.state.message, {bankcode: this.state.bankcode, accountnum: this.state.accountnum})}>
                                        <input type="button" className={`${style['sidebar__bottom--button']}`} value={lang.orderflow_myself_agent_details_before_accept.view_agent_details_sidebar.red_button_request_delivery}/>
                                    </Link>
                                </div>
                            </Fragment>
                        )
                    }
                children={<Fragment/>}
                touchHandleWidth={0}
                open={this.props.isOpen}
                pullRight={true}
                rootClassName={style['sidebarbg']}
                sidebarClassName={style['sidebar']}
                onSetOpen={() => this.props.goBack('privateSidebar3_bankTransfer')}
                styles={ !this.props.isOpen ?
                    {root:{
                        pointerEvents: 'none'
                    }}
                    :
                    {}
                }
            />
        )
    }
}



class PublicSidebar extends Component {
    constructor(props){
        super(props);
        this.state = {
            street: '',
            city: '',
            zip: '',
            country: '',
            lat: '',
            long: '',
            description: '',
            max_fee: 6,
            deliver_payment1: false,
            deliver_payment2: false,
            deliver_payment3: false,
            bankcode: '',
            accountnum: '',
            mobile_identifier: '',
            mobile_application: '',
            message: ''
        }
    }

    geocodeLocation = async (e) => {
        var z = await geocodeByPlaceId(e.value.place_id)
        const len = z[0].address_components.length
        var street = ''
        var city = ''
        var country = ''
        if(Object.keys(z[0]).includes("plus_code") && z[0].plus_code.compound_code){
            var area = z[0].plus_code.compound_code.split(",")
            var city = area[0].split(" ").slice(1).join(" ")
            var country = area[1].substring(1)
        }
        var zip = ''
        const lat = await z[0].geometry.location.lat()
        const long = await z[0].geometry.location.lng()
        for(var i = 0; i < len; i++){
            const ts = z[0].address_components[i].types
            const value = z[0].address_components[i].long_name
            if(ts.includes("route")){
                street = value
            }
            else if(ts.includes("postal_code")){
                zip = value
            }
            else if(!city && ts.includes("locality")){
                city = value
            }
            else if(!country && ts.includes("country")){
                country = value
            }
        }
        this.setState({
            street: street,
            city: city,
            zip: zip,
            country: country,
            long: long,
            lat: lat
        })
    }


    submitpublic = () => {
        let formData = new FormData();
        formData.append("max_fee", this.state.max_fee)
        formData.append("public", true)
        formData.append("description", this.state.description)
        // formData.append("street", this.state.street)
        formData.append("city", this.state.city)
        formData.append("zip", this.state.zip)
        formData.append("country", this.state.country)
        formData.append("firstname", this.props.firstname)
        formData.append("lastnames", this.props.lastnames)
        formData.append("phone_cc", this.props.phone_cc)
        formData.append("phone_number", this.props.phone_number)
        formData.append("avatar", this.props.avatar)
        formData.append("location", [this.state.lat, this.state.long])
        formData.append("message", this.state.message)
        formData.append("token", this.props.match.params.token)

        var payment_methods = []
        if(this.state.deliver_payment1){payment_methods.push("In person")}
        if(this.state.deliver_payment2){payment_methods.push("Bank transfer")}
        if(this.state.deliver_payment3){payment_methods.push("Mobile payment")}
        formData.append("payment_methods", payment_methods)

        // Bank transfer was chosen
        if(this.state.deliver_payment2){
            formData.append("bankcode", this.state.bankcode)
            formData.append("accountnum", this.state.accountnum)
        }
        // Mobile payment was chosen
        if(this.state.deliver_payment3){
            formData.append("mobile_identifier", this.state.mobile_identifier)
            formData.append("mobile_application", this.state.mobile_application)
        }
        axios.post('/api/order/finish_order', formData)
        .then(resp => {
            alert(resp.data.message);
            this.props.public_continue(resp.data.creator)
        }).catch(err => {
            alert(err.response.data.message);
        })
    }

    setFee = (e) => {
        const val = e.target.value
        if(val === ''){
            this.setState({max_fee: ''})
        }
        else if(val < constants.min_fee){
            this.setState({max_fee: constants.min_fee})
        }
        else if(val > (constants.max_fee_num)){
            this.setState({max_fee: (constants.max_fee_num)})
        }
        else{
            this.setState({max_fee: val})
        }
    }

    handlePayment(e){
        const x = !this.state[e.target.name]
        this.setState({[e.target.name]: x})
    }

    componentDidUpdate(prevprops){
        preventScrolling(prevprops.isOpen, this.props.isOpen)
    }
    componentWillUnmount(){
        document.body.style.height = 'auto'
        document.body.style.overflowY = 'auto'
    }


    render() {
        return (

                <Sidebar
                    sidebar=
                        {
                            <Fragment>
                                <div className={`${style['sidebar__top']}`}>
                                    <Link to="#" onClick={() => this.props.handler('publicsidebar')}><FontAwesomeIcon icon={faTimes} className={style['sidebar__top--close']} /></Link>
                                    <p className={`${style['sidebar__top--title']}`}>{lang.create_public_order.header}</p>
                                </div>
                                <div className={`${style['sidebar__body']}`}>
                                    <p className={`${style['sidebar__body--text']}`}>{lang.orderflow_myself_recruit_a_friend_or_public_order.white_box.top_button.text_under_headline}</p>
                                    <div className={`${style['sidebar__body--spacer4']}`}/>
                                    <div className={`${style['sidebar__body--line']}`}/>
                                    <div className={`${style['sidebar__body--spacer']}`}/>
                                    <p className={`${style['sidebar__body--header2']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.select_method}</p>
                                    <p className={`${style['sidebar__body--text2']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.one_or_more}</p>
                                    {/* How */}
                                    <div className={`${style['sidebar__body--paymentcontainer']}`}>
                                        {/*In person*/}
                                        <div className={`${style['sidebar__body--paymentcontainer-item']}`}>
                                            <div className={`${style['sidebar__body--paymentcontainer-item__left']}`}>
                                                <Checkbox
                                                    defaultChecked={this.state.deliver_payment1}
                                                    onClick={(e) => this.handlePayment(e)}
                                                    name="deliver_payment1"
                                                />
                                            </div>
                                            <div className={`${style['sidebar__body--paymentcontainer-item__right']}`}>
                                                <p className={`${style['sidebar__body--paymentcontainer-item__right--header']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.receive_cash_header}</p>
                                                <p className={`${style['sidebar__body--paymentcontainer-item__right--body']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.receive_cash_subtext}</p>
                                            </div>
                                        </div>
                                        {/* Bank transfer */}
                                        <div className={`${style['sidebar__body--paymentcontainer-item']}`}>
                                            <div className={`${style['sidebar__body--paymentcontainer-item__left']}`}>
                                                <Checkbox
                                                    defaultChecked={this.state.deliver_payment2}
                                                    onClick={(e) => this.handlePayment(e)}
                                                    name="deliver_payment2"
                                                />
                                            </div>
                                            <div className={`${style['sidebar__body--paymentcontainer-item__right']}`}>
                                                <p className={`${style['sidebar__body--paymentcontainer-item__right--header']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.receive_transfer_header}</p>
                                                <p className={`${style['sidebar__body--paymentcontainer-item__right--body']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.receive_transfer_subtext}</p>
                                            </div>
                                        </div>
                                        {/* Mobile payment */}
                                        <div className={`${style['sidebar__body--paymentcontainer-item']}`}>
                                            <div className={`${style['sidebar__body--paymentcontainer-item__left']}`}>
                                                <Checkbox
                                                    defaultChecked={this.state.deliver_payment3}
                                                    onClick={(e) => this.handlePayment(e)}
                                                    name="deliver_payment3"
                                                />
                                            </div>
                                            <div className={`${style['sidebar__body--paymentcontainer-item__right']}`}>
                                                <p className={`${style['sidebar__body--paymentcontainer-item__right--header']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.receive_other_header}</p>
                                                <p className={`${style['sidebar__body--paymentcontainer-item__right--body']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.receive_other_subtext} {lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.preferred_filler}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Where */}
                                    <Fragment>
                                        <div className={`${style['sidebar__body--spacer7']}`}/>
                                        <p className={`${style['sidebar__body--header2']}`}>{lang.create_public_order.sub_header_where}</p>
                                        <p className={`${style['sidebar__body--text2']}`}>{lang.create_public_order.text_under_subheader}</p>
                                        <div className={`${style['sidebar__body--searchcontainer']} ${style['sidebar__body--spacer3']}`}>
                                            <Zoom width="17.49px" height="17.53px" viewBox="0 0 17.5 17.5" className={`${style['sidebar__body--searchcontainer-icon']}`}/>
                                            <GooglePlacesAutocomplete
                                                debounce={500}
                                                selectProps={
                                                    {
                                                        placeholder: lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.filler_address,
                                                        onChange: (e) => this.geocodeLocation(e),
                                                    }
                                                }
                                            />
                                        </div>
                                    </Fragment>
                                    {/* Bank transfers foldout*/}
                                    {this.state.deliver_payment2 && (
                                        <Fragment>
                                            <p className={`${style['sidebar__body--header']}  ${style['sidebar__body--spacer']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.receive_transfer_header}</p>
                                            <p className={`${style['sidebar__body--header3']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.bank_extra_subheader}</p>
                                            <div className={`${style['sidebar__body--inputcontainer']}`}>
                                                <img src={iconBankcode} className={`${style['sidebar__body--inputcontainer-icon']}`}/>
                                                <input type="text" value={this.state.bankcode} onChange={(e) => this.setState({bankcode: e.target.value})} className={`${style['sidebar__body--inputcontainer-input']}`} placeholder={lang.sidebars.check.bank_code}/>
                                            </div>

                                            <p className={`${style['sidebar__body--header3']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.bank_extra_subheader_two}</p>
                                            <div className={`${style['sidebar__body--inputcontainer']}`}>
                                                <img src={iconAccountnonew} className={`${style['sidebar__body--inputcontainer-icon']}`}/>
                                                <input type="text" value={this.state.accountnum} onChange={(e) => this.setState({accountnum: e.target.value})} className={`${style['sidebar__body--inputcontainer-input']}`} placeholder={lang.sidebars.check.account_no}/>
                                            </div>
                                        </Fragment>
                                    )}

                                    {/* Mobile payment foldout */}
                                    {this.state.deliver_payment3 && (
                                        <Fragment>
                                            <p className={`${style['sidebar__body--header']}  ${style['sidebar__body--spacer']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.receive_other_header}</p>

                                            <p className={`${style['sidebar__body--header3']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.receive_other_subtext}</p>
                                            <div className={`${style['sidebar__body--inputcontainer']}`}>
                                                <img src={iconOther} className={`${style['sidebar__body--inputcontainer-icon']}`}/>
                                                <input type="text" value={this.state.mobile_application} onChange={(e) => this.setState({mobile_application: e.target.value})} className={`${style['sidebar__body--inputcontainer-input']}`} placeholder={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.other_filler}/>
                                                <p className={`${style['sidebar__body--inputcontainer-helpertext']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.other_filler_sub}</p>
                                            </div>

                                            <p className={`${style['sidebar__body--header3']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.other_address}</p>
                                            <div className={`${style['sidebar__body--inputcontainer']}`}>
                                                <img src={iconAccountno} className={`${style['sidebar__body--inputcontainer-icon']}`}/>
                                                <input type="text" value={this.state.mobile_identifier} onChange={(e) => this.setState({mobile_identifier: e.target.value})} className={`${style['sidebar__body--inputcontainer-input']}`} placeholder={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.other_address_filler}/>
                                            </div>
                                        </Fragment>
                                    )}

                                    {/* Message for the agent */}
                                    <p className={`${style['sidebar__body--header']}  ${style['sidebar__body--spacer']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.message}</p>
                                    <p className={`${style['sidebar__body--header3']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.personal_message}</p>
                                    <div className={`${style['sidebar__body--inputcontainer']}`}>
                                        <textarea value={this.state.message} onChange={(e) => this.setState({message: e.target.value})} className={`${style['sidebar__body--inputcontainer-textarea']}`} placeholder={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.message_sub}/>
                                    </div>

                                    <p className={`${style['sidebar__body--header2']} ${style['sidebar__body--spacer2']}`}>{lang.create_public_order.subheader_fee}</p>
                                    <p className={`${style['sidebar__body--text2']}`}>{lang.create_public_order.text_under_subheader2}</p>
                                    <div className={`${style['sidebar__body--inputcontainer']} ${style['sidebar__body--spacer2']}`} style={{width: '100px'}}>
                                        <label htmlFor="feeField" className={style['sidebar__body--inputcontainer-inlinelabel']}>%</label>
                                        <input type="number" id="feeField" onChange={(e) => this.setFee(e)} className={`${style['sidebar__body--inputcontainer-input']}`} style={{paddingLeft: '29px'}} value={this.state.max_fee} />
                                    </div>
                                </div>

                                <div className={`${style['sidebar__bottom']}`}>
                                    <Link to="#" onClick={() => this.submitpublic()}><input type="button" className={`${style['sidebar__bottom--button']}`} value={lang.create_public_order.create_red_button}/></Link>
                                </div>
                            </Fragment>
                        }
                    children={<Fragment/>}
                    touchHandleWidth={0}
                    open={this.props.isOpen}
                    pullRight={true}
                    rootClassName={style['sidebarbg']}
                    sidebarClassName={style['sidebar']}
                    onSetOpen={() => this.props.handler('publicsidebar')}
                    styles={ !this.props.isOpen ?
                        {root:{
                            pointerEvents: 'none'
                        }}
                        :
                        {}
                    }
                />
        )
    };
}

class HelpSidebar extends Component {
    constructor(props){
        super(props);
        this.state = {
            email: '',
            text: '',
            disabled: false
        }
    }

    submit = async () => {
        if(this.state.disabled) return
        this.setState({disabled: true})
        await axios.post('/api/support/question', {
            email: this.state.email,
            text: this.state.text
        }).then(resp => {
            alert(resp.data.message)
            this.props.handler("helpSidebar")
            this.setState({text: '', disabled: false}) // reset
        }).catch(err => {
            alert(err.response.data.message);
            this.setState({disabled: false})
        })

    }

    componentDidUpdate(prevprops){
        preventScrolling(prevprops.isOpen, this.props.isOpen)
    }
    componentWillUnmount(){
        document.body.style.height = 'auto'
        document.body.style.overflowY = 'auto'
    }


    render() {

        return (
            <Sidebar
                sidebar=
                    {
                    <Fragment>
                        <div className={`${style['sidebar__top']}`}>
                            <Link to="#" onClick={() => this.props.handler("helpSidebar")}><FontAwesomeIcon icon={faTimes} className={style['sidebar__top--close']} /></Link>
                            <p className={`${style['sidebar__top--title']}`}>{lang.help.header}</p>
                        </div>
                        <div className={`${style['sidebar__body']}`}>
                            <p className={`${style['sidebar__body--text']}`}>{lang.help.subheader}</p>

                            <div className={`${style['sidebar__body--spacer']}`}/>
                            {!this.props.isLoggedIn && (
                                <div className={`${style['sidebar__body--inputcontainer']}`}>
                                    <FontAwesomeIcon icon={faPencilAlt} className={`${style['sidebar__body--inputcontainer-icon']}`} />
                                    <input type="email" name="loginEmail" onChange={(e) => this.setState({email: e.target.value})} className={`${style['sidebar__body--inputcontainer-input']}`} value={this.state.email} placeholder={lang.sign_up.filler_email} />
                                </div>
                            )}
                            <div className={`${style['sidebar__body--inputcontainer']}`}>
                                <textarea className={`${style['sidebar__body--inputcontainer-textarea']}`} placeholder={lang.help.filler} onChange={(e) => this.setState({text: e.target.value})} value={this.state.text}/>
                            </div>
                            <Link to="#" onClick={() => this.submit()}><input type="button" className={`${style['sidebar__body--button']}`} value={lang.help.submit_button}/></Link>
                        </div>
                    </Fragment>
                    }
                children={<Fragment/>}
                touchHandleWidth={0}
                open={this.props.isOpen}
                pullRight={true}
                rootClassName={style['sidebarbg']}
                sidebarClassName={style['sidebar']}
                onSetOpen={() => this.props.handler("helpSidebar")}
                styles={ !this.props.isOpen ?
                    {root:{
                        pointerEvents: 'none'
                    }}
                    :
                    {}
                }
            />
        )
    };
}
class RecipientSidebar extends Component {
    constructor(props){
        super(props);
        this.state = {
            releasing: false
        }
    }


    accept_agent = (userid, orderid) => {
        axios.post('/api/order/list/user/active/accept_user', {user_id: userid, order_id: orderid})
        .then(response => {
            window.location.reload(false) // temp dirty solution
        })
        .catch(error => {
            alert(error.response.data.message);
        })

    }

    decline_agent = (userid, orderid) => {
        axios.post('/api/order/list/user/active/decline_user', {user_id: userid, order_id: orderid})
        .then(response => {
            window.location.reload(false) // temp dirty solution
        })
        .catch(error => {
            alert(error.response.data.message);
        })

    }

    cancel_order = (orderid) => {
        var test = confirm(lang.deliverflow_private_order_overview.password_or_cancel.cancel_consequences)
        this.setState({releasing: true})
        if(!test){return}
        axios.post('/api/order/list/user/active/cancel_order', {id: orderid})
        .then(response => {
            window.location.reload(false)
        })
        .catch(error => {
            try{
                alert(error.response.data.message);
            } catch(e){
                alert(error)
            }
            this.setState({releasing: false})
        })
    }

    Make_Reviews = (props) => {
        const reviews = props.agent_info.ratings
        var maxCount = 0
        if(props.agent_info.reviewCount === -1){
            maxCount = reviews.length
        } else{
            maxCount = 0
        }
        var ret_reviews = [];
        for (var i = 0; i < maxCount; i++) {
            const review = reviews[i]
            if(review){
                ret_reviews.push(
                    <div key={i} className={`${style['sidebar__agent--ratingscontainer-reviewcontainer']}`}>
                        <div className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__top']}`}>
                            <div className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__top--agent']}`}>
                                <img src={review.avatar ? `/api/user/avatar/${review.avatar}` : defaultprofile} alt="Recipient avatar" className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__top--agent-avatar']}`}/>
                                <div className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__top--agent-info']}`}>
                                    <p className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__top--agent-info__header']}`}>{review.name}</p>
                                    <p className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__top--agent-info__body']}`}>{format_date(review.rated_when)}</p>
                                </div>
                            </div>
                            <div className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__top--starcontainer']}`}>
                                <MakeStars ratings={review.rating}/>
                            </div>
                        </div>
                        <div className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__bottom']}`}>
                            <p className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__bottom--review']}`}>{review.review}</p>
                            {review.response && (
                                <div className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__bottom--responsecontainer']}`}>
                                    <div className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__bottom--responsecontainer-infocontainer']}`}>
                                        <p className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__bottom--responsecontainer-infocontainer__from']}`}>Response</p>
                                        <p className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__bottom--responsecontainer-infocontainer__when']}`}>{format_date(review.response.response_when)}</p>
                                    </div>
                                    <div className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__bottom--responsecontainer-body']}`}>
                                        <p className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__bottom--responsecontainer-body__text']}`}>{review.response.answer}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )
            }
        }

        return ret_reviews
    }


    Make_requested_agents = (props) => {
        var items = []

        for(const [index, agent_info] of this.props.order.agent_info.entries()){
            if(!agent_info.reviewCount){
                agent_info.reviewCount = 0
            }
            items.push(
                <Fragment key={index}>
                    <div className={`${style['sidebar__body--flexcontainer']} ${style['sidebar__body--spacer3']}`}>
                        <div className={`${style['sidebar__body--flexcontainer-container']}`}>
                            <div className={`${style['sidebar__body--flexcontainer-container__subcontainer']}`}>
                                <img src={agent_info.avatar ? `/api/user/avatar/${agent_info.avatar}` : defaultprofile} alt="Avatar" className={`${style['sidebar__body--flexcontainer-container__subcontainer--avatar']}`}/>
                                <div>
                                    <p className={`${style['sidebar__body--flexcontainer-container__subcontainer--darkheader']}`}>{`${agent_info.name}`}</p>
                                    <MakeStars ratings={agent_info.ratings.map(r => r.rating)}/>
                                </div>
                            </div>
                        </div>
                        <div className={`${style['sidebar__body--flexcontainer-container']}`}>
                            <p className={`${style['sidebar__body--flexcontainer-container__header']}`}>{lang.orderflow_amount.white_box.delivery_fee}</p>
                            <p className={`${style['sidebar__body--flexcontainer-container__text']}`}>{`${agent_info.fee}%`}</p>
                        </div>
                    </div>
                    <div className={`${style['sidebar__body--flexcontainer']} ${style['sidebar__body--spacer3']}`}>
                        <div className={`${style['sidebar__body--flexcontainer-container']}`}>
                            <p className={`${style['sidebar__body--flexcontainer-container__header']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.delivery_method}</p>
                            <p className={`${style['sidebar__body--flexcontainer-container__text']}`}>{agent_info.method}</p>
                        </div>
                        {agent_info.method === 'Mobile payment' && (
                            <div className={`${style['sidebar__body--flexcontainer-container']}`}>
                                <p className={`${style['sidebar__body--flexcontainer-container__header']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.payment_solution}</p>
                                <p className={`${style['sidebar__body--flexcontainer-container__text']}`}>{this.props.order.payment_info.mobile_application}</p>
                            </div>
                        )}
                    </div>
                    {/*Profile text */}
                    <div className={`${style['sidebar__body--flexcontainer-container']} ${style['sidebar__body--spacer3']}`}>
                        <div className={`${style['sidebar__body--flexcontainer-container__subcontainer']}`}>
                            <div>
                                <p className={`${style['sidebar__body--flexcontainer-container__subcontainer--header']}`}>{`Profile text`}</p>
                                <p className={`${style['sidebar__body--flexcontainer-container__subcontainer--bold']}`}>{`${agent_info.profile_text}`}</p>
                            </div>
                        </div>
                    </div>

                    {/* Your message to the agent*/}
                    <div className={`${style['sidebar__body--flexcontainer-container']} ${style['sidebar__body--spacer3']}`}>
                        <div className={`${style['sidebar__body--flexcontainer-container__subcontainer']}`}>
                            <div>
                                <p className={`${style['sidebar__body--flexcontainer-container__subcontainer--header']}`}>{`Your message`}</p>
                                <p className={`${style['sidebar__body--flexcontainer-container__subcontainer--bold']}`}>{`${agent_info.message}`}</p>
                            </div>
                        </div>
                    </div>

                    {this.props.order.status === 'Requested' && agent_info.ratings && agent_info.ratings.length > 0 && (
                        <div className={`${style['sidebar__agent--ratingscontainer']} ${style['sidebar__body--spacer2']}`}>
                            <div className={`${style['sidebar__agent--ratingscontainer-infocontainer']}`}>
                                <p className={`${style['sidebar__agent--ratingscontainer-infocontainer__reviewsheader']}`}>{`Reviews`} ({agent_info.ratings.length})</p>
                                {(agent_info.ratings.length > 0 && agent_info.reviewCount === 0) && (
                                    <Link to="#" onClick={() => agent_info.reviewCount = -1} className={`${style['sidebar__agent--ratingscontainer-infocontainer__allheader']}`}>{`See all reviews`}</Link>
                                )}
                                {(agent_info.reviewCount === -1) && (
                                    <Link to="#" onClick={() => agent_info.reviewCount = 0} className={`${style['sidebar__agent--ratingscontainer-infocontainer__allheader']}`}>{`Hide all reviews`}</Link>
                                )}
                            </div>
                            <this.Make_Reviews agent_info={agent_info}/>
                        </div>
                    )}

                    {this.props.order.recipient === 'self' && (
                        <div className={`${style['sidebar__body--flexcontainer']} ${style['sidebar__body--spacer5']}`}>
                            <input type="button" onClick={() => this.accept_agent(agent_info.id, props.id)} className={`${style['sidebar__body--flexcontainer-acceptbutton']}`} value={lang.deliverflow_private_order_view_order_view_to_accept.accept_or_decline.red_button_accept}/>
                            <input type="button" onClick={() => this.decline_agent(agent_info.id, props.id)} className={`${style['sidebar__body--flexcontainer-declinebutton']}`} value={lang.deliverflow_private_order_view_order_view_to_accept.accept_or_decline.white_button_decline}/>
                        </div>
                    )}

                </Fragment>
            )
        }
        return(
            <Fragment>
                {items}
            </Fragment>
        )
    }

    componentDidUpdate(prevprops){
        preventScrolling(prevprops.isOpen, this.props.isOpen)
        if(prevprops.order != this.props.order){
            this.setState({releasing: false})
        }
    }
    componentWillUnmount(){
        document.body.style.height = 'auto'
        document.body.style.overflowY = 'auto'
    }


    render() {
        return (

            <Sidebar
                sidebar=
                    {
                        Object.keys(this.props.order).length > 0 && (
                            <Fragment>
                                <div className={`${style['sidebar__top']}`}>
                                    <Link to="#" onClick={() => this.props.handler()}><FontAwesomeIcon icon={faTimes} className={style['sidebar__top--close']} /></Link>
                                    <div className={`${style['sidebar__top--userheader']}`}>
                                        <div className={`${style['sidebar__top--userheader-imagecontainer']}`}>
                                            <img src={this.props.order.avatar ? `/api/order/avatar/${this.props.order.avatar}` : this.props.order.recipient === 'self' ? (this.props.user.avatar ? '/api/user/image/avatar' : defaultprofile) : defaultprofile} alt="Recipient avatar" className={`${style['sidebar__top--userheader-imagecontainer__image']}`} />
                                        </div>
                                        <div className={`${style['sidebar__top--userheader-infocontainer']}`}>
                                            <p className={`${style['sidebar__top--userheader-infocontainer__header']}`}>{lang.orderflow_amount.top_menu.recipient}</p>
                                            {this.props.order.status === 'Missing info'
                                                ? this.props.order.recipient === 'self'
                                                    ? <p className={`${style['sidebar__top--userheader-infocontainer__name']}`}>{this.props.order.name}</p>
                                                    : <p className={`${style['sidebar__top--userheader-infocontainer__name']}`}>{`${this.props.order.email}`}</p>
                                                : <p className={`${style['sidebar__top--userheader-infocontainer__name']}`}>{`${this.props.order.name}`}</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={`${style['sidebar__body']}`}>
                                    <div className={`${style['sidebar__body--flexcontainer']}`}>
                                        <div className={`${style['sidebar__body--flexcontainer-container']}`}>
                                            <p className={`${style['sidebar__body--flexcontainer-container__header']}`}>{lang.my_orders.main_window.active_orders.receive_fat_grey}</p>
                                            <div className={`${style['sidebar__body--flexcontainer-container__subcontainer']}`}>
                                                <ReactFlagsSelect
                                                    className={`${style['sidebar__body--flexcontainer-container__subcontainer--flag']} only-flag2`}
                                                    selected={constants.shorthand_curr_to_flag[this.props.order.currency]}
                                                    showSelectedLabel={false}
                                                    placeholder=""
                                                    disabled={true}
                                                    selectedSize={16}
                                                 />
                                                <p className={`${style['sidebar__body--flexcontainer-container__subcontainer--amount']}`}>{Object.keys(this.props.order).length > 0 && new BN(this.props.order.amount['$numberDecimal']).toFixed(2)}</p>
                                                <p className={`${style['sidebar__body--flexcontainer-container__subcontainer--currency']}`}>{this.props.order.currency}</p>
                                            </div>
                                        </div>
                                        <div className={`${style['sidebar__body--flexcontainer-container']}`}>
                                            <p className={`${style['sidebar__body--flexcontainer-container__header']}`}>{lang.my_orders.main_window.active_orders.deposited_fat_grey}</p>
                                            <div className={`${style['sidebar__body--flexcontainer-container__subcontainer']}`}>
                                                <img src={dai} alt="DAI" className={`${style['sidebar__body--flexcontainer-container__subcontainer--dai']}`}/>
                                                <p className={`${style['sidebar__body--flexcontainer-container__subcontainer--amount']}`}>{Object.keys(this.props.order).length > 0 && new BN(this.props.order.payment['$numberDecimal']).toFixed(2)}</p>
                                                <p className={`${style['sidebar__body--flexcontainer-container__subcontainer--currency']}`}>{this.props.order.paymentcurrency}</p>
                                            </div>
                                        </div>
                                        <div className={`${style['sidebar__body--flexcontainer-container']}`}>
                                            {this.props.order.agent
                                                ? <p className={`${style['sidebar__body--flexcontainer-container__header']}`}>{lang.myorder_private_order_view_details_accepted_by_agent.fat_grey_fee}</p>
                                                : <p className={`${style['sidebar__body--flexcontainer-container__header']}`}>{lang.orderflow_amount.white_box.max_delivery_fee}</p>
                                            }
                                            <div className={`${style['sidebar__body--flexcontainer-container__subcontainer']}`}>
                                                <img src={dai} alt="DAI" className={`${style['sidebar__body--flexcontainer-container__subcontainer--dai']}`}/>
                                                {this.props.order.status === 'Publicly available' || this.props.order.status === 'Requested' || this.props.order.status === 'Publicly taken' || this.props.order.status === 'Privately taken'
                                                    ? <p className={`${style['sidebar__body--flexcontainer-container__subcontainer--amount']}`}>{(Object.keys(this.props.order).length > 0 && constants.get_agent_reward(this.props.order.payment['$numberDecimal'], this.props.order.delivery_fee, false))}</p>
                                                    : <p className={`${style['sidebar__body--flexcontainer-container__subcontainer--amount']}`}>{(Object.keys(this.props.order).length > 0 && constants.get_agent_reward(this.props.order.payment['$numberDecimal'], this.props.order.max_fee - this.props.order.matching_fee, false))}</p>
                                                }
                                                <p className={`${style['sidebar__body--flexcontainer-container__subcontainer--currency']}`}>{this.props.order.paymentcurrency}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {(this.props.order.agent && this.props.order.agent_info) && (
                                        <Fragment>
                                            <div className={style['sidebar__body--spacer2']}/>
                                            <div className={style['sidebar__body--line']}/>
                                            <div className={style['sidebar__body--spacer4']}/>
                                            <div className={`${style['sidebar__body--flexcontainer']}`}>
                                                <div className={`${style['sidebar__body--flexcontainer-container']}`}>
                                                    <div className={`${style['sidebar__body--flexcontainer-container__subcontainer']}`}>
                                                        <img src={this.props.order.agent_info.avatar ? `/api/user/avatar/${this.props.order.agent_info.avatar}` : defaultprofile} alt="Agent avatar" className={`${style['sidebar__body--flexcontainer-container__subcontainer--avatar']}`}/>
                                                        <div>
                                                            <p className={`${style['sidebar__body--flexcontainer-container__subcontainer--header']}`}>{lang.my_orders.main_window.active_orders.delivered_by}</p>
                                                            <p className={`${style['sidebar__body--flexcontainer-container__subcontainer--bold']}`}>{`${this.props.order.agent_info.name}`}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`${style['sidebar__body--flexcontainer-container']}`}>
                                                    <p className={`${style['sidebar__body--flexcontainer-container__header']}`}>{lang.myorder_private_order_view_details_accepted_by_agent.fat_grey_rating}</p>
                                                    <MakeStars ratings={this.props.order.agent_info.ratings.map(r => r.rating)}/>
                                                </div>
                                            </div>
                                            <div className={`${style['sidebar__body--flexcontainer']} ${style['sidebar__body--spacer3']}`}>
                                                <div className={`${style['sidebar__body--flexcontainer-container']}`}>
                                                    <p className={`${style['sidebar__body--flexcontainer-container__header']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.delivery_method}</p>
                                                    <p className={`${style['sidebar__body--flexcontainer-container__text']}`}>{`${this.props.order.method}`}</p>
                                                </div>
                                                {this.props.order.method === "Mobile payment" && (
                                                    <div className={`${style['sidebar__body--flexcontainer-container']}`}>
                                                        <p className={`${style['sidebar__body--flexcontainer-container__header']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.payment_solution}</p>
                                                        <p className={`${style['sidebar__body--flexcontainer-container__text']}`}>{this.props.order.payment_info.mobile_application || ''}</p>
                                                    </div>
                                                )}
                                            </div>

                                            {/* Agents profile text */}
                                            <div className={`${style['sidebar__body--flexcontainer-container']} ${style['sidebar__body--spacer3']}`}>
                                                <div className={`${style['sidebar__body--flexcontainer-container__subcontainer']}`}>
                                                    <div>
                                                        <p className={`${style['sidebar__body--flexcontainer-container__subcontainer--header']}`}>{`Profile text`}</p>
                                                        <p className={`${style['sidebar__body--flexcontainer-container__subcontainer--bold']}`}>{`${this.props.order.agent_info.profile_text}`}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Your message to the agent */}
                                            {this.props.order.agent_info.message && (
                                                <div className={`${style['sidebar__body--flexcontainer-container']} ${style['sidebar__body--spacer3']}`}>
                                                    <div className={`${style['sidebar__body--flexcontainer-container__subcontainer']}`}>
                                                        <div>
                                                            <p className={`${style['sidebar__body--flexcontainer-container__subcontainer--header']}`}>{`Your message`}</p>
                                                            <p className={`${style['sidebar__body--flexcontainer-container__subcontainer--bold']}`}>{`${this.props.order.agent_info.message}`}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            <div className={style['sidebar__body--spacer5']}/>
                                            <div className={`${style['sidebar__body--smallflex']}`}>
                                                {Object.keys(this.props.order.agent_info).includes("phonenumber") && (
                                                    <Fragment>
                                                        <div className={`${style['sidebar__body--smallflex-iconcontainer']}`}>
                                                            <img src={iconPhone} alt="Phone" className={`${style['sidebar__body--smallflex-iconcontainer__icon']}`}/>
                                                        </div>
                                                        <Link to={`tel:+${this.props.order.agent_info.country_code}${this.props.order.agent_info.phonenumber}`} className={`${style['sidebar__body--smallflex-text']}`}>{`+${this.props.order.agent_info.country_code} ${this.props.order.agent_info.phonenumber}`}</Link>
                                                    </Fragment>
                                                )}
                                            </div>
                                            <div className={`${style['sidebar__body--smallflex']}`}>
                                                <div className={`${style['sidebar__body--smallflex-iconcontainer']}`}>
                                                    <img src={iconMail} alt="Phone" className={`${style['sidebar__body--smallflex-iconcontainer__icon']}`}/>
                                                </div>
                                                <Link to={`mailto:${this.props.order.agent_info.email}`} className={`${style['sidebar__body--smallflex-text']}`}>{`${this.props.order.agent_info.email}`}</Link>
                                            </div>
                                            {this.props.order.status === 'Transfer registered' && this.props.order.recipient === "self" && (
                                                <div className={`${style['sidebar__body--spacer5']}`}>
                                                    <Link to={`/orders/confirm/${this.props.order.confirmationToken}`}><input type="button" className={`${style['sidebar__body--button']}`} value={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.confirm_button}/></Link>
                                                </div>
                                            )}

                                        </Fragment>
                                    )}
                                    {(this.props.order.status === 'Requested') && (
                                        <Fragment>
                                            <div className={style['sidebar__body--spacer2']}/>
                                            <div className={style['sidebar__body--line']}/>
                                            <div className={style['sidebar__body--spacer4']}/>
                                            <p className={style['sidebar__body--header']}>Agent requests</p>
                                            <this.Make_requested_agents id={this.props.order.id}/>
                                        </Fragment>
                                    )}
                                    {(this.props.order.status === 'Publicly available' && this.props.order.message) && (
                                        <Fragment>
                                            <div className={`${style['sidebar__body--flexcontainer-container']} ${style['sidebar__body--spacer3']}`}>
                                                <div className={`${style['sidebar__body--flexcontainer-container__subcontainer']}`}>
                                                    <div>
                                                        <p className={`${style['sidebar__body--flexcontainer-container__subcontainer--header']}`}>{`Your message`}</p>
                                                        <p className={`${style['sidebar__body--flexcontainer-container__subcontainer--bold']}`}>{`${this.props.order.message}`}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`${style['sidebar__body--noorders']}`}>
                                                <p className={`${style['sidebar__body--noorders-header']}`}>{lang.my_orders.main_window.public_tasks.no_agent_yet_grey_box_public}</p>
                                            </div>
                                        </Fragment>
                                    )}
                                    {(this.props.order.status === 'Privately available') && (
                                        <div className={`${style['sidebar__body--noorders']}`}>
                                            <p className={`${style['sidebar__body--noorders-header']}`}>{lang.my_orders.main_window.public_tasks.no_agent_yet_grey_box_private}</p>
                                        </div>
                                    )}
                                    {(this.props.order.status != 'Missing info' && this.props.order.method === 'In person') && (
                                        <Fragment>
                                            <div className={style['sidebar__body--spacer2']}/>
                                            <div className={style['sidebar__body--line']}/>
                                            <div className={style['sidebar__body--mapcontainer']}>
                                                <div className={style['sidebar__body--mapcontainer-flexcontainer']}>
                                                    <img src={iconPin} alt="Map" className={style['sidebar__body--mapcontainer-flexcontainer__icon']}/>
                                                    <p className={style['sidebar__body--mapcontainer-flexcontainer__text']}>{`Near ${this.props.order.city && this.props.order.city+", "}${this.props.order.country && this.props.order.country}`}</p>

                                                </div>
                                            </div>
                                        </Fragment>
                                    )}
                                    {this.props.order.status === 'Missing info' && this.props.order.recipient === "self" && (
                                        <div className={`${style['sidebar__body--spacer5']}`}>
                                            <Link to={`/orders/token/${this.props.order.token}`}><input type="button" className={`${style['sidebar__body--greenbutton']}`} value={lang.orderflow_myself_find_agents_near_you_search_side.top_menu.find_agent}/></Link>
                                        </div>
                                    )}
                                </div>
                                {(this.props.order.status != 'Transfer registered' && this.props.order.status != 'Frozen') && (
                                    <div className={`${style['sidebar__bottom']}`}>
                                        { this.state.releasing
                                            ? <Spinner text="Cancelling order (This might take up to 2 minutes)"/>
                                            : <input type="button" onClick={() => this.cancel_order(this.props.order.id)} className={`${style['sidebar__bottom--button']}`} value={lang.deliverflow_private_order_overview.password_or_cancel.white_button_decline}/>
                                        }
                                    </div>
                                )}
                            </Fragment>
                        )
                    }
                children={<Fragment/>}
                touchHandleWidth={0}
                open={this.props.isOpen}
                pullRight={true}
                rootClassName={style['sidebarbg']}
                sidebarClassName={style['sidebar']}
                onSetOpen={() => this.props.handler()}
                styles={ !this.props.isOpen ?
                    {root:{
                        pointerEvents: 'none'
                    }}
                    :
                    {}
                }
            />
        )
    };
}





class DeliverSidebar extends Component {
    constructor(props){
        super(props);
        this.state = {
            reviewCount: 2
        }
    }


    accept_order = () => {
        axios.post('/api/order/list/user/active/accept_request', {order_id: this.props.task.id})
        .then(response => {
            window.location.reload(false) // temp dirty solution
        })
        .catch(error => {
            alert(error.response.data.message);
        })
    }

    decline_order = () => {
        axios.post('/api/order/list/user/active/decline_request', {order_id: this.props.task.id})
        .then(response => {
            window.location.reload(false) // temp dirty solution
        })
        .catch(error => {
            alert(error.response.data.message);
        })

    }

    componentDidUpdate(prevprops){
        preventScrolling(prevprops.isOpen, this.props.isOpen)
    }

    Make_Reviews = (props) => {
        const reviews = props.reviews
        var maxCount = 0
        if(this.state.reviewCount == 0){
            maxCount = reviews.length
        } else{
            maxCount = Math.min(reviews.length, this.state.reviewCount) // either 1 or 2 reviews
        }
        var ret_reviews = [];
        for (var i = 0; i < maxCount; i++) {
            const review = reviews[i]
            if(review){
                ret_reviews.push(
                    <div key={i} className={`${style['sidebar__agent--ratingscontainer-reviewcontainer']}`}>
                        <div className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__top']}`}>
                            <div className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__top--agent']}`}>
                                <img src={review.avatar ? `/api/user/avatar/${review.avatar}` : defaultprofile} alt="Recipient avatar" className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__top--agent-avatar']}`}/>
                                <div className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__top--agent-info']}`}>
                                    <p className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__top--agent-info__header']}`}>{review.name}</p>
                                    <p className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__top--agent-info__body']}`}>{format_date(review.rated_when)}</p>
                                </div>
                            </div>
                            <div className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__top--starcontainer']}`}>
                                <MakeStars ratings={review.rating}/>
                            </div>
                        </div>
                        <div className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__bottom']}`}>
                            <p className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__bottom--review']}`}>{review.review}</p>
                            {review.response && (
                                <div className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__bottom--responsecontainer']}`}>
                                    <div className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__bottom--responsecontainer-infocontainer']}`}>
                                        <p className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__bottom--responsecontainer-infocontainer__from']}`}>Response</p>
                                        <p className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__bottom--responsecontainer-infocontainer__when']}`}>{format_date(review.response.response_when)}</p>
                                    </div>
                                    <div className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__bottom--responsecontainer-body']}`}>
                                        <p className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__bottom--responsecontainer-body__text']}`}>{review.response.answer}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )
            }
        }

        return ret_reviews
    }
    componentWillUnmount(){
        document.body.style.height = 'auto'
        document.body.style.overflowY = 'auto'
    }


    render() {
        return (

            <Sidebar
                sidebar=
                    {
                        Object.keys(this.props.task).length > 0 && (
                            <Fragment>
                                <div className={`${style['sidebar__top']}`}>
                                    <Link to="#" onClick={() => this.props.handler("deliversidebar")}><FontAwesomeIcon icon={faTimes} className={style['sidebar__top--close']} /></Link>
                                    <div className={`${style['sidebar__top--userheader']}`}>
                                        <div className={`${style['sidebar__top--userheader-imagecontainer']}`}>
                                            <img src={this.props.task.customer_info.avatar ? `/api/${this.props.task.customer_info.avatar.prefix}/avatar/${this.props.task.customer_info.avatar.avatar}` : defaultprofile} alt="Recipient avatar" className={`${style['sidebar__top--userheader-imagecontainer__image']}`} />
                                        </div>
                                        <div className={`${style['sidebar__top--userheader-infocontainer']}`}>
                                            <p className={`${style['sidebar__top--userheader-infocontainer__header']}`}>{lang.deliverflow_private_order_view_order_view_to_accept.accept_or_decline.fat_grey_then_first_name}</p>
                                            <p className={`${style['sidebar__top--userheader-infocontainer__name']}`}>{`${this.props.task.customer_info.name}`}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${style['sidebar__body']}`}>
                                    <div className={`${style['sidebar__body--flexcontainer']}`}>
                                        <div className={`${style['sidebar__body--flexcontainer-container']}`}>
                                            <p className={`${style['sidebar__body--flexcontainer-container__header']}`}>{lang.deliverflow_private_order_view_order_view_to_accept.accept_or_decline.fat_grey_cash_to_deliver}</p>
                                            <div className={`${style['sidebar__body--flexcontainer-container__subcontainer']}`}>
                                                <ReactFlagsSelect
                                                    className={`${style['sidebar__body--flexcontainer-container__subcontainer--flag']} only-flag2`}
                                                    selected={constants.shorthand_curr_to_flag[this.props.task.currency]}
                                                    showSelectedLabel={false}
                                                    placeholder=""
                                                    disabled={true}
                                                    selectedSize={16}
                                                 />
                                                <p className={`${style['sidebar__body--flexcontainer-container__subcontainer--amount']}`}>{Object.keys(this.props.task).length > 0 && new BN(this.props.task.amount['$numberDecimal']).toFixed(2)}</p>
                                                <p className={`${style['sidebar__body--flexcontainer-container__subcontainer--currency']}`}>{this.props.task.currency}</p>
                                            </div>
                                        </div>
                                        <div className={`${style['sidebar__body--flexcontainer-container']}`}>
                                            <p className={`${style['sidebar__body--flexcontainer-container__header']}`}>{lang.deliverflow_private_order_view_order_view_to_accept.accept_or_decline.fat_grey_receive}</p>
                                            <div className={`${style['sidebar__body--flexcontainer-container__subcontainer']}`}>
                                                <img src={dai} alt="DAI" className={`${style['sidebar__body--flexcontainer-container__subcontainer--dai']}`}/>
                                                <p className={`${style['sidebar__body--flexcontainer-container__subcontainer--amount']}`}>{Object.keys(this.props.task).length > 0 && constants.get_agent_payment(this.props.task.payment['$numberDecimal'], this.props.task.max_fee, this.props.task.delivery_fee)}</p>
                                                <p className={`${style['sidebar__body--flexcontainer-container__subcontainer--currency']}`}>{this.props.task.paymentcurrency}</p>
                                            </div>
                                        </div>
                                        <div className={`${style['sidebar__body--flexcontainer-container']}`}>
                                            <p className={`${style['sidebar__body--flexcontainer-container__header']}`}>{lang.deliverflow_private_order_view_order_view_to_accept.accept_or_decline.fat_grey_you_earn}</p>
                                            <div className={`${style['sidebar__body--flexcontainer-container__subcontainer']}`}>
                                                <img src={dai} alt="DAI" className={`${style['sidebar__body--flexcontainer-container__subcontainer--dai']}`}/>
                                                <p className={`${style['sidebar__body--flexcontainer-container__subcontainer--amount']}`}>{Object.keys(this.props.task).length > 0 && constants.get_agent_reward(this.props.task.payment['$numberDecimal'], this.props.task.delivery_fee)}</p>
                                                <p className={`${style['sidebar__body--flexcontainer-container__subcontainer--currency']}`}>{this.props.task.paymentcurrency}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style['sidebar__body--spacer2']}/>
                                    <div className={style['sidebar__body--line']}/>
{/*                                    <div className={`${style['sidebar__body--flexcontainer']} ${style['sidebar__body--spacer3']}`}>
                                        <div className={`${style['sidebar__body--flexcontainer-container']}`}>
                                            <p className={`${style['sidebar__body--flexcontainer-container__header']}`}>{`User rating`}</p>
                                            <MakeStars ratings={this.props.task}/>
                                        </div>
                                    </div>*/}
                                    <div className={style['sidebar__body--methodcontainer']}>
                                        <div className={style['sidebar__body--methodcontainer-container']}>
                                            <p className={style['sidebar__body--methodcontainer-container__header']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.delivery_method}</p>
                                            <p className={style['sidebar__body--methodcontainer-container__body']}>{`${this.props.task.method}`}</p>
                                        </div>
                                        {this.props.task.method === "Mobile payment" && (
                                            <div className={style['sidebar__body--methodcontainer-container']}>
                                                <p className={style['sidebar__body--methodcontainer-container__header']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.payment_solution}</p>
                                                <p className={style['sidebar__body--methodcontainer-container__body']}>{`${this.props.task.mobile_application}`}</p>
                                            </div>
                                        )}
                                    </div>
                                    {this.props.task.status === "Accepted" && (
                                        <Fragment>
                                            {this.props.task.method === "Mobile payment" && (
                                                <div className={style['sidebar__body--detailscontainer']}>
                                                    <p className={style['sidebar__body--detailscontainer-header']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.transfer_money_to}</p>
                                                    <div className={style['sidebar__body--detailscontainer-bodycontainer']}>
                                                        <div className={style['sidebar__body--detailscontainer-bodycontainer__iconcontainer']}>
                                                            <img src={iconOther} alt="Phone" className={`${style['sidebar__body--detailscontainer-bodycontainer__iconcontainer--icon']}`}/>
                                                        </div>
                                                        <p className={style['sidebar__body--detailscontainer-bodycontainer__text']}>{this.props.task.mobile_identifier}</p>
                                                    </div>
                                                </div>
                                            )}
                                            {this.props.task.method === "Bank transfer" && (
                                                <div className={style['sidebar__body--detailscontainer']}>
                                                    <p className={style['sidebar__body--detailscontainer-header']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.transfer_money_to}</p>
                                                    <div className={style['sidebar__body--detailscontainer-bodycontainer']}>
                                                        <div className={style['sidebar__body--detailscontainer-bodycontainer__iconcontainer']}>
                                                            <img src={iconBaccount} alt="Phone" className={`${style['sidebar__body--detailscontainer-bodycontainer__iconcontainer--icon']}`}/> {/* replace icon*/}
                                                        </div>
                                                        <div>
                                                            <p className={style['sidebar__body--detailscontainer-bodycontainer__text']}>Bank code: {this.props.task.bankcode}</p>
                                                            <p className={style['sidebar__body--detailscontainer-bodycontainer__text']}>Account number: {this.props.task.accountnum}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Fragment>
                                    )}
                                    {this.props.task.method === 'In person' && (
                                        <div className={style['sidebar__body--mapcontainer']}>
                                            <div className={style['sidebar__body--mapcontainer-flexcontainer']}>
                                                <img src={iconPin} alt="Map" className={style['sidebar__body--mapcontainer-flexcontainer__icon']}/>
                                                <p className={style['sidebar__body--mapcontainer-flexcontainer__text']}>{`${lang.deliverflow_private_order_view_order_view_to_accept.accept_or_decline.near_the_area} ${this.props.task.city && this.props.task.city+", "}${this.props.task.country && this.props.task.country}`}</p>
                                            </div>
                                        </div>
                                    )}
                                    <Fragment>
                                        <div className={style['sidebar__body--spacer3']}/>
                                        <div className={style['sidebar__body--line']}/>
                                        <div className={`${style['sidebar__body--recipientcontainer']}`}>
                                            <div className={style['sidebar__body--recipientcontainer-topcontainer']}>
                                                <img src={this.props.task.customer_info.avatar ? `/api/${this.props.task.customer_info.avatar.prefix}/avatar/${this.props.task.customer_info.avatar.avatar}` : defaultprofile} alt="Recipient avatar" className={style['sidebar__body--recipientcontainer-topcontainer__avatar']}/>
                                                {this.props.task.customer_info.ratings && (
                                                    <div>
                                                        <p className={style['sidebar__body--recipientcontainer-topcontainer__name']}>{`${this.props.task.customer_info.name}`}</p>
                                                        <MakeStars containerstyle={{marginLeft: '10px'}} ratings={this.props.task.customer_info.ratings.map(r => r.rating)}/>
                                                    </div>
                                                )}
                                            </div>
                                            {this.props.task.message && (
                                                <div className={style['sidebar__body--recipientcontainer-messagecontainer']}>
                                                    <div className={style['sidebar__body--recipientcontainer-messagecontainer__triangle']}/>
                                                    <p className={style['sidebar__body--recipientcontainer-messagecontainer__text']}>{this.props.task.message}</p>
                                                </div>
                                            )}
                                            <div className={`${style['sidebar__body--recipientcontainer-infocontainer']}`}>
                                                <div className={`${style['sidebar__body--recipientcontainer-infocontainer__iconcontainer']}`}>
                                                    <img src={iconPhone} alt="Phone" className={`${style['sidebar__body--recipientcontainer-infocontainer__iconcontainer--icon']}`}/>
                                                </div>
                                                <Link to={`tel:+${this.props.task.customer_info.country_code}${this.props.task.customer_info.phonenumber}`} className={`${style['sidebar__body--recipientcontainer-infocontainer__text']}`}>{`+${this.props.task.customer_info.country_code} ${this.props.task.customer_info.phonenumber}`}</Link>
                                            </div>
                                            <div className={`${style['sidebar__body--recipientcontainer-infocontainer']}`}>
                                                <div className={`${style['sidebar__body--recipientcontainer-infocontainer__iconcontainer']}`}>
                                                    <img src={iconMail} alt="Phone" className={`${style['sidebar__body--recipientcontainer-infocontainer__iconcontainer--icon']}`}/>
                                                </div>
                                                <Link to={`mailto:${this.props.task.customer_info.email}`} className={`${style['sidebar__body--recipientcontainer-infocontainer__text']}`}>{`${this.props.task.customer_info.email}`}</Link>
                                            </div>
                                        </div>
                                    </Fragment>
                                    {this.props.task.status === 'Requested' && this.props.task.customer_info.ratings && this.props.task.customer_info.ratings.length > 0 && (
                                        <div className={`${style['sidebar__agent--ratingscontainer']} ${style['sidebar__body--spacer2']}`}>
                                            <div className={`${style['sidebar__agent--ratingscontainer-infocontainer']}`}>
                                                <p className={`${style['sidebar__agent--ratingscontainer-infocontainer__reviewsheader']}`}>{`Reviews`} ({this.props.task.customer_info.ratings.length})</p>
                                                {(this.props.task.customer_info.ratings.length > 0 && this.state.reviewCount != 0 && this.state.reviewCount != 2) && (
                                                    <Link to="#" onClick={() => this.setState({reviewCount: 0})} className={`${style['sidebar__agent--ratingscontainer-infocontainer__allheader']}`}>{`See all reviews`}</Link>
                                                )}
                                                {(this.state.reviewCount === 0 || this.state.reviewCount === 2) && (
                                                    <Link to="#" onClick={() => this.setState({reviewCount: 2})} className={`${style['sidebar__agent--ratingscontainer-infocontainer__allheader']}`}>{`Hide reviews`} ({this.props.task.customer_info.ratings.length - 2})</Link>
                                                )}
                                            </div>
                                            <this.Make_Reviews reviews={this.props.task.customer_info.ratings}/>
                                        </div>
                                    )}
                                </div>
                                <div className={`${style['sidebar__bottom']}`}>
                                    {(this.props.task.status === 'Accepted' && this.props.task.method != "In person") && (
                                        <Link to="#" onClick={() => this.props.handlerOpenRegister(this.props.task)}><input type="button" className={`${style['sidebar__bottom--button']}`} value={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.register_transfer}/></Link>
                                    )}
                                    {this.props.task.status === 'Requested' && (
                                        <Fragment>
                                            <Link to="#" onClick={() => this.accept_order()}><input type="button" className={`${style['sidebar__bottom--acceptbutton']}`} value={lang.deliverflow_private_order_view_order_view_to_accept.accept_or_decline.red_button_accept}/></Link>
                                            <Link to="#" onClick={() => this.decline_order()}><input type="button" className={`${style['sidebar__bottom--declinebutton']}`} value={lang.deliverflow_private_order_view_order_view_to_accept.accept_or_decline.white_button_decline}/></Link>
                                        </Fragment>
                                    )}
                                </div>
                            </Fragment>
                        )
                    }
                children={<Fragment/>}
                touchHandleWidth={0}
                open={this.props.isOpen}
                pullRight={true}
                rootClassName={style['sidebarbg']}
                sidebarClassName={style['sidebar']}
                onSetOpen={() => this.props.handler("deliversidebar")}
                styles={ !this.props.isOpen ?
                    {root:{
                        pointerEvents: 'none'
                    }}
                    :
                    {}
                }
            />
        )
    };
}




class PasswordSidebar extends Component {
    constructor(props){
        super(props);
        this.state = {
            password: '',
            releasing: false
        }
    }


    release_order = () => {
        this.setState({releasing: true})
        axios.post('/api/order/release', {order_id: this.props.task.id, password: this.state.password})
        .then(response => {
            window.location.reload(false) // temp dirty solution
        })
        .catch(error => {
            releasing: false,
            alert(error.response.data.message);
        })
    }

    scan_code = () => {
    }

    componentDidUpdate(prevprops){
        preventScrolling(prevprops.isOpen, this.props.isOpen)
        if(prevprops.task != this.props.task){
            this.setState({password: '', releasing: false})
        }
    }

    render(){
        return(
            <Sidebar
                sidebar=
                    {
                        Object.keys(this.props.task).length > 0 && (
                            <Fragment>
                                <div className={`${style['sidebar__top']}`}>
                                    <Link to="#" onClick={() => this.props.handler("passwordsidebar")}><FontAwesomeIcon icon={faTimes} className={style['sidebar__top--close']} /></Link>
                                    <div className={`${style['sidebar__top--userheader']}`}>
                                        <div className={`${style['sidebar__top--userheader-imagecontainer']}`}>
                                            <img src={this.props.task.avatar ? `/api/${this.props.task.avatar.prefix}/avatar/${this.props.task.avatar.avatar}` : defaultprofile} alt="Recipient avatar" className={`${style['sidebar__top--userheader-imagecontainer__image']}`} />
                                        </div>
                                        <div className={`${style['sidebar__top--userheader-infocontainer']}`}>
                                            <p className={`${style['sidebar__top--userheader-infocontainer__header']}`}>{lang.deliverflow_private_order_view_order_view_to_accept.accept_or_decline.fat_grey_then_first_name}</p>
                                            <p className={`${style['sidebar__top--userheader-infocontainer__name']}`}>{`${this.props.task.customer_info.name}`}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${style['sidebar__body']}`}>
                                    <p className={`${style['sidebar__body--header3']}`}>{lang.deliverflow_private_order_insert_password.get_deposit.sub_header}</p>
                                    <div className={`${style['sidebar__body--spacer2']}`}/>
                                    <div className={`${style['sidebar__body--inputcontainer']}`}>
                                        <img src={iconLock} className={`${style['sidebar__body--inputcontainer-icon']}`}/>
                                        <input type="text" value={this.state.password} onChange={(e) => this.setState({password: e.target.value})} className={`${style['sidebar__body--inputcontainer-input']}`} placeholder={lang.deliverflow_private_order_insert_password.get_deposit.filler_password}/>
                                    </div>
                                    {this.state.releasing
                                        ? <Spinner text={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.spinning_two}/>
                                        : <input type="button" value={lang.deliverflow_private_order_insert_password.get_deposit.red_button_accept} onClick={() => this.release_order()} className={`${style['sidebar__body--button']}`}/>
                                    }
                                    <div className={`${style['sidebar__body--spacer3']}`}/>
                                    {/*<input type="button" value={lang.deliverflow_private_order_insert_password.get_deposit.white_button_decline} onClick={() => this.scan_code()} className={`${style['sidebar__body--whitebutton']}`}/>*/}
                                    <div className={`${style['sidebar__body--warningcontainer']}`}>
                                        <p className={`${style['sidebar__body--warningcontainer-header']}`}>{lang.deliverflow_private_order_insert_password.get_deposit.header_in_lightredbox}</p>
                                        <p className={`${style['sidebar__body--warningcontainer-body']}`}>{lang.deliverflow_private_order_insert_password.get_deposit.text_in_lightredbox_one_of_two} {new BN(this.props.task.amount["$numberDecimal"]).toFixed(2)} {this.props.task.currency} {lang.deliverflow_private_order_insert_password.get_deposit.text_in_lightredbox_two_of_two}</p>
                                    </div>
                                </div>
                            </Fragment>
                        )
                    }
                children={<Fragment/>}
                touchHandleWidth={0}
                open={this.props.isOpen}
                pullRight={true}
                rootClassName={style['sidebarbg']}
                sidebarClassName={style['sidebar']}
                onSetOpen={() => this.props.handler("passwordsidebar")}
                styles={ !this.props.isOpen ?
                    {root:{
                        pointerEvents: 'none'
                    }}
                    :
                    {}
                }
            />
        )
    }
}
class HowToStoreDaiSidebar extends Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }

    componentDidUpdate(prevprops){
        preventScrolling(prevprops.isOpen, this.props.isOpen)
    }

    render(){
        return(
            <Sidebar
                sidebar=
                    {
                        <Fragment>
                            <div className={`${style['sidebar__top']}`}>
                                <Link to="#" onClick={() => this.props.handler("howToStoreDaiSidebar")}><FontAwesomeIcon icon={faTimes} className={style['sidebar__top--close']} /></Link>
                                <p className={`${style['sidebar__top--title']}`}>{lang.sidebars.store.header}</p>
                            </div>
                            <div className={`${style['sidebar__body']}`}>
                                <p className={`${style['sidebar__body--header']}`}>{lang.sidebars.store.how_to_store_header}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header2']}`}>{lang.sidebars.store.how_to_store_first_subheader}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.store.metamask} <a href="//metamask.io/" className={`${style['sidebar__body--link']}`}>https://metamask.io/</a></p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.store.dafnir}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header2']}`}>{lang.sidebars.store.how_to_store_second_subheader}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.store.what_is_a_wallet}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header2']}`}>{lang.sidebars.store.how_to_store_third_subheader}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.store.you_are_in_control}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header2']}`}>{lang.sidebars.store.how_to_store_fourth_subheader}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.store.protect_seed}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header2']}`}>{lang.sidebars.store.how_to_store_fifth_subheader}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.store.hardware_wallet}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header2']}`}>{lang.sidebars.store.how_to_store_sixth_subheader}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.store.not_your_keys}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <div className={`${style['sidebar__body--line']}`}/>
                            </div>
                        </Fragment>
                    }
                children={<Fragment/>}
                touchHandleWidth={0}
                open={this.props.isOpen}
                pullRight={true}
                rootClassName={style['sidebarbg']}
                sidebarClassName={style['sidebar']}
                onSetOpen={() => this.props.handler("howToStoreDaiSidebar")}
                styles={ !this.props.isOpen ?
                    {root:{
                        pointerEvents: 'none'
                    }}
                    :
                    {}
                }
            />
        )
    }
}
class HowToGrowDaiSidebar extends Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }

    componentDidUpdate(prevprops){
        preventScrolling(prevprops.isOpen, this.props.isOpen)
    }

    render(){
        return(
            <Sidebar
                sidebar=
                    {
                        <Fragment>
                            <div className={`${style['sidebar__top']}`}>
                                <Link to="#" onClick={() => this.props.handler("howToGrowDaiSidebar")}><FontAwesomeIcon icon={faTimes} className={style['sidebar__top--close']} /></Link>
                                <p className={`${style['sidebar__top--title']}`}>{lang.sidebars.grow.header}</p>
                            </div>
                            <div className={`${style['sidebar__body']}`}>
                                <p className={`${style['sidebar__body--header']}`}>{lang.sidebars.grow.grow_header}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.grow.visit_oasis} <a href="//oasis.app/save" className={`${style['sidebar__body--link']}`}>https://oasis.app/save</a></p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header2']}`}>{lang.sidebars.grow.seven_steps_subheader}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.grow.step_one_subsubheader}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.grow.step_one_text}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.grow.step_two_subsubheader}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.grow.step_two_text}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.grow.step_three_subsubheader}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.grow.step_three_text}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.grow.step_four_subsubheader}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.grow.step_four_text}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.grow.step_five_subsubheader}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.grow.step_five_text}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.grow.step_five_two_text}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.grow.step_six_subsubheader}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.grow.step_six_text}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.grow.step_seven_subsubheader}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.grow.step_seven_text}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.grow.step_seven_two_text}</p>
                                <p className={`${style['sidebar__body--text']}`}>lang.sidebars.grow.step_sseven_three_text</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header2']}`}>{lang.sidebars.grow.how_to_decide_subheader}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.grow.how_to_decide}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header2']}`}>{lang.sidebars.grow.risks_subheader}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.grow.risks}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <div className={`${style['sidebar__body--line']}`}/>
                            </div>
                        </Fragment>
                    }
                children={<Fragment/>}
                touchHandleWidth={0}
                open={this.props.isOpen}
                pullRight={true}
                rootClassName={style['sidebarbg']}
                sidebarClassName={style['sidebar']}
                onSetOpen={() => this.props.handler("howToGrowDaiSidebar")}
                styles={ !this.props.isOpen ?
                    {root:{
                        pointerEvents: 'none'
                    }}
                    :
                    {}
                }
            />
        )
    }
}
class DaiEqualsDollarSidebar extends Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }

    componentDidUpdate(prevprops){
        preventScrolling(prevprops.isOpen, this.props.isOpen)
    }

    render(){
        return(
            <Sidebar
                sidebar=
                    {
                        <Fragment>
                            <div className={`${style['sidebar__top']}`}>
                                <Link to="#" onClick={() => this.props.handler()}><FontAwesomeIcon icon={faTimes} className={style['sidebar__top--close']} /></Link>
                                <p className={`${style['sidebar__top--title']}`}>{lang.sidebars.equals_dollar.header}</p>
                            </div>
                            <div className={`${style['sidebar__body']}`}>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.equals_dollar.dai_equals_dollar_header}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.equals_dollar.free_money}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.equals_dollar.benefits}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.equals_dollar.how_to_get}</p>
                                <div className={`${style['sidebar__body--line']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.equals_dollar.what_to_spend_on}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.equals_dollar.backed}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                            </div>
                        </Fragment>
                    }
                children={<Fragment/>}
                touchHandleWidth={0}
                open={this.props.isOpen}
                pullRight={true}
                rootClassName={style['sidebarbg']}
                sidebarClassName={style['sidebar']}
                onSetOpen={() => this.props.handler()}
                styles={ !this.props.isOpen ?
                    {root:{
                        pointerEvents: 'none'
                    }}
                    :
                    {}
                }
            />
        )
    }
}
class FeesSidebar extends Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }

    componentDidUpdate(prevprops){
        preventScrolling(prevprops.isOpen, this.props.isOpen)
    }

    render(){
        return(
            <Sidebar
                sidebar=
                    {
                        <Fragment>
                            <div className={`${style['sidebar__top']}`}>
                                <Link to="#" onClick={() => this.props.handler("feesSidebar")}><FontAwesomeIcon icon={faTimes} className={style['sidebar__top--close']} /></Link>
                                <p className={`${style['sidebar__top--title']}`}>{lang.sidebars.fees.header}</p>
                            </div>
                            <div className={`${style['sidebar__body']}`}>
                                <p className={`${style['sidebar__body--header']}`}>{lang.sidebars.fees.fees_header}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.fees.support_text}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.fees.dafnir_subheader}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.fees.dafnir_text}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.fees.agent_subheader}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.fees.agent_text}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.fees.deposit_subheader}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.fees.deposit}</p>
                            </div>
                        </Fragment>
                    }
                children={<Fragment/>}
                touchHandleWidth={0}
                open={this.props.isOpen}
                pullRight={true}
                rootClassName={style['sidebarbg']}
                sidebarClassName={style['sidebar']}
                onSetOpen={() => this.props.handler("feesSidebar")}
                styles={ !this.props.isOpen ?
                    {root:{
                        pointerEvents: 'none'
                    }}
                    :
                    {}
                }
            />
        )
    }
}
class IdSidebar extends Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }

    componentDidUpdate(prevprops){
        preventScrolling(prevprops.isOpen, this.props.isOpen)
    }

    render(){
        return(
            <Sidebar
                sidebar=
                    {
                        <Fragment>
                            <div className={`${style['sidebar__top']}`}>
                                <Link to="#" onClick={() => this.props.handler("idSidebar")}><FontAwesomeIcon icon={faTimes} className={style['sidebar__top--close']} /></Link>
                                <p className={`${style['sidebar__top--title']}`}>{lang.sidebars.id.header}</p>
                            </div>
                            <div className={`${style['sidebar__body']}`}>
                                <p className={`${style['sidebar__body--header']}`}>{lang.sidebars.id.acceptable_header}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.id.passport_subheader}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.id.national_id_subheader}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.id.emergency_subheader}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.id.drivers_subheader}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.id.residence_subheader}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header']}`}>{lang.sidebars.id.no_proof_header}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.id.no_proof_text}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.id.we_will_always_comply}</p>
                            </div>
                        </Fragment>
                    }
                children={<Fragment/>}
                touchHandleWidth={0}
                open={this.props.isOpen}
                pullRight={true}
                rootClassName={style['sidebarbg']}
                sidebarClassName={style['sidebar']}
                onSetOpen={() => this.props.handler("idSidebar")}
                styles={ !this.props.isOpen ?
                    {root:{
                        pointerEvents: 'none'
                    }}
                    :
                    {}
                }
            />
        )
    }
}
class TermsBusinessSidebar extends Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }

    componentDidUpdate(prevprops){
        preventScrolling(prevprops.isOpen, this.props.isOpen)
    }

    render(){
        return(
            <Sidebar
                sidebar=
                    {
                        <Fragment>
                            <div className={`${style['sidebar__top']}`}>
                                <Link to="#" onClick={() => this.props.handler("termsBusinessSidebar")}><FontAwesomeIcon icon={faTimes} className={style['sidebar__top--close']} /></Link>
                                <p className={`${style['sidebar__top--title']}`}>{lang.sidebars.terms_business.header}</p>
                            </div>
                            <div className={`${style['sidebar__body']}`}>
                                <p className={`${style['sidebar__body--header']}`}>{lang.sidebars.terms_business.business_header}</p>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_business.business_subsubheader}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_business.business_subheader_one}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_one_one}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_one_two}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_one_three}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_one_four}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_one_five}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_business.business_subheader_two}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_two_one}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_two_two}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_business.business_subheader_three}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_three_one}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_three_two}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_three_three}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_three_four}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_business.business_subheader_four}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_four_one}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_four_two}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_four_three}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_four_four}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_four_five}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_business.business_subheader_five}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_five_one}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_five_two}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_five_three}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_five_four}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_five_five}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_five_six}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_business.business_subheader_six}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_six_one}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_six_two}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_business.business_subsubheader_one}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_six_three}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_business.business_subsubheader_two}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_six_four}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_business.business_subsubheader_three}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_six_five}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_six_six}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_six_seven}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_six_eight}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_six_nine}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_six_ten}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_six_eleven}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_business.business_subsubheader_four}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_six_twelve}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_business.business_subheader_seven}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_seven_one}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_seven_two}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_seven_three}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_seven_four}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_business.business_subheader_eight}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_eight_one}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_eight_two}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_eight_three}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_eight_four}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_eight_five}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_eight_six}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_eight_seven}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_eight_eight}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_eight_nine}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_eight_ten}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_eight_eleven}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_eight_twelve}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_business.business_subheader_nine}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_nine_one}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_nine_two}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_nine_three}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_nine_four}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_nine_five}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_nine_six}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_business.business_subheader_ten}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_ten_one}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_ten_two}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_business.business_subheader_eleven}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_eleven_one}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_eleven_two}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_business.business_subheader_twelve}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_twelve}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_business.business_subheader_thirteen}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_thirteen_one}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_thirteen_two}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_thirteen_three}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_thirteen_four}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_thirteen_five}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_thirteen_six}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_thirteen_seven}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_thirteen_eight}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_thirteen_nine}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_business.business_subheader_fourteen}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_fourteen}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_business.business_subheader_fifteen}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_fifteen_one}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_fifteen_two}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_fifteen_three}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_fifteen_four}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_business.business_text_fifteen_five}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                            </div>
                        </Fragment>
                    }
                children={<Fragment/>}
                touchHandleWidth={0}
                open={this.props.isOpen}
                pullRight={true}
                rootClassName={style['sidebarbg']}
                sidebarClassName={style['sidebar']}
                onSetOpen={() => this.props.handler("termsBusinessSidebar")}
                styles={ !this.props.isOpen ?
                    {root:{
                        pointerEvents: 'none'
                    }}
                    :
                    {}
                }
            />
        )
    }
}
class TermsPrivacySidebar extends Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }

    componentDidUpdate(prevprops){
        preventScrolling(prevprops.isOpen, this.props.isOpen)
    }

    render(){
        return(
            <Sidebar
                sidebar=
                    {
                        <Fragment>
                            <div className={`${style['sidebar__top']}`}>
                                <Link to="#" onClick={() => this.props.handler("termsPrivacySidebar")}><FontAwesomeIcon icon={faTimes} className={style['sidebar__top--close']} /></Link>
                                <p className={`${style['sidebar__top--title']}`}>{lang.sidebars.terms_privacy.header}</p>
                            </div>
                            <div className={`${style['sidebar__body']}`}>
                                <p className={`${style['sidebar__body--header']}`}>{lang.sidebars.terms_privacy.privacy_header}</p>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_privacy.privacy_subsubheader}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_privacy.privacy_subheader_one}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_one_one}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_one_two}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_one_three}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_privacy.privacy_subheader_two}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_two_one}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_two_two}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_two_three}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_two_four}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_two_five}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_two_six}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_two_seven}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_two_eight}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_two_nine}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_privacy.privacy_subheader_three}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_three}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_privacy.privacy_subheader_four}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_four_one}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_four_two}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_four_three}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_four_four}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_privacy.privacy_subheader_five}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_five_one}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_five_two}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_five_three}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_five_four}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_five_five}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_privacy.privacy_subheader_six}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_six}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_privacy.privacy_subheader_seven}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_seven_one}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_seven_two}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_privacy.privacy_subheader_eight}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_eight}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_privacy.privacy_subheader_nine}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_nine_one}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_nine_two}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_privacy.privacy_subheader_ten}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_ten_one}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_ten_two}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_privacy.privacy_subheader_eleven}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_eleven}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_privacy.privacy_subheader_twelve}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_twelve}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_privacy.privacy_subheader_thirteen}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_thirteen}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_privacy.privacy_subheader_fourteen}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_fourteen}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_privacy.privacy_subheader_fifteen}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_fifteen}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_privacy.privacy_subheader_sixteen}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_sixteen_one}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_sixteen_two}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_privacy.privacy_subheader_seventeen}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_seventeen}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_privacy.privacy_subheader_eighteen}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_eighteen_one}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_eighteen_two}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_eighteen_three}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_eighteen_four}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_privacy.privacy_subheader_nineteen}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_nineteen}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_privacy.privacy_subheader_twenty}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_twenty}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.sidebars.terms_privacy.privacy_subheader_twentyone}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.terms_privacy.privacy_text_twentyone}</p>
                                <div className={`${style['sidebar__body--spacer']}`}/>
                            </div>
                        </Fragment>
                    }
                children={<Fragment/>}
                touchHandleWidth={0}
                open={this.props.isOpen}
                pullRight={true}
                rootClassName={style['sidebarbg']}
                sidebarClassName={style['sidebar']}
                onSetOpen={() => this.props.handler("termsPrivacySidebar")}
                styles={ !this.props.isOpen ?
                    {root:{
                        pointerEvents: 'none'
                    }}
                    :
                    {}
                }
            />
        )
    }
}

class RegisterTransferSidebar extends Component {
    constructor(props){
        super(props);
        this.state = {
            terms: false,
            registering: false
        }
    }

    register = () => {
        this.setState({registering: true})
        if(!this.state.terms){
            alert("Please accept the terms before continuing")
            return
        }
        axios.post('/api/order/registertransfer', {
            id: this.props.task.id,
        }).then(resp => {
            this.setState({registering: false})
            this.props.handlerOpenRegistered(this.props.task)
        }).catch(err => {
            this.setState({registering: false})
            try{
                alert(err.response.data.message);
            } catch(e){
                alert(err)
            }
        })
    }

    componentDidUpdate(prevprops){
        preventScrolling(prevprops.isOpen, this.props.isOpen)
    }
    componentWillUnmount(){
        document.body.style.height = 'auto'
        document.body.style.overflowY = 'auto'
    }


    render() {

        return (
            <Sidebar
                sidebar=
                    {
                    this.props.task.status === "Accepted" && (
                        <Fragment>
                            <div className={`${style['sidebar__top']}`}>
                                <Link to="#" onClick={() => this.props.handler("registertransfersidebar")}><FontAwesomeIcon icon={faTimes} className={style['sidebar__top--close']} /></Link>
                                <div className={`${style['sidebar__top--userheader']}`}>
                                    <div className={`${style['sidebar__top--userheader-imagecontainer']}`}>
                                        <img src={this.props.task.customer_info.avatar ? `/api/${this.props.task.customer_info.avatar.prefix}/avatar/${this.props.task.customer_info.avatar.avatar}` : defaultprofile} alt="Recipient avatar" className={`${style['sidebar__top--userheader-imagecontainer__image']}`} />
                                    </div>
                                    <div className={`${style['sidebar__top--userheader-infocontainer']}`}>
                                        <p className={`${style['sidebar__top--userheader-infocontainer__header']}`}>{lang.deliverflow_private_order_my_tasks.main_window.active_orders.deliver_to_fat_grey}</p>
                                        <p className={`${style['sidebar__top--userheader-infocontainer__name']}`}>{`${this.props.task.customer_info.name}`}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={`${style['sidebar__body']}`}>
                                <p className={`${style['sidebar__body--header']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.register_transfer}</p>
                                <p className={`${style['sidebar__body--text']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.register_transfer_subtext_one} {lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.register_transfer_subtext_two} {lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.register_transfer_subtext_three}</p>

                                <Fragment>
                                    <div className={`${style['sidebar__body--line']} ${style['sidebar__body--spacer']}`}/>
                                    {this.props.task.method === "Mobile payment" && (
                                        <div className={style['sidebar__body--detailscontainer']}>
                                            <p className={style['sidebar__body--detailscontainer-header']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.transfer_money_to}</p>
                                            <div className={style['sidebar__body--detailscontainer-bodycontainer']}>
                                                <div className={style['sidebar__body--detailscontainer-bodycontainer__iconcontainer']}>
                                                    <img src={iconOther} alt="Phone" className={`${style['sidebar__body--detailscontainer-bodycontainer__iconcontainer--icon']}`}/> {/* replace icon*/}
                                                </div>
                                                <p className={style['sidebar__body--detailscontainer-bodycontainer__text']}>{this.props.task.mobile_identifier}</p>
                                            </div>
                                        </div>
                                    )}
                                    {this.props.task.method === "Bank transfer" && (
                                        <div className={style['sidebar__body--detailscontainer']}>
                                            <p className={style['sidebar__body--detailscontainer-header']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.transfer_money_to}</p>
                                            <div className={style['sidebar__body--detailscontainer-bodycontainer']}>
                                                <div className={style['sidebar__body--detailscontainer-bodycontainer__iconcontainer']}>
                                                    <img src={iconBaccount} alt="Phone" className={`${style['sidebar__body--detailscontainer-bodycontainer__iconcontainer--icon']}`}/> {/* replace icon*/}
                                                </div>
                                                <div>
                                                    <p className={style['sidebar__body--detailscontainer-bodycontainer__text']}>Bank code: {this.props.task.bankcode}</p>
                                                    <p className={style['sidebar__body--detailscontainer-bodycontainer__text']}>Account number: {this.props.task.accountnum}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Fragment>
                                <div className={`${style['sidebar__body--flexcontainer']} ${style['sidebar__body--spacer8']}`}>
                                    <Checkbox
                                        defaultChecked={this.state.terms}
                                        onClick={() => this.setState({terms: !this.state.terms})}
                                    />
                                    <p className={`${style['sidebar__body--flexcontainer-text']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.accept} <Link to="#" onClick={() => alert("what to do")} className={`${style['sidebar__body--link']}`}>{lang.sidebars.terms_business.header}</Link></p>
                                </div>
                                {
                                    this.state.registering
                                        ? <Spinner text={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.spinning_three} style={{marginTop: '10px'}}/>
                                        : <input type="button" onClick={() => this.register()} className={`${style['sidebar__body--button']} ${style['sidebar__body--spacer9']}`} value={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.register_transfer}/>
                                }

                                <div className={style['sidebar__body--warningcontainer']}>
                                    <p className={`${style['sidebar__body--warningcontainer-header']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.attention}</p>
                                    <p className={`${style['sidebar__body--warningcontainer-body']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.warning_text} {new BN(this.props.task.amount["$numberDecimal"]).toFixed(2)} {this.props.task.currency} {lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.by} {this.props.task.method === 'Mobile payment' ? this.props.task.mobile_application : this.props.task.method}.</p>
                                </div>

                            </div>
                        </Fragment>
                    )
                    }
                children={<Fragment/>}
                touchHandleWidth={0}
                open={this.props.isOpen}
                pullRight={true}
                rootClassName={style['sidebarbg']}
                sidebarClassName={style['sidebar']}
                onSetOpen={() => this.props.handler("registertransfersidebar")}
                styles={ !this.props.isOpen ?
                    {root:{
                        pointerEvents: 'none'
                    }}
                    :
                    {}
                }
            />
        )
    };
}


class TransferRegisteredSidebar extends Component {
    constructor(props){
        super(props);
    }

    componentDidUpdate(prevprops){
        preventScrolling(prevprops.isOpen, this.props.isOpen)
    }
    componentWillUnmount(){
        document.body.style.height = 'auto'
        document.body.style.overflowY = 'auto'
    }

    render() {

        return (
            <Sidebar
                sidebar=
                    {
                        this.props.task && Object.keys(this.props.task).length > 0 && (
                        <Fragment>
                            <div className={`${style['sidebar__top']}`}>
                                <Link to="#" onClick={() => this.props.handler("transferregisteredsidebar")}><FontAwesomeIcon icon={faTimes} className={style['sidebar__top--close']} /></Link>
                                <div className={`${style['sidebar__top--userheader']}`}>
                                    <div className={`${style['sidebar__top--userheader-imagecontainer']}`}>
                                        <img src={this.props.task.customer_info.avatar ? `/api/${this.props.task.customer_info.avatar.prefix}/avatar/${this.props.task.customer_info.avatar.avatar}` : defaultprofile} alt="Recipient avatar" className={`${style['sidebar__top--userheader-imagecontainer__image']}`} />
                                    </div>
                                    <div className={`${style['sidebar__top--userheader-infocontainer']}`}>
                                        <p className={`${style['sidebar__top--userheader-infocontainer__header']}`}>{lang.deliverflow_private_order_my_tasks.main_window.active_orders.deliver_to_fat_grey}</p>
                                        <p className={`${style['sidebar__top--userheader-infocontainer__name']}`}>{`${this.props.task.customer_info.name}`}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={`${style['sidebar__body']}`}>
                                <div className={`${style['sidebar__body--bigiconcontainer']}`}>
                                    <RoundCheckmark width="91.86px" height="84.8px" viewBox="0 0 70 65" className={`${style['sidebar__body--bigiconcontainer-bigicon']}`}/>
                                </div>
                                <p className={`${style['sidebar__body--centeredheader']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.registered_complete}</p>
                                <p className={`${style['sidebar__body--header3']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.next}</p>
                                <div className={`${style['sidebar__body--bulletflex']}`}>
                                    <div className={`${style['sidebar__body--bulletflex-bullet']}`}>
                                        <p className={`${style['sidebar__body--bulletflex-bullet__text']}`}>1</p>
                                    </div>
                                    <p className={`${style['sidebar__body--bulletflex-text']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.next_one}</p>
                                </div>
                                <div className={`${style['sidebar__body--bulletflex']}`}>
                                    <div className={`${style['sidebar__body--bulletflex-bullet']}`}>
                                        <p className={`${style['sidebar__body--bulletflex-bullet__text']}`}>2</p>
                                    </div>
                                    <p className={`${style['sidebar__body--bulletflex-text']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.next_two}</p>
                                </div>
                                <div className={`${style['sidebar__body--bulletflex']}`}>
                                    <div className={`${style['sidebar__body--bulletflex-bullet']}`}>
                                        <p className={`${style['sidebar__body--bulletflex-bullet__text']}`}>3</p>
                                    </div>
                                    <p className={`${style['sidebar__body--bulletflex-text']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.next_three}</p>
                                </div>
                            </div>
                        </Fragment>

                        )
                    }
                children={<Fragment/>}
                touchHandleWidth={0}
                open={this.props.isOpen}
                pullRight={true}
                rootClassName={style['sidebarbg']}
                sidebarClassName={style['sidebar']}
                onSetOpen={() => this.props.handler("transferregisteredsidebar")}
                styles={ !this.props.isOpen ?
                    {root:{
                        pointerEvents: 'none'
                    }}
                    :
                    {}
                }
            />
        )
    };
}

class PublicDetailsSidebar extends Component {
    constructor(props){
        super(props)
        this.state = {
            reviewCount: 2
        }
    }

    componentDidUpdate(prevprops){
        preventScrolling(prevprops.isOpen, this.props.isOpen)
    }

    Make_Reviews = (props) => {
        const reviews = props.reviews
        var maxCount = 0
        if(this.state.reviewCount == 0){
            maxCount = reviews.length
        } else{
            maxCount = Math.min(reviews.length, this.state.reviewCount) // either 1 or 2 reviews
        }
        var ret_reviews = [];
        for (var i = 0; i < maxCount; i++) {
            const review = reviews[i]
            if(review){
                ret_reviews.push(
                    <div key={i} className={`${style['sidebar__agent--ratingscontainer-reviewcontainer']}`}>
                        <div className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__top']}`}>
                            <div className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__top--agent']}`}>
                                <img src={review.avatar ? `/api/user/avatar/${review.avatar}` : defaultprofile} alt="Recipient avatar" className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__top--agent-avatar']}`}/>
                                <div className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__top--agent-info']}`}>
                                    <p className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__top--agent-info__header']}`}>{review.name}</p>
                                    <p className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__top--agent-info__body']}`}>{format_date(review.rated_when)}</p>
                                </div>
                            </div>
                            <div className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__top--starcontainer']}`}>
                                <MakeStars ratings={review.rating}/>
                            </div>
                        </div>
                        <div className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__bottom']}`}>
                            <p className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__bottom--review']}`}>{review.review}</p>
                            {review.response && (
                                <div className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__bottom--responsecontainer']}`}>
                                    <div className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__bottom--responsecontainer-infocontainer']}`}>
                                        <p className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__bottom--responsecontainer-infocontainer__from']}`}>Response</p>
                                        <p className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__bottom--responsecontainer-infocontainer__when']}`}>{format_date(review.response.response_when)}</p>
                                    </div>
                                    <div className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__bottom--responsecontainer-body']}`}>
                                        <p className={`${style['sidebar__agent--ratingscontainer-reviewcontainer__bottom--responsecontainer-body__text']}`}>{review.response.answer}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )
            }
        }

        return ret_reviews
    }

    componentWillUnmount(){
        document.body.style.height = 'auto'
        document.body.style.overflowY = 'auto'
    }


    render() {
        return (
            <Sidebar
                sidebar=
                    {
                        this.props.order && (
                            <Fragment>
                                <div className={`${style['sidebar__top']}`}>
                                    <Link to="#" onClick={() => this.props.handler("publicsidebar")}><FontAwesomeIcon icon={faTimes} className={style['sidebar__top--close']} /></Link>
                                    <div className={`${style['sidebar__top--userheader']}`}>
                                        <div className={`${style['sidebar__top--userheader-imagecontainer']}`}>
                                            <img src={this.props.order.avatar ? `/api/${this.props.order.avatar.prefix}/avatar/${this.props.order.avatar.avatar}` : defaultprofile} alt="Recipient avatar" className={`${style['sidebar__top--userheader-imagecontainer__image']}`} />
                                        </div>
                                    </div>
                                </div>
                                <div className={`${style['sidebar__agent']}`}>
                                    <div className={`${style['sidebar__agent--firstcontainer']}`}>
                                        <div className={`${style['sidebar__agent--firstcontainer-ratingcontainer']}`}>
                                            <p className={`${style['sidebar__agent--firstcontainer-ratingcontainer__header']}`}>{lang.orderflow_myself_agent_details_before_accept.view_agent_details_sidebar.user_rating}</p>
                                            <MakeStars ratings={this.props.order.ratings ? this.props.order.ratings.map(r => r.rating) : []}/>
                                        </div>
                                    </div>

                                    {this.props.order.payment_methods && (
                                        <div className={`${style['sidebar__agent--firstcontainer']}`}>
                                            <div className={`${style['sidebar__agent--firstcontainer-feecontainer']}`}>
                                                <p className={`${style['sidebar__agent--firstcontainer-feecontainer__header']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.payment_solution}</p>
                                                <p className={`${style['sidebar__agent--firstcontainer-feecontainer__body']}`}>{constants.makeMethods(this.props.order.payment_methods, this.props.order.mobile_method)}</p>
                                            </div>
                                        </div>
                                    )}

                                    {this.props.order.payment_methods && (
                                        <div className={`${style['sidebar__agent--firstcontainer']}`}>
                                            <div className={`${style['sidebar__agent--firstcontainer-feecontainer']}`}>
                                                <p className={`${style['sidebar__agent--firstcontainer-feecontainer__header']}`}>{lang.create_public_order.sub_header_description}</p>
                                                <p className={`${style['sidebar__agent--firstcontainer-feecontainer__body']}`}>{this.props.order.description}</p>
                                            </div>
                                        </div>
                                    )}

                                    {this.props.order.ratings && this.props.order.ratings.length > 0 && (
                                        <div className={`${style['sidebar__agent--ratingscontainer']}`}>
                                            <div className={`${style['sidebar__agent--ratingscontainer-infocontainer']}`}>
                                                <p className={`${style['sidebar__agent--ratingscontainer-infocontainer__reviewsheader']}`}>{`Reviews`} ({this.props.order.ratings.length})</p>
                                                {(this.props.order.ratings.length > 2 && this.state.reviewCount != 0 && this.state.reviewCount != 2) && (
                                                    <Link to="#" onClick={() => this.setState({reviewCount: 0})} className={`${style['sidebar__agent--ratingscontainer-infocontainer__allheader']}`}>{`See all reviews`}</Link>
                                                )}
                                                {(this.state.reviewCount === 0 || this.state.reviewCount === 2) && (
                                                    <Link to="#" onClick={() => this.setState({reviewCount: 2})} className={`${style['sidebar__agent--ratingscontainer-infocontainer__allheader']}`}>{`Hide reviews`} ({this.props.order.ratings.length - 2})</Link>
                                                )}
                                            </div>
                                            <this.Make_Reviews reviews={this.props.order.ratings}/>
                                        </div>
                                    )}
                                </div>
                                <div className={`${style['sidebar__bottom']}`}>
                                    <input type="button" onClick={() => this.props.next()} className={`${style['sidebar__bottom--button']}`} value={lang.orderflow_myself_agent_details_before_accept.view_agent_details_sidebar.red_button_request_delivery}/>
                                </div>
                            </Fragment>
                        )
                    }
                children={<Fragment/>}
                touchHandleWidth={0}
                open={this.props.isOpen}
                pullRight={true}
                rootClassName={style['sidebarbg']}
                sidebarClassName={style['sidebar']}
                onSetOpen={() => this.props.handler("publicsidebar")}
                styles={ !this.props.isOpen ?
                    {root:{
                        pointerEvents: 'none'
                    }}
                    :
                    {}
                }
            />
        )
    }
}


class PublicChooseMethodSidebar extends Component {
    constructor(props){
        super(props)
    }

    componentDidUpdate(prevprops){
        preventScrolling(prevprops.isOpen, this.props.isOpen)
    }
    componentWillUnmount(){
        document.body.style.height = 'auto'
        document.body.style.overflowY = 'auto'
    }


    render() {
        return (
            <Sidebar
                sidebar=
                    {
                        this.props.order && (
                            <Fragment>
                                <div className={`${style['sidebar__top']}`}>
                                    <Link to="#" onClick={() => this.props.previous()}><FontAwesomeIcon icon={faArrowLeft} className={style['sidebar__top--closearrow']} /></Link>
                                    <div className={`${style['sidebar__top--userheader']}`}>
                                        <div className={`${style['sidebar__top--userheader-imagecontainer']}`}>
                                            <img src={this.props.order.avatar ? `/api/${this.props.order.avatar.prefix}/avatar/${this.props.order.avatar.avatar}` : defaultprofile} alt="Recipient avatar" className={`${style['sidebar__top--userheader-imagecontainer__image']}`} />
                                        </div>
                                    </div>
                                </div>
                                <div className={`${style['sidebar__body']}`}>
                                    <p className={`${style['sidebar__body--header3']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.how_will_you}</p>
                                    { this.props.order.requestable_in_person && (
                                        this.props.order.payment_methods && this.props.order.payment_methods.includes("In person") && (
                                            <Link to="#" style={{textDecoration: 'none'}} onClick={() => this.props.request("In person", this.props.order.id)}>
                                                <div className={`${style['sidebar__body--spacer6']} ${style['sidebar__body--deliverymethod']}`}>
                                                    <div className={`${style['sidebar__body--deliverymethod-left']}`}>
                                                        <p className={`${style['sidebar__body--deliverymethod-left__header']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.deliver_cash_header}</p>
                                                        <p className={`${style['sidebar__body--deliverymethod-left__body']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.deliver_cash}</p>
                                                    </div>
                                                    <div className={`${style['sidebar__body--deliverymethod-right']}`}>
                                                        <FontAwesomeIcon icon={faArrowRight} className={style['sidebar__body--deliverymethod-right__arrow']} />
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                    )}
                                    { this.props.order.payment_methods && this.props.order.payment_methods.includes("Bank transfer") && (
                                        <Link to="#" style={{textDecoration: 'none'}} onClick={() => this.props.request("Bank transfer", this.props.order.id)}>
                                            <div className={`${style['sidebar__body--deliverymethod']}`}>
                                                <div className={`${style['sidebar__body--deliverymethod-left']}`}>
                                                    <p className={`${style['sidebar__body--deliverymethod-left__header']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.receive_transfer_header}</p>
                                                    <p className={`${style['sidebar__body--deliverymethod-left__body']}`}>{`Deliver the cash through a bank transfer. This usually takes a couple of days.`}</p>
                                                </div>
                                                <div className={`${style['sidebar__body--deliverymethod-right']}`}>
                                                    <FontAwesomeIcon icon={faArrowRight} className={style['sidebar__body--deliverymethod-right__arrow']} />
                                                </div>
                                            </div>
                                        </Link>
                                    )}
                                    { this.props.order.payment_methods && this.props.order.payment_methods.includes("Mobile payment") && (
                                        <Link to="#" style={{textDecoration: 'none'}} onClick={() => this.props.request("Mobile payment", this.props.order.id)}>
                                            <div className={`${style['sidebar__body--deliverymethod']}`}>
                                                <div className={`${style['sidebar__body--deliverymethod-left']}`}>
                                                    <p className={`${style['sidebar__body--deliverymethod-left__header']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.receive_other_header}</p>
                                                    <p className={`${style['sidebar__body--deliverymethod-left__body']}`}>{`Deliver the money using ${this.props.order.mobile_method}`}</p>
                                                </div>
                                                <div className={`${style['sidebar__body--deliverymethod-right']}`}>
                                                    <FontAwesomeIcon icon={faArrowRight} className={style['sidebar__body--deliverymethod-right__arrow']} />
                                                </div>
                                            </div>
                                        </Link>
                                    )}

                                </div>
                            </Fragment>
                        )
                    }
                children={<Fragment/>}
                touchHandleWidth={0}
                open={this.props.isOpen}
                pullRight={true}
                rootClassName={style['sidebarbg']}
                sidebarClassName={style['sidebar']}
                onSetOpen={() => this.props.previous()}
                styles={ !this.props.isOpen ?
                    {root:{
                        pointerEvents: 'none'
                    }}
                    :
                    {}
                }
            />
        )
    }
}

class AvailabilitySidebar extends Component {
    constructor(props){
        super(props)
        this.state = {
            location: '',
            testInPerson: true,
            testBank: true,
            testOther: true,
            status: 'start',
            lat: '',
            long: '',
            city: '',
            country: '',
            terms: false,
            email: '',
            deliverer_stats: {},
            recruitModal: false
        }
    }

    componentDidUpdate(prevprops){
        preventScrolling(prevprops.isOpen, this.props.isOpen)
    }
    componentWillUnmount(){
        document.body.style.height = 'auto'
        document.body.style.overflowY = 'auto'
    }


    getDeliverers = async (state) => {

        if(state){
            await this.setState({[state]: !this.state[state]})
        }


        const response_dels = await axios.get('/api/order/deliverers',{
            params:
                {
                    country: this.state.country,
                    lat: this.state.lat,
                    long: this.state.long,
                    cash: this.state.testInPerson,
                    bank: this.state.testBank,
                    other: this.state.testOther,
                    summary: true // Dont want specific information like names, only a short summary
                }
        })
        if(typeof(response_dels.data) === 'object' && Object.keys(response_dels.data).length > 0){
            this.setState({status: 'agentsFound', deliverer_stats: response_dels.data})
        } else{
            this.setState({status: 'noAgents'})
        }
    }


    geocodeLocation = async (e) => {
        var z = await geocodeByPlaceId(e.value.place_id)
        const len = z[0].address_components.length
        var area = z[0].plus_code.compound_code.split(",")
        var city = ''
        var country = ''
        if(Object.keys(z[0]).includes("plus_code") && z[0].plus_code.compound_code){
            var area = z[0].plus_code.compound_code.split(",")
            var city = area[0].split(" ").slice(1).join(" ")
            var country = area[1].substring(1)
        }
        const lat = await z[0].geometry.location.lat()
        const long = await z[0].geometry.location.lng()
        if(!city || !country){

            for(var i = 0; i < len; i++){
                const ts = z[0].address_components[i].types
                const value = z[0].address_components[i].long_name
                if(!city && ts.includes("locality")){
                    city = value
                }
                else if(!country && ts.includes("country")){
                    country = value
                }
            }
        }
        this.setState({
            city: city, country: country, lat: lat, long: long
        })
        this.getDeliverers()
    }


    GetHeader = () => {
        var header = '';
        switch(this.state.status){
            case 'start':
                header = lang.orderflow_customer_landingpage.slide_1_amount.check
                break;
            case 'noAgents':
                header = lang.sidebars.check.no_agents
                break;
            case 'agentsFound':
                header = lang.sidebars.check.available_agents
                break;
            default:
                header = ''
                break;
        }
        return header
    }

    StartBody = () => {
        return <p className={`${style['sidebar__body--text']} ${style['sidebar__body--spacer2']}`}>{lang.sidebars.check.check}</p>
    }

    subscribeEmail = (e) => {
        e.preventDefault()
        if(!this.state.terms){
            alert(lang.sidebars.check.accept)
            return
        }
        axios.post('/api/order/subscribeAvailability', {
            email: this.state.email,
            terms: this.state.terms,
            country: this.state.country,
            lat: this.state.lat,
            long: this.state.long,
            methods: [this.state.testInPerson, this.state.testBank, this.state.testOther]
        }).then(resp => {
            alert(lang.sidebars.check.email)
            this.setState({
                location: '',
                testInPerson: true,
                testBank: true,
                testOther: true,
                status: 'start',
                city: '',
                country: '',
                terms: false,
                email: ''
            })
            this.props.handler("avSidebar")
        }).catch(err => {
            alert(err.response.data.message);
        })
    }

    closeModal = (e) => {
        this.setState({recruitModal: false});
    }

    NoAgentsBody = () => {
        return (
                (!this.state.testInPerson && !this.state.testBank && !this.state.testOther)
                    ?
                        <p className={`${style['sidebar__body--text']} ${style['sidebar__body--spacer']}`}>{lang.sidebars.check.one_method}</p>
                    :
                    <Fragment>
                        <Modal
                          isOpen={this.state.recruitModal}
                          onRequestClose={this.closeModal}
                          className={`${recrstyle['Recruit-modal']}`}
                          style={{overlay: {zIndex: 6}}}
                        >
                            <Recruit func={this.closeModal}/>
                        </Modal>
                        <div className={`${style['sidebar__body']}`}>
                            <div className={`${style['sidebar__body--centericoncontainer']}`}>
                                <img className={`${style['sidebar__body--centericoncontainer-icon']}`} src={ponderEmoji}/>
                            </div>
                            <p className={`${style['sidebar__body--header2']}`}>{lang.sidebars.check.expand}</p>
                            <p className={`${style['sidebar__body--text']}`}>{lang.sidebars.check.no_agent_near} "{this.state.city}, {this.state.country}" {lang.sidebars.check.at_the_moment} {lang.sidebars.check.want_a_reminder}</p>
                            <div className={`${style['sidebar__body--flexcontainer']}`} style={{marginTop: '16px'}}>
                                <Checkbox
                                    extendinputclass={'col-check-red'}
                                    spanstyle={{backgroundColor: 'var(--primgray8)'}}
                                    defaultChecked={this.state.terms}
                                    onClick={() => this.setState({terms: !this.state.terms})}
                                />
                                <p style={{width: '290px'}} className={`${style['sidebar__body--flexcontainer-text']}`}>{lang.sidebars.check.consent}</p>
                            </div>
                            <form>
                                <div className={`${style['sidebar__body--inputcontainer']} ${style['sidebar__body--spacer']}`}>
                                    <FontAwesomeIcon icon={faEnvelope} className={`${style['sidebar__body--inputcontainer-icon']}`} />
                                    <input type="email" name="email" onChange={(e) => this.setState({email: e.target.value})} className={`${style['sidebar__body--inputcontainer-input']}`} value={this.state.email} placeholder={lang.sidebars.check.filler} />
                                </div>
                                <input type="submit" onClick={e => this.subscribeEmail(e)} className={`${style['sidebar__body--button']}`} value={lang.sidebars.check.sign_up}/>
                            </form>
                        </div>
                        <Link to="#" onClick={() => {this.setState({recruitModal: true}); this.props.handler('avSidebar')}} style={{textDecoration: 'none', marginTop: '100px'}}>
                            <div className={`${style['sidebar__body--recruit']}`}>
                                <div className={`${style['sidebar__body--recruit-left']}`}>
                                    <div className={`${style['sidebar__body--recruit-left__cut']}`}/>
                                    <img src={handover} alt={`Handover money`} className={`${style['sidebar__body--recruit-left__img']}`}/>
                                </div>
                                <div className={`${style['sidebar__body--recruit-right']}`}>
                                    <p className={`${style['sidebar__body--recruit-right__header']}`}>RECRUIT A FRIEND</p>
                                    <p className={`${style['sidebar__body--recruit-right__subheader']}`}>Know somebody who wants to become an agent?</p>
                                    <p className={`${style['sidebar__body--recruit-right__text']}`}>Recruit a friend to become an agent and deliver money.</p>
                                </div>
                                <div className={`${style['sidebar__body--recruit-arrowcontainer']}`}>
                                    <FontAwesomeIcon icon={faArrowRight} className={style['sidebar__body--recruit-arrowcontainer__arrow']} />
                                </div>
                            </div>
                        </Link>
                    </Fragment>
        )

    }

    translateTransferType = (transfertype) => {
        var ret = transfertype
        switch(transfertype){
            case 'cash':
                ret = lang.sidebars.check.cash
                break
            case 'bank':
                ret = lang.sidebars.check.bank
                break
            default:
                break
        }
        return ret
    }

    formatAgents = (count) => {
        if(count > 1){
            return `${count} ${lang.sidebars.check.agents}`
        } else{
            return `${count} ${lang.sidebars.check.agent}`
        }
    }

    formatFee = (minfee, maxfee) => {
        if(minfee === maxfee){
            return `${minfee}%`
        } else{
            return `${minfee} - ${maxfee}%`
        }
    }

    AgentsBody = () => {
        var ret = []
        const ks = Object.keys(this.state.deliverer_stats)
        for(var i = 0; i < ks.length; i++){
            var t = this.state.deliverer_stats[ks[i]]
            ret.push(
                <div key={i} className={`${style['sidebar__body--flexcontainer']} ${style['sidebar__body--spacer4']}`}>
                    <div className={`${style['sidebar__body--flexcontainer-icons']}`}>
                        <div>
                            <img src={iconTransfer} className={`${style['sidebar__body--flexcontainer-icons__icon']}`}/>
                        </div>
                        <div>
                            <img src={iconPin} className={`${style['sidebar__body--flexcontainer-icons__icon']}`}/>
                        </div>
                    </div>
                    <div className={`${style['sidebar__body--flexcontainer-texts']}`}>
                        <p className={`${style['sidebar__body--flexcontainer-texts__header']}`}>{this.translateTransferType(ks[i])} ({this.formatAgents(t.agents)})</p>
                        {ks[i] === 'cash'
                        ? <p className={`${style['sidebar__body--flexcontainer-texts__text']}`}>{lang.sidebars.check.near} {this.state.city}</p>
                        : <p className={`${style['sidebar__body--flexcontainer-texts__text']}`}>{lang.sidebars.check.in} {this.state.country}</p>
                        }
                    </div>
                    <div className={`${style['sidebar__body--flexcontainer-fees']}`}>
                        <p className={`${style['sidebar__body--flexcontainer-fees__feeheader']}`}>{lang.sidebars.check.fee}</p>
                        <p className={`${style['sidebar__body--flexcontainer-fees__fee']}`}>{this.formatFee(t.minfee, t.maxfee)}</p>
                    </div>
                </div>
            )
        }
        return (
            <div className={`${style['sidebar__body']}`}>
                <div className={`${style['sidebar__body--centericoncontainer']}`}>
                    <img className={`${style['sidebar__body--centericoncontainer-icon']}`} src={celebrateEmoji}/>
                </div>
                <p className={`${style['sidebar__body--header']} ${style['sidebar__body--spacer3']}`}>{lang.sidebars.check.yay}</p>
                <div className={`${style['sidebar__body--spacer']}`}>
                    {ret}
                </div>
            </div>
        )
    }

    render() {
        return (
            <Sidebar
                sidebar=
                    {
                        <Fragment>
                            <div className={`${style['sidebar__top']}`}>
                                <Link to="#" onClick={() => this.props.handler("avSidebar")}><FontAwesomeIcon icon={faTimes} className={style['sidebar__top--close']} /></Link>
                                <p className={`${style['sidebar__top--title']}`}><this.GetHeader/></p>
                            </div>
                            <div className={`${style['sidebar__body']}`}>
                                <div className={`${style['sidebar__body--searchcontainer']} ${style['sidebar__body--spacer3']}`}>
                                    <FontAwesomeIcon icon={faSearch} className={`${style['sidebar__body--searchcontainer-icon']}`}/>
                                    <GooglePlacesAutocomplete
                                        debounce={500}
                                        selectProps={
                                            {
                                                placeholder: lang.sidebars.check.search_location,
                                                onChange: (e) => this.geocodeLocation(e),
                                            }
                                        }
                                    />

                                </div>
                                <div className={`${style['sidebar__body--flexcontainer3']}`}>
                                    <div className={`${style['sidebar__body--flexcontainer3-subflex']}`}>
                                        <Checkbox
                                            extendinputclass={'avCheckbox'}
                                            spanstyle={{backgroundColor: 'var(--primgray8)', height: '20px', width: '20px'}}
                                            defaultChecked={this.state.testInPerson}
                                            onClick={() => this.getDeliverers('testInPerson')}
                                        />
                                        <p className={`${style['sidebar__body--flexcontainer3-subflex__text']}`}>{lang.sidebars.check.in_person}</p>
                                    </div>
                                    <div className={`${style['sidebar__body--flexcontainer3-subflex']}`}>
                                        <Checkbox
                                            extendinputclass={'avCheckbox'}
                                            spanstyle={{backgroundColor: 'var(--primgray8)', height: '20px', width: '20px'}}
                                            defaultChecked={this.state.testBank}
                                            onClick={() => this.getDeliverers('testBank')}
                                        />
                                        <p className={`${style['sidebar__body--flexcontainer3-subflex__text']}`}>{lang.sidebars.check.bank}</p>
                                    </div>
                                    <div className={`${style['sidebar__body--flexcontainer3-subflex']}`}>
                                        <Checkbox
                                            extendinputclass={'avCheckbox'}
                                            spanstyle={{backgroundColor: 'var(--primgray8)', height: '20px', width: '20px'}}
                                            defaultChecked={this.state.testOther}
                                            onClick={() => this.getDeliverers('testOther')}
                                        />
                                        <p className={`${style['sidebar__body--flexcontainer3-subflex__text']}`}>{lang.sidebars.check.other}</p>
                                    </div>
                                </div>
                            </div>
                                {
                                    this.state.status === 'start'
                                        ? <this.StartBody/>
                                        : this.state.status === 'noAgents'
                                            ? <this.NoAgentsBody/>
                                            : <this.AgentsBody/>
                                }
                        </Fragment>
                    }
                children={<Fragment/>}
                touchHandleWidth={0}
                open={this.props.isOpen}
                pullRight={true}
                rootClassName={style['sidebarbg']}
                sidebarClassName={style['sidebar']}
                onSetOpen={() => this.props.handler("avSidebar")}
                styles={ !this.props.isOpen ?
                    {root:{
                        pointerEvents: 'none'
                    }}
                    :
                    {}
                }
            />
        )
    }
}









const PublicOrderSidebar = withRouter(PublicSidebar)
SignUpSidebar = withRouter(SignUpSidebar)

export {
    HowToSidebar,
    HowToGrowDaiSidebar,
    SignInSidebar,
    SignUpSidebar,
    AgentSidebar,
    PublicOrderSidebar,
    PrivateOrderStep2,
    PrivateOrderStep3_InPerson,
    PrivateOrderStep3_BankTransfer,
    PrivateOrderStep3_MobilePayment,
    HelpSidebar,
    RecipientSidebar,
    DeliverSidebar,
    PasswordSidebar,
    HowToStoreDaiSidebar,
    DaiEqualsDollarSidebar,
    FeesSidebar,
    IdSidebar,
    TermsBusinessSidebar,
    TermsPrivacySidebar,
    RegisterTransferSidebar,
    TransferRegisteredSidebar,
    PublicDetailsSidebar,
    PublicChooseMethodSidebar,
    AvailabilitySidebar
};