import React, { Fragment } from 'react';
import style from './Progress.module.scss';
import { inject, observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';


function makeBullets(orderStep, content){
    var width = 0;
    switch(orderStep){
        case 0:
            width = 0
            break;
        case 1:
            width = 0;
            break;
        case 2:
            width = 189;
            break;
        case '2a':
            width = 189;
            break;
        case 3:
            width = 364;
            break;
        case 4:
            width = 518;
            break;
        default:
            width = 0;
            break;
    }
        return(
            <div style={{maxWidth: '100%', position: 'relative'}}>
                <div className={style['incompleteline']}/>
                <div style={{width: width+'px'}} className={style['line']}/>
                <Bullets step={orderStep} content={content} />
          </div>
        )
}

function Bullets(props){
  var items = Array.apply(null, { length: props.content.length }).map((e, i) => (
    (i+1 < props.step
        ?
            <li key={i}>
                  <p>
                    {props.content[i]}
                </p>
            </li>
          :
          (i+1 === props.step || i+1 === parseInt(props.step.toString().substring(0,1)))
            ?
              <li key={i} className={`${style['current']}`}>
                  <p>
                      {props.content[i]}
                  </p>
              </li>
            :
              // all other cases
              <li key={i} className={`${style['missing']}`}>
                  <p>
                      {props.content[i]}
                  </p>
              </li>

  )))

  return(
        <div className={`${style['Progress']}`}>
            <ol>
              {items}
          </ol>
        </div>
    );
}


export default @inject('orderStore') @withRouter @observer class ProgressBar extends React.Component {
    render() {
        return(
            <Fragment>
              {makeBullets(this.props.orderStore.step, this.props.content)}
            </Fragment>
        )
    }
}