import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import style from './Slide5.module.scss';
import grow from 'img/icons/grow.png';
import wallet from 'img/icons/wallet.png';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HowToStoreDaiSidebar} from 'components/Sidebars/Sidebars';
import { HowToGrowDaiSidebar} from 'components/Sidebars/Sidebars';
import { constants } from 'consts/utils'
var lang = constants.getTranslations

export default class extends Component {

    constructor(){
        super();
        this.state = {
            howToStoreDaiSidebar: false,
            howToGrowDaiSidebar: false,
        }
    }
    handler = (state) => {
        this.setState({[state]: false})
    }
    render(){
		return(
            <Fragment>
                <HowToStoreDaiSidebar handler={this.handler} isOpen={this.state.howToStoreDaiSidebar}/>
                <HowToGrowDaiSidebar handler={this.handler} isOpen={this.state.howToGrowDaiSidebar}/>
                <div className={style['FifthSlide']}>
                    <div className={style['FifthSlide__centercontainer']}>
                        <div className={`${style['FifthSlide__centercontainer--boxcontainer']}`}>
                            <Link to="#" onClick={() => this.setState({howToGrowDaiSidebar: true})}>
                                <div className={`${style['FifthSlide__centercontainer--boxcontainer-left']}`}>
                                    <div className={style['FifthSlide__centercontainer--boxcontainer-left__left']}>
                                        <div className={style['FifthSlide__centercontainer--boxcontainer-left__left--imagecontainer']}>
                                            <img className={style['FifthSlide__centercontainer--boxcontainer-left__left--imagecontainer-img']} src={grow} />
                                        </div>
                                    </div>
                                    <div className={style['FifthSlide__centercontainer--boxcontainer-left__right']}>
                                        <div className={style['FifthSlide__centercontainer--boxcontainer-left__right--content']}>
                                            <p className={style['FifthSlide__centercontainer--boxcontainer-left__right--content-learn']}>{lang.agent_landingpage.slide_5_two_important_links.left_button.learn_how_to}</p>
                                            <p className={style['FifthSlide__centercontainer--boxcontainer-left__right--content-header']}>{lang.agent_landingpage.slide_5_two_important_links.left_button.headline_under_learn_how_to_two}</p>
                                        {/*Martin, vi vil gerne skrive: "Earn x% interests on your Dai". x = den til enhver tid gældende savings rate. Denne kan hentes fra Makers API, som er tilgængelig her: https://github.com/makerdao/developerguides/blob/master/dai/dsr-integration-guide/dsr-integration-guide-01.md / https://github.com/makerdao/dsr-pymaker-example*/}
                                        {/*Svar: Ok, Ikke lige til at fikse så gemmer den :)*/}
                                            <p className={style['FifthSlide__centercontainer--boxcontainer-left__right--content-body']}>{lang.agent_landingpage.slide_5_two_important_links.left_button.text_under_learn_how_to}</p>
                                        </div>
                                        <div className={style['FifthSlide__centercontainer--boxcontainer-left__right--arrowcontainer']}>
                                            <FontAwesomeIcon icon={faArrowRight} className={style['FifthSlide__centercontainer--boxcontainer-left__right--arrowcontainer-arrow']} />
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <Link to="#" onClick={() => this.setState({howToStoreDaiSidebar: true})}>
                                <div className={`${style['FifthSlide__centercontainer--boxcontainer-right']}`}>
                                    <div className={style['FifthSlide__centercontainer--boxcontainer-right__left']}>
                                        <div className={style['FifthSlide__centercontainer--boxcontainer-right__left--imagecontainer']}>
                                            <img className={style['FifthSlide__centercontainer--boxcontainer-right__left--imagecontainer-img']} src={wallet} />
                                        </div>
                                    </div>
                                    <div className={style['FifthSlide__centercontainer--boxcontainer-right__right']}>
                                        <div className={style['FifthSlide__centercontainer--boxcontainer-right__right--content']}>
                                            <p className={style['FifthSlide__centercontainer--boxcontainer-right__right--content-learn']}>{lang.agent_landingpage.slide_5_two_important_links.right_button.learn_how_to}</p>
                                            <p className={style['FifthSlide__centercontainer--boxcontainer-right__right--content-header']}>{lang.agent_landingpage.slide_5_two_important_links.right_button.headline_under_learn_how_to}</p>
                                            <p className={style['FifthSlide__centercontainer--boxcontainer-right__right--content-body']}>{lang.agent_landingpage.slide_5_two_important_links.right_button.text_under_learn_how_to}.</p>
                                        </div>
                                        <div className={style['FifthSlide__centercontainer--boxcontainer-right__right--arrowcontainer']}>
                                            <FontAwesomeIcon icon={faArrowRight} className={style['FifthSlide__centercontainer--boxcontainer-right__right--arrowcontainer-arrow']} />
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </Fragment>
		)
	}
}