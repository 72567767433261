import React, { Component } from 'react';
import style from './Spinner.module.scss';
import Loader from 'react-loader-spinner'

const SpinnerC = (props) => {
    return(
        <div className={`${style['spinner']}`} style={props.style}>
            <div className={`${style['spinner__container']}`}>
                <Loader
                    type="Oval"
                    color="#FF4057"
                    height="80px"
                    width="80px"
                />
                <p className={`${style['spinner__text']}`}>{props.text || 'Processing...'}</p>
            </div>
        </div>
    )
}

export default class Spinner extends Component {
    render(){
        return(
            <SpinnerC {...this.props}/>
        )
    }
}