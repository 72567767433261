import React, { Component } from 'react';
import RouterComponent from './RouterComponent';
import {UserContext} from './services/userContext';
import axios from 'axios';
import TagManager from 'react-gtm-module'
import { gtm } from './config';

const tagManagerArgs = {
  gtmId: gtm
}

export default class App extends Component {


  componentDidMount(){
    TagManager.initialize(tagManagerArgs)
  }

  constructor(props){
    super(props);

    this.setUser = (user) => {
      this.setState(state => ({
        'user': user
      }));
    }
    this.setStatus = (status) => {
      this.setState(state => ({
        isLoggedIn: status
      }));
    }
    this.state = {
      response: '',
      user: {},
      requesting: true,
      isLoggedIn: false,
      setUser: this.setUser,
      setStatus: this.setStatus
    };
  }


  // check if user is already logged in
  checkLogin(){
      axios.get('/api/user')
      .then(resp => {
        if(resp.data.user){
          this.setState({
            isLoggedIn: resp.data.user ? true : false,
            user: resp.data.user
          })
        }
      this.setState({requesting: false});
      }).catch(err => {
          console.log("Error: The server is currently offline, please try again later");
      })
  }


  componentDidMount() {
    this.checkLogin();
  }

  callApi = async () => {
    const response = await fetch('/api/');
    if (response.status !== 200) throw Error("Server is not currently running");
    return response;
  };

  render() {
    return (
      <UserContext.Provider value={this.state}>
          <RouterComponent />
      </UserContext.Provider>
    );
  }
}