import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import style from './Footer.module.scss';
import logo from 'img/logo/logo_icon.png';
import {UserContext} from '../../services/userContext';
import { ReactComponent as Facebook } from 'img/socials/facebook.react.svg'
import { ReactComponent as Instagram } from 'img/socials/instagram.react.svg'
import { ReactComponent as Twitter } from 'img/socials/twitter.react.svg'
import { constants } from 'consts/utils'
import Modal from 'react-modal';

import { HelpSidebar, FeesSidebar, TermsBusinessSidebar, TermsPrivacySidebar } from 'components/Sidebars/Sidebars';


var lang = constants.getTranslations

Modal.setAppElement('body');

class Footer extends Component {
    constructor(props) {
        super(props)
        this.state = {
                videoModal: false,
                videoModalAgent: false,
                helpSidebar: false,
                feesSidebar: false,
                TermsBusinessSidebar: false,
                termsPrivacySidebar: false
        }
    }

    handler = (state) => {
        this.setState({[state]: false})
    }

    render(){

        return(
        	<Fragment>
            	<Modal
                      isOpen={this.state.videoModal}
                      onRequestClose={() => this.setState({videoModal: false})}
                      className={`videoModal`}
                      style={{overlay: {zIndex: 10, margin: 'auto'}}}
                >
                    <iframe src="https://player.vimeo.com/video/366319612?autoplay=1" width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                </Modal>
                <Modal
                      isOpen={this.state.videoModalAgent}
                      onRequestClose={() => this.setState({videoModalAgent: false})}
                      className={`videoModal`}
                      style={{overlay: {zIndex: 10, margin: 'auto'}}}
                >
                    <iframe src="https://player.vimeo.com/video/366319512?autoplay=1" width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                </Modal>

                <HelpSidebar handler={this.handler} isLoggedIn={this.props.context.isLoggedIn} isOpen={this.state.helpSidebar}/>
                <FeesSidebar handler={this.handler} isOpen={this.state.feesSidebar}/>
                <TermsBusinessSidebar handler={this.handler} isOpen={this.state.termsBusinessSidebar}/>
                <TermsPrivacySidebar handler={this.handler} isOpen={this.state.termsPrivacySidebar}/>

            	<div className={style['Footer']}>
            		<div className={style['Footer__container']}>
    					<div className={style['Footer__container--subcontainer']}>
    						<p className={style['Footer__container--subcontainer-header']}>{lang.orderflow_customer_landingpage.footer.dafnir.header}</p>
    						<ul className={style['Footer__container--subcontainer-list']}>
    							<Link to="#" onClick={() => this.setState({helpSidebar: true})}><li className={style['Footer__container--subcontainer-list__item']}>{lang.orderflow_customer_landingpage.footer.dafnir.link_help}</li></Link>
    						</ul>
    					</div>
    					<div className={style['Footer__container--subcontainer']}>
    						<p className={style['Footer__container--subcontainer-header']}>{lang.orderflow_customer_landingpage.footer.send_money.header}</p>
    						<ul className={style['Footer__container--subcontainer-list']}>
    							<Link to='#' onClick={() => this.setState({videoModal: true})}><li className={style['Footer__container--subcontainer-list__item']}>{lang.orderflow_customer_landingpage.footer.send_money.link_how_to_send}</li></Link>
    							<Link to="#" onClick={() => this.setState({feesSidebar: true})}><li className={style['Footer__container--subcontainer-list__item']}>{lang.orderflow_customer_landingpage.footer.send_money.link_fees}</li></Link>
                            </ul>
    					</div>
    					<div className={style['Footer__container--subcontainer']}>
    						<p className={style['Footer__container--subcontainer-header']}>{lang.orderflow_customer_landingpage.footer.agents.header}</p>
    						<ul className={style['Footer__container--subcontainer-list']}>
    							{/*Martin, jeg kan ikke få endnu en modal på til at afspille agent-videoen, har indsat én ovenfor til how to send money.*/}
                                {/*Svar: Mangler at ændre url'en i modal*/}
    							<Link to='#' onClick={() => this.setState({videoModalAgent: true})}><li className={style['Footer__container--subcontainer-list__item']}>{lang.orderflow_customer_landingpage.footer.agents.link_how_to_agent}</li></Link>
    							{this.props.context.isLoggedIn
                                    ? <Link to="/orders/tasks"><li className={style['Footer__container--subcontainer-list__item']}>{lang.orderflow_customer_landingpage.footer.agents.link_earn}</li></Link>
                                    : <Link to="/agent"><li className={style['Footer__container--subcontainer-list__item']}>{lang.orderflow_customer_landingpage.footer.agents.link_earn}</li></Link>
                                }
    						</ul>
    					</div>
    					<div className={style['Footer__container--subcontainer']}>
    						{/*Social media*/}
    						<div className={style['Footer__container--subcontainer-socials']}>
    							<a href='//facebook.com/Dafnircrypto'><Facebook width="12px" height="20.15px" viewBox="0 12 37 35" className={style['Footer__container--subcontainer-socials__icon']}/></a>
    							<a href='//twitter.com/dafnircrypto'><Twitter width="24.15px" height="20.15px" viewBox="0 0 62 52" className={style['Footer__container--subcontainer-socials__icon']}/></a>
    							<Link to='#'><Instagram width="20.15px" height="20.15px" viewBox="0 0 62 62" className={style['Footer__container--subcontainer-socials__icon']}/></Link>
    						</div>
    						<ul className={style['Footer__container--subcontainer-list']}>
    							<Link to="#" onClick={() => this.setState({termsBusinessSidebar: true})}><li className={style['Footer__container--subcontainer-list__item']}>{lang.orderflow_customer_landingpage.footer.other.link_terms}</li></Link>
    							<Link to="#" onClick={() => this.setState({termsPrivacySidebar: true})}><li className={style['Footer__container--subcontainer-list__item']}>{lang.orderflow_customer_landingpage.footer.other.link_privacy}</li></Link>
    						</ul>
    					</div>
    		        </div>
    		        <div className={style['Footer__bottom']}>
    					<div className={style['Footer__bottom--left']}>
    						<img className={style['Footer__bottom--left-logo']} src={logo}/>
    						<p className={style['Footer__bottom--left-text']}>{lang.orderflow_customer_landingpage.footer.all_rights}</p>
    					</div>
    					<div className={style['Footer__bottom--right']}>
    						<select className={style['Footer__bottom--right-button']} defaultValue={constants.getLanguage} type="button" onChange={(e) => constants.setLang(e.target.value)}>
    							<option key={0} value="English">English</option>
    							<option key={1} value="Spanish">Español</option>
    						</select>
    					</div>
    		        </div>
            	</div>
            </Fragment>
        )
 	}
}
export default withRouter(React.forwardRef((props, ref) => (
  <UserContext.Consumer>
    {(user, setStatus, setUser, isLoggedIn) => <Footer {...props} context={user} ref={ref} />}
  </UserContext.Consumer>
)));