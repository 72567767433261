import React, { Component, Fragment } from 'react';
import style from './Review.module.scss';
import { constants } from 'consts/utils'
import axios from 'axios';
import { Link } from 'react-router-dom';
import defaultprofile from 'img/icons/defaultprofile.png';
import { ReactComponent as Checkmark } from 'img/icons/icon-checkmark-round.react.svg'

var lang = constants.getTranslations

export default class Response extends Component{

    constructor(){
        super()
        this.state = {
            response: '', // error message if any
            complete: false,

            name: '',
            firstname: '',
            review: '',
            rating: 0,
            avatar: '',

            answer: ''
        }
    }

    async componentDidMount(){
        if(!name && !this.state.response){
            await this.get_recipient_name()
        }
    }

    get_recipient_name = async () => {
        try {
            const response = await axios.get('/api/order/get_review_from_token', {
                params:
                    {
                        token: this.props.match.params.token
                    }
            })
            this.setState({rating: response.data.rating, review: response.data.review, name: response.data.name, firstname: response.data.firstname, avatar: response.data.avatar})
        } catch (err) {
            try{
                this.setState({ response: err.response.data.message})
            } catch (e){
                this.setState({ response: `Something went wrong, please try again or contact support`})
            }
        }
    }


    MakeStars = (props) => {
        var stars = [];
        for(var i = 1; i <= 5; i++){
            if(props.rating >= i){
                stars.push(<span key={i} data-key={i} className={`${style['starsmall']} ${style['on']}`}></span>)
            } else{
                stars.push(<span key={i} data-key={i} className={`${style['starsmall']}`}></span>)
            }
        }
        return stars
    }

    submitResponse = () => {
        axios.post('/api/order/review/respond', {
            answer: this.state.answer,
            token: this.props.match.params.token
        })
        .then(resp => {
            this.setState({complete: true})
        }).catch(err => {
            alert(err.response.data.message);
        })
    }

    ReviewCompleted = () => {
        return(
            <Fragment>
                <p className={`${style['Review__container--header']}`}>{lang.review.stored}</p>
                <div className={style['Review__container--imagecontainer']}>
                    <hr className={style['Review__container--imagecontainer-line']}/>
                </div>
                <div className={style['Review__container--completecontainer']}>
                    <div className={style['Review__container--completecontainer-iconcontainer']}>
                        <Checkmark width="91.8px" height="84.8px" viewBox="0 10 70 45" className={style['Review__container--completecontainer-iconcontainer__icon']}/>
                    </div>
                    <input type="button" value="Go to frontpage" onClick={() => this.props.history.push("/")} className={`${style['Review__container--completecontainer-button']}`}/>
                </div>
            </Fragment>
        )
    }

    MakeReview = (props) => {
        return(
            <Fragment>
                <p className={`${style['Review__container--header']}`}>{lang.review.answer_to} {props.name}</p>
                <div className={style['Review__container--imagecontainer']}>
                    <hr className={style['Review__container--imagecontainer-line']}/>
                    <img src={props.avatar ? `/api/user/avatar/${props.avatar}` : defaultprofile} alt="Avatar" className={style['Review__container--imagecontainer-image']}/>
                </div>
                <div className={`${style['Review__container--feedbackcontainer']}`}>
                    <this.MakeStars rating={props.rating}/>
                    <p className={`${style['Review__container--feedbackcontainer-review']}`}>“{props.review}”</p>
                </div>
                <p className={`${style['Review__container--subheader']}`}>{lang.review.make_an_answer}</p>
                <div className={`${style['Review__container--reviewcontainer']}`}>
                    <textarea className={`${style['Review__container--reviewcontainer-input']}`} placeholder={`${lang.review.tell} ${props.firstname}`} onChange={(e) => this.setState({answer: e.target.value})} value={props.response}/>
                </div>
                <div className={`${style['Review__container--buttoncontainer']}`}>
                    <input type="button" value="Submit response" onClick={() => this.submitResponse()} className={`${style['Review__container--buttoncontainer-button']}`}/>
                </div>
            </Fragment>
        )
    }

    render(){
        return(
            <div className={`${style['Review__container']}`}>
                {this.state.response
                    ? <p className={`${style['Review__container--text']}`}>{this.state.response}</p>
                    :
                        this.state.complete
                        ?
                            <this.ReviewCompleted/>
                        :
                            <this.MakeReview name={this.state.name} rating={this.state.rating} review={this.state.review} firstname={this.state.firstname} avatar={this.state.avatar}/>
                }
            </div>
        )

    }
}