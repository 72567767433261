import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {UserContext} from '../../services/userContext';
import BN from 'bignumber.js';
import ReactFlagsSelect from 'react-flags-select'
import { constants } from 'consts/utils';
import pin from 'img/icons/icon-mappin.png';
import transfer from 'img/icons/icon-transfer.png';
import flyWallet from 'img/illustrations/fly-wallet.png';
import defaultprofile from 'img/icons/defaultprofile.png';
import { RecipientSidebar } from 'components/Sidebars/Sidebars';
import style from './MyOrders.module.scss';
import dai from 'img/icons/Dai/Logos/PNGs/Mark_Dai.png';

var lang = constants.getTranslations

class MyOrders extends Component {

    constructor(props, context){
        super(props);
        this.state = {
            inProgress: [],
            publicTasks: [],
            recipientsidebar: false,
            chosen_order: {},
            show_completed_orders: false,
            complete_orders: []
        };
        this.handler = this.handler.bind(this);
    }

    handler(state){
        this.setState({recipientsidebar: false})
    }

    componentDidMount(){
        if(Object.keys(this.props.context.user).length === 0){
            location.reload()
        } else{
            if(this.props.context.user.dafnir_purposes.includes(0) && this.props.context.user.verified){
                this.loadOrders();
            }
        }
    }

    loadOrders = async () => {
        // Load orders, then split them into two depending if they're inProgress or not and update state
        try {
            const response = await axios.get('/api/order/list/user/active/orders', {})
            const inProgress = response.data.filter(o => o.status === 'Publicly taken' || o.status === 'Privately taken' || o.status === 'Missing info' || o.status === 'Transfer registered' || o.status === 'Frozen')
            const publicTasks = response.data.filter(o => o.status === 'Publicly available' || o.status === 'Privately available' || o.status === 'Requested')
            this.setState({ inProgress: inProgress, publicTasks: publicTasks });
        } catch (err) {
        }
    }

    test_purpose_orderer(user){
        if(user){
            return user.includes(0);
        }
    }

    showCompleteOrders = async () => {
        const flag = !this.state.show_completed_orders
        var orders = []
        if(flag){
            try {
                const response = await axios.get('/api/order/list/user/history/orders', {
                })
                orders = response.data
            } catch (err) {
            }
        }

        this.setState({
            show_completed_orders: flag,
            complete_orders: orders
        })
    }

    MakeOrders = (props) => {
        var orders = props.orders
        var title = props.title

        var items = []
        function getTitle(order){
            switch(title){
                case 'Complete orders':
                    return lang.my_orders.main_window.active_orders.delivered_by
                case 'Orders in progress':
                    return lang.my_orders.main_window.active_orders.delivered_by
                case 'Public tasks':
                    return lang.settings.profile.purpose_agent
            }
        }

        function cashReceive(){
            switch(title){
                case 'Complete orders':
                    return lang.my_orders.main_window.active_orders.received_fat_grey
                case 'Orders in progress':
                    return lang.my_orders.main_window.active_orders.receive_fat_grey
                case 'Public tasks':
                    return lang.my_orders.main_window.active_orders.receive_fat_grey

            }
        }

        for(var i = 0; i < orders.length; i++){
            console.log(i, orders.length)
            const order = orders[i]
            const amount = new BN(order.amount["$numberDecimal"]).toFixed(2)
            const payment = new BN(order.payment["$numberDecimal"]).toFixed(2)
            items.push(
                <div key={i} className={`${style['Order']}`}>
                    <div className={`${style['Order__top']}`}>
                        <div className={`${style['Order__top--left']}`}>
                            <div className={`${style['Order__top--left-recipient']}`}>
                                <img src={order.avatar ? `/api/order/avatar/${order.avatar}` : order.recipient_id === this.props.context.user._id ? (this.props.context.user.avatar ? '/api/user/image/avatar' : defaultprofile) : defaultprofile} className={`${style['Order__top--left-recipient__avatar']}`} />
                                <div className={`${style['Order__top--left-recipient__container']}`}>
                                    <p className={`${style['Order__top--left-recipient__container--header']}`}>{lang.my_orders.main_window.active_orders.recipient_fat_grey}</p>
                                    {order.status === 'Missing info'
                                        ? order.recipient_id === this.props.context.user._id
                                            ? <p className={`${style['Order__top--left-recipient__container--text']}`}>{this.props.context.user.firstname ? (this.props.context.user.firstname.length > 15 ? this.props.context.user.firstname.substring(0,12)+"..." : this.props.context.user.firstname) : ''} {this.props.context.user.lastnames.split(" ")[0][0]}.</p>
                                            : <p className={`${style['Order__top--left-recipient__container--text']}`}>{order.email}</p>
                                        : <p className={`${style['Order__top--left-recipient__container--text']}`}>{order.name}</p>
                                    }
                                </div>
                            </div>
                            {(order.status === 'Publicly available' || order.status === 'Privately available' || (order.status !== 'Missing info' && (order.agent_info && !Array.isArray(order.agent_info))) &&
                                <div className={`${style['Order__top--left-delivered']}`}>
                                        {(order.status === 'Publicly available')
                                            ?
                                                <div className={style['Order__top--left-delivered__container--noagent']}>
                                                    <p className={style['Order__top--left-delivered__container--noagent-text']}>{lang.my_orders.main_window.public_tasks.no_agent_yet_grey_box_public}</p>
                                                </div>
                                            : (order.status === 'Privately available')
                                                    ?
                                                        <div className={style['Order__top--left-delivered__container--noagent']}>
                                                            <p className={style['Order__top--left-delivered__container--noagent-text']}>{lang.my_orders.main_window.public_tasks.no_agent_yet_grey_box_private}</p>
                                                        </div>
                                                    :
                                                      <Fragment>
                                                        {order.status !== 'Missing info' &&  (
                                                            order.agent_info && !Array.isArray(order.agent_info) && (
                                                                    <Fragment>
                                                                        <img src={order.agent_info.avatar ? `/api/user/avatar/${order.agent_info.avatar}` : defaultprofile} className={`${style['Order__top--left-delivered__avatar']}`}/>
                                                                        <div className={`${style['Order__top--left-delivered__container']}`}>
                                                                            <p className={`${style['Order__top--left-delivered__container--header']}`}>{getTitle(order)}</p>
                                                                            <p className={`${style['Order__top--left-delivered__container--text']}`}>{(typeof(order.agent_info) === 'object' && Object.keys(order.agent_info).length > 0 && order.agent_info.firstname) && (`${order.agent_info.name}`)}</p>
                                                                        </div>
                                                                    </Fragment>
                                                                )
                                                        )}
                                                      </Fragment>
                                        }
                                </div>
                            )}
                            <div className={`${style['Order__top--left-cashtodeliver']}`}>
                                <p className={`${style['Order__top--left-cashtodeliver__text']}`}>{cashReceive()}</p>
                                <div className={`${style['Order__top--left-cashtodeliver__container']}`}>
                                    <ReactFlagsSelect
                                        className={'inline-flag'}
                                        selected={constants.shorthand_curr_to_flag[order.currency]}
                                        showSelectedLabel={false}
                                        placeholder=""
                                        disabled={true}
                                        selectedSize={14}
                                        optionsSize={20}
                                    />
                                    <p className={`${style['Order__top--left-cashtodeliver__container--amount']}`}>{amount}</p>
                                    <p className={`${style['Order__top--left-deposited__container--currency']}`}>{`${order.currency}`}</p>
                                </div>
                            </div>
                            <div className={`${style['Order__top--left-deposited']}`}>
                                <p className={`${style['Order__top--left-deposited__text']}`}>{lang.my_orders.main_window.active_orders.deposited_fat_grey}</p>
                                <div className={`${style['Order__top--left-deposited__container']}`}>
                                    <img src={dai} className={`${style['Order__bottom--left-container__img']}`} />
                                    <p className={`${style['Order__top--left-deposited__container--amount']}`}>{payment}</p>
                                    <p className={`${style['Order__top--left-deposited__container--currency']}`}>{`${order.paymentcurrency}`}</p>
                                </div>
                            </div>
                        </div>
                        <div className={`${style['Order__top--right']}`}>
                        { title != 'Complete orders' && (
                            (order.status === 'Missing info' && order.recipient_id === this.props.context.user._id)
                                ? <input type="button" onClick={() => this.setState({recipientsidebar: true, chosen_order: order})} className={`${style['Order__top--right-button2']}`} value={lang.my_orders.main_window.active_orders.green_button_complete_order}/>
                                : (order.recipient_id === this.props.context.user._id && (order.status === 'Transfer registered' || order.status === ''))
                                    ? <input type="button" onClick={() => this.setState({recipientsidebar: true, chosen_order: order})} className={`${style['Order__top--right-button2']}`} value={lang.my_orders.main_window.active_orders.view_details}/>
                                    : <input type="button" onClick={() => this.setState({recipientsidebar: true, chosen_order: order})} className={`${style['Order__top--right-button']}`} value={lang.my_orders.main_window.active_orders.view_details}/>
                        )}
                        </div>
                    </div>
                    <div className={`${style['Order__bottom']}`}>
                        <div className={`${style['Order__bottom--left']}`}>
                            {order.method
                                ?
                                    <div className={`${style['Order__bottom--left-methodcontainer']}`}>
                                        <img src={transfer} className={`${style['Order__bottom--left-methodcontainer__img']}`} />
                                        <p className={`${style['Order__bottom--left-methodcontainer__text']}`}>{`${constants.makeMethods(order.method, order.payment_info)}`}</p>
                                    </div>
                                :
                                    order.status === 'Publicly available' || order.status === 'Requested'
                                        ?
                                            <div className={`${style['Order__bottom--left-methodcontainer']}`}>
                                                <img src={transfer} className={`${style['Order__bottom--left-methodcontainer__img']}`} />
                                                <p className={`${style['Order__bottom--left-methodcontainer__text']}`}>{`${constants.makeMethods(order.public_methods, order.payment_info)}`}</p>
                                            </div>
                                        :   <Fragment></Fragment>
                            }
                            {(order.status != 'Missing info' && order.status != 'Refunded' && (order.method && (order.method === "In person" || order.method.includes("In person")))) && (
                                <div className={`${style['Order__bottom--left-container']}`}>
                                    <img src={pin} className={`${style['Order__bottom--left-container__img']}`} />
                                    <p className={`${style['Order__bottom--left-container__text']}`}>{`Near ${order.city ? `${order.city}, ` : ''}${order.country ? `${order.country}` : ''}`}</p>
                                </div>
                            )}
{/*                            <div className={`${style['Order__bottom--left-container']}`}>
                                <img src={defaultprofile} className={`${style['Order__bottom--left-container__img']}`} />
                                <p className={`${style['Order__bottom--left-container__text']}`}>8:15 pm - 21 July 2019</p>
                            </div>*/}
                        </div>
                        <div className={`${style['Order__bottom--right']}`}>
                            <p className={`${style['Order__bottom--right-text']}`}>{`${order.status === 'Requested' ? `${lang.my_orders.main_window.public_tasks.agent_status_indicator_requested} ${order.agent_info.length} ${order.agent_info.length > 1 ? lang.my_orders.main_window.public_tasks.agents : lang.my_orders.main_window.public_tasks.agent}` : constants.translateStatus(order.status)}`}</p>
                            {
                                order.status == 'Privately available'
                                ? <div className={`${style['Order__bottom--right-status']} ${style['blue']}`}/>
                                : order.status == 'Publicly available'
                                ? <div className={`${style['Order__bottom--right-status']} ${style['blue']}`}/>
                                : order.status == 'Privately taken'
                                ? <div className={`${style['Order__bottom--right-status']} ${style['green']}`}/>
                                : order.status == 'Publicly taken'
                                ? <div className={`${style['Order__bottom--right-status']} ${style['green']}`}/>
                                : order.status == 'Transfer registered'
                                ? <div className={`${style['Order__bottom--right-status']} ${style['yellow']}`}/>
                                : order.status == 'Privately completed'
                                ? <div className={`${style['Order__bottom--right-status']} ${style['green']}`}/>
                                : order.status == 'Publicly completed'
                                ? <div className={`${style['Order__bottom--right-status']} ${style['green']}`}/>
                                : order.status == 'Requested'
                                ? <div className={`${style['Order__bottom--right-status']} ${style['yellow']}`}/>
                                : order.status == 'Frozen'
                                ? <div className={`${style['Order__bottom--right-status']} ${style['primred']}`}/>
                                : <div className={`${style['Order__bottom--right-status']} ${style['primred']}`}/>
                            }
                        </div>
                    </div>
                </div>
            )
        }
        return items
    }

    render(){
        return(
            <Fragment>
                <RecipientSidebar
                    handler={this.handler}
                    isOpen={this.state.recipientsidebar}
                    order={this.state.chosen_order}
                    user={this.props.context.user}

                />
                <div className={style['MyOrders']}>
                    <div className={style['MyOrders__container']}>
                        <div className={style['MyOrders__container--headercontainer']}>
                            <h1 className={style['MyOrders__container--headercontainer-header']}>{lang.my_orders.main_window.header}</h1>
                            <div className={style['MyOrders__container--headercontainer-right']}>
                                <Link to="#" onClick={() => this.showCompleteOrders()} className={style['MyOrders__container--headercontainer-right__link']}>{this.state.show_completed_orders ? lang.my_orders.main_window.hide : lang.my_orders.main_window.show}</Link>
                                <input type="button" className={style['MyOrders__container--headercontainer-right__button']} value={lang.my_orders.top_menu.send_money} onClick={e => this.props.history.push('/orders/new')}/>
                            </div>
                        </div>
                        {this.props.context.user.complete ?
                            <Fragment>
                            {this.props.context.user.verified ?
                                <Fragment>
                                {this.test_purpose_orderer(this.props.context.user.dafnir_purposes)
                                    ?
                                        (this.state.inProgress.length === 0 && this.state.publicTasks.length === 0 && !this.state.show_completed_orders)
                                            ?
                                                <div className={`${style['MyOrders__container--empty']}`}>
                                                    <img src={flyWallet} alt="Wallet" className={`${style['MyOrders__container--empty-illustration']}`}/>
                                                    <p className={`${style['MyOrders__container--empty-text']}`}>{lang.my_orders.main_window.active_orders.no_order}</p>
                                                    <input type="button" className={style['MyOrders__container--empty-button']} value={lang.my_orders.main_window.active_orders.start} onClick={e => this.props.history.push('/orders/new')}/>
                                                    <div className={`${style['MyOrders__container--empty-linkcontainer']}`}>
                                                        <Link to="#" onClick={() => this.showCompleteOrders()} className={`${style['MyOrders__container--empty-linkcontainer__link']}`}>{lang.my_orders.main_window.show}</Link>
                                                    </div>
                                                </div>
                                            :
                                                <Fragment>
                                                    {this.state.show_completed_orders && (
                                                        <Fragment>
                                                            <h2 className={`${style['MyOrders__container--subheader']}`}>{lang.my_orders.main_window.complete}</h2>
                                                            <this.MakeOrders orders={this.state.complete_orders} title="Complete orders"/>
                                                        </Fragment>
                                                    )}
                                                    {this.state.inProgress.length > 0 && (
                                                        <Fragment>
                                                            <h2 className={`${style['MyOrders__container--subheader']}`}>{lang.my_orders.main_window.active_orders.subheader}</h2>
                                                            <this.MakeOrders orders={this.state.inProgress} title="Orders in progress"/>
                                                        </Fragment>
                                                    )}
                                                    {this.state.publicTasks.length > 0 && (
                                                        <Fragment>
                                                            <h2 className={`${style['MyOrders__container--subheader']}`}>{lang.my_orders.main_window.public_tasks.subheader}</h2>
                                                            <this.MakeOrders orders={this.state.publicTasks} title="Public tasks"/>
                                                        </Fragment>
                                                    )}
                                                </Fragment>
                                    : <center><h2><i>Please set your purpose as a sender in the account settings</i></h2></center>
                                }
                                </Fragment>
                            : <center><h2><i>Please wait for your profile to be verified</i></h2></center>
                        }
                            </Fragment>
                    : <center><h2><i>Please complete your profile</i></h2></center>
                    }

                    </div>
                </div>
            </Fragment>
        )
    }
}

export default React.forwardRef((props, ref) => (
  <UserContext.Consumer>
    {user => <MyOrders {...props} context={user} ref={ref} />}
  </UserContext.Consumer>
));