import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import iconGuarantee from 'img/icons/Icon-guarantee.png'
import style from '../../../Onboarding.module.scss'
import { inject, observer } from 'mobx-react';
import axios from 'axios';
import {UserContext} from 'services/userContext';
import { constants } from 'consts/utils';
import Avatar from 'react-avatar-edit'
var lang = constants.getTranslations

@inject('extendedKYCStore')
@observer
class Step5 extends Component{

    constructor(props){
        super(props)
        this.onCrop = this.onCrop.bind(this)
        this.onClose = this.onClose.bind(this)
        this.onBeforeFileLoad = this.onBeforeFileLoad.bind(this)
    }

    onClose() {
        this.props.extendedKYCStore.avatar = null
      }

    onCrop(preview) {
      this.props.extendedKYCStore.avatar = preview
    }

    onBeforeFileLoad(elem) {
        if(elem.target.files[0].size > 5 * 1024 * 1024){ // 5 MB
          alert("File is too big!");
          elem.target.value = "";
        };
    }


    next = async () => {
        if(this.props.extendedKYCStore.avatar){
            if(this.props.context.user.dafnir_purposes.includes(2)){
                this.props.extendedKYCStore.step = 6
            } else{
                let formData = new FormData();
                formData.append("passport", this.props.extendedKYCStore.passport);
                formData.append("ssn", this.props.extendedKYCStore.ssn);
                formData.append("ether_address", this.props.extendedKYCStore.ether_address);
                formData.append("avatar", constants.dataURLtoFile(this.props.extendedKYCStore.avatar, 'avatar.png'));
                axios.post('/api/user/completeprofile', formData)
                .then(resp => {
                    var user = this.props.context.user
                    user.avatar = resp.data.avatar
                    user.ether_address = this.props.extendedKYCStore.ether_address
                    user.ssn = this.props.extendedKYCStore.ssn
                    user.complete = true
                    this.props.context.setUser(user)
                    this.props.history.push('/?onboarding_complete')
                }).catch(err => {
                    try{
                        alert(err.response.data.message);
                    } catch(e){
                        alert(err)
                    }
                })
            }

        } else{
            alert("Please upload a profile picture")
        }
    }

    render(){
        return(
            <Fragment>
                <div className={style['Onboarding']}>
                    <div className={style['Onboarding__container']}>
                        <p className={style['Onboarding__container--header3']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.picture}</p>
                        <p className={style['Onboarding__container--subheader']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.picture_support}</p>
                        <div className={style['Onboarding__container--imagecontainer']}>
                            <Avatar
                                      imageWidth={constants.avatarconf.width}
                                      imageHeight={constants.avatarconf.height}
                                      width={constants.avatarconf.editorWidth}
                                      height={constants.avatarconf.editorHeight}
                                      onCrop={this.onCrop}
                                      onClose={this.onClose}
                                      onBeforeFileLoad={this.onBeforeFileLoad}
                                      // src={null}
                                />
                            {this.props.extendedKYCStore.avatar && (
                                <img src={this.props.extendedKYCStore.avatar} alt="Preview" className={style['Onboarding__container--imagecontainer-avatar']}/>
                            )}
                        </div>
                        <div className={style['Onboarding__container--buttoncontainer']}>
                            {this.props.extendedKYCStore.avatar && (
                                <input type="button" onClick={() => this.next()} className={style['Onboarding__container--buttoncontainer-button']} value={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.continue}/>
                            )}
                            <Link to="#" onClick={() => this.props.extendedKYCStore.step = 4} className={style['Onboarding__container--buttoncontainer-link']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.previous}</Link>
                        </div>
                        <div className={style['Onboarding__container--textcontainer']}>
                            <img src={iconGuarantee} className={style['Onboarding__container--textcontainer-icon']} alt="Secure" />
                            <p className={style['Onboarding__container--textcontainer-text']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.storage}</p>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default withRouter(React.forwardRef((props, ref) => (
  <UserContext.Consumer>
    {(user, setStatus, setUser, isLoggedIn) => <Step5 {...props} context={user} />}
  </UserContext.Consumer>
)));