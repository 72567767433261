import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import iconGuarantee from 'img/icons/Icon-guarantee.png'
import style from '../../../Onboarding.module.scss'
import { inject, observer } from 'mobx-react';
import { constants } from 'consts/utils';
var lang = constants.getTranslations

export default @inject('extendedKYCStore') @observer class Step9 extends Component{

    constructor(props){
        super(props)
    }

    next = () => {
        this.props.extendedKYCStore.step = 10
    }

    render(){
        return(
            <Fragment>
                <div className={style['Onboarding']}>
                    <div className={style['Onboarding__container']}>
                        <p className={style['Onboarding__container--header3']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.deliver}</p>
                        <p className={style['Onboarding__container--subheader']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.deliver_support}</p>
                        <form>
                            <div className={style['Onboarding__container--inputcontainer']}>
                                <table className={style['Onboarding__container--inputcontainer-table']}>
                                    <thead>
                                        <tr>
                                            <td></td>
                                            <td>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.from}</td>
                                            <td>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.to}</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.monday}</td>
                                            <td><input type="text" value={this.props.extendedKYCStore.deliver_starttime_monday} placeholder="09:00" onChange={(e) => this.props.extendedKYCStore.deliver_starttime_monday = e.target.value}/></td>
                                            <td><input type="text" value={this.props.extendedKYCStore.deliver_endtime_monday} placeholder="16:00" onChange={(e) => this.props.extendedKYCStore.deliver_endtime_monday = e.target.value}/></td>
                                        </tr>
                                        <tr>
                                            <td>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.tuesday}</td>
                                            <td><input type="text" value={this.props.extendedKYCStore.deliver_starttime_tuesday} placeholder="09:00" onChange={(e) => this.props.extendedKYCStore.deliver_starttime_tuesday = e.target.value}/></td>
                                            <td><input type="text" value={this.props.extendedKYCStore.deliver_endtime_tuesday} placeholder="16:00" onChange={(e) => this.props.extendedKYCStore.deliver_endtime_tuesday = e.target.value}/></td>
                                        </tr>
                                        <tr>
                                            <td>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.wednesday}</td>
                                            <td><input type="text" value={this.props.extendedKYCStore.deliver_starttime_wednesday} placeholder="09:00" onChange={(e) => this.props.extendedKYCStore.deliver_starttime_wednesday = e.target.value}/></td>
                                            <td><input type="text" value={this.props.extendedKYCStore.deliver_endtime_wednesday} placeholder="16:00" onChange={(e) => this.props.extendedKYCStore.deliver_endtime_wednesday = e.target.value}/></td>
                                        </tr>
                                        <tr>
                                            <td>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.thursday}</td>
                                            <td><input type="text" value={this.props.extendedKYCStore.deliver_starttime_thursday} placeholder="09:00" onChange={(e) => this.props.extendedKYCStore.deliver_starttime_thursday = e.target.value}/></td>
                                            <td><input type="text" value={this.props.extendedKYCStore.deliver_endtime_thursday} placeholder="16:00" onChange={(e) => this.props.extendedKYCStore.deliver_endtime_thursday = e.target.value}/></td>
                                        </tr>
                                        <tr>
                                            <td>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.friday}</td>
                                            <td><input type="text" value={this.props.extendedKYCStore.deliver_starttime_friday} placeholder="09:00" onChange={(e) => this.props.extendedKYCStore.deliver_starttime_friday = e.target.value}/></td>
                                            <td><input type="text" value={this.props.extendedKYCStore.deliver_endtime_friday} placeholder="16:00" onChange={(e) => this.props.extendedKYCStore.deliver_endtime_friday = e.target.value}/></td>
                                        </tr>
                                        <tr>
                                            <td>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.saturday}</td>
                                            <td><input type="text" value={this.props.extendedKYCStore.deliver_starttime_saturday} placeholder="09:00" onChange={(e) => this.props.extendedKYCStore.deliver_starttime_saturday = e.target.value}/></td>
                                            <td><input type="   text" value={this.props.extendedKYCStore.deliver_endtime_saturday} placeholder="16:00" onChange={(e) => this.props.extendedKYCStore.deliver_endtime_saturday = e.target.value}/></td>
                                        </tr>
                                        <tr>
                                            <td>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.sunday}</td>
                                            <td><input type="text" value={this.props.extendedKYCStore.deliver_starttime_sunday} placeholder="09:00" onChange={(e) => this.props.extendedKYCStore.deliver_starttime_sunday = e.target.value}/></td>
                                            <td><input type="text" value={this.props.extendedKYCStore.deliver_endtime_sunday} placeholder="16:00" onChange={(e) => this.props.extendedKYCStore.deliver_endtime_sunday = e.target.value}/></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={style['Onboarding__container--buttoncontainer']}>
                                <input type="submit" onClick={() => this.next()} className={style['Onboarding__container--buttoncontainer-button']} value={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.continue}/>
                                <Link to="#" onClick={() => this.props.extendedKYCStore.step = 8} className={style['Onboarding__container--buttoncontainer-link']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.previous}</Link>
                            </div>
                        </form>
                        <div className={style['Onboarding__container--textcontainer']}>
                            <img src={iconGuarantee} className={style['Onboarding__container--textcontainer-icon']} alt="Secure" />
                            <p className={style['Onboarding__container--textcontainer-text']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.storage}</p>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}