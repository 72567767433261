import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import style from './Slide4.module.scss';
import dai from 'img/icons/Dai/Logos/PNGs/Mark_Dai.png';
import moneyhand from 'img/icons/money-hand.png';
import daihand from 'img/icons/dai-use.png';
import wallet from 'img/icons/wallet.png';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HowToStoreDaiSidebar } from 'components/Sidebars/Sidebars';
import { HowToSidebar } from 'components/Sidebars/Sidebars';
import { constants } from 'consts/utils'
var lang = constants.getTranslations

export default class extends Component {

    constructor(){
        super();
        this.state = {
            howToGetDaiSidebar: false,
            howToStoreDaiSidebar: false,
        }
    }
    handler = (state) => {
        this.setState({[state]: false})
    }

    render(){
		return(
            <Fragment>
                <HowToSidebar handler={this.handler} isOpen={this.state.howToGetDaiSidebar}/>
                <HowToStoreDaiSidebar handler={this.handler} isOpen={this.state.howToStoreDaiSidebar}/>
                <div className={style['FourthSlide']}>
                    <div className={style['FourthSlide__centercontainer']}>
                        <div className={`${style['FourthSlide__centercontainer--boxcontainer']}`}>
                            <Link to="#" onClick={() => this.setState({howToGetDaiSidebar: true})}>
                                <div className={`${style['FourthSlide__centercontainer--boxcontainer-left']}`}>
                                    <div className={style['FourthSlide__centercontainer--boxcontainer-left__left']}>
                                        <div className={style['FourthSlide__centercontainer--boxcontainer-left__left--imagecontainer']}>
                                            <img className={style['FourthSlide__centercontainer--boxcontainer-left__left--imagecontainer-img']} src={daihand} />
                                        </div>
                                    </div>
                                    <div className={style['FourthSlide__centercontainer--boxcontainer-left__right']}>
                                        <div className={style['FourthSlide__centercontainer--boxcontainer-left__right--content']}>
                                            <p className={style['FourthSlide__centercontainer--boxcontainer-left__right--content-learn']}>{lang.orderflow_customer_landingpage.slide_4_two_important_links.left_button.get_help}</p>
                                            <p className={style['FourthSlide__centercontainer--boxcontainer-left__right--content-header']}>{lang.orderflow_customer_landingpage.slide_1_amount.get_dai}</p>
                                            <p className={style['FourthSlide__centercontainer--boxcontainer-left__right--content-body']}>{lang.orderflow_customer_landingpage.slide_4_two_important_links.left_button.text_under_learn_how_to}</p>
                                        </div>
                                        <div className={style['FourthSlide__centercontainer--boxcontainer-left__right--arrowcontainer']}>
                                            <FontAwesomeIcon icon={faArrowRight} className={style['FourthSlide__centercontainer--boxcontainer-left__right--arrowcontainer-arrow']} />
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <Link to="#" onClick={() => this.setState({howToStoreDaiSidebar: true})}>
                                <div className={`${style['FourthSlide__centercontainer--boxcontainer-right']}`}>
                                    <div className={style['FourthSlide__centercontainer--boxcontainer-right__left']}>
                                        <div className={style['FourthSlide__centercontainer--boxcontainer-right__left--imagecontainer']}>
                                            <img className={style['FourthSlide__centercontainer--boxcontainer-right__left--imagecontainer-img']} src={wallet} />
                                        </div>
                                    </div>
                                    <div className={style['FourthSlide__centercontainer--boxcontainer-right__right']}>
                                        <div className={style['FourthSlide__centercontainer--boxcontainer-right__right--content']}>
                                            <p className={style['FourthSlide__centercontainer--boxcontainer-right__right--content-learn']}>{lang.orderflow_customer_landingpage.slide_1_amount.learn_how_to_red_text}</p>
                                            <p className={style['FourthSlide__centercontainer--boxcontainer-right__right--content-header']}>{lang.orderflow_customer_landingpage.slide_1_amount.headline_under_learn_how_to_two}</p>
                                            <p className={style['FourthSlide__centercontainer--boxcontainer-right__right--content-body']}>{lang.orderflow_customer_landingpage.slide_1_amount.text_under_learn_how_to_two}</p>

                                        </div>
                                        <div className={style['FourthSlide__centercontainer--boxcontainer-right__right--arrowcontainer']}>
                                            <FontAwesomeIcon icon={faArrowRight} className={style['FourthSlide__centercontainer--boxcontainer-right__right--arrowcontainer-arrow']} />
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </Fragment>

		)
	}
}