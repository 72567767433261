import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import why_kyc from '../../../img/Why_KYC.png'
import iconGuarantee from 'img/icons/Icon-guarantee.png'
import style from '../../../Onboarding.module.scss'
import Modal from 'react-modal';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { inject, observer } from 'mobx-react';
import {UserContext} from 'services/userContext';
import { IdSidebar } from 'components/Sidebars/Sidebars';
import { constants } from 'consts/utils';
var lang = constants.getTranslations

@inject('extendedKYCStore')
@observer
class Step1 extends Component{

    constructor(props){
        super(props)
        this.state = {
            videoModal: false,
            idSidebar: false,
        }
    }

    handler = (state) => {
        this.setState({[state]: false})
    }

    componentDidMount(){
        if(this.props.context.user.complete){
            this.props.history.push('/')
        }
    }

    render(){
        return(
            <Fragment>
                <IdSidebar handler={this.handler} isOpen={this.state.idSidebar}/>
                <Modal
                  isOpen={this.state.videoModal}
                  onRequestClose={() => this.setState({videoModal: false})}
                  className={'videoModal'}
                >
                    <iframe src="https://player.vimeo.com/video/405320739" width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                </Modal>
                <div className={style['Onboarding']}>
                    <div className={style['Onboarding__container']}>
                        <p className={style['Onboarding__container--header3']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.id}</p>
                        <Link to="#" onClick={() => this.setState({idSidebar: true})} className={style['Onboarding__container--sublink']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.id_link}</Link>
                        <div className={style['Onboarding__container--imagecontainer']}>
                            <img src={why_kyc} alt="Email verified" className={style['Onboarding__container--imagecontainer-picture']}/>
                            <div className={`${style['Onboarding__container--imagecontainer-playcontainer']}`}>
                                <Link to="#" onClick={() => this.setState({videoModal: true})}>
                                    <div className={`${style['Onboarding__container--imagecontainer-playcontainer__circle']}`}>
                                        <FontAwesomeIcon icon={faPlay} className={`${style['Onboarding__container--imagecontainer-playcontainer__circle--play']}`} />
                                    </div>
                                </Link>
                                <Link to="#" onClick={() => this.setState({videoModal: true})}>
                                    <p className={`${style['Onboarding__container--imagecontainer-playcontainer__text']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.how}</p>
                                </Link>
                            </div>
                        </div>
                        <div className={style['Onboarding__container--buttoncontainer']}>
                            <input type="button" onClick={() => this.props.extendedKYCStore.step = 2} className={style['Onboarding__container--buttoncontainer-button']} value={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.ready}/>
                        </div>
                        <div className={style['Onboarding__container--textcontainer']}>
                            <img src={iconGuarantee} className={style['Onboarding__container--textcontainer-icon']} alt="Secure" />
                            <p className={style['Onboarding__container--textcontainer-text']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.storage}</p>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(React.forwardRef((props, ref) => (
  <UserContext.Consumer>
    {(user, setStatus, setUser, isLoggedIn) => <Step1 {...props} context={user} ref={ref} />}
  </UserContext.Consumer>
)));