import React, { Component, Fragment } from 'react';
import { Link} from 'react-router-dom';
import iconMail from './img/icon-mail.png'
import style from './Onboarding.module.scss'
import { inject, observer } from 'mobx-react';
import axios from 'axios';
import { constants } from 'consts/utils';
var lang = constants.getTranslations

export default @inject('basicKYCStore') @observer class SignedUp extends Component{

    constructor(props){
        super(props)
        if(!props.basicKYCStore.email){
            this.props.history.push('/');
        }
    }

    resend_verification_email = () => {
        axios.post('/api/user/resend_token', {email: this.props.basicKYCStore.email})
        .then(resp => {
            try{
                alert(resp.data.message)
            } catch(err){
                alert(lang.kys_kyr_kya.customer_due_diligence.email.wrong)
            }
        }).catch(err => {
            try{
                alert(err.response.data.message)
            } catch(err){
                alert(lang.kys_kyr_kya.customer_due_diligence.email.wrong)
            }
        })
    }

    render(){
        return(
            <div className={style['Onboarding']}>
                <div className={style['Onboarding__container']}>
                    <p className={style['Onboarding__container--header']}>{lang.kys_kyr_kya.customer_due_diligence.email.to_continue}</p>
                    <div className={style['Onboarding__container--imagecontainer']}>
                        <img src={iconMail} alt="Email verified" className={style['Onboarding__container--imagecontainer-image']}/>
                    </div>
                    <div className={style['Onboarding__container--textcontainer']}>
                        <p className={style['Onboarding__container--textcontainer-center']}>{lang.kys_kyr_kya.customer_due_diligence.email.for_security} <b>{this.props.basicKYCStore.email}</b>. {lang.kys_kyr_kya.customer_due_diligence.email.simply_click}</p>
                    </div>
                    <div className={style['Onboarding__container--textcontainer']}>
                        <p className={style['Onboarding__container--textcontainer-center']}>{lang.kys_kyr_kya.customer_due_diligence.email.no_email} <Link to="#" onClick={() => this.resend_verification_email()} className={style['Onboarding__container--resend-link']}>{lang.kys_kyr_kya.customer_due_diligence.email.send_again}</Link></p>
                    </div>
                </div>
            </div>
        )
    }
}