import React, { Component } from 'react';
import sty from './Checkbox.module.scss';

class Checkbox extends Component {

    componentDidMount(){

    }

    render(){
        return(
            <label
                className={`${sty['label']} ${this.props.extendlabelclass ? ' '+this.props.extendlabelclass : ''}`}
                style={this.props.labelstyle}>
                    <input
                        className={`${sty['input']} ${this.props.extendinputclass ? ' '+this.props.extendinputclass : ''}`}
                        type="checkbox"
                        style={this.props.inputstyle}
                        {...this.props}
                    />
                    <span
                        className={`${sty['span']} ${this.props.extendspanclass ? ' '+this.props.extendspanclass : ''}`}
                        style={this.props.spanstyle}
                    />
            </label>
        )
    }
}
export default Checkbox;
