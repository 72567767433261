import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import style from './Slide2.module.scss';
import moneyhand from 'img/icons/money-hand.png';
import nobanks from './img/Icon-no-banks.png'
import trust from './img/Icon-trust1.png'
import trust2 from './img/Icon-trust2.png'
import guarantee from './img/Icon-guarantee.png'
import privacy from './img/Icon-privacy.png'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { constants } from 'consts/utils'
var lang = constants.getTranslations

export default class extends Component {
	render(){
		return(
            <div className={style['SecondSlide']}>
                <Link to="/agent">
                    <div className={style['SecondSlide__learncontainer']}>
                        <div className={style['SecondSlide__learncontainer--box']}>
                            <div className={style['SecondSlide__learncontainer--box-left']}>
                                <div className={style['SecondSlide__learncontainer--box-left__imagecontainer']}>
                                    <img className={style['SecondSlide__learncontainer--box-left__imagecontainer--img']} src={moneyhand} />
                                </div>
                            </div>
                            <div className={style['SecondSlide__learncontainer--box-right']}>
                                <div className={style['SecondSlide__learncontainer--box-right__content']}>
                                    <p className={style['SecondSlide__learncontainer--box-right__content--learn']}>{lang.orderflow_customer_landingpage.slide_1_amount.learn_how_to_red_text}</p>
                                    <p className={style['SecondSlide__learncontainer--box-right__content--header']}>{lang.orderflow_customer_landingpage.slide_1_amount.headline_under_learn_how_to}</p>
                                    <p className={style['SecondSlide__learncontainer--box-right__content--body']}>{lang.orderflow_customer_landingpage.slide_1_amount.text_under_learn_how_to}</p>
                                </div>
                                <div className={style['SecondSlide__learncontainer--box-right__arrowcontainer']}>
                                    <FontAwesomeIcon icon={faArrowRight} className={style['SecondSlide__learncontainer--box-right__arrowcontainer--arrow']} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
                <div className={style['SecondSlide__container']}>
                    <div className={style['SecondSlide__container--itemcontainer']}>
                        <div className={style['SecondSlide__container--itemcontainer-item']}>
                            <div className={style['SecondSlide__container--itemcontainer-item__iconcontainer']}>
                                <img className={style['SecondSlide__container--itemcontainer-item__iconcontainer--icon']} src={nobanks} alt='No banks' />
                            </div>
                            <div className={style['SecondSlide__container--itemcontainer-item__textcontainer']}>
                                <p className={style['SecondSlide__container--itemcontainer-item__textcontainer--header']}>{lang.orderflow_customer_landingpage.slide_2_overview_of_valueprop.no_banks.header}</p>
                                <p className={style['SecondSlide__container--itemcontainer-item__textcontainer--body']}>{lang.orderflow_customer_landingpage.slide_2_overview_of_valueprop.no_banks.text}</p>
                            </div>
                        </div>
                        <div className={style['SecondSlide__container--itemcontainer-item']}>
                            <div className={style['SecondSlide__container--itemcontainer-item__iconcontainer']}>
                                <img className={style['SecondSlide__container--itemcontainer-item__iconcontainer--icon']} src={trust} alt='Trust' />
                                <img className={style['SecondSlide__container--itemcontainer-item__iconcontainer--icon']} src={trust2} />
                            </div>
                            <div className={style['SecondSlide__container--itemcontainer-item__textcontainer']}>
                                <p className={style['SecondSlide__container--itemcontainer-item__textcontainer--header']}>{lang.orderflow_customer_landingpage.slide_2_overview_of_valueprop.trust.header}</p>
                                <p className={style['SecondSlide__container--itemcontainer-item__textcontainer--body']}>{lang.orderflow_customer_landingpage.slide_2_overview_of_valueprop.trust.text}</p>
                            </div>
                        </div>
                        <div className={style['SecondSlide__container--itemcontainer-item']}>
                            <div className={style['SecondSlide__container--itemcontainer-item__iconcontainer']}>
                                <img className={style['SecondSlide__container--itemcontainer-item__iconcontainer--icon']} src={guarantee} alt='Guarantee' />
                            </div>
                            <div className={style['SecondSlide__container--itemcontainer-item__textcontainer']}>
                                <p className={style['SecondSlide__container--itemcontainer-item__textcontainer--header']}>{lang.orderflow_customer_landingpage.slide_2_overview_of_valueprop.guarantee.header}</p>
                                <p className={style['SecondSlide__container--itemcontainer-item__textcontainer--body']}>{lang.orderflow_customer_landingpage.slide_2_overview_of_valueprop.guarantee.text}</p>
                            </div>
                        </div>
                        <div className={style['SecondSlide__container--itemcontainer-item']}>
                            <div className={style['SecondSlide__container--itemcontainer-item__iconcontainer']}>
                                <img className={style['SecondSlide__container--itemcontainer-item__iconcontainer--icon']} src={privacy} alt='Privacy' />
                            </div>
                            <div className={style['SecondSlide__container--itemcontainer-item__textcontainer']}>
                                <p className={style['SecondSlide__container--itemcontainer-item__textcontainer--header']}>{lang.orderflow_customer_landingpage.slide_2_overview_of_valueprop.privacy.header}</p>
                                <p className={style['SecondSlide__container--itemcontainer-item__textcontainer--body']}>{lang.orderflow_customer_landingpage.slide_2_overview_of_valueprop.privacy.text}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		)
	}
}