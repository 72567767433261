import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import iconGuarantee from 'img/icons/Icon-guarantee.png'
import ssn from '../../../img/ssn.png'
import style from '../../../Onboarding.module.scss'
import { inject, observer } from 'mobx-react';
import axios from 'axios';
import { constants } from 'consts/utils';
import { HowToStoreDaiSidebar} from 'components/Sidebars/Sidebars';
var lang = constants.getTranslations

@inject('extendedKYCStore')
@observer
class Step4 extends Component{

    constructor(props){
        super(props)
        this.state = {
            howToStoreDaiSidebar: false
        }
    }

    next = (e) => {
        e.preventDefault()
        if(this.props.extendedKYCStore.ether_address){
            if(this.props.extendedKYCStore.ether_address.length === 42){
                var ch2 = this.props.extendedKYCStore.ether_address.substr(0,2)
                if(ch2 === '0x' || ch2 === '0X'){
                    this.props.extendedKYCStore.step = 5
                } else{
                    alert("An ether address has to start with 0x")
                }
            } else{
                alert("An ether address has to be 42 characters long")
            }
        } else{
            alert(lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.please_wallet)
        }
    }

    handler = (state) => {
        this.setState({[state]: false})
    }

    render(){
        return(
            <Fragment>
            <HowToStoreDaiSidebar handler={this.handler} isOpen={this.state.howToStoreDaiSidebar}/>
                <div className={style['Onboarding']}>
                    <form>
                        <div className={style['Onboarding__container']}>
                            <p className={style['Onboarding__container--header3']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.wallet}</p>
                            <p className={style['Onboarding__container--subheader']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.wallet_support}</p>
                            <Link to="#" onClick={() => this.setState({howToStoreDaiSidebar: true})} className={style['Onboarding__container--sublink']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.wallet_link}</Link>
                            <div className={style['Onboarding__container--inputcontainer']}>
                                <p className={style['Onboarding__container--inputcontainer-label']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.address}</p>
                                <input placeholder={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.address_filler} className={style['Onboarding__container--inputcontainer-input']} type="text" value={this.props.extendedKYCStore.ether_address} onChange={(e) => this.props.extendedKYCStore.ether_address = e.target.value}/>
                            </div>
                            <div className={style['Onboarding__container--buttoncontainer']}>
                                <input type="submit" onClick={(e) => this.next(e)} className={style['Onboarding__container--buttoncontainer-button']} value={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.continue}/>
                                <Link to="#" onClick={() => this.props.extendedKYCStore.step = 3} className={style['Onboarding__container--buttoncontainer-link']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.previous}</Link>
                            </div>
                            <div className={style['Onboarding__container--textcontainer']}>
                                <img src={iconGuarantee} className={style['Onboarding__container--textcontainer-icon']} alt="Secure" />
                                <p className={style['Onboarding__container--textcontainer-text']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.storage}</p>
                            </div>
                        </div>
                    </form>
                </div>
            </Fragment>
        )
    }
}
export default Step4