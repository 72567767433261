import React, { Component, Fragment } from 'react';
import style from './Step2.module.scss';
import someoneelse from './img/Avatar.png'
import ordstyle from '../../Order.module.scss';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import defaultprofile from 'img/icons/defaultprofile.png';
import {UserContext} from 'services/userContext';
import { constants } from 'consts/utils';
import axios from 'axios';
var lang = constants.getTranslations

@inject('orderStore') @observer class Step2 extends Component {


    async setOrderStoreSelf(){
        const response = await axios.get('/api/user')
        const user = response.data.user;
        try{
            this.props.orderStore.receiver_email = user.email;
        } catch(e){
            this.props.orderStore.receiver_email = '';
        }
        try{
            if(user.ether_address){
                this.props.orderStore.ether_address = user.ether_address
            } else{
                this.props.orderStore.ether_address = 'No ether wallet found'
            }
        } catch(e){
            this.props.orderStore.ether_address = 'No ether wallet found'
        }
    }

    async setOrderStoreOther(){
        const response = await axios.get('/api/user')
        const user = response.data.user;
        try{
            if(user.ether_address){
                this.props.orderStore.ether_address = user.ether_address
            } else{
                this.props.orderStore.ether_address = 'No ether wallet found'
            }
        } catch(e){
            this.props.orderStore.ether_address = 'No ether wallet found'
        }
    }

    nextStepSelf = (e) => {
        e.preventDefault();
        this.setOrderStoreSelf();
        this.props.orderStore.recipient = 'self';
        this.props.orderStore.step = 3;
    }

    nextStepOther = (e) => {
        e.preventDefault();
        this.setOrderStoreOther();
        this.props.orderStore.recipient = 'other';
        this.props.orderStore.step = '2a';
    }

    render() {
        return(
            <div className={style['Step2']}>
                <div className={`${ordstyle['Order__container']}`}>
                    <p className={`${ordstyle['Order__container--header']}`}>{lang.orderflow_who_is_the_recipient_myself_or_someone_else.white_box.header}</p>
                    <div className={style['Step2__container--itemscontainer']}>
                        <div className={style['Step2__container--itemscontainer-item']}>
                            <div className={style['Step2__container--itemscontainer-item__imgcontainer']}>
                                <img className={style['Step2__container--itemscontainer-item__imgcontainer--img']} src={this.props.context.user.avatar ? '/api/user/image/avatar' : defaultprofile} alt="Myself"/>
                            </div>
                            <p className={style['Step2__container--itemscontainer-item__who']}>{lang.orderflow_who_is_the_recipient_myself_or_someone_else.left_button.header}</p>
                            <p className={style['Step2__container--itemscontainer-item__subtext']}>{lang.orderflow_who_is_the_recipient_myself_or_someone_else.left_button.explanation_text}</p>
                            <input type="button" className={style['Step2__container--itemscontainer-item__button']} onClick={this.nextStepSelf} value={lang.orderflow_who_is_the_recipient_myself_or_someone_else.left_button.red_button}/>
                        </div>
                        <div className={style['Step2__container--itemscontainer-item']}>
                            <div className={style['Step2__container--itemscontainer-item__imgcontainer']}>
                                <img className={style['Step2__container--itemscontainer-item__imgcontainer--img']} src={someoneelse} alt="Myself"/>
                            </div>
                            <p className={style['Step2__container--itemscontainer-item__who']}>{lang.orderflow_who_is_the_recipient_myself_or_someone_else.right_button.header}</p>
                            <p className={style['Step2__container--itemscontainer-item__subtext']}>{lang.orderflow_who_is_the_recipient_myself_or_someone_else.right_button.explanation_text}</p>
                            <input type="button" className={style['Step2__container--itemscontainer-item__button']} onClick={this.nextStepOther} value={lang.orderflow_who_is_the_recipient_myself_or_someone_else.left_button.red_button}/>
                        </div>
                    </div>
                    <div className={ordstyle['Order__nextcontainer']}>
                        <div>
                            <Link to="#" onClick={e => this.props.orderStore.step = 1} className={ordstyle['Order__nextcontainer--prev']}>{lang.orderflow_amount.white_box.previous_step_link}</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default React.forwardRef((props, ref) => (
  <UserContext.Consumer>
    {user => <Step2 {...props} context={user} ref={ref} />}
  </UserContext.Consumer>
));