import React from "react";
import {UserContext} from '../services/userContext';
import { Route, Redirect } from "react-router-dom";

export default ({ component: C, props: cProps, ...rest }) =>
  <UserContext.Consumer>
    {
      ({isLoggedIn, requesting, user})=>
        <Route
          {...rest}
          render={props =>
            requesting
              ? (<center><i>Checking admin status...</i></center>)
              : !(user.role === 'Admin')
                ? (
                  <Redirect
                    to={`/`}
                  />
                )
                : (
                  <C {...props} {...cProps} />
                )
}
        />
    }
  </UserContext.Consumer>