import React, { Component, Fragment } from 'react';
import style from './BecomeAnAgent.module.scss';
import Navbar from 'components/Navbar/Navbar';

import FirstSlide from './localComponents/Slide1/Slide1';
import SecondSlide from './localComponents/Slide2/Slide2';
import ThirdSlide from './localComponents/Slide3/Slide3';
import FourthSlide from './localComponents/Slide4/Slide4';
import FifthSlide from './localComponents/Slide5/Slide5';
import Footer from 'components/Footer/Footer';

export default class BecomeAnAgent extends Component {

    render(){
        return(
            <Fragment>
                <div className={style['BecomeAnAgent']}>
                    <FirstSlide/>
                    <SecondSlide/>
{/*                    <ThirdSlide/>*/}
                    <FourthSlide/>
                    <FifthSlide/>
                </div>
            </Fragment>
        )
    }
}