import React, { Component, Fragment } from 'react';
import logo from 'img/logo/Dafnir-logo-white.svg';
import { withRouter, Link, NavLink } from 'react-router-dom';
import Welcome from 'components/Welcome/Welcome';
import OnboardingComplete from 'components/OnboardingComplete/OnboardingComplete';
import axios from 'axios';
import Modal from 'react-modal';
import {UserContext} from '../../services/userContext';
import Recruit from 'components/Recruit/Recruit';
import { constants } from 'consts/utils'
import defaultprofile from 'img/icons/defaultprofile.png';
import ProgressBar from './localComponents/Progress/Progress';
import style from './Navbar.module.scss';
import recrstyle from 'components/Recruit/Recruit.module.scss';
import { SignInSidebar, SignUpSidebar, HelpSidebar, TermsPrivacySidebar, TermsBusinessSidebar } from 'components/Sidebars/Sidebars';

var lang = constants.getTranslations

class Navbar extends Component {
    constructor(props){
        super(props);
        this.state = {
            isOpen: false,
            showMenu: false,
            signinSidebar: false,
            signupSidebar: false,
            helpSidebar: false,
            mobmenu: false, change_class: '',
            isWelcomeOpen: false,
            isOnboardingOpen: false,
            change_class: '',
            termsBusinessSidebar: false,
            termsPrivacySidebar: false,
            firstname: props.context.user.firstname || '',
            lastname: Object.keys(props.context.user).length > 0 && props.context.user.lastnames != '' ? `${props.context.user.lastnames.split(" ")[0][0]}.` : '' || '',
        };
        this.openModal = this.openModal.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handler = this.handler.bind(this);
    }

    componentDidUpdate(props){
        if((this.state.firstname === '' || this.state.lastname === '') && props.context.user.firstname && props.context.user.lastnames){
            this.setState({
                firstname: props.context.user.firstname || '',
                lastname: Object.keys(props.context.user).length > 0 && props.context.user.lastnames != '' ? `${props.context.user.lastnames.split(" ")[0][0]}.` : '' || '',
            })
        }
    }




    componentDidMount(){
        this.unlisten = this.props.history.listen((location, action) => {
            if(location.search==='?onboarding_complete' && this.props.context.isLoggedIn){
                this.setState({isOnboardingOpen: true})
            }
            else if(location.search==='?welcome' && this.props.context.isLoggedIn && !this.props.context.user.complete) {
                this.setState({isWelcomeOpen: true})
            }
            else if(location.search==='?login' && this.props.context.isLoggedIn) {
                this.setState({signinSidebar: true})
            }
            else if(location.search==='?signup' && !this.props.context.isLoggedIn) {
                this.setState({signupSidebar: true})
            }
        });
    }

    componentWillUnmount() {
      this.unlisten();
    }

    logout = () => {
        axios.get('/api/auth/logout', {
        }).then(resp => {
            window.location.reload();
        }).catch(err => {
            alert('Something went wrong, please try again');
        })
    }

    // Recruit a friend modal
    openModal = (e) => {
        e.preventDefault();
        this.setState({isOpen: true});
    }
    closeModal = (e) => {
        this.setState({isOpen: false});
    }

    // Login sidebar
    handleInputChange(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            [name]: value
        });
    }

    handler = (state) => {
        this.setState({[state]: false})
        if(state === 'signupSidebar' && this.props.history.location.search == '?signup'){
            this.props.history.push(this.props.history.location.pathname) // remove url
        }
    }

    handlerOpen = (state) => {
        this.setState({[state]: true})
    }

    openSidebar = (key) => {
        this.setState({[key]: true, mobmenu: false, change_class: ''})
    }


    render(){
        return(
            <Fragment>
                {/* Recruit a friend modal */}
                <Modal
                  isOpen={this.state.isOpen}
                  onRequestClose={this.closeModal}
                  className={`${recrstyle['Recruit-modal']}`}
                  style={{overlay: {zIndex: 6}}}
                >
                    <Recruit func={this.closeModal}/>
                </Modal>
                <Modal
                  isOpen={this.state.isWelcomeOpen}
                  onRequestClose={() => this.setState({isWelcomeOpen: false})}
                  className={`${style['Welcome-modal']}`}
                  style={{overlay: {zIndex: 6, backgroundColor: 'rgba(0, 0, 0, 0.7)'}}}
                >
                    {this.props.context.isLoggedIn && (
                        <Welcome
                            handler={this.handler}
                            name={this.props.context.user.firstname}
                            testAgent={this.props.context.user}
                        />
                    )}
                </Modal>
                <Modal
                  isOpen={this.state.isOnboardingOpen}
                  onRequestClose={() => this.setState({isOnboardingOpen: false})}
                  className={`${style['Welcome-modal']}`}
                  style={{overlay: {zIndex: 6, backgroundColor: 'rgba(0, 0, 0, 0.7)'}}}
                >
                    {this.props.context.isLoggedIn && (
                        <OnboardingComplete handler={this.handler}/>
                    )}
                </Modal>

                {/*Sidebars available when not logged in*/}
                {!this.props.context.isLoggedIn && (
                    <Fragment>
                        <SignInSidebar handler={this.handler} handlerOpen={this.handlerOpen} setUser={this.props.context.setUser} setStatus={this.props.context.setStatus} isOpen={this.state.signinSidebar}/>
                        <SignUpSidebar handler={this.handler} handlerOpen={this.handlerOpen} isOpen={this.state.signupSidebar}/>
                        <TermsBusinessSidebar handler={this.handler} isOpen={this.state.termsBusinessSidebar}/>
                        <TermsPrivacySidebar handler={this.handler} isOpen={this.state.termsPrivacySidebar}/>
                    </Fragment>
                )}

                <HelpSidebar handler={this.handler} isLoggedIn={this.props.context.isLoggedIn} isOpen={this.state.helpSidebar}/>

                {/* Logo */}
                <div className={style['Navbar']+' bignav'} style={this.props.style || {}}>
                        <NavLink activeClassName={''} to="/">
                            <div className={style['Navbar__logo']}>
                                <img className={`${style['Navbar__logo--img']}`} src={logo} alt="Logo"/>
                            </div>
                        </NavLink>
                        {(this.props.location.pathname == '/orders/new' || this.props.location.pathname == '/orders/new/') && (
                            <div className={style['Navbar__center']}>
                                {(this.props.context.user.verified && this.props.context.user.complete) &&(
                                    <ProgressBar content={[lang.orderflow_amount.top_menu.amount, lang.orderflow_amount.top_menu.recipient, lang.orderflow_amount.top_menu.review, lang.orderflow_amount.top_menu.deposit]}/>

                                )}
                            </div>
                        )}
{/*                        {(this.props.location.pathname.split("/")[1]+"/"+this.props.location.pathname.split("/")[2]) == 'orders/token' && (
                            <div className={style['Navbar__center']}>
                                {(this.props.context.user.verified && this.props.context.user.complete) &&(<ProgressBar content={["You", "Find agent", "Overview"]}/>)
                                }
                            </div>
                            )}
*/}
                    {/* Navbar right side */}
                    {constants.isMobile()
                        ?
                            <Fragment>
                                <div className={`${style['Navbar__rightside--mobmenu']}`}></div>
                                <Link to="#" onClick={() => this.setState({mobmenu: !this.state.mobmenu, change_class: this.state.mobmenu ? '' : style['change']})}>
                                    <div className={`${style['Navbar__rightside--mobmenu-burger']} ${this.state.change_class}`}>
                                        <div className={`${style['bar1']}`}/>
                                        <div className={`${style['bar2']}`}/>
                                        <div className={`${style['bar3']}`}/>
                                    </div>
                                </Link>
                                {this.state.mobmenu && (
                                    <div className={`${style['Navbar__rightside--mobmenu-content']}`}>
                                        <ul>
                                            <Link to="/" onClick={() => this.setState({mobmenu: false, change_class: ''})}><li>{lang.orderflow_customer_landingpage.top_menu.send_money}</li></Link>
                                            {this.props.context.isLoggedIn && (
                                                <Fragment>
                                                    {(Object.keys(this.props.context.user).length > 0 && this.props.context.user.dafnir_purposes.includes(0))
                                                        ? <Link to="/orders/my" onClick={() => this.setState({mobmenu: false, change_class: ''})}><li>{lang.my_orders.top_menu.my_orders}</li></Link>
                                                        : <Fragment></Fragment>
                                                    }
                                                    {(Object.keys(this.props.context.user).length > 0 && this.props.context.user.dafnir_purposes.includes(2)) // user chose to deliver money
                                                        ?
                                                            <Fragment>
                                                                <Link to="/orders/tasks" onClick={() => this.setState({mobmenu: false, change_class: ''})}><li>{lang.deliverflow_private_order_my_tasks.top_menu.my_tasks}</li></Link>
                                                                <Link to="/orders/public" onClick={() => this.setState({mobmenu: false, change_class: ''})}><li>{lang.deliverflow_private_order_my_tasks.top_menu.public_tasks}</li></Link>
                                                            </Fragment>
                                                        : <Link to="/agent" onClick={() => this.setState({mobmenu: false, change_class: ''})}><li>{lang.my_orders.top_menu.become_an_agent}</li></Link>
                                                    }
                                                </Fragment>
                                            )}
                                            {!this.props.context.isLoggedIn && (
                                                <Link to="/agent" onClick={() => this.setState({mobmenu: false, change_class: ''})}><li>{lang.my_orders.top_menu.become_an_agent}</li></Link>
                                            )}
                                            <Link to="#" onClick={() => this.setState({helpSidebar: true, mobmenu: false, change_class: ''})}><li>{lang.my_orders.top_menu.help}</li></Link>
                                            {!this.props.context.isLoggedIn && (
                                                <Fragment>
                                                    <Link to="#" onClick={() => this.openSidebar("signinSidebar")}><li>{lang.sign_in.red_button}</li></Link>
                                                    <Link to="#" onClick={() => this.openSidebar("signupSidebar")}><li>{lang.orderflow_customer_landingpage.top_menu.sign_up}</li></Link>
                                                </Fragment>
                                            )}
                                            {this.props.context.isLoggedIn && (
                                                <Fragment>
                                                    <Link to="/manage/account" onClick={() => this.setState({mobmenu: false, change_class: ''})}><li>{lang.deliverflow_private_order_my_tasks.icon_dropdown.account_settings}</li></Link>
                                                    <Link to="#" onClick={() => this.setState({isOpen: true, mobmenu: false, change_class: ''})}><li>{lang.deliverflow_private_order_my_tasks.icon_dropdown.recruit_a_friend}</li></Link>
                                                    <Link to="#" onClick={() => this.logout()}><li>Logout</li></Link>
                                                </Fragment>
                                            )}
                                        </ul>
                                    </div>
                                )}
                            </Fragment>
                        :
                            (
                                this.props.location.pathname == '/orders/new' ||
                                this.props.location.pathname == '/orders/new/' ||
                                (this.props.location.pathname.split("/")[1]+"/"+this.props.location.pathname.split("/")[2]) == 'orders/token' ||
                                this.props.location.pathname.split("/")[1] == 'onboarding' ||
                                this.props.location.pathname.split("/")[1] == 'signedup' ||
                                this.props.location.pathname.split("/")[1] == 'kyc' ||
                                (this.props.location.pathname.split("/")[1]+"/"+this.props.location.pathname.split("/")[2]) == 'orders/review' ||
                                (this.props.location.pathname.split("/")[1]+"/"+this.props.location.pathname.split("/")[2]) == 'support/forgotpassword' ||
                                (this.props.location.pathname.split("/")[1]+"/"+this.props.location.pathname.split("/")[2]) == 'orders/confirm'
                            ) ?
                                <div className={style['Navbar__rightside']}>
                                    {/*Get help*/}
                                    <input type="button" className={style["Navbar__rightside--gethelp"]} onClick={() => this.setState({helpSidebar: true})} value={lang.orderflow_amount.top_menu.get_help}/>
                                </div>
                            :
                                <div className={style['Navbar__rightside']}>
                                    <ul className={style['Navbar__rightside--list']}>
                                        <li className={style['Navbar__rightside--list-item']}><NavLink exact activeClassName={style['active']} to="/">{lang.orderflow_customer_landingpage.top_menu.send_money}</NavLink></li>
                                        { this.props.context.isLoggedIn && (Object.keys(this.props.context.user).length > 0 ? this.props.context.user.dafnir_purposes.includes(0) : false)
                                            ? <li className={style['Navbar__rightside--list-item']}><NavLink exact activeClassName={style['active']} to="/orders/my">{lang.my_orders.top_menu.my_orders}</NavLink></li>
                                            : <Fragment/>
                                        }
                                        { this.props.context.isLoggedIn
                                            ? (Object.keys(this.props.context.user).length > 0 && this.props.context.user.dafnir_purposes.includes(2)) // user chose to deliver money
                                                ? <li className={style['Navbar__rightside--list-item']}><NavLink exact activeClassName={style['active']} to="/orders/tasks">{lang.deliverflow_private_order_my_tasks.top_menu.my_tasks}</NavLink></li>
                                                : <li className={style['Navbar__rightside--list-item']}><NavLink exact activeClassName={style['active']} to="/agent">{lang.my_orders.top_menu.become_an_agent}</NavLink></li>
                                            : <li className={style['Navbar__rightside--list-item']}><NavLink exact activeClassName={style['active']} to="agent">{lang.my_orders.top_menu.become_an_agent}</NavLink></li>
                                        }
                                        { this.props.context.isLoggedIn && (Object.keys(this.props.context.user).length > 0 ? this.props.context.user.dafnir_purposes.includes(2) : false) // user chose to deliver money
                                            ? <li className={style['Navbar__rightside--list-item']}><NavLink exact activeClassName={style['active']} to="/orders/public">{lang.deliverflow_private_order_my_tasks.top_menu.public_tasks}</NavLink></li>
                                            : <Fragment/>
                                        }
                                        <li className={style['Navbar__rightside--list-item']}><Link to="#" onClick={() => this.setState({helpSidebar: true})}>{lang.my_orders.top_menu.help}</Link></li>
                                        {this.props.context.isLoggedIn
                                            ?
                                                <li className={style['Navbar__rightside--list-profile']}>
                                                    <Link to="#" onClick={e => this.setState({showMenu: !this.state.showMenu})}><img src={this.props.context.user.avatar ? '/api/user/image/avatar' : defaultprofile} alt="Profile picture"/></Link>
                                                    {this.state.showMenu && (
                                                        <div className={style['dropdown']}>
                                                            <div className={style['dropdown__mask']}/>
                                                            {this.props.context.user.complete
                                                                ?
                                                                    <div className={style['dropdown__containername']}>
                                                                        <div>
                                                                            <Fragment>
                                                                                <p className={style['dropdown__container--name']}>{`${this.state.firstname} ${this.state.lastname}`}</p>
                                                                                <p className={style['dropdown__container--text']}>{this.props.context.user.email}</p>
                                                                            </Fragment>
                                                                        </div>
                                                                    </div>
                                                                :
                                                                    <div className={style['dropdown__containercomplete']}>
                                                                        <div className={style['dropdown__containercomplete-headercontainer']}>
                                                                            <img src={defaultprofile} className={style['dropdown__containercomplete-headercontainer__icon']} alt="Checkmark"/>
                                                                            <p className={style['dropdown__containercomplete-headercontainer__text']}>{this.props.context.user.dafnir_purposes.includes(2) ? lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.continue_steps1 : lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.continue_steps2}</p>
                                                                        </div>
                                                                        <p className={style['dropdown__containercomplete-subheader']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_in_menu.validation}</p>
                                                                        <input type="button" onClick={() => this.props.history.push('/kyc')} className={style['dropdown__containercomplete-button']} value={lang.kys_kyr_kya.customer_due_diligence.kyc_in_menu.now}/>
                                                                    </div>
                                                                }
                                                            <div className={style['dropdown__container']}>
                                                                <div>
                                                                    <Link to="/manage/account" onClick={() => this.setState({showMenu: false})} className={style['dropdown__container--link']}>{lang.deliverflow_private_order_my_tasks.icon_dropdown.account_settings}</Link>
                                                                    <Link to="#" onClick={() => this.setState({isOpen: true, showMenu: false})} className={style['dropdown__container--recruit']}>{lang.deliverflow_private_order_my_tasks.icon_dropdown.recruit_a_friend}</Link>
                                                                </div>
                                                            </div>
                                                            <div className={style['dropdown__container']}>
                                                                <Link to="#" onClick={this.logout} className={style['dropdown__container--logout']}>Logout</Link>
                                                                {this.props.context.user.role === 'Admin' && (
                                                                    <Link to="/admin" onClick={() => this.setState({showMenu: false})} className={style['dropdown__container--link']}>Admin</Link>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </li>
                                            :
                                                <Fragment>
                                                    <li className={style['Navbar__rightside--list-item']}><Link to="#" onClick={e => this.setState({signinSidebar: true})}>{lang.sign_in.red_button}</Link></li>
                                                    <li className={style['Navbar__rightside--list-signup']}><Link to="#" onClick={e => this.setState({signupSidebar: true})}>{lang.orderflow_customer_landingpage.top_menu.sign_up}</Link></li>
                                                </Fragment>
                                        }
                                    </ul>
                                </div>
                    }
                </div>
            </Fragment>
    )}
}

export default withRouter(React.forwardRef((props, ref) => (
  <UserContext.Consumer>
    {(user, setStatus, setUser, isLoggedIn) => <Navbar {...props} context={user} ref={ref} />}
  </UserContext.Consumer>
)));