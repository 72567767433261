import { makeObservable, observable, action } from 'mobx';
import camera from 'img/illustrations/camera.png'
import passport from 'img/illustrations/passport.png'

class ExtendedKYCStore{
    @observable step = 1;
    @observable passport = null;
    @observable passportimg = passport;
    @observable passportname = '';
    @observable ssn = '';
    @observable nationality = '';
    @observable ether_address = '';
    @observable avatar = null;
    @observable profilename = '';
    @observable deliver_payment1 = false; // Meet in person
    @observable deliver_payment2 = false; // Bank transfer
    @observable deliver_payment3 = false; // Mobile payment
    @observable deliver_mobile_applications = ''; // String to be converted to array by splitting on comma
    @observable deliver_profiletext = '';
    @observable deliver_radius = 25; // default
    @observable deliver_street = '';
    @observable deliver_city = '';
    @observable deliver_country = '';
    @observable deliver_zip = '';
    @observable deliver_lat = null;
    @observable deliver_long = null;
    @observable deliver_starttime_monday = "";
    @observable deliver_endtime_monday = "";
    @observable deliver_starttime_tuesday = "";
    @observable deliver_endtime_tuesday = "";
    @observable deliver_starttime_wednesday = "";
    @observable deliver_endtime_wednesday = "";
    @observable deliver_starttime_thursday = "";
    @observable deliver_endtime_thursday = "";
    @observable deliver_starttime_friday = "";
    @observable deliver_endtime_friday = "";
    @observable deliver_starttime_saturday = "";
    @observable deliver_endtime_saturday = "";
    @observable deliver_starttime_sunday = "";
    @observable deliver_endtime_sunday = "";
    @observable deliver_amount_min = '';
    @observable deliver_amount_max = '';
    @observable deliver_currency = 'ARS'; // default
    @observable deliver_exchangefee = 4;

    constructor() {
        makeObservable(this)
    }
}

export default new ExtendedKYCStore();