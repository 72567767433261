import React, { Component } from 'react';
import axios from 'axios';
import {UserContext} from 'services/userContext';

class VerifyNewEmail extends Component {
    constructor(props){
        super(props);
        this.state = {
            response: ''
        };
    }
    componentDidMount(){
        this.confirm_email()
    }


    confirm_email = async () => {
        try {
            const response = await axios.post('/api/user/verify_new_email', {token: this.props.match.params.token})
            if(!this.props.context.isLoggedIn){
                this.props.context.setStatus(true)
                this.props.context.setUser(response.data);
            }
            this.props.history.push('/');
        } catch (err) {
            try{
                this.setState({ response: err.response.data.message})
            } catch (e){
                this.setState({ response: `Something went wrong, please try again or contact support`})
            }
        }
    }


    render(){
        return(
            <div>
                <br/>
                <center><h1>{this.state.response}</h1></center>
            </div>
        )
    }
}
export default React.forwardRef((props, ref) => (
  <UserContext.Consumer>
    {(user, setStatus, setUser, isLoggedIn) => <VerifyNewEmail {...props} context={user} setStatus={setStatus} setUser={setUser} isLoggedIn={isLoggedIn} ref={ref} />}
  </UserContext.Consumer>
));