import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import k from 'img/other/k.png' // temporary
import iconGuarantee from 'img/icons/Icon-guarantee.png'
import style from '../../../Onboarding.module.scss'
import { inject, observer } from 'mobx-react';
import axios from 'axios';
import { IdSidebar } from 'components/Sidebars/Sidebars';
import { constants } from 'consts/utils';
var lang = constants.getTranslations

@inject('extendedKYCStore') @observer class Step2 extends Component{

    constructor(props){
        super(props)
        this.state = {
            idSidebar: false
        }
    }

    handlePassport = (event) => {
        if(event.target.files.length > 0){
            this.props.extendedKYCStore.passport = event.target.files[0];
            this.props.extendedKYCStore.passportimg = URL.createObjectURL(event.target.files[0])
            this.props.extendedKYCStore.passportname = event.target.files[0].name
        }
    }

    handler = (state) => {
        this.setState({[state]: false})
    }

    next = () => {
        if(this.props.extendedKYCStore.passport){
            this.props.extendedKYCStore.step = 3
        } else{
            alert(lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.please)
        }
    }

    render(){
        return(
            <Fragment>
                <IdSidebar handler={this.handler} isOpen={this.state.idSidebar}/>
                <div className={style['Onboarding']}>
                    <div className={style['Onboarding__container']}>
                        <p className={style['Onboarding__container--header3']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.id}</p>
                        <Link to="#" onClick={() => this.setState({idSidebar: true})} className={style['Onboarding__container--sublink']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.id_link}</Link>
                        <div className={style['Onboarding__container--imagecontainer']}>
                            {this.props.extendedKYCStore.passportimg && (
                                <img src={this.props.extendedKYCStore.passportimg} alt="Passport image" className={style['Onboarding__container--imagecontainer-picture']}/>
                            )}
                            <div className={`${style['Onboarding__container--imagecontainer-filecontainer']}`}>
                                <input type="file" id="passport" className={`${style['Onboarding__container--imagecontainer-filecontainer__button']}`} onChange={this.handlePassport}/>
                                <label htmlFor="passport" className={`${style['Onboarding__container--imagecontainer-filecontainer__label']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.upload}</label>
                                {this.props.extendedKYCStore.passport && (
                                    <p className={`${style['Onboarding__container--imagecontainer-filecontainer__filetext']}`}><b>file:</b> {this.props.extendedKYCStore.passportname}</p>
                                )}
                            </div>
                        </div>
                        <div className={style['Onboarding__container--buttoncontainer']}>
                            <input type="button" onClick={() => this.next()} className={style['Onboarding__container--buttoncontainer-button']} value={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.continue}/>
                            <Link to="#" onClick={() => this.props.extendedKYCStore.step = 1} className={style['Onboarding__container--buttoncontainer-link']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.previous}</Link>
                        </div>
                        <div className={style['Onboarding__container--textcontainer']}>
                            <img src={iconGuarantee} className={style['Onboarding__container--textcontainer-icon']} alt="Secure" />
                            <p className={style['Onboarding__container--textcontainer-text']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.storage}</p>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default Step2