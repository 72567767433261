import React, { Component, Fragment } from 'react';
import axios from 'axios';
import AdminNav from '../AdminNav/AdminNav';
import style from '../../Admin.module.scss';

function GetDate(){
    var first_this_month = new Date()
    first_this_month.setHours(0,0,0,0)
    first_this_month.setDate(1)
    first_this_month = new Date(first_this_month.getTime())

    var last_this_month = new Date()
    last_this_month = new Date(last_this_month.getFullYear(), last_this_month.getMonth()+1, 0);
    last_this_month.setHours(23,59,59,999)
    last_this_month = new Date(last_this_month.getTime())

    var fd = first_this_month.getDate()
    var fm = first_this_month.getMonth() + 1

    if (fd < 10) {
      fd = '0' + fd;
    }
    if (fm < 10) {
      fm = '0' + fm;
    }
    first_this_month = fd + '/' + fm;

    var ld = last_this_month.getDate()
    var lm = last_this_month.getMonth() + 1

    if (ld < 10) {
      ld = '0' + ld;
    }
    if (lm < 10) {
      lm = '0' + lm;
    }
    last_this_month = ld + '/' + lm;

    return(
        <Fragment> {first_this_month} - {last_this_month}</Fragment>
    )
}

class Stats extends Component {
    constructor(){
        super()
        this.state = {
            statistics: {}
        }
    }
    componentWillMount(){
        axios.get('/api/admin/statistics')
        .then(response => {
            this.setState({ statistics: response.data})
        })
        .catch(err => {
            alert(err)
        })
    }
    render(){
        return(
            <Fragment>
                <AdminNav/>
                <div className={`${style['Admin']}`}>
                        {Object.keys(this.state.statistics).length > 0 && (
                            <div className={`${style['Admin__container']}`}>
                                <p className={`${style['Admin__container--header']}`}>Statistics</p>
                                <table className={`${style['Admin__container--stattable']}`} cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <td></td>
                                            <td>This month</td>
                                            <td>Last month</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Completed orders</td>
                                            <td>{this.state.statistics.trade_count.this_month}</td>
                                            <td>{this.state.statistics.trade_count.last_month}</td>
                                        </tr>
                                        <tr>
                                            <td>Active deliverers</td>
                                            <td>{this.state.statistics.deliverers.this_month}</td>
                                            <td>{this.state.statistics.deliverers.last_month}</td>
                                        </tr>
                                        <tr>
                                            <td>Active orderers</td>
                                            <td>{this.state.statistics.orderers.this_month}</td>
                                            <td>{this.state.statistics.orderers.last_month}</td>
                                        </tr>
                                        <tr>
                                            <td>Revenue</td>
                                            <td>${this.state.statistics.revenue.this_month.toFixed(2)}</td>
                                            <td>${this.state.statistics.revenue.last_month.toFixed(2)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}
                </div>
            </Fragment>
        )
    }
}
export default Stats;
