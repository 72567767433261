import React, { Component, Fragment } from 'react';
import style from './Review.module.scss';
import { constants } from 'consts/utils'
import axios from 'axios';
import { Link } from 'react-router-dom';
import defaultprofile from 'img/icons/defaultprofile.png';
import { ReactComponent as Checkmark } from 'img/icons/icon-checkmark-round.react.svg'

var lang = constants.getTranslations

export default class Recipient extends Component{

	constructor(){
		super()
        this.state = {
            name: '',
            firstname: '',
            review: '',
            response: '',
            rating: 0,
            hoverrating: 0,
            complete: false,
            avatar: {}
        }
        this.selectStar = this.selectStar.bind(this)
	}

    async componentDidMount(){
        if(!name && !this.state.response){
            await this.get_recipient_name()
        }
    }

    get_recipient_name = async () => {
        try {
            const response = await axios.get('/api/order/get_recipient_from_token', {
                params:
                    {
                        token: this.props.match.params.token
                    }
            })
            this.setState({name: response.data.name, firstname: response.data.firstname, avatar: response.data.avatar})
        } catch (err) {
            try{
                this.setState({ response: err.response.data.message})
            } catch (e){
                this.setState({ response: `Something went wrong, please try again or contact support`})
            }
        }
    }

    selectStar(e){
        const rating = e.target.dataset.key
        this.setState({rating: rating})
    }

    hoverStar(e){
        const rating = e.target.dataset.key
        this.setState({hoverrating: rating})
    }

    restoreRating(){
        this.setState({hoverrating: this.state.rating || 0})
    }

    MakeStars = () => {
        var stars = [];
        for(var i = 1; i <= 5; i++){
            if(this.state.hoverrating >= i){
                stars.push(<Link to="#" key={i} onMouseOut={(e) => this.restoreRating()} onMouseOver={(e) => this.hoverStar(e)} onClick={(e) => this.selectStar(e)}><span key={i} data-key={i} className={`${style['star']} ${style['on']}`}></span></Link>)
            } else{
                stars.push(<Link to="#" key={i} onMouseOut={(e) => this.restoreRating()} onMouseOver={(e) => this.hoverStar(e)} onClick={(e) => this.selectStar(e)}><span key={i} data-key={i} className={`${style['star']}`}></span></Link>)
            }
        }
        return stars
    }

    submitReview = () => {
        axios.post('/api/order/review/recipient', {
            rating: this.state.rating,
            review: this.state.review,
            token: this.props.match.params.token
        })
        .then(resp => {
            this.setState({complete: true})
        }).catch(err => {
            alert(err.response.data.message);
        })
    }

    ReviewCompleted = () => {
        return(
            <Fragment>
                <p className={`${style['Review__container--header']}`}>{lang.review.sent}</p>
                <div className={style['Review__container--imagecontainer']}>
                    <hr className={style['Review__container--imagecontainer-line']}/>
                </div>
                <div className={style['Review__container--completecontainer']}>
                    <div className={style['Review__container--completecontainer-iconcontainer']}>
                        <Checkmark width="91.8px" height="84.8px" viewBox="0 10 70 45" className={style['Review__container--completecontainer-iconcontainer__icon']}/>
                    </div>
                    <p className={style['Review__container--completecontainer-text']}>{lang.review.rating_appear}</p>
                    <p className={style['Review__container--completecontainer-text']}></p>
                    <input type="button" value="Go to frontpage" onClick={() => this.props.history.push("/")} className={`${style['Review__container--completecontainer-button']}`}/>
                </div>
            </Fragment>
        )
    }

    MakeReview = (props) => {
        return(
            <Fragment>
                <p className={`${style['Review__container--header']}`}>{lang.review.review} {props.name}</p>
                <div className={style['Review__container--imagecontainer']}>
                    <hr className={style['Review__container--imagecontainer-line']}/>
                    {Object.keys(props.avatar).length > 0
                        ? <img src={`/api/${props.avatar.prefix}/avatar/${props.avatar.avatar}`} alt="Avatar" className={style['Review__container--imagecontainer-image']}/>
                        : <img src={defaultprofile} alt="Avatar" className={style['Review__container--imagecontainer-image']}/>

                    }
                </div>
                <p className={`${style['Review__container--subheader']}`}>{lang.review.how} {props.firstname}?</p>
                <div className={`${style['Review__container--starcontainer']}`}>
                <this.MakeStars/>
                </div>
                <p className={`${style['Review__container--subheader']}`}>{lang.review.why}</p>
                <div className={`${style['Review__container--reviewcontainer']}`}>
                    <textarea className={`${style['Review__container--reviewcontainer-input']}`} placeholder={`${lang.review.tell} ${props.firstname}`} onChange={(e) => this.setState({review: e.target.value})} value={props.review}/>
                </div>
                <div className={`${style['Review__container--buttoncontainer']}`}>
                    <input type="button" value={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.submit_review} onClick={() => this.submitReview()} className={`${style['Review__container--buttoncontainer-button']}`}/>
                </div>
                <p className={`${style['Review__container--disclaimer']}`}>{lang.review.rating_appear}</p>
            </Fragment>
        )
    }

	render(){
		return(
            <div className={`${style['Review__container']}`}>
                {this.state.response
                    ? <p className={`${style['Review__container--text']}`}>{this.state.response}</p>
                    :
                        this.state.complete
                        ?
                            <this.ReviewCompleted/>
                        :
                            <this.MakeReview name={this.state.name} review={this.state.review} firstname={this.state.firstname} avatar={this.state.avatar}/>
                }
            </div>
		)

	}
}