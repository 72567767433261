import { makeObservable, observable, action } from 'mobx';


class BasicKYCStore{
    @observable step = 1;
	@observable email = '';
    @observable firstname = '';
    @observable lastnames = '';
    @observable street = '';
    @observable addressno = '';
    @observable city = '';
    @observable country = '';
    @observable zip = '';
    @observable country_code = 54 // default value
    @observable phone_number = '';
    @observable pep = null;// politically exposed person
    @observable purpose1 = false;
    @observable purpose2 = false;
    @observable purpose3 = false;

    @action
    setEmail = (email) => {
        this.email = email;
    }

    constructor() {
        makeObservable(this)
    }
}

export default new BasicKYCStore();