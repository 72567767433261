import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import Step1 from './localComponents/Step1/Step1';
import Step2 from './localComponents/Step2/Step2';
import Step2a from './localComponents/Step2a/Step2a';
import Step3 from './localComponents/Step3/Step3';
import Step4 from './localComponents/Step4/Step4';
import style from './Order.module.scss';

export default @inject('orderStore') @observer class Order extends Component{

	constructor(){
		super()
	}

	componentWillUnmount(){
		this.props.orderStore.reset();
	}

	showStep = () => {
		switch (this.props.orderStore.step) {
			case 1: return(<Step1 />)
			case 2: return(<Step2 />)
			case '2a': return(<Step2a />)
			case 3: return(<Step3 />)
			case 4: return(<Step4 />)
			default: return(<Step1 />)
		}
	}
	render(){
		return(
			<Fragment>
				{this.showStep()}
			</Fragment>
		)

	}
}