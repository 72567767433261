import React, { Component, Fragment } from 'react';
import style from '../../../Onboarding.module.scss'
import { inject, observer } from 'mobx-react';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import axios from 'axios';
import { constants } from 'consts/utils';
var lang = constants.getTranslations

export default @inject('basicKYCStore') @observer class Step2 extends Component{

    constructor(props){
        super(props)
    }

    geocodeAddress = async (e) => {
        var z = await geocodeByPlaceId(e.value.place_id)
        var address = ''
        const len = z[0].address_components.length
        var city = ''
        var country = ''
        if(Object.keys(z[0]).includes("plus_code") && z[0].plus_code.compound_code){
            var area = z[0].plus_code.compound_code.split(",")
            var city = area[0].split(" ").slice(1).join(" ")
            var country = area[1].substring(1)
        }
        var street = ''
        var no = ''
        var zip = ''
        for(var i = 0; i < len; i++){
            const ts = z[0].address_components[i].types
            const value = z[0].address_components[i].long_name
            if(ts.includes("street_number")){
                no = value
            }
            else if(ts.includes("route")){
                //address += value+", "
                street = value
            }
            else if(ts.includes("postal_code")){
                zip = value
            }
            else if(!city && ts.includes("locality")){
                city = value
            }
            else if(!country && ts.includes("country")){
                country = value
            }
        }
        this.props.basicKYCStore.street = street
        this.props.basicKYCStore.addressno = no
        this.props.basicKYCStore.city = city
        this.props.basicKYCStore.country = country
        this.props.basicKYCStore.zip = zip
    }

    setPEPyes = () => {
        this.props.basicKYCStore.pep = true;
    }

    setPEPno = () => {
        this.props.basicKYCStore.pep = false;
    }


    next = (e) => {
        e.preventDefault()

        if(!this.props.basicKYCStore.firstname){
            alert("Please write your firstname")
            return
        }
        if(!this.props.basicKYCStore.lastnames){
            alert("Please write your lastnames")
            return
        }
        if(!this.props.basicKYCStore.city){
            alert("Please declare which city you live in")
            return
        }
        if(!this.props.basicKYCStore.country){
            alert("Please declare which country you live in")
            return
        }
        if(!this.props.basicKYCStore.zip){
            alert("We could not detect a zip code for this location, if you believe this is a mistake, please contact Dafnir")
            return
        }


        this.props.basicKYCStore.step = 3
    }

    render(){
        return(
            <div className={style['Onboarding']}>
                <div className={style['Onboarding__container']}>
                    <p className={style['Onboarding__container--header']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.fill_in}</p>
                    <form>
                    <div className={style['Onboarding__container--inputcontainer']}>
                       <p className={style['Onboarding__container--inputcontainer-label']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.name}</p>
                       <div className={style['inputflex']}>
                           <input type="text" className={style['Onboarding__container--inputcontainer-input']} onChange={(e) => this.props.basicKYCStore.firstname = e.target.value} value={this.props.basicKYCStore.firstname} placeholder={lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.filler_first}/>
                           <input type="text" className={style['Onboarding__container--inputcontainer-input']} onChange={(e) => this.props.basicKYCStore.lastnames = e.target.value} value={this.props.basicKYCStore.lastnames} placeholder={lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.filler_last}/>
                       </div>
                       <p className={style['Onboarding__container--inputcontainer-description']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.support_name}</p>
                    </div>
                    <div className={style['Onboarding__container--searchcontainer']}>
                       <p className={style['Onboarding__container--searchcontainer-label']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.address}</p>
                        <GooglePlacesAutocomplete
                            debounce={500}
                            selectProps={
                                {
                                    placeholder: lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.filler_address,
                                    onChange: (e) => this.geocodeAddress(e),
                                }
                            }
                        />
                        <p className={style['Onboarding__container--inputcontainer-description']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.address}</p>
                    </div>
                    <div className={style['Onboarding__container--inputcontainer']}>
                        <p className={style['Onboarding__container--inputcontainer-label']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.phone}</p>
                        <div className={style['inlineflex']}>
                            <select value={this.props.basicKYCStore.country_code} className={style['Onboarding__container--inputcontainer-input']} onChange={(e) => this.props.basicKYCStore.country_code = e.target.value}>
                                {[...new Set(constants.countries.map(c => c.code))].sort(function(a,b){return a-b}).map((c,i) => <option key={i} value={c}>{`+${c}`}</option>)}
                            </select>
                            <input type="text" className={style['Onboarding__container--inputcontainer-input']} onChange={(e) => this.props.basicKYCStore.phone_number = e.target.value} value={this.props.basicKYCStore.phone_number} placeholder={lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.filler_phone}/>
                        </div>
                        <p className={style['Onboarding__container--inputcontainer-description']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.support_phone}</p>
                    </div>
                    <div className={style['Onboarding__container--inputcontainerflex']}>
                        <div>
                            <p className={style['Onboarding__container--inputcontainer-label2']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.pep}</p>
                            <p className={style['Onboarding__container--inputcontainer-description2']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.pep_support}</p>
                        </div>
                        <div className={style['Onboarding__container--inputcontainerflex-buttoncontainer']}>
                            <input type="button" value={lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.yes} className={`${style['Onboarding__container--inputcontainerflex-buttoncontainer__button']} ${this.props.basicKYCStore.pep && style['active']}`} onClick={() => this.setPEPyes()}/>
                            <input type="button" value={lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.no} className={`${style['Onboarding__container--inputcontainerflex-buttoncontainer__button']} ${this.props.basicKYCStore.pep === false && style['active']}`} onClick={() => this.setPEPno()}/>
                        </div>
                    </div>

                    <div className={style['Onboarding__container--buttoncontainer']}>
                        <input type="submit" onClick={(e) => this.next(e)} className={style['Onboarding__container--buttoncontainer-button']} value={lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.continue}/>
                    </div>
                    </form>
                </div>
            </div>
        )
    }
}