import React, { Component, Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-modal';
import style from './Admin.module.scss';
import AdminNav from './LocalComponents/AdminNav/AdminNav';

class Admin extends Component {
    render(){
        return(
            <Fragment>
                <AdminNav/>
                <div className={style['Admin']}>
                    <center><h1>ADMIN LANDINGPAGE</h1></center>
                </div>
            </Fragment>
        )
    }
}
export default Admin;
