import { withRouter, Link, NavLink } from 'react-router-dom';
import React, { Component } from 'react';
import style from './AdminNav.module.scss';



class AdminNav extends Component {
    constructor(props){
        super(props);
        this.NavItem = this.NavItem.bind(this)
    }
    NavItem = ({to, text, sub}) => {
        const path = this.props.location.pathname
        const c = path === to ? style['active'] : ''
        return <NavLink className={c} to={to}>{text}</NavLink>
    }

    render(){
        return(
            <div className={style['AdminNav']}>
                <NavLink to="/admin/">Admin panel</NavLink>
                <this.NavItem to="/admin/users/" text="Users"/>
                <this.NavItem to="/admin/orders/" text="Transactions"/>
                <this.NavItem to="/admin/stats/" text="Statistics"/>
                <this.NavItem to="/admin/emails/" text="Emails"/>>
            </div>
        )
    }
}
export default withRouter(AdminNav);