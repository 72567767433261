import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {UserContext} from 'services/userContext';
import { inject, observer } from 'mobx-react';
import { constants } from 'consts/utils';
import defaultprofile from 'img/icons/defaultprofile.png';
import iconMail from 'img/icons/icon-mail.png';
import MakeStars from 'components/MakeStars/MakeStars.js';
import iconPhone from 'img/icons/icon-phone.png';
import style from './FillOrder.module.scss';
import { ReactComponent as Zoom } from 'img/icons/icon-zoom.react.svg'
import { ReactComponent as Checkmark } from 'img/icons/icon-checkmark.react.svg'
import BN from 'bignumber.js';
import Checkbox from 'components/Forms/Checkbox/Checkbox.js';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import pin from 'img/icons/icon-mappin.png';
import camera from 'img/icons/Icon-camera.png'
import puzzledEmote from 'img/icons/icon-puzzled.png'
import makepublic from 'img/icons/icon-makepublic.png'
import transfer from 'img/icons/icon-transfer.png';
import recruitfriend from 'img/icons/ppl.png'
import { AgentSidebar, PublicOrderSidebar, PrivateOrderStep2, PrivateOrderStep3_InPerson, PrivateOrderStep3_BankTransfer, PrivateOrderStep3_MobilePayment } from 'components/Sidebars/Sidebars';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import recrstyle from 'components/Recruit/Recruit.module.scss';
import ReactFlagsSelect from 'react-flags-select'
import Recruit from 'components/Recruit/Recruit';
import Modal from 'react-modal';
import { debounce } from "debounce";

var lang = constants.getTranslations

class FillOrder extends Component {
    constructor(props){
        super(props);
        this.state = {
            response: '',
            address: '',
            autofilled: false,
            avatar_file: '',
            avatar: camera,
            nameavatar: '',
            city: '',
            country: '',
            long: '',
            lat: '',
            agentsidebar: false,
            publicsidebar: false,
            chosen_agent: {},
            isOpen: false,
            step: 1,
            deliverers: [],
            order: {},
            agent: {},
            firstname: '',
            lastnames: '',
            phone_cc: 1,
            phone_number: '',
            location: '',
            selected: [],
            makepublic: true,
            filter_inPerson: true,
            filter_bankTransfer: true,
            filter_mobilePayment: true,
            privateSidebar2: false,
            privateSidebar3_inPerson: false,
            privateSidebar3_bankTransfer: false,
            privateSidebar3_mobilePayment: false,

        };

        this.handler = this.handler.bind(this);
        this.back_step1 = this.back_step1.bind(this);
        this.back_step2 = this.back_step2.bind(this);
        this.next = this.next.bind(this);
        this.makeRating = this.makeRating.bind(this);
        this.get_order = this.get_order.bind(this);
        this.autofill_KYC = this.autofill_KYC.bind(this);
        this.setPhoneCC = this.setPhoneCC.bind(this);
        this.setName = this.setName.bind(this);
        this.setLastNames = this.setLastNames.bind(this);
        this.setPhoneNumber = this.setPhoneNumber.bind(this);
        this.privateOrder_Step2 = this.privateOrder_Step2.bind(this);
        this.select_func = this.select_func.bind(this);
    }

    async componentDidMount(){
        if(Object.keys(this.state.order).length === 0){
            await this.get_order()
        }
    }

    componentDidUpdate(prev){
        if(Object.keys(this.props.context.user).length > 0 && this.state.order){
            if(this.state.order.recipient === 'self'){
                if(!this.props.context.isLoggedIn){
                    alert("please login before filling out the order to yourself")
                    this.props.history.push('/?login')
                    return
                }
                if(this.props.context.user && !this.state.autofilled){this.autofill_KYC()} // if logged in, attempt to auto fill fields if they aren't set already
            }
        }
    }

    componentWillUnmount(){
        this.setState({order: {}})
    }

    handler(state){
        this.setState({[state]: false})
    }

    back_step1(){
        this.setState({
            agentsidebar: true,
            privateSidebar2: false,
        })
    }

    back_step2(state){
        this.setState({
            privateSidebar2: true,
            [state]: false
        })
    }

    next(state){
        this.setState({
            privateSidebar2: false,
            [state]: true
        })
    }

    makeRating = (rating) => {
        var rows = [];
        for (var i = 1; i < 6; i++) {
            if(rating >= i){
                rows.push(<span key={i} className="star on"/>);
            }
            else{
                var checkHalf = rating % (i-1)
                if(checkHalf >= 0.5 && checkHalf < 1){
                    rows.push(<span key={i} className="star half"/>);
                }
                else{
                    rows.push(<span key={i} className="star"/>);
                }
            }
        }
        return rows;
    }

    privateOrder_Step2 = () => {
        this.setState({
            agentsidebar: false,
            privateSidebar2: true,
        })
    }

    select_func = (id, method, message, additional) => {
        var deliverers = this.state.deliverers
        var index = deliverers.findIndex(d => d.id === id)
        if(index != -1){
            deliverers[index].selected = true
            deliverers[index].message = message
            deliverers[index].method = method
            if(method === "Mobile payment"){
                if(!additional || !additional.mobile_application || !additional.mobile_identifier){
                    alert("Pleae make sure you enter a valid mobile application and a valid name, email or phonenumber you use for the application")
                    return
                } else{
                    deliverers[index].mobile_application = additional.mobile_application
                    deliverers[index].mobile_identifier = additional.mobile_identifier
                }
            } else if(method === "Bank transfer"){
                if(!additional || !additional.bankcode || !additional.accountnum){
                    alert("Pleae make sure you enter a valid bank code and a valid accountnum")
                    return
                } else{
                    deliverers[index].bankcode = additional.bankcode
                    deliverers[index].accountnum = additional.accountnum
                }
            }
            this.setState({
                deliverers: deliverers,
                agentsidebar: false,
                privateSidebar2: false,
                privateSidebar3_inPerson: false,
                privateSidebar3_bankTransfer: false,
                privateSidebar3_mobilePayment: false,
                makepublic: false,
            })
        }

    }

    get_order = async () => {
        try {
            const response = await axios.get('/api/order/get_order_from_token', {
                params:
                    {
                        token: this.props.match.params.token
                    }
            })
            response.data.order.send_amount = new BN(response.data.order.send_amount["$numberDecimal"])
            response.data.order.receive_amount = new BN(response.data.order.receive_amount["$numberDecimal"])
            this.setState({order: response.data.order, agent: response.data.agent, creator: response.data.creator})
        } catch (err) {
            try{
                this.setState({ response: err.response.data.message})
            } catch (e){
                this.setState({ response: `Something went wrong, please try again or contact support`})
            }
        }
    }

    autofill_KYC(){
        if(this.props.context.isLoggedIn && !this.state.firstname && !this.state.lastnames && !this.state.country_code && !this.state.phonenumber){
            this.setState({
                autofilled: true,
                step: 2,
                firstname: this.props.context.user.firstname,
                lastnames: this.props.context.user.lastnames,
                phone_cc: this.props.context.user.country_code,
                phone_number: this.props.context.user.phonenumber,
                avatar: this.props.context.user.avatar ? '/api/user/image/avatar' : camera
            })
        }
    }

    geocodeLocation = async (e) => {
        var z = await geocodeByPlaceId(e.value.place_id)
        const len = z[0].address_components.length
        var street = ''
        var city = ''
        var country = ''
        if(Object.keys(z[0]).includes("plus_code") && z[0].plus_code.compound_code){
            var area = z[0].plus_code.compound_code.split(",")
            var city = area[0].split(" ").slice(1).join(" ")
            var country = area[1].substring(1)
        }        var zip = ''
        const lat = await z[0].geometry.location.lat()
        const long = await z[0].geometry.location.lng()
        for(var i = 0; i < len; i++){
            const ts = z[0].address_components[i].types
            const value = z[0].address_components[i].long_name
            if(ts.includes("route")){
                street = value
            }
            else if(ts.includes("postal_code")){
                zip = value
            }
            else if(!city && ts.includes("locality")){
                city = value
            }
            else if(!country && ts.includes("country")){
                country = value
            }
        }
        this.setState({
            street: street,
            city: city,
            zip: zip,
            country: country,
            long: long,
            lat: lat
        })

        this.fetchDeliverers()

    }

    fetchDeliverers = async () => {
        const response_dels = await axios.get('/api/order/deliverers',{
            params:
                {
                    id: this.state.order._id,
                    country: this.state.country,
                    lat: this.state.lat,
                    long: this.state.long,
                    cash: this.state.filter_inPerson,
                    bank: this.state.filter_bankTransfer,
                    other: this.state.filter_mobilePayment,
                    summary: false
                }
        })
        this.setState({
            deliverers: response_dels.data,
        })
    }


    setPhoneCC = (e) => {
        this.setState({phone_cc: e.target.value})
    }
    setName = (e) => {
        this.setState({firstname: e.target.value})
    }
    setLastNames = (e) => {
        this.setState({lastnames: e.target.value})
    }
    setPhoneNumber = (e) => {
        this.setState({phone_number: e.target.value})
    }

    handleAvatar = (event) => {
        if(event.target.files.length == 1){
            this.setState({
                avatar_file: event.target.files[0],
                avatar: URL.createObjectURL(event.target.files[0]),
                nameavatar: event.target.files[0].name
            })
        }
    }

    updateFilter = async (filter) => {
        await this.setState({
            [filter]: !this.state[filter],
        })

        this.fetchDeliverers()
    }

    viewAgent = (agent) => {
        this.setState({
            agentsidebar: true,
            chosen_agent: agent
        })
    }

    submit = () => {

        var selected = this.state.deliverers.filter(d => d.selected)
        var newselected = {}
        var keys = ['message', 'method', 'mobile_application', 'mobile_identifier', 'bankcode', 'accountnum']
        for(const s of selected){
            newselected[s.id] = {}
            for (const k of keys){
                const v = s[k]
                if(v){
                    newselected[s.id][k] = v
                }
            }
        }
        let formData = new FormData();
        formData.append("public", false)
        // formData.append("street", this.state.street)
        formData.append("city", this.state.city)
        formData.append("zip", this.state.zip)
        formData.append("country", this.state.country)
        formData.append("firstname", this.state.firstname)
        formData.append("lastnames", this.state.lastnames)
        formData.append("phone_cc", this.state.phone_cc)
        formData.append("phone_number", this.state.phone_number)
        formData.append("avatar", this.state.avatar_file)
        formData.append("selected", JSON.stringify(newselected))
        formData.append("token", this.props.match.params.token)
        formData.append("lat", this.state.lat)
        formData.append("long", this.state.long)
        axios.post('/api/order/finish_order', formData)
        .then(resp => {
            if(this.state.order.recipient === 'self'){
                this.props.history.push('/orders/my')
            }
            else{
                window.location.reload(false) // temp dirty solution
/*                var order = this.state.order
                order.status = 'Privately available'
                this.setState({step: 3, order: order, creator: resp.data.creator})*/
            }
        }).catch(err => {
            alert(err.response.data.message);
        })
    }

    Set_KYC = () => {
        return(
            <div className={style['FillOrder__KYC']}>
                <form>
                    <h2 className={style['FillOrder__KYC--header']}>{lang.orderflow_recipient_please_fill_in_name_no_account.white_box.header}</h2>
                    <div className={style['FillOrder__KYC--form']}>
                        <div className={style['FillOrder__KYC--form-row']}>
                            <p className={style['FillOrder__KYC--form-row__text']}>{lang.orderflow_recipient_please_fill_in_name_no_account.white_box.name_fields.sub_header}</p>
                            <div className={style['FillOrder__KYC--form-row__fields']}>
                                <input type="text" value={this.state.firstname} className={style['FillOrder__KYC--form-row__fields--input']} onChange={this.setName} placeholder={lang.settings.profile.sub_header_first_name} autoFocus />
                                <input type="text" value={this.state.lastnames} className={style['FillOrder__KYC--form-row__fields--input']} onChange={this.setLastNames} placeholder={lang.settings.profile.sub_header_last_name} />
                            </div>
                            <p className={style['FillOrder__KYC--form-row__comment']}>{lang.orderflow_recipient_please_fill_in_name_no_account.white_box.name_fields.grey_support_text}</p>
                        </div>
                        <div className={style['FillOrder__KYC--form-row']}>
                            <p className={style['FillOrder__KYC--form-row__text']}>{lang.orderflow_recipient_please_fill_in_name_no_account.white_box.phone_fields.sub_header}</p>
                            <div className={style['FillOrder__KYC--form-row__fields']}>
                                <select value={this.state.phone_cc} className={style['FillOrder__KYC--form-row__fields--phone_cc']} onChange={this.setPhoneCC}>
                                    {[...new Set(constants.countries.map(c => c.code))].sort(function(a,b){return a-b}).map((c,i) => <option key={i} value={c}>{`+${c}`}</option>)}
                                </select>
                                <input type="text" value={this.state.phone_number} className={style['FillOrder__KYC--form-row__fields--phone']} onChange={this.setPhoneNumber} placeholder={lang.settings.profile.sub_header_phone} />
                            </div>
                        </div>
                        <div className={style['FillOrder__KYC--form-row']}>
                            <p className={style['FillOrder__KYC--form-row__text']}>{lang.orderflow_recipient_please_fill_in_name_no_account.white_box.picture.sub_header}</p>
                            <p className={style['FillOrder__KYC--form-row__subtext']}>{lang.orderflow_recipient_please_fill_in_name_no_account.white_box.picture.text_with_reason}</p>
                            <div className={style['FillOrder__KYC--form-row__fields']}>
                                <div className={style['FillOrder__KYC--form-row__fields--iconcontainer']}>
                                    <img src={this.state.avatar} alt="Avatar" className={style['FillOrder__KYC--form-row__fields--iconcontainer-icon']} />
                                </div>
                                <input type="file" name="avatar" id="avatar" onChange={(e) => this.handleAvatar(e)} className={style['FillOrder__KYC--form-row__fields--button']}/>
                                <label htmlFor="avatar">{lang.orderflow_recipient_please_fill_in_name_no_account.white_box.picture.white_button}</label>
                            </div>
                            {this.state.nameavatar && <b className={style['FillOrder__KYC--form-row__imagetext']}>Chosen picture:<p> {this.state.nameavatar}</p></b>}
                        </div>
                    </div>

                        <div className={style['seperator']}/>

                <div className={style['FillOrder__buttoncontainer']}>
                    <input type="submit" className={style['FillOrder__buttoncontainer--button']} onClick={() => this.setState({step: 2})} value={lang.orderflow_amount.white_box.continue_button}/>
                </div>
                </form>
            </div>
        )
    }


    previous_step = () => {
        this.setState({step: 1, deliverers: [], city: '', country: ''})
    }

    Set_Order = () => {
        return(
            <Fragment>
                <div className={style['FillOrder__findagent']}>
                    <p className={style['FillOrder__findagent--header']}>{lang.orderflow_myself_find_agents_near_you_agent_overview.white_box.header}</p>
                    <div className={style['FillOrder__findagent--inputcontainer']}>
                        <Zoom width="17.49px" height="17.53px" viewBox="0 0 17.5 17.5" className={style['FillOrder__findagent--inputcontainer-icon']}/>
                        <GooglePlacesAutocomplete
                            debounce={500}
                            selectProps={
                                {
                                    placeholder: lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.filler_address,
                                    onChange: (e) => this.geocodeLocation(e),
                                }
                            }
                        />


                        {/*<input type="text" placeholder="Search for location" value={this.state.address} onChange={(e) => this.setState({address: e.target.value})} className={style['FillOrder__findagent--inputcontainer-input']}/>*/}
                    </div>
                    <div className={style['FillOrder__findagent--skincontainer']}>
                    {/* User only entered a country */}
                        {this.state.city === '' && this.state.country && (
                            <p className={style['FillOrder__findagent--skincontainer-agentstatus']}>Please enter a city, the narrower the search the better!</p>
                        )}
                    {/* Agents found */}
                    {this.state.city != '' && (
                        <Fragment>
                            <p className={style['FillOrder__findagent--skincontainer-agentstatus']}>{this.state.deliverers.length} {this.state.deliverers.length > 1 ? lang.orderflow_myself_agent_details_before_accept.view_agent_details_sidebar.agents : lang.orderflow_myself_agent_details_before_accept.view_agent_details_sidebar.agent} {lang.orderflow_myself_agent_details_before_accept.view_agent_details_sidebar.near} '{this.state.street && this.state.street+", "}{this.state.city && this.state.city}'</p>

                            {/* Filters */}
                            <div className={style['FillOrder__findagent--skincontainer-filtercontainer']}>
                                <div className={style['FillOrder__findagent--skincontainer-filtercontainer__container']}>
                                    <Checkbox
                                        extendinputclass={style['FillOrder__findagent--skincontainer-filtercontainer__container--checkboxinput']}
                                        extendspanclass={style['FillOrder__findagent--skincontainer-filtercontainer__container--checkboxspan']}
                                        defaultChecked={this.state.filter_inPerson}
                                        onClick={() => this.updateFilter('filter_inPerson')}
                                    />
                                    <p className={style['FillOrder__findagent--skincontainer-filtercontainer__container--text']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.receive_cash_header}</p>
                                </div>
                                <div className={style['FillOrder__findagent--skincontainer-filtercontainer__container']}>
                                    <Checkbox
                                        extendinputclass={style['FillOrder__findagent--skincontainer-filtercontainer__container--checkboxinput']}
                                        extendspanclass={style['FillOrder__findagent--skincontainer-filtercontainer__container--checkboxspan']}
                                        defaultChecked={this.state.filter_bankTransfer}
                                        onClick={() => this.updateFilter('filter_bankTransfer')}
                                    />
                                    <p className={style['FillOrder__findagent--skincontainer-filtercontainer__container--text']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.receive_transfer_header}</p>
                                </div>
                                <div className={style['FillOrder__findagent--skincontainer-filtercontainer__container']}>
                                    <Checkbox
                                        extendinputclass={style['FillOrder__findagent--skincontainer-filtercontainer__container--checkboxinput']}
                                        extendspanclass={style['FillOrder__findagent--skincontainer-filtercontainer__container--checkboxspan']}
                                        defaultChecked={this.state.filter_mobilePayment}
                                        onClick={() => this.updateFilter('filter_mobilePayment')}
                                    />
                                    <p className={style['FillOrder__findagent--skincontainer-filtercontainer__container--text']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.receive_other_header}</p>
                                </div>

                            </div>
                            {this.state.city != '' && this.state.deliverers.length > 0 && (
                                <Fragment>
                                    {this.state.deliverers.map((user, index) => {
                                        return(
                                            /* Agent container*/
                                            <div key={index} className={style['FillOrder__findagent--skincontainer-agentcontainer']}>
                                                {/* Agent container - top*/}
                                                <div className={style['FillOrder__findagent--skincontainer-agentcontainer__top']}>
                                                    <div className={style['FillOrder__findagent--skincontainer-agentcontainer__top--info']}>
                                                        <img className={style['FillOrder__findagent--skincontainer-agentcontainer__top--info-avatar']} src={user.avatar ? `/api/user/avatar/${user.avatar}` : defaultprofile} alt="Avatar"/>
                                                        <div className={style['FillOrder__findagent--skincontainer-agentcontainer__top--info-details']}>
                                                            <p className={style['FillOrder__findagent--skincontainer-agentcontainer__top--info-details__header']}>{user.name}</p>
                                                            <div className={style['FillOrder__findagent--skincontainer-agentcontainer__top--info-details__rating']}>
                                                                <MakeStars ratings={user.ratings ? user.ratings.map(r => r.rating) : []}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={style['FillOrder__findagent--skincontainer-agentcontainer__top--feecontainer']}>
                                                        <div className={style['FillOrder__findagent--skincontainer-agentcontainer__top--feecontainer-innercontainer']}>
                                                            <p className={style['FillOrder__findagent--skincontainer-agentcontainer__top--feecontainer-innercontainer__header']}>{lang.orderflow_recipient_overview_one_of_two_no_account.white_box.delivery_fee_grey_text}</p>
                                                            <p className={style['FillOrder__findagent--skincontainer-agentcontainer__top--feecontainer-innercontainer__body']}>{user.fee}%</p>
                                                        </div>
                                                    </div>
                                                    <input type="button" value={lang.orderflow_myself_find_agents_near_you_agent_overview.white_box.red_button_view_details} className={style['FillOrder__findagent--skincontainer-agentcontainer__top--button']} onClick={() => this.viewAgent(this.state.deliverers[index])}/>
                                                </div>
                                                {/* Agent container - bottom*/}
                                                <div className={style['FillOrder__findagent--skincontainer-agentcontainer__bottom']}>
                                                    <div className={style['FillOrder__findagent--skincontainer-agentcontainer__bottom--left']}>
                                                        {/* Near */ }
                                                        <div className={style['FillOrder__findagent--skincontainer-agentcontainer__bottom--left-flexcontainer']}>
                                                            <img src={pin} className={style['FillOrder__findagent--skincontainer-agentcontainer__bottom--left-flexcontainer__icon']}/>
                                                            <p className={style['FillOrder__findagent--skincontainer-agentcontainer__bottom--left-flexcontainer__text']}>{lang.orderflow_recipient_overview_one_of_two_no_account.white_box.near_city} {user.city}, {user.country}</p>
                                                        </div>
                                                        {/* Deliver methods */ }
                                                        <div className={style['FillOrder__findagent--skincontainer-agentcontainer__bottom--left-flexcontainer']}>
                                                            <img src={transfer} className={style['FillOrder__findagent--skincontainer-agentcontainer__bottom--left-flexcontainer__icon']}/>
                                                            <p className={style['FillOrder__findagent--skincontainer-agentcontainer__bottom--left-flexcontainer__text']}>{constants.makeMethods(user.deliver_methods, user.deliver_mobile_applications)}</p>
                                                        </div>
                                                    </div>
                                                    <div className={style['FillOrder__findagent--skincontainer-agentcontainer__bottom--right']}>
                                                        {user.selected && (
                                                            <Fragment>
                                                                <p className={style['FillOrder__findagent--skincontainer-agentcontainer__bottom--right-text']}>{lang.orderflow_recipient_overview_one_of_two_no_account.white_box.status_agent_request}</p>
                                                                <FontAwesomeIcon icon={faArrowRight} className={style['FillOrder__findagent--skincontainer-agentcontainer__bottom--right-arrow']} />
                                                            </Fragment>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                    {/* No agents */}
                        {this.state.deliverers.length === 0 && this.state.city != '' && (
                            <Fragment>
                                <img src={puzzledEmote} alt="Puzzled emote" className={style['FillOrder__findagent--skincontainer-emote']}/>
                                <p className={style['FillOrder__findagent--skincontainer-errmsg']}>{lang.orderflow_myself_recruit_a_friend_or_public_order.white_box.no_result_text_before_cityname} '{this.state.street && this.state.street+", "}{this.state.city && this.state.city}'</p>
                                <Link to="#" onClick={() => this.setState({publicsidebar: true})} style={{textDecoration: 'none'}}>
                                    <div className={style['FillOrder__findagent--skincontainer-box']}>
                                        <div className={style['FillOrder__findagent--skincontainer-box__left']}>
                                            <img src={makepublic} alt="Make public" className={style['FillOrder__findagent--skincontainer-box__left--image']}/>
                                        </div>
                                        <div className={style['FillOrder__findagent--skincontainer-box__right']}>
                                            <div id="contentbox1" className={style['FillOrder__findagent--skincontainer-box__right--content']}>
                                                <p className={style['FillOrder__findagent--skincontainer-box__right--content-header']}>{lang.orderflow_myself_recruit_a_friend_or_public_order.white_box.top_button.no_agents_red_text}</p>
                                                <p className={style['FillOrder__findagent--skincontainer-box__right--content-subheader']}>{lang.orderflow_myself_recruit_a_friend_or_public_order.white_box.top_button.headline_under_red_text}</p>
                                                <p className={style['FillOrder__findagent--skincontainer-box__right--content-body']}>{lang.orderflow_myself_recruit_a_friend_or_public_order.white_box.top_button.text_under_headline}</p>
                                            </div>
                                            <div className={style['FillOrder__findagent--skincontainer-box__right--arrowcontainer']}>
                                                <FontAwesomeIcon icon={faArrowRight} className={style['FillOrder__findagent--skincontainer-box__right--arrowcontainer-arrow']} />
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                <Link to="#" onClick={() => this.setState({isOpen: true})} style={{textDecoration: 'none'}}>
                                    <div className={style['FillOrder__findagent--skincontainer-box']}>
                                        <div className={style['FillOrder__findagent--skincontainer-box__left']}>
                                            <img src={recruitfriend} alt="Make public" className={style['FillOrder__findagent--skincontainer-box__left--image']}/>
                                        </div>
                                        <div className={style['FillOrder__findagent--skincontainer-box__right']}>
                                            <div id="contentbox1" className={style['FillOrder__findagent--skincontainer-box__right--content']}>
                                                <p className={style['FillOrder__findagent--skincontainer-box__right--content-header']}>{lang.orderflow_myself_recruit_a_friend_or_public_order.white_box.buttom_button.recruit_a_friend_red_text}</p>
                                                <p className={style['FillOrder__findagent--skincontainer-box__right--content-subheader']}>{lang.orderflow_myself_recruit_a_friend_or_public_order.white_box.buttom_button.headline_under_red_text}</p>
                                                <p className={style['FillOrder__findagent--skincontainer-box__right--content-body']}>{lang.orderflow_myself_recruit_a_friend_or_public_order.white_box.buttom_button.text_under_headline}</p>
                                            </div>
                                            <div className={style['FillOrder__findagent--skincontainer-box__right--arrowcontainer']}>
                                                <FontAwesomeIcon icon={faArrowRight} className={style['FillOrder__findagent--skincontainer-box__right--arrowcontainer-arrow']} />
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Fragment>
                        )}
                    {/* User hasn't requested a user yet */}
                        {(this.state.makepublic && (this.state.deliverers.length > 0) || this.state.city === '') && (
                            <Link to="#" onClick={() => this.setState({publicsidebar: true})} className={style['FillOrder__findagent--skincontainer-link']}>{lang.orderflow_myself_find_agents_near_you_search_side.white_box.public_order}</Link>
                        )}
                    </div>
                </div>
                <div className={style['FillOrder__buttoncontainer']} style={{display: 'inline-grid'}}>
                    {!this.state.makepublic
                        ? <input type="submit" className={`${style['FillOrder__buttoncontainer--button']}`}  onClick={() => this.submit()} value={lang.orderflow_amount.white_box.continue_button}/>
                        : <input type="submit" className={`${style['FillOrder__buttoncontainer--button']} ${style['disabled']}`} disabled value={lang.orderflow_amount.white_box.continue_button}/>
                    }
                    <Link to="#" onClick={(e) => this.previous_step()} style={{marginTop: '10px'}} className={style['FillOrder__buttoncontainer--link']}>{lang.orderflow_myself_recruit_a_friend_or_public_order.white_box.below_buttons.previous_step_link}</Link>
                </div>
            </Fragment>
        )
    }

/*    makeRequestItems(){
        if(this.state.order.reveals){
            return(
                this.state.order.reveals.map((item, i) =>
                    <a href='#' key={i} onClick={this.userModal.bind(this, item.username)}>
                        <li className={`${style['requests__item']}`}>
                            <p className={`${style[`requests__item--username`]}`}>{`${item.username}`}</p>
                            <p className={`${style[`requests__item--fee`]}`}>{`${item.fee}% fee`}</p>
                        </li>
                    </a>
                )
            )
        }
    }*/

    closeModal = () => {
        this.setState({isOpen: false});
    }

    public_continue = (creator) => {
        var order = this.state.order
        var cr = this.state.creator
        order.status = 'Publicly available'
        window.location.reload(false) // temp dirty solution
        //this.setState({order: order, creator: creator, step: 3, publicsidebar: false})
    }

    Make_reveals = () => {
        var items = []
        for (var i = 0; i < this.state.order.reveals.length; i++) {
            const user = this.state.order.reveals[i] // {avatar, {names: first, last}, ratings, fee, }
            items.push(
                <div key={i} className={style['FillOrder__overview--skincontainer-revealscontainer__item']}>
                    <div className={style['FillOrder__overview--skincontainer-revealscontainer__item--top']}>
                        <div className={style['FillOrder__overview--skincontainer-revealscontainer__item--top-info']}>
                            <img className={style['FillOrder__overview--skincontainer-revealscontainer__item--top-info__avatar']} src={user.avatar ? `/api/user/avatar/${user.avatar}` : defaultprofile} alt="Avatar"/>
                            <div className={style['FillOrder__overview--skincontainer-revealscontainer__item--top-info__details']}>
                                <p className={style['FillOrder__overview--skincontainer-revealscontainer__item--top-info__details--header']}>{user.firstname.length > 15 ? user.firstname.substring(0,12)+"..." : user.firstname} {user.lastname}.</p>
                                <div className={style['FillOrder__overview--skincontainer-revealscontainer__item--top-info__details--rating']}>
                                    <MakeStars ratings={user.rating}/>
                                </div>
                            </div>
                        </div>
                        <div className={style['FillOrder__overview--skincontainer-revealscontainer__item--top-feecontainer']}>
                            <div className={style['FillOrder__overview--skincontainer-revealscontainer__item--top-feecontainer__innercontainer']}>
                                <p className={style['FillOrder__overview--skincontainer-revealscontainer__item--top-feecontainer__innercontainer--header']}>{lang.myorder_private_order_view_details_accepted_by_agent.fat_grey_fee}</p>
                                <p className={style['FillOrder__overview--skincontainer-revealscontainer__item--top-feecontainer__innercontainer--body']}>{user.fee}%</p>
                            </div>
                        </div>
                        <input type="button" value={lang.orderflow_myself_find_agents_near_you_agent_overview.white_box.red_button_view_details} className={style['FillOrder__overview--skincontainer-revealscontainer__item--top-button']} onClick={() => this.viewAgent(user)}/>
                    </div>
                    <div className={style['FillOrder__overview--skincontainer-revealscontainer__item--bottom']}>
                        <div>
                        {user.method && (
                            <Fragment>
                                {user.method === "In person" && (
                                    <div className={style['FillOrder__overview--skincontainer-revealscontainer__item--bottom-left']}>
                                        <img src={pin} className={style['FillOrder__overview--skincontainer-revealscontainer__item--bottom-left__icon']}/>
                                        <p className={style['FillOrder__overview--skincontainer-revealscontainer__item--bottom-left__text']}>Near {user.city}, {user.country}</p>
                                    </div>
                                )}
                                <div className={style['FillOrder__overview--skincontainer-revealscontainer__item--bottom-left']}>
                                    <img src={transfer} className={style['FillOrder__overview--skincontainer-revealscontainer__item--bottom-left__icon']}/>
                                    <p className={style['FillOrder__overview--skincontainer-revealscontainer__item--bottom-left__text']}>{constants.makeMethods(user.method, this.state.order.payment_info)}</p>
                                </div>
                            </Fragment>
                        )}
                        </div>
                        <div className={style['FillOrder__overview--skincontainer-revealscontainer__item--bottom-right']}>
                            {user.selected && (
                                <Fragment>
                                    <p className={style['FillOrder__overview--skincontainer-revealscontainer__item--bottom-right-text']}>Has requested this order</p>
                                    <FontAwesomeIcon icon={faArrowRight} className={style['FillOrder__findagent--skincontainer-agentcontainer__bottom--right-arrow']} />
                                </Fragment>
                            )}
                        </div>
                    </div>
                </div>
            );
        }
        return(
            <Fragment>{items}</Fragment>
        )
    }

    Overview = () => {
        if(this.state.order.recipient === 'self'){
            this.props.history.push('/orders/my')
        }
        return(
            <div className={style['FillOrder__overview']}>
                <div className={style['FillOrder__overview--transferdetails']}>
                    <p className={style['FillOrder__overview--transferdetails-header']}>{lang.orderflow_recipient_overview_one_of_two_no_account.white_box.header}</p>
                    <div className={style['FillOrder__overview--transferdetails-spacer']}/>
                    <p className={style['FillOrder__overview--transferdetails-subheader']}>{lang.orderflow_recipient_overview_one_of_two_no_account.white_box.sub_header}</p>
                    <div className={style['FillOrder__overview--transferdetails-flexcontainer']}>
                        <div className={style['FillOrder__overview--transferdetails-flexcontainer__left']}>
                            <p className={style['FillOrder__overview--transferdetails-flexcontainer__left--text']}>
                                {this.state.order.status === 'Publicly completed' || this.state.order.status === 'Privately completed'
                                    ? lang.orderflow_recipient_overview_one_of_two_no_account.white_box.you_have_received
                                    : this.state.order.status === 'Cancelled'
                                        ? lang.orderflow_recipient_overview_one_of_two_no_account.white_box.you_would_have_received
                                        : lang.orderflow_recipient_overview_one_of_two_no_account.white_box.you_will_receive
                                }
                            </p>
                        </div>
                        <div className={style['FillOrder__overview--transferdetails-flexcontainer__right']}>
                        {this.state.order.receive_currency && (
                            <ReactFlagsSelect
                                className={`inline-flag ${style['FillOrder__overview--transferdetails-flexcontainer__right--flag']}`}
                                selected={constants.shorthand_curr_to_flag[this.state.order.receive_currency]}
                                placeholder={''}
                                showSelectedLabel={false}
                                disabled={true}
                                selectedSize={14}
                                optionsSize={20}
                            />
                        )}
                            <p className={style['FillOrder__overview--transferdetails-flexcontainer__right--largetext']}>{this.state.order.receive_amount ? this.state.order.receive_amount.toFixed(2) : ''}</p>
                            <p className={style['FillOrder__overview--transferdetails-flexcontainer__right--currency']}>{this.state.order.receive_currency} </p>
                        </div>
                    </div>
                 </div>
             {/* Order has an agent attached to it */}
                     {this.state.agent && Object.keys(this.state.agent).length > 0 && (
                         <Fragment>
                             {this.state.order.payment_method === "In person" && (
                                 <div className={style['FillOrder__overview--passwordcontainer']}>
                                     <p className={style['FillOrder__overview--passwordcontainer-password']}>{lang.deliverflow_private_order_insert_password.get_deposit.overview_password}</p>
                                     <div className={style['FillOrder__overview--passwordcontainer-container']}>
                                        <div className={style['FillOrder__overview--passwordcontainer-container__password']}>
                                            <p className={style['FillOrder__overview--passwordcontainer-container__password--text']}>{this.state.order.password}</p>
                                        </div>
    {/*                                    <div className={style['FillOrder__overview--passwordcontainer-container__qr']}>
                                            <img src={this.state.agent.avatar ? `/api/user/avatar/${this.state.agent.avatar}` : defaultprofile} alt="QR code" className={style['FillOrder__overview--passwordcontainer-container__qr--img']}/>
                                        </div>*/}
                                     </div>
                                     <div className={style['FillOrder__overview--passwordcontainer-warningcontainer']}>
                                         <b className={style['FillOrder__overview--passwordcontainer-warningcontainer__header']}>{lang.myorder_private_order_view_send_to_self_password.white_box.attention_box_header}</b>
                                         <p className={style['FillOrder__overview--passwordcontainer-warningcontainer__text']}>{lang.myorder_private_order_view_send_to_self_password.white_box.attention_box_text}</p>
                                     </div>
                                 </div>
                             )}
                             <div className={style['FillOrder__overview--skincontainer']}>
                                <div className={style['FillOrder__overview--skincontainer-headercontainer']}>
                                    <p className={style['FillOrder__overview--skincontainer-headercontainer__header']}>{lang.orderflow_recipient_overview_two_of_two_no_account.white_box.sub_header_agent_has_accepted}</p>
{/*                                    <a href="#" onClick={() => alert("cancelled")} className={style['FillOrder__overview--skincontainer-headercontainer__right']}>Cancel</a>*/}
                                 </div>
                                 <div className={style['FillOrder__overview--skincontainer-revealscontainer']}>
                                     <div className={style['FillOrder__overview--skincontainer-revealscontainer__item']}>
                                         <div className={style['FillOrder__overview--skincontainer-revealscontainer__item--top2']}>
                                             <div className={style['FillOrder__overview--skincontainer-revealscontainer__item--top2-info']}>
                                                 <img src={this.state.agent.avatar ? `/api/user/avatar/${this.state.agent.avatar}` : defaultprofile} alt="Avatar" className={style['FillOrder__overview--skincontainer-revealscontainer__item--top2-info__avatar']}/>
                                                 <div className={style['FillOrder__overview--skincontainer-revealscontainer__item--top2-info__details']}>
                                                     <p className={style['FillOrder__overview--skincontainer-revealscontainer__item--top2-info__details--header']}>{this.state.agent.name}</p>
                                                     <div className={style['FillOrder__overview--skincontainer-revealscontainer__item--top2-info__details--rating']}>
                                                         <MakeStars ratings={this.state.agent.ratings.map(r => r.rating)}/>
                                                     </div>
                                                 </div>
                                             </div>
                                             <div className={style['FillOrder__overview--skincontainer-revealscontainer__item--top2-acceptedcontainer']}>
                                                 <p className={style['FillOrder__overview--skincontainer-revealscontainer__item--top2-acceptedcontainer__text']}>{lang.orderflow_recipient_overview_two_of_two_no_account.white_box.accepted}</p>
                                                 <Checkmark width="20px" height="14.24px" viewBox="0 0 70 45" className={`${style['FillOrder__overview--skincontainer-revealscontainer__item--top2-acceptedcontainer__icon']}`}/>
                                             </div>
                                         </div>
                                         <div className={style['FillOrder__overview--skincontainer-revealscontainer__item--middle']}>
                                            {this.state.agent.phone_number && (
                                                <div className={style['FillOrder__overview--skincontainer-revealscontainer__item--middle-container']}>
                                                    <img src={iconPhone} alt="Phone number" className={style['FillOrder__overview--skincontainer-revealscontainer__item--middle-container__icon']}/>
                                                    <p className={style['FillOrder__overview--skincontainer-revealscontainer__item--middle-container__text']}>{`+${this.state.agent.phone_cc || ''} ${this.state.agent.phone_number || ''}`}</p>
                                                </div>
                                            )}
                                            <div className={style['FillOrder__overview--skincontainer-revealscontainer__item--middle-container']}>
                                                <img src={iconMail} alt="Phone number" className={style['FillOrder__overview--skincontainer-revealscontainer__item--middle-container__icon']}/>
                                                <p className={style['FillOrder__overview--skincontainer-revealscontainer__item--middle-container__text']}>{`${this.state.agent.email || ''}`}</p>
                                            </div>
                                         </div>
                                        <div className={style['FillOrder__overview--skincontainer-revealscontainer__item--middlenobotmarg']}>
                                            {/* Payment method */}
                                            <div className={style['FillOrder__overview--skincontainer-revealscontainer__item--middlenobotmarg-nonflexcontainer']}>
                                                <p className={style['FillOrder__overview--skincontainer-revealscontainer__item--middlenobotmarg-nonflexcontainer__header']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.delivery_method}</p>
                                                <p className={style['FillOrder__overview--skincontainer-revealscontainer__item--middlenobotmarg-nonflexcontainer__body']}>{constants.translateMethod(this.state.order.payment_method)}</p>
                                            </div>
                                            {/* Payment solution */}
                                            {(this.state.order.payment_method === "Mobile payment") && (
                                                <div className={style['FillOrder__overview--skincontainer-revealscontainer__item--middlenobotmarg-nonflexcontainer']}>
                                                    <p className={style['FillOrder__overview--skincontainer-revealscontainer__item--middlenobotmarg-nonflexcontainer__header']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.payment_solution}</p>
                                                    <p className={style['FillOrder__overview--skincontainer-revealscontainer__item--middlenobotmarg-nonflexcontainer__body']}>{constants.makeMethods(this.state.order.payment_method, this.state.order.payment_info)}</p>
                                                </div>
                                            )}
                                         </div>
                                         {(this.state.order.payment_method === 'Mobile payment' || this.state.order.payment_method === 'Bank transfer') && (
                                             <div className={style['FillOrder__overview--skincontainer-revealscontainer__item--middlesmall']}>
                                                <div className={style['FillOrder__overview--skincontainer-revealscontainer__item--middlesmall-nonflexcontainer']}>
                                                    <p className={style['FillOrder__overview--skincontainer-revealscontainer__item--middlesmall-nonflexcontainer__header']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.will_be_transfered_to}</p>
                                                    {this.state.order.payment_method === 'Mobile payment' && (
                                                        <p className={style['FillOrder__overview--skincontainer-revealscontainer__item--middlesmall-nonflexcontainer__body']}>{this.state.order.payment_info && this.state.order.payment_info.mobile_identifier ? this.state.order.payment_info.mobile_identifier : ''}</p>
                                                    )}
                                                    {this.state.order.payment_method === 'Bank transfer' && (
                                                        <Fragment>
                                                            <p className={style['FillOrder__overview--skincontainer-revealscontainer__item--middlesmall-nonflexcontainer__body']}>Bank code: {this.state.order.payment_info.bankcode}</p>
                                                            <p className={style['FillOrder__overview--skincontainer-revealscontainer__item--middlesmall-nonflexcontainer__body']}>Account number: {this.state.order.payment_info.accountnum}</p>
                                                        </Fragment>
                                                    )}
                                                </div>
                                             </div>
                                        )}
                                         {(this.state.order.status === 'Privately taken' || this.state.order.status === 'Privately registered') ? (
                                             <div className={style['FillOrder__overview--skincontainer-revealscontainer__item--middle']}>
                                                <div className={style['FillOrder__overview--skincontainer-revealscontainer__item--middlenobotmarg-nonflexcontainer']}>
                                                    <p className={style['FillOrder__overview--skincontainer-revealscontainer__item--middlenobotmarg-nonflexcontainer__header']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.personal_message}</p>
                                                    <p className={style['FillOrder__overview--skincontainer-revealscontainer__item--middlenobotmarg-nonflexcontainer__body']}>„ {this.state.agent.message} “</p>
                                                </div>
                                             </div>
                                         ) : (
                                             <div className={style['FillOrder__overview--skincontainer-revealscontainer__item--middle']}>
                                                <div className={style['FillOrder__overview--skincontainer-revealscontainer__item--middlenobotmarg-nonflexcontainer']}>
                                                    <p className={style['FillOrder__overview--skincontainer-revealscontainer__item--middlenobotmarg-nonflexcontainer__header']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.public_message}</p>
                                                    <p className={style['FillOrder__overview--skincontainer-revealscontainer__item--middlenobotmarg-nonflexcontainer__body']}>„ {this.state.order.description} “</p>
                                                </div>
                                             </div>
                                         )}
                                         {this.state.order.payment_method === 'In person' && (
                                             <div className={style['FillOrder__overview--skincontainer-revealscontainer__item--bottom']}>
                                                 <div className={style['FillOrder__overview--skincontainer-revealscontainer__item--bottom-left']}>
                                                     <img src={pin} className={style['FillOrder__overview--skincontainer-revealscontainer__item--bottom-left__icon']}/>
                                                     <p className={style['FillOrder__overview--skincontainer-revealscontainer__item--bottom-left__text']}>{`Near ${this.state.agent.location}`}</p>
                                                 </div>

                                             </div>
                                         )}
                                     </div>
                                 </div>
                             </div>
                        </Fragment>
                     )}
              {/* A user has requested the order */}
                    { this.state.order.reveals && this.state.order.reveals.length > 0 && (
                        <div className={style['FillOrder__overview--skincontainer']}>
                            <div className={style['FillOrder__overview--skincontainer-headercontainer']}>
                                <p className={style['FillOrder__overview--skincontainer-headercontainer__header']}>{lang.orderflow_recipient_overview_one_of_two_no_account.white_box.agent_requests_from_public} {this.state.order.reveals.length}</p>
                                {/*
                                    <a href="#" className={style['FillOrder__overview--skincontainer-headercontainer__findmore']}>Find more agents</a>
                                */}
                            </div>
                            <div className={style['FillOrder__overview--skincontainer-revealscontainer']}>
                                    <this.Make_reveals/>
                            </div>
                        </div>
                    )}
                {this.state.order.recipient === 'other' && (
                    <div className={style['FillOrder__overview--skincontainer-sendercontainer']}>
                        <p className={style['FillOrder__overview--skincontainer-sendercontainer__header']}>{lang.orderflow_recipient_overview_one_of_two_no_account.white_box.sub_header_sender}</p>
                        <div className={style['FillOrder__overview--skincontainer-sendercontainer__container']}>
                            <div className={style['FillOrder__overview--skincontainer-sendercontainer__container--left']}>
                                <img src={this.state.creator.avatar ? `/api/user/avatar/${this.state.creator.avatar}` : defaultprofile} alt="Avatar" className={style['FillOrder__overview--skincontainer-sendercontainer__container--left-avatar']} />
                                <p className={style['FillOrder__overview--skincontainer-sendercontainer__container--left-name']}>{this.state.creator.name || ''}</p>
                            </div>
                            {this.state.creator.phone_number && (
                                <div className={style['FillOrder__overview--skincontainer-sendercontainer__container--center']}>
                                    <img src={iconPhone} alt="Phone number" className={style['FillOrder__overview--skincontainer-sendercontainer__container--center-icon']}/>
                                    <a href={`tel:+${this.state.creator.phone_cc}${this.state.creator.phone_number}`} className={style['FillOrder__overview--skincontainer-sendercontainer__container--center-text']}>{`+${this.state.creator.phone_cc} ${this.state.creator.phone_number}`}</a>
                                </div>
                            )}
                            <div className={style['FillOrder__overview--skincontainer-sendercontainer__container--right']}>
                                <img src={iconMail} alt="E-mail" className={style['FillOrder__overview--skincontainer-sendercontainer__container--right-icon']}/>
                                <a href={`mailto:${this.state.creator.email}`} className={style['FillOrder__overview--skincontainer-sendercontainer__container--right-text']}>{this.state.creator.email}</a>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    acceptAgent = (id) => {
        axios.post('/api/order/list/user/active/accept_user_token', {id: id, token: this.props.match.params.token})
        .then(resp => {
            window.location.reload(false) // temp dirty solution
        }).catch(err => {
            alert(err.response.data.message);
        })
    }

    declineAgent = (id) => {
        axios.post('/api/order/list/user/active/decline_user_token', {id: id, token: this.props.match.params.token})
        .then(resp => {
            var order = this.state.order
            for(var i = 0; i < order.reveals.length; i++){
                if(order.reveals[i].id === this.state.chosen_agent.id){
                    order.reveals.splice(i, 1)
                }
            }
            this.setState({order: order, agentsidebar: false})
        }).catch(err => {
            alert(err.response.data.message);
        })
    }

    render(){
        return(
            <Fragment>
                <Modal
                  isOpen={this.state.isOpen}
                  onRequestClose={this.closeModal}
                  className={`${recrstyle['Recruit-modal']}`}
                  style={{overlay: {zIndex: 6}}}
                >
                    <Recruit func={this.closeModal}/>
                </Modal>
                <AgentSidebar
                    handler={this.handler}
                    isOpen={this.state.agentsidebar}
                    agent={this.state.chosen_agent}
                    order={this.state.order}
                    continue={this.privateOrder_Step2}
                    request={this.state.order.status === 'Missing info'} // request button, or accept / decline
                    acceptAgent={this.acceptAgent}
                    declineAgent={this.declineAgent}

                />
                <PrivateOrderStep2
                    handler={this.handler}
                    isOpen={this.state.privateSidebar2}
                    agent={this.state.chosen_agent}
                    goBack={this.back_step1}
                    next={this.next}
                />
                <PrivateOrderStep3_InPerson
                    handler={this.handler}
                    isOpen={this.state.privateSidebar3_inPerson}
                    selectFunc={this.select_func}
                    agent={this.state.chosen_agent}
                    goBack={this.back_step2}
                />
                <PrivateOrderStep3_BankTransfer
                    handler={this.handler}
                    isOpen={this.state.privateSidebar3_bankTransfer}
                    selectFunc={this.select_func}
                    agent={this.state.chosen_agent}
                    goBack={this.back_step2}
                />
                <PrivateOrderStep3_MobilePayment
                    handler={this.handler}
                    isOpen={this.state.privateSidebar3_mobilePayment}
                    selectFunc={this.select_func}
                    agent={this.state.chosen_agent}
                    goBack={this.back_step2}
                />
                <PublicOrderSidebar
                    handler={this.handler}
                    isOpen={this.state.publicsidebar}
                    firstname={this.state.firstname}
                    lastnames={this.state.lastnames}
                    phone_cc={this.state.phone_cc}
                    phone_number={this.state.phone_number}
                    avatar={this.state.avatar_file}
                    public_continue={this.public_continue}

                />
                <div className={`${style['FillOrder']}`}>
                    {this.state.response
                        ? <center><h1>{this.state.response}</h1></center>
                        :
                            (this.state.order.status === 'Missing info' && this.props.match.params.token)
                                ?
                                    this.state.step === 1
                                        ?
                                            <this.Set_KYC/>
                                        : <this.Set_Order/>
                                : <this.Overview/>

                    }
                </div>
            </Fragment>
        )
    }
}

export default React.forwardRef((props, ref) => (
  <UserContext.Consumer>
    {(user) => <FillOrder {...props} context={user} ref={ref} />}
  </UserContext.Consumer>
));