import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import iconGuarantee from 'img/icons/Icon-guarantee.png'
import iconZoom from 'img/icons/icon-zoom.png'
import tempArea from '../../../img/temparea.png'
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import style from '../../../Onboarding.module.scss'
import { inject, observer } from 'mobx-react';
import { constants } from 'consts/utils';
var lang = constants.getTranslations

export default @inject('extendedKYCStore') @observer class Step8 extends Component{

    constructor(props){
        super(props)
        this.state = {
            sliderVal: 25
        }
        this.sliderRef = React.createRef()
    }

    next = () => {
        if(
            !this.props.extendedKYCStore.deliver_radius ||
            !this.props.extendedKYCStore.deliver_street ||
            !this.props.extendedKYCStore.deliver_city ||
            !this.props.extendedKYCStore.deliver_country ||
            !this.props.extendedKYCStore.deliver_lat ||
            !this.props.extendedKYCStore.deliver_long
            )
        {
            alert(lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.deliver_missing)
        } else{
            this.props.extendedKYCStore.step = 9
        }
    }

    geocodeAddress = async (e) => {
        var z = await geocodeByPlaceId(e.value.place_id)
        var address = ''
        var city = ''
        var country = ''
        if(Object.keys(z[0]).includes("plus_code") && z[0].plus_code.compound_code){
            var area = z[0].plus_code.compound_code.split(",")
            var city = area[0].split(" ").slice(1).join(" ")
            var country = area[1].substring(1)
        }
        const len = z[0].address_components.length
        var street = ''
        var zip = ''
        for(var i = 0; i < len; i++){
            const ts = z[0].address_components[i].types
            const value = z[0].address_components[i].long_name
            if(ts.includes("route")){
                //address += value+", "
                street = value
            }
            else if(!city && ts.includes("locality")){
                city = value
            }
            else if(!country && ts.includes("country")){
                country = value
            }
            if(ts.includes("postal_code")){
                zip = value
            }
        }
        this.props.extendedKYCStore.deliver_lat = await z[0].geometry.location.lat()
        this.props.extendedKYCStore.deliver_long = await z[0].geometry.location.lng()
        this.props.extendedKYCStore.deliver_street = street
        this.props.extendedKYCStore.deliver_city = city
        this.props.extendedKYCStore.deliver_country = country
        this.props.extendedKYCStore.deliver_zip = zip
    }

    getRangeVal = () => {
        var x = ((this.props.extendedKYCStore.deliver_radius-1) / 100) * (this.sliderRef.current ? this.sliderRef.current.offsetWidth : 0)
        return x
    }

    updateVal = (val) => {
        this.props.extendedKYCStore.deliver_radius = val;
        this.setState({sliderVal: val})
    }

    componentDidMount = () => {
        this.updateVal(25)
    }

    render(){
        return(
            <Fragment>
                <div className={style['Onboarding']}>
                    <div className={style['Onboarding__container']}>
                        <p className={style['Onboarding__container--header3']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.deliver}</p>
                        <p className={style['Onboarding__container--subheader']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.deliver_support}</p>
                        {/*<div className={style['Onboarding__container--imagecontainer']}>
                            <img src={tempArea} alt="Area image" className={style['Onboarding__container--imagecontainer-area']}/>
                        </div>*/}
                        <div className={style['Onboarding__container--searchcontainer2']}>
                            <img src={iconZoom} className={`${style['Onboarding__container--searchcontainer2-icon']}`}/>
                            <GooglePlacesAutocomplete
                                debounce={500}
                                selectProps={
                                    {
                                        placeholder: lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.filler_address,
                                        onChange: (e) => this.geocodeAddress(e),
                                    }
                                }
                            />
                        </div>
                        <div className="flex" style={{justifyContent: 'space-between', marginTop: '22px', marginBottom: '8px'}}>
                            <p className={style['Onboarding__container--inputcontainersmall-label']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.radius}</p>
                            <p className={style['Onboarding__container--inputcontainersmall-label']}>{this.props.extendedKYCStore.deliver_radius || 25} km</p>
                        </div>
                        <div ref={this.sliderRef} className={style['Onboarding__container--inputcontainersmall-slidecontainer']}>
                            <input type="range" min="1" max="100" value={this.state.sliderVal} onChange={e => this.updateVal(e.target.value)} className={style['Onboarding__container--inputcontainersmall-slidecontainer__slider']} />
                        <div className={style['Onboarding__container--inputcontainersmall-slidecontainer__trail']} style={{width: `${this.getRangeVal()}px`}} />
                        </div>
                        <div className={style['Onboarding__container--buttoncontainer']}>
                            <input type="button" onClick={() => this.next()} className={style['Onboarding__container--buttoncontainer-button']} value={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.continue}/>
                            <Link to="#" onClick={() => this.props.extendedKYCStore.step = 7} className={style['Onboarding__container--buttoncontainer-link']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.previous}</Link>
                        </div>
                        <div className={style['Onboarding__container--textcontainer']}>
                            <img src={iconGuarantee} className={style['Onboarding__container--textcontainer-icon']} alt="Secure" />
                            <p className={style['Onboarding__container--textcontainer-text']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.storage}</p>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}