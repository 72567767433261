import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import Step1 from './localComponents/KYC/Step1/Step1';
import Step2 from './localComponents/KYC/Step2/Step2';
import Step3 from './localComponents/KYC/Step3/Step3';
import Step4 from './localComponents/KYC/Step4/Step4';
import Step5 from './localComponents/KYC/Step5/Step5';
import Step6 from './localComponents/KYC/Step6/Step6';
import Step7 from './localComponents/KYC/Step7/Step7';
import Step8 from './localComponents/KYC/Step8/Step8';
import Step9 from './localComponents/KYC/Step9/Step9';
import Step10 from './localComponents/KYC/Step10/Step10';
import Step11 from './localComponents/KYC/Step11/Step11';
import camera from 'img/illustrations/camera.png'

export default @inject('extendedKYCStore') @observer class Onboarding extends Component{

    constructor(props){
        super(props)
    }

    ShowStep = () => {
        switch (this.props.extendedKYCStore.step) {
            case 1: return(<Step1 />) // how to upload picture
            case 2: return(<Step2 />) // passport
            case 3: return(<Step3 />) // ssn
            case 4: return(<Step4 />) // ether wallet
            case 5: return(<Step5 />) // profile picture
            case 6: return(<Step6 />) // delivery settings -> payment methods
            case 7: return(<Step7 />) // delivery settings -> preferred payment & profile text

            case 8: return(<Step8 />) // delivery settings -> radius
            case 9: return(<Step9 />) // delivery settings -> times
            case 10: return(<Step10 />) // delivery settings -> currency
            case 11: return(<Step11 />) // delivery settings -> set fee
            default: return(<Step1 />)
        }
    }
    render(){
        return(
            <Fragment>
                <this.ShowStep/>
            </Fragment>
        )

    }
}