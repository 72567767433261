
import React, { Component, Fragment } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import axios from 'axios'
import matchSorter from 'match-sorter'
import BN from 'bignumber.js';
import {UserContext} from 'services/userContext'
import Modal from 'react-modal';
import style from './Slide1.module.scss'
import dai from 'img/icons/Dai/Logos/PNGs/Mark_Dai.png'
import pin from 'img/icons/icon-mappin-white.png'
import rightbg from 'img/background/landright.png'
import ReactFlagsSelect from 'react-flags-select'
import Sidebar from 'react-sidebar';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HowToSidebar, AvailabilitySidebar } from 'components/Sidebars/Sidebars';
import { constants } from 'consts/utils'
var lang = constants.getTranslations

Modal.setAppElement('body');

@inject('orderStore')
@observer
class Slide1 extends Component {
    constructor(props) {
        super(props)
        this.state = {receive: '',
                      send: '',
                      data: [],
                      exchange_rates: {},
                      videoModal: false,
                      howToGetDaiSidebar: false,
                      avSidebar: false,
                      curr: 'AR'
                  }
        this.props.orderStore.reset()
        this.handler = this.handler.bind(this)
    }

    componentDidMount() {
        axios.get('/api/fiat/all')
        .then(
              response => {
                  this.setState({ exchange_rates: response.data })
                  this.props.orderStore.local_usd = response.data[this.props.orderStore.receive_currency]
                  this.props.orderStore.exchange_rate = this.props.orderStore.local_usd * this.props.orderStore.dai_usd
                  this.props.orderStore.exchange_rates = response.data
        })
        .catch(err => {
        })
    }

    nextStep = (e) => {
        e.preventDefault()
        if(!this.props.orderStore.send_amount){
           alert("Not a valid send amount")
           return
        }
        if(!this.props.orderStore.receive_amount){
           alert("Not a valid send amount")
           return
        }
        if(this.props.orderStore.send_amount <= 0 || this.props.orderStore.receive_amount <= 0){
            alert('Amounts have to be greater than 0')
            return
        }
        if(this.props.orderStore.send_amount < constants.min_amount){
            alert(`The order amount has to be greater or equal to ${constants.min_amount} DAI`)
            return
        }
        if(this.props.orderStore.send_amount > constants.max_amount){
            alert(`The order amount has to be less or equal to ${constants.max_amount} DAI`)
            return
        }
        this.props.orderStore.step = 2 // skip first
        //this.props.orderStore.send_amount = this.state.send
        //this.props.orderStore.receive_amount = this.state.receive
        this.props.history.push('/orders/new')
    }

    set_receive_currency = (country) => {
        var c = constants.currflags.find(a => a.flagname === country)
        var cc = c.curr
        this.props.orderStore.local_usd = this.state.exchange_rates[cc]
        this.props.orderStore.receive_currency = cc
        this.props.orderStore.exchange_rate = +(this.props.orderStore.dai_usd * this.props.orderStore.local_usd)
        this.props.orderStore.receive_amount = +(this.props.orderStore.exchange_rate * (this.props.orderStore.send_amount - this.props.orderStore.get_deposit_fee))
        this.setState({receive: this.props.orderStore.receive_amount.toFixed(2), curr: country})
    }
    set_send_amount = (e) => {
        if(e.target.value){
            this.props.orderStore.send_amount = e.target.value
            this.props.orderStore.receive_amount = +(this.props.orderStore.send_amount * this.props.orderStore.exchange_rate)
            this.setState({receive: this.props.orderStore.total_minus_fee_local.toFixed(2), send: this.props.orderStore.send_amount})
        } else{
            this.setState({receive: "", send: ""})

        }
    }

    set_receive_amount = (e) => {
        this.props.orderStore.receive_amount = e.target.value
        this.props.orderStore.send_amount = +(this.props.orderStore.receive_amount / ((1 - this.props.orderStore.deposit_fee)*this.props.orderStore.exchange_rate))
        this.setState({receive: e.target.value, send: this.props.orderStore.send_amount.toFixed(2)})
    }

    handler = (state) => {
        this.setState({[state]: false})
    }

    render(){

        return(
                <Fragment>
                    <Modal
                      isOpen={this.state.videoModal}
                      onRequestClose={() => this.setState({videoModal: false})}
                      className={`videoModal`}
                      style={{overlay: {zIndex: 10, margin: 'auto'}}}
                    >
                    <iframe src="https://player.vimeo.com/video/366319612?autoplay=1" width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                    </Modal>

                    <HowToSidebar handler={this.handler} isOpen={this.state.howToGetDaiSidebar}/>
                    <AvailabilitySidebar handler={this.handler} isOpen={this.state.avSidebar}/>

                    <div className={style['FirstSlide']}>
                        <div className={`${style['FirstSlide__left']}`}>
                            <div className={`${style['FirstSlide__left--headercontainer']}`}>
                                <p className={`${style['FirstSlide__left--headercontainer-header']}`}>{lang.orderflow_customer_landingpage.slide_1_amount.header}</p>
                                <p className={`${style['FirstSlide__left--headercontainer-body']}`}>{lang.orderflow_customer_landingpage.slide_1_amount.subheader}</p>
                            </div>
                            <div className={style['FirstSlide__left--orderform']}>
                                <form>
                                    <div className={style['FirstSlide__left--orderform-inputcontainer']}>
                                        <div className={style['FirstSlide__left--orderform-inputcontainer__container']}>
                                            <label className={style['FirstSlide__left--orderform-inputcontainer__container--label']}>{lang.orderflow_customer_landingpage.slide_1_amount.get_label}</label>
                                            <input className={style['FirstSlide__left--orderform-inputcontainer__container--input']} type='number' value={this.state.receive} onChange={this.set_receive_amount} placeholder='0.00' />
                                        </div>
                                        <ReactFlagsSelect
                                            searchable={true}
                                            className={'default-flag'}
                                             onSelect={this.set_receive_currency}
                                             countries={constants.country_labels}
                                             customLabels={constants.shorthand}
                                             selected={this.state.curr}
                                             selectedSize={22.31}
                                             optionsSize={20}
                                             />
                                    </div>
                                    <div className={style['FirstSlide__left--orderform-inputcontainer']}>
                                        <div className={style['FirstSlide__left--orderform-inputcontainer__container']}>
                                            <label className={style['FirstSlide__left--orderform-inputcontainer__container--label']}>{lang.orderflow_customer_landingpage.slide_1_amount.pay_label}</label>
                                            <input className={style['FirstSlide__left--orderform-inputcontainer__container--input']} type='number' value={this.state.send} onChange={this.set_send_amount} placeholder='0.00' />
                                        </div>
                                        <div className={style['FirstSlide__left--orderform-inputcontainer__token']}>
                                            <div className={style['FirstSlide__left--orderform-inputcontainer__token--container']}>
                                                <img className={style['FirstSlide__left--orderform-inputcontainer__token--container-img']} src={dai} alt='DAI'/>
                                                <p className={style['FirstSlide__left--orderform-inputcontainer__token--container-currency']}>Dai</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${style['FirstSlide__left--orderform-exchangeinfo']}`}>
                                        <div className={`${style['FirstSlide__left--orderform-exchangeinfo__left']}`}>
                                            <p className={`${style['FirstSlide__left--orderform-exchangeinfo__left--text']}`}>{lang.orderflow_customer_landingpage.slide_1_amount.exchange_rate} {this.props.orderStore.exchange_rate.toFixed(2)} Dai</p>
                                        </div>
                                        <div className={`${style['FirstSlide__left--orderform-exchangeinfo__right']}`}>
                                            <p className={`${style['FirstSlide__left--orderform-exchangeinfo__right--text']}`}>{this.props.orderStore.get_matching_fee.toFixed(2)} {lang.orderflow_customer_landingpage.slide_1_amount.fee}</p>
                                        </div>
                                    </div>
{                                    <div className={style['FirstSlide__left--orderform-buttoncontainer']}>
                                         <input type='submit'  onClick={this.nextStep} value={lang.orderflow_customer_landingpage.slide_1_amount.main_button} className={`${style['FirstSlide__left--orderform-buttoncontainer__order']}`}/>
                                         <button onClick={(e) => {e.preventDefault(); this.setState({avSidebar: true})}} className={`${style['FirstSlide__left--orderform-buttoncontainer__availability']}`}>{lang.orderflow_customer_landingpage.slide_1_amount.check} <img src={pin} className={`${style['FirstSlide__left--orderform-buttoncontainer__availability--icon']}`}/></button>
                                    </div>}
                                </form>
                            </div>
                        </div>
                        <div className={`${style['FirstSlide__right']}`}>
                            {/*<div className={`${style['FirstSlide__right--cut']}`}/>*/}
                            <div className={`${style['FirstSlide__right--overlay']}`}/>
                            <div className={`${style['FirstSlide__right--imagecontainer']}`}>
                                <img className={`${style['FirstSlide__right--imagecontainer-image']}`} src={rightbg}/>
                            </div>
                            <div className={`${style['FirstSlide__right--playcontainer']}`}>
                                <Link to="#" onClick={() => this.setState({videoModal: true})}>
                                    <div className={`${style['FirstSlide__right--playcontainer-circle']}`}>
                                        <FontAwesomeIcon icon={faPlay} className={`${style['FirstSlide__right--playcontainer-circle__play']}`} />
                                    </div>
                                </Link>
                                <Link to="#" onClick={() => this.setState({videoModal: true})}>
                                    <p className={`${style['FirstSlide__right--playcontainer-text']}`}>{lang.orderflow_customer_landingpage.slide_1_amount.video_text}</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Fragment>
        )
    }
}
export default withRouter(Slide1)