import React, { Component } from 'react';
import axios from 'axios';
import {UserContext} from 'services/userContext';
import style from './ResetPassword.module.scss';
import { constants } from 'consts/utils'

var lang = constants.getTranslations

export default class ResetPassword extends Component {

    constructor(){
        super();
        this.state = {
            password: '',
            confirm_password: '',
            errcode: ''
        };
    }

    ResetPassword = (e) => {
        e.preventDefault()
        axios.post('/api/user/resetpassword', {
            password: this.state.password,
            confirm_password: this.state.confirm_password,
            token: this.props.match.params.token
        })
        .then(async resp => {
            alert(resp.data.message)
            this.props.history.push('/?login')
        })
        .catch(err => {
            const msg = err.response.data.message
            const errcode = err.response.data.errcode
            alert(`${msg}`)
            if(errcode === 1){
                this.props.history.push('/support/forgotpassword')
            }
        })
    }

    render(){
        return(
            <div className={`${style['ResetPassword']}`}>
                <p className={`${style['ResetPassword__text']}`}>{lang.settings.new_password.please}</p>
                <div className={`${style['ResetPassword__formcontainer']}`}>
                    <form>
                        <input type="password" value={this.state.password} onChange={(i) => this.setState({password: i.target.value})} className={`${style['ResetPassword__formcontainer--input']}`} placeholder={lang.settings.new_password.new} autoFocus />
                        <input type="password" value={this.state.confirm_password} onChange={(i) => this.setState({confirm_password: i.target.value})} className={`${style['ResetPassword__formcontainer--input']}`} placeholder={lang.settings.new_password.retype} />
                        <input type="submit" value={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.update_password} onClick={this.ResetPassword} className={style['ResetPassword__formcontainer--submit']} />
                    </form>
                </div>
            </div>
        )
    }
}