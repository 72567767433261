import React, { Component, Fragment, useCallback, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import { constants } from 'consts/utils';
import metamask from 'img/icons/metamask@2x.png';
import walletConnect from 'img/icons/walletconnect.svg';
import { ReactComponent as Checkmark } from 'img/icons/icon-checkmark-round.react.svg'
import style from './Step4.module.scss';
import ordstyle from '../../Order.module.scss';
import axios from 'axios';
import {UserContext} from 'services/userContext';
import BN from 'bignumber.js';
import { infura } from 'config';
import Web3 from "web3";
// import { ethers } from "ethers";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
var lang = constants.getTranslations


const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: infura, // required
      rpc: 3,
      qrcode: true
    }
  }
};

var web3 = null;

const web3Modal = new Web3Modal({
  // network: "mainnet", // optional
  cacheProvider: false, // optional
  providerOptions: providerOptions, // required
});


@inject('orderStore')
@observer
class Step4 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            status: 'choose',
            message: '',
            // unlocked: false,
            // enabler: null,
            web3: null,
            accounts: [],
            connected: false
        }
    }




    componentDidMount(){
        if(Object.keys(this.props.context.user).length === 0){
            this.setState({status: 'errored', message: 'Please login before proceeding'})
            return;
        }
        if(!this.props.context.user.complete){
            this.setState({status: 'errored', message: 'Please complete your profile and wait for it to be verified by Dafnir'})
            return;
        }
        if(!this.props.context.user.verified){
            this.setState({status: 'errored', message: 'Please wait for your profile to be verified by Dafnir'})
            return;
        }

        if(!this.props.orderStore.terms){
            this.setState({status: 'errored', message: "Terms have to be accepted in order to create an order"})
            return;
        }
    }


    connect_wallet = async () => {
        const provider = await web3Modal.connect();
        this.subscribeProvider(provider);
        // web3 = new ethers.providers.Web3Provider(prov);
        web3 = new Web3(provider)
        var accounts = await web3.eth.getAccounts()
        if(accounts.includes(this.props.orderStore.ether_address)){
            this.setState({accounts: accounts, connected: true})
        } else{
            this.setState({status: 'errored', message: `Please connect with the account with the following wallet address: ${this.props.orderStore.ether_address}`})
            return
        }
        this.new_order()
    };


    disconnect_wallet = async () => {
        await web3Modal.clearCachedProvider();
        this.setState({accounts: [], connected: false, status: 'choose', message: ''})
    }

    subscribeProvider = async (provider) => {
        if (!provider.on) {
            return;
        }
        provider.on("connect", () => {
        });
        provider.on("disconnect", () => {
            this.setState({accounts: [], connected: false, status: 'choose', message: ''})
        });
        provider.on("close", () => {
            this.setState({accounts: [], connected: false, status: 'choose', message: ''})
        });
        provider.on("accountsChanged", async (accounts) => {
          // await this.setState({ address: accounts[0] });
          // await this.getAccountAssets();
          this.setState({accounts: [], connected: false, status: 'choose', message: ''})
        });
        provider.on("chainChanged", async (chainId) => {
          // const { web3 } = this.state;
          // const networkId = await web3.eth.net.getId();
          // await this.setState({ chainId, networkId });
          // await this.getAccountAssets();
          this.setState({accounts: [], connected: false, status: 'choose', message: ''})
        });

        provider.on("networkChanged", async (networkId) => {
          // const { web3 } = this.state;
          // const chainId = await web3.eth.chainId();
          // await this.setState({ chainId, networkId });
          // await this.getAccountAssets();
          this.setState({accounts: [], connected: false, status: 'choose', message: ''})
        });
    };

/*    use_metamask(){
        const t = constants.metamask_installed()
        if(!t){
            this.setState({status: 'errored', message: "Please install metamask"})
            return;
        }
        this.setState({status: 'waiting', enabler: setInterval(this.ask_permission, 3000)})
        try{
            ethereum.enable()
            this.ask_permission()
        } catch(e){
        }
    }*/






/*    ask_permission = () => {
        try{
            if(this.state.unlocked){
                clearInterval(this.state.enabler)
                return
            }
            const r = constants.metamask_unlocked( res => {
                this.setState({unlocked: res})
                if(res){
                    this.new_order()
                }
            })
        } catch(e){
        }
    }*/

    new_order = async () => {
        axios.post('/api/order/new', {
            send_amount: this.props.orderStore.send_amount,
            send_currency: this.props.orderStore.send_currency,
            receive_currency: this.props.orderStore.receive_currency,
            recipient: this.props.orderStore.recipient,
            email: this.props.orderStore.receiver_email,
            wallet_address: this.props.orderStore.ether_address,
            terms: this.props.orderStore.terms,
            transaction_hash: this.props.orderStore.transaction_hash || null
        })
        .then(async resp => {
            // order completed
            if(resp.data.complete){
                this.setState({status: 'complete', token: resp.data.token})
            } else{
                const user_address = this.props.orderStore.ether_address
                //this.setState({amount: resp.data.amount, wallet: resp.data.wallet_address})
                // attempt to transfer
                const n_id = await web3.eth.net.getId()
                if(n_id !== resp.data.network){
                    if(resp.data.network === 3) {
                        this.setState({status: 'errored', message: 'Please change your metamask to the ropsten network'})
                    }
                    else {
                        this.setState({status: 'errored', message: 'Please change your metamask network to mainnet'})
                    }
                    return;
                }
                const token_contract = new web3.eth.Contract(resp.data.abi, resp.data.c_addr)
                token_contract.methods.balanceOf(user_address).call().then(async (bal) => {
                    var bnbal = new BN(bal)
                    var divisor = new BN(18)
                    var hbal = bnbal.dividedBy(new BN(10).pow(divisor))
                    if(new BN(resp.data.amount).gt(hbal)){
                        this.setState({status: 'errored', message: "You don't have enough balance to complete this order"})
                        return;
                    } else{
                        token_contract.methods
                        .transfer(resp.data.wallet_address, web3.utils.toWei(resp.data.amount, 'ether'))
                        .send({from: user_address}, (err, res) => {
                            if(err){
                                console.error(err, res)
                                this.setState({status: 'errored', message: err.message})
                            } else{
                                this.setState({status: 'waiting'})
                                this.props.orderStore.transaction_hash = res
                                this.new_order()
                            }
                        })
                    }
                })
            }

        })
        .catch(error => {
            try{
                this.setState({status: 'errored', message: error.response.data.message});
            } catch(err){
                alert(error)
            }
        })
    }


    render() {
        return (
            <div className={style['Step4']}>
                <div className={ordstyle['Order__container']}>
                    {this.state.status === 'choose' && (
                        <Fragment>
                            {(this.state.accounts.length === 0 && !this.state.connected)
                                ? <input type="button" onClick={() => this.connect_wallet()} className={style['Step4__linkmethod']} value='Connect wallet'/>
                                : <Fragment>
                                    <input type="button" onClick={() => this.new_order()} className={style['Step4__linkmethod']} value='Transfer DAI'/>
                                    <br/><br/>
                                    <input type="button" onClick={() => this.disconnect_wallet()} className={style['Step4__linkmethod']} value='Try another wallet'/>
                                  </Fragment>
                            }
                        </Fragment>
                    )}
                    {this.state.status == 'waiting' && (
                        <Fragment>
                            <p className={ordstyle['Order__container--header']}>Waiting for MetaMask</p>
                            <img src={metamask} className={style['Step4__metamask']} alt="MetaMask"/>
                            {/*<Link to="#" className={style['Step4__problems']}>Not opening Metamask chrome extension ?</Link> rel="noopener noreferrer" */}
                        </Fragment>
                    )}
                    {this.state.status == 'complete' && (
                        <Fragment>
                            <p className={ordstyle['Order__container--header']}>{lang.orderflow_myself_your_money_is_on_its_way.white_box.header}</p>
                            <Checkmark className={style['Step4__checkmark']} width='135.19px' height='124.8px' viewBox='4.1 0.1 64 64'/>
                            {this.props.orderStore.recipient === 'self'
                                ?
                                    <Fragment>
                                        <div className={style['Step4__overviewcontainer']}>
                                            <Link to={`/orders/token/${this.state.token}`}><input type="button" className={style['Step4__overviewcontainer--button']} value={lang.orderflow_myself_your_money_is_on_its_way.white_box.red_button}/></Link>
                                            {/*<Link to={`/orders/token/${this.state.token}`}><input type="button" className={style['Step4__overviewcontainer--whitebutton']} value="See overview of your order"/></Link>*/}

                                        </div>
                                    </Fragment>
                                :
                                    <Fragment>
                                {/* What overview is this? */}
{/*                                        <div className={style['Step4__overviewcontainer']}>
                                            <input type="button" className={style['Step4__overviewcontainer--button']} value="See overview of your order" onClick={() => alert("overview")}/>
                                        </div>*/}
                                        <p className={style['Step4__header']}>{lang.orderflow_someone_else_your_money_is_on_its_way.white_box.sub_header}</p>
                                        <div className={style['Step4__nextcontainer']}>
                                            <div className={style['Step4__nextcontainer--circle']}><p>1</p></div>
                                            <p className={style['Step4__nextcontainer--text']}>{lang.orderflow_someone_else_your_money_is_on_its_way.white_box.step_one} <b>{this.props.orderStore.receiver_email}</b></p>
                                        </div>
                                        <div className={style['Step4__nextcontainer']}>
                                            <div className={style['Step4__nextcontainer--circle']}><p>2</p></div>
                                            <p className={style['Step4__nextcontainer--text']}>{lang.orderflow_someone_else_your_money_is_on_its_way.white_box.step_two}</p>
                                        </div>
                                        <div className={style['Step4__nextcontainer']}>
                                            <div className={style['Step4__nextcontainer--circle']}><p>3</p></div>
                                            <p className={style['Step4__nextcontainer--text']}>{lang.orderflow_someone_else_your_money_is_on_its_way.white_box.step_three}</p>
                                        </div>
                                    </Fragment>
                            }
                        </Fragment>
                    )}
                    {this.state.status == 'errored' && (
                        <Fragment>
                            <p className={ordstyle['Order__container--header']}>Transaction failed</p>
                            <p className={ordstyle['Order__container--text']}>{this.state.message}</p>
                            <div className={ordstyle['Order__nextcontainer']}>
                                <div>
                                    <input type="button" onClick={() => this.new_order()} className={style['Step4__linkmethod']} value='Try again'/>
                                    <br/><br/>
                                    <input type="button" onClick={() => this.disconnect_wallet()} className={style['Step4__linkmethod']} value='Try another wallet'/>
                                    <br/><br/>
                                    <Link to="#" onClick={e => this.props.orderStore.step = 3} className={ordstyle['Order__nextcontainer--prev']}>Previous step</Link>
                                </div>
                            </div>
                        </Fragment>
                    )}
                </div>
            </div>
        );
    }
}

export default React.forwardRef((props, ref) => (
  <UserContext.Consumer>
    {user => <Step4 {...props} context={user} ref={ref} />}
  </UserContext.Consumer>
));
