import React, { Component, Fragment } from 'react';
import axios from 'axios';
import BN from 'bignumber.js';
import { Link } from 'react-router-dom';
import AdminNav from '../AdminNav/AdminNav';
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import style from '../../Admin.module.scss';


const parameters = {
    message: 'Hey, please deliver for me?',
    receive_amount: 200,
    receive_currency: 'DKK',
    reward: 5000,
    rewardlocal: 'ARS',
    send_amount: 100,
    send_currency: 'DAI',
    owncurrency: 'DKK',
    host: 'dafnir.com',
    token: '4432ca2f66f04ef8b4450c1473d851b8feff4d57736f2dcd13dfe9959d9a7e58',
    purposes: [0,1,2],
    email: 'email@email.com',
    friend: 'friend',
    pay_amount: 200,
    creatorName: 'Peter P.',
    recname: 'Katja K.',
    pay_currency: 'DAI',
    password: null,
    name: 'Abdi B.',
    agentName: 'Rebecca C.',
    agentPhone: '+54 12345678',
    agentMail: 'muhmuh@gmail.com',
    orderer: 'orderer',
    amount: 200,
    equalTo: 5000,
    curr: 'ARS',
    mobile_application: 'Swish',
    area: 'Copenhagen, Denmark',
    delivered: 200,
    deliverCurrency: 'DKK',
    txHash: '0x123456789',
    refundAmount: new BN(1.2), // dynamic
    fee: new BN(0.5),
    refundStatus: true,
    refundWallet: '0x1234567890123456789012345678901234567890',
    refundHash: '0x987654321',
    walletAddress: '0x1234567890123456789012345678901234567890',
    txStatus: true,
    cancellationFee: 1.0,
    adminName: 'Admin A.',
    id: '_id1203021o0asdoaosd91i2',
    user_id: '_123020199292x21kijg',
    agent_id: '_32102002x029g',
    errorMessage: 'Something went wrong',
    path: 'admin/emails/',
    question: 'Question text',
    etherscan: 'https://etherscan.io/tx/12345',
    recmail: 'recipient@email.com',
    recipient: 'other',
    role: `agent`,
    review: `Very good communication and came on the exact time of the delivery.`,
    rating: 3,
    methods: ['In person', 'Mobile pay'],
    avatar: `5e8c60a718236d1eaaa2776a/avatar/avatar-1586259270260.png`,
    delinfo: {mobile_application: 'Mobile pay', mobile_identifier: '51311404', bankcode: 45, accountnum: 12345678}
}

class Emails extends Component {
    constructor(){
        super()
        this.state = {
            emailtemplates: [],
            chosenEmailTemplate: 'review',
            email: {}
        }
    }
    componentWillMount(){
        axios.get('/api/admin/emails')
        .then(response => {
            this.setState({ emailtemplates: response.data })
        })
        .catch(err => {
            alert(err)
        })
        if(this.state.chosenEmailTemplate){
            axios.post('/api/admin/email_preview', {
                email: this.state.chosenEmailTemplate,
                parameters: parameters,
            }).then(resp => {
                this.setState({email: resp.data})
                if(resp.data.html && resp.data.html.includes("undefined")) alert("There's an error, something is undefined")
            }).catch(err => {
                alert(err)
            })
        }
    }

    CreateMarkup = () => {
        return {
            __html: this.state.email.html
        }
    }

    emailPreview(email){
        this.setState({chosenEmailTemplate: email})
        axios.post('/api/admin/email_preview', {
            email: email,
            parameters: parameters
        }).then(resp => {
            this.setState({email: resp.data})
            if(resp.data.html.includes("undefined")) alert("There's an error, something is undefined")
        }).catch(err => {
            console.error(err)
            alert(err)
        })
    }

    render(){
        return(
            <Fragment>
                <AdminNav/>
                <div className={style['Admin']}>
                    <div className={style['Admin__container']}>
                        <p className={`${style['Admin__container--header']}`}>Email preview</p>
                        <span><FontAwesomeIcon icon={faSyncAlt} className={`${style['Admin__container--refresh']}`} onClick={() => this.emailPreview(this.state.chosenEmailTemplate)}/></span>

                        {Object.keys(this.state.emailtemplates).length > 0 && (
                            <select defaultValue={this.state.chosenEmailTemplate} className={style['Admin__container--select']} onChange={(e) => this.emailPreview(e.target.value)}>
                                <option key={0} value="" disabled>Select email template</option>
                                {this.state.emailtemplates.map((e,i) => <option key={i} value={e}>{e}</option>)}
                            </select>
                        )}
                        {this.state.chosenEmailTemplate && (
                            <div className={`${style['Admin__container--emailcontainer']}`}>
                                <p className={`${style['Admin__container--emailcontainer-from']}`}>{this.state.email.from}</p>
                                <p className={`${style['Admin__container--emailcontainer-subject']}`}>{this.state.email.subject}</p>
                                <div className={`${style['Admin__container--emailcontainer-view']}`} dangerouslySetInnerHTML={{__html: this.state.email.html}}/>
                            </div>
                        )}
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default Emails;
