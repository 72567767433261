import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import iconGuarantee from 'img/icons/Icon-guarantee.png'
import inPerson from 'img/illustrations/hand-over.png'
import bankTransfer from 'img/illustrations/bank_transfer.png'
import mobilePayment from 'img/illustrations/mobile_payment.png'
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import style from '../../../Onboarding.module.scss'
import { inject, observer } from 'mobx-react';
import Checkbox from 'components/Forms/Checkbox/Checkbox.js';
import { constants } from 'consts/utils';
var lang = constants.getTranslations

export default @inject('extendedKYCStore') @observer class Step6 extends Component{

    constructor(props){
        super(props)
    }

    next = () => {
        if(this.props.extendedKYCStore.deliver_payment1 || this.props.extendedKYCStore.deliver_payment2 || this.props.extendedKYCStore.deliver_payment3){
            this.props.extendedKYCStore.step = 7
        } else{
            alert("Please choose at least one delivery method")
        }
    }

    render(){
        return(
            <Fragment>
                <div className={style['Onboarding']}>
                    <div className={style['Onboarding__container']}>
                        <p className={style['Onboarding__container--header3']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.deliver}</p>
                        <p className={style['Onboarding__container--subheader2']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.how_will_you}</p>

                        <div className={style['Onboarding__container--purposecontainer']}>
                            <div className={style['Onboarding__container--purposecontainer-item']}>
                                <div className={style['Onboarding__container--purposecontainer-item__imagecontainer']}>
                                    <img src={inPerson} alt="Send money" className={style['Onboarding__container--purposecontainer-item__imagecontainer--image']}/>
                                </div>
                                <p className={style['Onboarding__container--purposecontainer-item__header']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.deliver_cash_header}</p>
                                <p className={style['Onboarding__container--purposecontainer-item__body']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.deliver_cash}</p>
                                <Checkbox
                                    extendlabelclass={`${style['Onboarding__container--purposecontainer-item__check']}`}
                                    defaultChecked={this.props.extendedKYCStore.deliver_payment1}
                                    onClick={(e) => this.props.extendedKYCStore.deliver_payment1 = !this.props.extendedKYCStore.deliver_payment1}
                                />
                            </div>
                            <div className={style['Onboarding__container--purposecontainer-item']}>
                                <div className={style['Onboarding__container--purposecontainer-item__imagecontainer']}>
                                    <img src={bankTransfer} alt="Receive money" className={style['Onboarding__container--purposecontainer-item__imagecontainer--image']}/>
                                </div>
                                <p className={style['Onboarding__container--purposecontainer-item__header']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.deliver_bank_header}</p>
                                <p className={style['Onboarding__container--purposecontainer-item__body']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.deliver_bank}</p>
                                <Checkbox
                                    extendlabelclass={`${style['Onboarding__container--purposecontainer-item__check']}`}
                                    defaultChecked={this.props.extendedKYCStore.deliver_payment2}
                                    onClick={(e) => this.props.extendedKYCStore.deliver_payment2 = !this.props.extendedKYCStore.deliver_payment2}
                                />
                            </div>
                            <div className={style['Onboarding__container--purposecontainer-item']}>
                                <div className={style['Onboarding__container--purposecontainer-item__imagecontainer']}>
                                    <img src={mobilePayment} alt="Agent" className={style['Onboarding__container--purposecontainer-item__imagecontainer--image']}/>
                                </div>
                                <p className={style['Onboarding__container--purposecontainer-item__header']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.deliver_mobile_header}</p>
                                <p className={style['Onboarding__container--purposecontainer-item__body']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.deliver_mobile}</p>
                                <Checkbox
                                    extendlabelclass={`${style['Onboarding__container--purposecontainer-item__check']}`}
                                    defaultChecked={this.props.extendedKYCStore.deliver_payment3}
                                    onClick={(e) => this.props.extendedKYCStore.deliver_payment3 = !this.props.extendedKYCStore.deliver_payment3}
                                />
                            </div>
                        </div>




                        <div className={style['Onboarding__container--buttoncontainer']}>
                            <input type="button" onClick={() => this.next()} className={style['Onboarding__container--buttoncontainer-button']} value={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.continue}/>
                            <Link to="#" onClick={() => this.props.extendedKYCStore.step = 5} className={style['Onboarding__container--buttoncontainer-link']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.previous}</Link>
                        </div>
                        <div className={style['Onboarding__container--textcontainer']}>
                            <img src={iconGuarantee} className={style['Onboarding__container--textcontainer-icon']} alt="Secure" />
                            <p className={style['Onboarding__container--textcontainer-text']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.storage}</p>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}