import React, { Component } from 'react';
import style from './Recruit.module.scss';
import { constants } from 'consts/utils';
import axios from 'axios';

var lang = constants.getTranslations

class Recruit extends Component {
    constructor(props){
        super(props);
        this.state = {
            email: '',
            message: ''
        }
    }
    setEmail = (e) => {
        this.setState({ email: e.target.value });
    }
    setMessage = (e) => {
        this.setState({ message: e.target.value });
    }

    submit = (e) => {
        e.preventDefault();
        axios.post('/api/invite/', {email: this.state.email, message: this.state.message})
        .then(resp => {
            alert(resp.data.message);
            this.props.func()
        }).catch(err => {
            alert(err.response.data.message);
        })
    }

    render(){
        return(
            <div className={style['Recruit']}>
                <div className={style['Recruit__container']}>
                    <form>
                        <div className={style['Recruit__container--headercontainer']}>
                            <p className={style['Recruit__container--headercontainer-header']}>{lang.recruit_agent.header}</p>
                        </div>
                        <p className={style['Recruit__container--text']}>{lang.recruit_agent.text}</p>
                        <p className={style['Recruit__container--label']}>{lang.recruit_agent.email}</p>
                        <input autoFocus type="text" value={this.state.email} onChange={this.setEmail} className={style['Recruit__container--input']} placeholder={lang.recruit_agent.filler}/>
                        <div className={style['Recruit__container--messagecontainer']}>
                            <p className={style['Recruit__container--messagecontainer-label']}>{lang.recruit_agent.message}</p>
                            <textarea value={this.state.message} onChange={this.setMessage} className={style['Recruit__container--messagecontainer-input']} placeholder={lang.recruit_agent.message_filler}/>
                        </div>
                        <div className={style['Recruit__container--buttoncontainer']}>
                            <input type="submit" className={style['Recruit__container--buttoncontainer-button']} onClick={this.submit} value={lang.sign_up.send}/>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}
export default Recruit;
