import { makeObservable, observable, action, computed } from 'mobx';
import { constants } from 'consts/utils';

const defaultcurr = "ARS";

class OrderStore{
	@observable step = 1;

	@observable matching_fee = constants.matching_fee;
	@observable deposit_fee = constants.deposit_fee; // 8 percent max fee which shall cover the delivery fee, rest will be refunded

	@observable dai_usd = 1; // 1 dai in USD
	@observable local_usd = 0; // 1 USD in ARS

	// step 1
	@observable send_currency = "DAI";
	@observable send_amount = '';
	@observable receive_currency = defaultcurr;
	@observable receive_amount = ''; // not to be used in backend
	@observable exchange_rate = +(this.dai_usd*this.local_usd); // not to be used in backend
	@observable exchange_rates = {};

	// Step 2
	@observable recipient = "" // self or other

	// step 3
	@observable receiver_email = "";

	// TODO: still used but shouldn't be?
	@observable ether_address = "";

	// step 4
	@observable transaction_hash = '';
	@observable terms = false;


	@computed get get_matching_fee(){
		return +(this.send_amount*this.matching_fee) || 0.0;
	}

	@computed get get_deposit_fee(){
		return +(this.send_amount*this.deposit_fee) || 0.0; // divide delivery fee by 100 to get percentage
	}
	@computed get total_minus_fee(){
		return +(this.send_amount - this.get_deposit_fee) || 0.0;
	}

	// local values
	@computed get get_matching_fee_local(){
		return +(this.get_matching_fee*this.exchange_rate) || 0.0;
	}

	@computed get get_deposit_fee_local(){
		return +(this.get_deposit_fee*this.exchange_rate) || 0.0;
	}
	@computed get total_minus_fee_local(){
		return +(this.total_minus_fee*this.exchange_rate) || 0.0;
	}

	@action
  	nextStep = () => {
	    this.step += 1;
	}
  	previousStep = () => {
	    this.step -= 1;
	}

	@action
	reset = () => {
		this.step = 1;
		this.matching_fee = constants.matching_fee;
		this.deposit_fee = constants.deposit_fee;
		this.dai_usd = 1;
		this.local_usd = 0;
		this.send_currency = "DAI";
		this.send_amount = '';
		this.receive_currency = defaultcurr;
		this.receive_amount = '';
		this.exchange_rate = +(this.dai_usd*this.local_usd);
		this.exchange_rates = {};
		this.recipient = "";
		this.receiver_email = "";
		this.ether_address = "";
		this.transaction_hash = '';
		this.terms = false;
	}

    constructor() {
        makeObservable(this)
    }
}

export default new OrderStore();