import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import iconGuarantee from 'img/icons/Icon-guarantee.png'
import style from '../../../Onboarding.module.scss'
import { inject, observer } from 'mobx-react';
import { constants } from 'consts/utils';
import axios from 'axios';
import {UserContext} from 'services/userContext';
var lang = constants.getTranslations

@inject('extendedKYCStore')
@observer
class Step11 extends Component{
    constructor(props){
        super(props)
        this.sliderRef = React.createRef()
    }

    next = () => {
        let formData = new FormData();
        const keys = Object.keys(this.props.extendedKYCStore)
        const skip = ['step', 'passportimg', 'passportname', 'profilename']
        const skip_client = ['passport', 'profile', 'deliver_payment1', 'deliver_payment2', 'deliver_payment3']

        var deliver_methods = []
        var user = this.props.context.user
        for (const k of keys){
            // server stuff
            if(skip.includes(k)){
                continue
            }
            const val = this.props.extendedKYCStore[k]
            if(k === 'avatar'){
                formData.append(k, constants.dataURLtoFile(this.props.extendedKYCStore[k], 'avatar.png'))
            } else{
                formData.append(k, val)
            }


            // Add delivery method to client
            if(k === 'deliver_payment1' || k === 'deliver_payment2' || k === 'deliver_payment3'){
                if(val === true){
                    var to_add = ''
                    switch(k){
                        case 'deliver_payment1':
                            to_add = 'In person'
                            break;
                        case 'deliver_payment2':
                            to_add = 'Bank transfer'
                            break;
                        case 'deliver_payment3':
                            to_add = 'Mobile payment'
                            break;
                        default:
                            break
                    }
                    deliver_methods.push(to_add)
                }
            }

            // client stuff
            if(skip_client.includes(k)){
                continue
            }
            user[k] = val
        }
        if(deliver_methods.length > 0){
            user['deliver_methods'] = deliver_methods
        }

        axios.post('/api/user/completeprofile', formData)
        .then(resp => {
            user.avatar = resp.data.avatar
            user.complete = true
            this.props.context.setUser(user)
            this.props.history.push('/?onboarding_complete')
        }).catch(err => {
            try{
                alert(err.response.data.message);
            } catch(e){
                alert(err)
            }
        })
    }


    getRangeVal = () => {
        var x = ((this.props.extendedKYCStore.deliver_exchangefee) / 6) * (this.sliderRef.current ? this.sliderRef.current.offsetWidth : 0)
        x = x === 0 ? 0 : x-1 // remove small trail behind ball when max
        if(!this.sliderRef.current){ // debug since its 0 on initialization
            x = 228
        }
        return x
    }

    updateVal = (val) => {
        this.props.extendedKYCStore.deliver_exchangefee = parseInt(val);
    }

    render(){
        return(
            <Fragment>
                <div className={style['Onboarding']}>
                    <div className={style['Onboarding__container']}>
                        <p className={style['Onboarding__container--header3']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.fee}</p>
                        <p className={style['Onboarding__container--subheader']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.fee_support}</p>
                        <div className={style['Onboarding__container--inputcontainersmall']}>
                            <div ref={this.sliderRef} className={style['Onboarding__container--inputcontainersmall-slidecontainer']}>
                                <input type="range" min="0" max={constants.max_fee_num} value={this.props.extendedKYCStore.deliver_exchangefee} onChange={e => this.updateVal(e.target.value)} className={style['Onboarding__container--inputcontainersmall-slidecontainer__slider']} />
                                <div className={style['Onboarding__container--inputcontainersmall-slidecontainer__trail']} style={{width: `${this.getRangeVal()}px`}} />
                                <p className={style['Onboarding__container--inputcontainersmall-slidecontainer__fee']}>{this.props.extendedKYCStore.deliver_exchangefee}%</p>
                            </div>
                            <div className={style['Onboarding__container--buttoncontainer']}>
                                <input type="button" onClick={() => this.next()} className={style['Onboarding__container--buttoncontainer-button']} value={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.continue}/>
                                <Link to="#" onClick={() => this.props.extendedKYCStore.step = 10} className={style['Onboarding__container--buttoncontainer-link']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.previous}</Link>
                            </div>
                            <div className={style['Onboarding__container--textcontainer']}>
                                <img src={iconGuarantee} className={style['Onboarding__container--textcontainer-icon']} alt="Secure" />
                                <p className={style['Onboarding__container--textcontainer-text']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.storage}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(React.forwardRef((props, ref) => (
  <UserContext.Consumer>
    {(user, setStatus, setUser, isLoggedIn) => <Step11 {...props} context={user} />}
  </UserContext.Consumer>
)));