import React, { Component, Fragment } from 'react';
import style from './Step2a.module.scss';
import ordstyle from '../../Order.module.scss';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { constants } from 'consts/utils'
var lang = constants.getTranslations

@inject('orderStore') @observer class Step2a extends Component {

    render() {
        return(
            <Fragment>
                <div className={`${ordstyle['Order__container']}`}>
                    <p className={`${ordstyle['Order__container--header']}`}>{lang.orderflow_someone_else_who_are_you_sending_cash_to.white_box.header}</p>
                    <form>
                        <div className={style['Step2a__inputcontainer']}>
                        <FontAwesomeIcon icon={faEnvelope} className={style['Step2a__inputcontainer--icon']} />
                        <input type="email" className={style['Step2a__inputcontainer--input']} value={this.props.orderStore.receiver_email} onChange={e => this.props.orderStore.receiver_email = e.target.value} placeholder={lang.orderflow_someone_else_who_are_you_sending_cash_to.white_box.email_field}/>
                        </div>
                        <div className={ordstyle['Order__nextcontainer']}>
                            <input type="submit" onClick={e => this.props.orderStore.step = 3} className={ordstyle['Order__nextcontainer--button']} value={lang.orderflow_someone_else_who_are_you_sending_cash_to.white_box.continue_button}/>
                            <div>
                                <Link to="#" onClick={() => this.props.orderStore.step = 1} className={ordstyle['Order__nextcontainer--prev']}>{lang.orderflow_amount.white_box.previous_step_link}</Link>
                            </div>
                        </div>
                    </form>
                </div>
            </Fragment>
        );
    }
}
export default Step2a