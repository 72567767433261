import React, { Component } from 'react';
import style from './Slide3.module.scss';
import styleBAA from '../../BecomeAnAgent.module.scss'
import BN from 'bignumber.js';
import ReactFlagsSelect from 'react-flags-select'
import { constants } from 'consts/utils'
var lang = constants.getTranslations

export default class extends Component {
    constructor(props) {
        super(props)
        this.state =
            {
              cashvalue: new BN(5000),
              flag: 'AR',
              currency: 'ARS'
            }
    }
    render(){
        return(
            <div className={style['ThirdSlide']}>
                <div className={style['ThirdSlide__container']}>
                    <div className={style['ThirdSlide__container--left']}>
                        <p className={style['ThirdSlide__container--left-header']}>{lang.agent_landingpage.slide_3_is_inflation_affecting_you.header}</p>
                        <p className={style['ThirdSlide__container--left-subheader']}>{lang.agent_landingpage.slide_3_is_inflation_affecting_you.text_under_header}</p>
                        <p className={style['ThirdSlide__container--left-subheader']}>{lang.agent_landingpage.slide_3_is_inflation_affecting_you.text_under_header_second_line}</p>
                        <p className={style['ThirdSlide__container--left-calculate']}>{lang.agent_landingpage.slide_3_is_inflation_affecting_you.sub_header}</p>
                        <div className={style['ThirdSlide__container--left-calculatecontainer']}>
                            <div className={style['ThirdSlide__container--left-calculatecontainer__flexcontainer']}>
                                <p className={style['ThirdSlide__container--left-calculatecontainer__flexcontainer--amount']}>{lang.agent_landingpage.slide_3_is_inflation_affecting_you.amount_delivered}: {this.state.cashvalue.toFixed(0)}</p>
                                <ReactFlagsSelect
                                    searchable={true}
                                     onSelect={e => this.setState({flag: e, currency: constants.shorthand[e]})}
                                     className={'default-flag'}
                                     countries={constants.country_labels}
                                     customLabels={constants.shorthand}
                                     selected={this.state.flag}
                                     selectedSize={22.31}
                                     optionsSize={20}
                                 />
                             </div>
                            <div className={styleBAA['slidecontainer']}>
                                <input type="range" min="1" max="25000" value={this.state.cashvalue} onChange={e => this.setState({cashvalue: new BN(e.target.value)})} className={styleBAA['slidecontainer__slider']} />
                                <div className={styleBAA['slidecontainer__trail']} style={{width: `${((this.state.cashvalue-1) / 26800)*358}px`}} />
                            </div>
                        </div>
                    </div>
{/*                    <div className={style['ThirdSlide__container--right']}>
                        <h1>HER VIL VÆRE EN FLOT GRAF</h1>
                    </div>*/}
                </div>
            </div>
        )
    }
}