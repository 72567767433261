import React, { Component, Fragment } from 'react';
import style from '../../../Onboarding.module.scss'
import { inject, observer } from 'mobx-react';
import Checkbox from 'components/Forms/Checkbox/Checkbox.js';
import axios from 'axios';
import { Link, withRouter } from 'react-router-dom';
import purp1 from 'img/illustrations/fly-wallet.png'
import purp2 from 'img/illustrations/choose-agent.png'
import purp3 from 'img/illustrations/hand-over.png';
import {UserContext} from 'services/userContext';
import { constants } from 'consts/utils';
var lang = constants.getTranslations

@inject('basicKYCStore')
@observer
class Step3 extends Component{

    constructor(props){
        super(props)
    }

    submit(){
        if(this.props.basicKYCStore.pep){
            alert("Unfortunately we don't allow politically exposed people on the Dafnir platform.")
            return
        }
        var data = this.props.basicKYCStore
        data.token = this.props.match.params.token
        axios.post('/api/user/basicKYC', data)
        .then(resp => {
            this.props.context.setUser(resp.data);
            this.props.context.setStatus(true);
            this.props.history.push('/?welcome');

        }).catch(err => {
            alert(err.response.data.message);
        })


    }

    render(){
        return(
            <div className={style['Onboarding']}>
                <div className={style['Onboarding__container']}>
                    <p className={style['Onboarding__container--header2']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.purpose}</p>
                    <p className={style['Onboarding__container--subheader']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.purpose_support}</p>
                    <div className={style['Onboarding__container--purposecontainer']}>
                        <div className={style['Onboarding__container--purposecontainer-item']}>
                            <div className={style['Onboarding__container--purposecontainer-item__imagecontainer']}>
                                <img src={purp1} alt="Send money" className={style['Onboarding__container--purposecontainer-item__imagecontainer--image']}/>
                            </div>
                            <p className={style['Onboarding__container--purposecontainer-item__header']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.send}</p>
                            <p className={style['Onboarding__container--purposecontainer-item__body']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.send_support}</p>
                            <Checkbox
                                extendlabelclass={`${style['Onboarding__container--purposecontainer-item__check']}`}
                                defaultChecked={this.props.basicKYCStore.purpose1}
                                onClick={(e) => this.props.basicKYCStore.purpose1 = !this.props.basicKYCStore.purpose1}
                            />
                        </div>
                        <div className={style['Onboarding__container--purposecontainer-item']}>
                            <div className={style['Onboarding__container--purposecontainer-item__imagecontainer']}>
                                <img src={purp2} alt="Receive money" className={style['Onboarding__container--purposecontainer-item__imagecontainer--image']}/>
                            </div>
                            <p className={style['Onboarding__container--purposecontainer-item__header']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.receive}</p>
                            <p className={style['Onboarding__container--purposecontainer-item__body']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.receive_support}</p>
                            <Checkbox
                                extendlabelclass={`${style['Onboarding__container--purposecontainer-item__check']}`}
                                defaultChecked={this.props.basicKYCStore.purpose2}
                                onClick={(e) => this.props.basicKYCStore.purpose2 = !this.props.basicKYCStore.purpose2}
                            />
                        </div>
                        <div className={style['Onboarding__container--purposecontainer-item']}>
                            <div className={style['Onboarding__container--purposecontainer-item__imagecontainer']}>
                                <img src={purp3} alt="Agent" className={style['Onboarding__container--purposecontainer-item__imagecontainer--image']}/>
                            </div>
                            <p className={style['Onboarding__container--purposecontainer-item__header']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.agent}</p>
                            <p className={style['Onboarding__container--purposecontainer-item__body']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.agent_support}</p>
                            <Checkbox
                                extendlabelclass={`${style['Onboarding__container--purposecontainer-item__check']}`}
                                defaultChecked={this.props.basicKYCStore.purpose3}
                                onClick={(e) => this.props.basicKYCStore.purpose3 = !this.props.basicKYCStore.purpose3}
                            />
                        </div>
                    </div>
                    <div className={style['Onboarding__container--buttoncontainer']}>
                        <input type="submit" onClick={() => this.submit()} className={style['Onboarding__container--buttoncontainer-button']} value={lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.continue}/>
                        <Link to="#" onClick={() => this.props.basicKYCStore.step = 2} className={style['Onboarding__container--buttoncontainer-link']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.previous}</Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(React.forwardRef((props, ref) => (
  <UserContext.Consumer>
    {(user, setStatus, setUser) => <Step3 {...props} context={user} setStatus={setStatus} setUser={setUser} ref={ref} />}
  </UserContext.Consumer>
)));