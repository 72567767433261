import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import axios from 'axios';
import { observer, inject } from 'mobx-react';
import { constants } from 'consts/utils';
import defaultprofile from 'img/icons/defaultprofile.png';
import wallet from 'img/icons/icon-wallet.png';
import purpose1 from './img/illustration_1.png';
import purpose2 from './img/illustration_2.png';
import purpose3 from './img/illustration_3.png';
import iconZoom from 'img/icons/icon-zoom.png'
import inPerson from 'img/illustrations/hand-over.png'
import bankTransfer from 'img/illustrations/bank_transfer.png'
import mobilePayment from 'img/illustrations/mobile_payment.png'
import Checkbox from 'components/Forms/Checkbox/Checkbox';
import { ReactComponent as Zoom } from 'img/icons/icon-zoom.react.svg'
import {UserContext} from 'services/userContext';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import style from './ManageAccount.module.scss';
import Avatar from 'react-avatar-edit'

var lang = constants.getTranslations

class ManageAccount extends Component {

    constructor(props){
        super(props);
        if(Object.keys(props.context.user).length === 0){
            window.location.reload();
        }
        const loc = (props.context.user.deliver_street ? props.context.user.deliver_street+", " : '') + (props.context.user.deliver_city ? props.context.user.deliver_city+", " : '') + (props.context.user.deliver_country ? props.context.user.deliver_country : '')
        const addr = (props.context.user.street ? props.context.user.street+", " : '') + (props.context.user.no ? props.context.user.no+", " : '') + (props.context.user.city ? props.context.user.city+", " : '') + (props.context.user.country+", " ? props.context.user.country : '')
        this.state = {
            isModalOpen: props.location.search === "?activate" && !props.context.user.complete ? true : false || false,
            email: props.context.user.email || '',
            password: '',
            purpose1: props.context.user.dafnir_purposes.includes(0) || false,
            purpose2: props.context.user.dafnir_purposes.includes(1) || false,
            purpose3: props.context.user.dafnir_purposes.includes(2) || false,
            upavatar: props.context.user.avatar ? '/api/user/image/avatar' : defaultprofile,
            firstname: props.context.user.firstname || '',
            lastnames: props.context.user.lastnames || '',
            address: addr || '',
            street: '',
            city: '',
            no: '',
            country: '',
            zip: '',
            country_code: props.context.user.country_code || '',
            phonenumber: props.context.user.phonenumber || '',
            deliver_radius: props.context.user.deliver_radius || 25,
            ether_address: props.context.user.ether_address || 'Please write the address of your ethereum account here.',
            location: loc || '',
            deliver_street: '',
            deliver_city: '',
            deliver_country: '',
            deliver_zip: '',
            deliver_location: '',
            deliver_currency: props.context.user.deliver_currency || '0',
            deliver_amount_min: props.context.user.deliver_amount_min || '',
            deliver_amount_max: props.context.user.deliver_amount_max || '',
            deliver_exchangefee: props.context.user.deliver_exchangefee || '',
            deliver_starttime_monday: props.context.user.deliver_starttime_monday || '',
            deliver_endtime_monday: props.context.user.deliver_endtime_monday || '',
            deliver_starttime_tuesday: props.context.user.deliver_starttime_tuesday || '',
            deliver_endtime_tuesday: props.context.user.deliver_endtime_tuesday || '',
            deliver_starttime_wednesday: props.context.user.deliver_starttime_wednesday || '',
            deliver_endtime_wednesday: props.context.user.deliver_endtime_wednesday || '',
            deliver_starttime_thursday: props.context.user.deliver_starttime_thursday || '',
            deliver_endtime_thursday: props.context.user.deliver_endtime_thursday || '',
            deliver_starttime_friday: props.context.user.deliver_starttime_friday || '',
            deliver_endtime_friday: props.context.user.deliver_endtime_friday || '',
            deliver_starttime_saturday: props.context.user.deliver_starttime_saturday || '',
            deliver_endtime_saturday: props.context.user.deliver_endtime_saturday || '',
            deliver_starttime_sunday: props.context.user.deliver_starttime_sunday || '',
            deliver_endtime_sunday: props.context.user.deliver_endtime_sunday || '',
            deliver_mobile_applications: props.context.user.deliver_mobile_applications || '',
            deliver_profiletext: props.context.user.deliver_profiletext || '',
            deliver_payment1: props.context.user.deliver_methods.includes("In person") || false,
            deliver_payment2: props.context.user.deliver_methods.includes("Bank transfer") || false,
            deliver_payment3: props.context.user.deliver_methods.includes("Mobile payment") || false,
            copy: {}, // copy of original data
            newpicstate: false
        };


        this.refAccount = React.createRef()
        this.refProfile = React.createRef()
        this.refWallet = React.createRef()
        this.refDelivery = React.createRef()
        this.sliderRef = React.createRef()
        this.testChanges = this.testChanges.bind(this);
        this.handlePurpose = this.handlePurpose.bind(this);
        this.handlePayment = this.handlePayment.bind(this);
        this.onCrop = this.onCrop.bind(this)
        this.onClose = this.onClose.bind(this)
        this.onBeforeFileLoad = this.onBeforeFileLoad.bind(this)

    }

    getRangeVal = () => {
        var x = ((this.state.deliver_radius-1) / 250) * (this.sliderRef.current ? this.sliderRef.current.offsetWidth : 0)
        return x
    }

    updateVal = (val) => {
        this.setState({deliver_radius: val})
    }


    handleOnClick = (event, ref) => {
        //.current is verification that your element has rendered
        if(ref.current){
            ref.current.scrollIntoView({
               behavior: "smooth",
               block: "start",
            })
        }
    }

    componentDidMount(){
        var copy = this.state
        delete copy.copy
        delete copy.newpicstate
        this.setState({
            copy: copy
        })
    }

    handlePurpose(e){
        const x = !this.state[e.target.name]
        this.setState({[e.target.name]: x})
    }

    handlePayment(e){
        const x = !this.state[e.target.name]
        this.setState({[e.target.name]: x})
    }

    geocodeAddress = async (e) => {
        var z = await geocodeByPlaceId(e.value.place_id)
        var address = ''
        const len = z[0].address_components.length
        var street = ''
        var no = ''
        var city = ''
        var country = ''
        if(Object.keys(z[0]).includes("plus_code") && z[0].plus_code.compound_code){
            var area = z[0].plus_code.compound_code.split(",")
            var city = area[0].split(" ").slice(1).join(" ")
            var country = area[1].substring(1)
        }
        var zip = ''
        for(var i = 0; i < len; i++){
            const ts = z[0].address_components[i].types
            const value = z[0].address_components[i].long_name
            if(ts.includes("street_number")){
                //address += value+", "
                no = value
            }
            else if(ts.includes("route")){
                //address += value+", "
                street = value
            }
            else if(ts.includes("postal_code")){
                zip = value
            }
            else if(!city && ts.includes("locality")){
                city = value
            }
            else if(!country && ts.includes("country")){
                country = value
            }
        }
        this.setState({
            address: e.value.description,
            street: street,
            no: no,
            city: city,
            country: country,
            zip: zip
        })
    }


    submit = async () => {
        var original = this.state
        var copy = this.state.copy
        var diff = {}
        var t = false
        if (copy != undefined){
            for (const key in original){
                if (original[key] != copy[key] && key != 'copy'){
                    diff[key] = original[key]
                    t = true
                }
            }
        }
        if(!t) return // no changes were made

        // if any location settings are changed, they should all change, to not save the house number but change city
        const location = ['street', 'no', 'city', 'country', 'zip']
        var locationflag = false
        for (const k in Object.keys(diff)){
            if(location.includes(k)){
                locationflag = true
                break
            }
        }
        if(locationflag){
            for (const l in location){
                if(!diff[l]){
                    diff[l] = ''
                }
            }
        }

         // if any delivery location settings are changed, they should all change
        const del_location = ['deliver_street', 'deliver_city', 'deliver_country', 'deliver_zip', 'deliver_location']
        var del_locationflag = false
        for (const k in Object.keys(diff)){
            if(del_location.includes(k)){
                del_locationflag = true
                break
            }
        }
        if(del_locationflag){
            for (const l in del_location){
                if(!diff[l]){
                    diff[l] = ''
                }
            }
        }

        let formData = new FormData();
        var purposeflag = false
        var methodflag = false
        const skip = ['location', 'address', 'newpicstate'] // states to skip sending

        for (const key in diff){
            if(skip.includes(key)){
                continue
            }
            if(key === 'purpose1' || key === 'purpose2' || key === 'purpose3'){
                purposeflag = true
                continue
            } else if(key === 'deliver_payment1' || key === 'deliver_payment2' || key === 'deliver_payment3'){
                methodflag = true
                continue
            }

            if(key === 'deliver_mobile_applications' && !this.state.deliver_payment3){ // no reason to update mobile applications if not handling mobile payments
                continue
            }

            if(key === 'upavatar'){
                formData.append('avatar', constants.dataURLtoFile(this.state.upavatar, 'avatar.png'))
                continue
            }

            formData.append(key, diff[key])
        }
        if(purposeflag){
            var purposes = [this.state.purpose1, this.state.purpose2, this.state.purpose3];
            const truepurposes = purposes.reduce((out, bool, index) => bool ? out.concat(index) : out, []);
            formData.append("dafnir_purposes", JSON.stringify(truepurposes))
        }
        if(methodflag){
            var payments = [];
            if(this.state.deliver_payment1){payments.push("In person")}
            if(this.state.deliver_payment2){payments.push("Bank transfer")}
            if(this.state.deliver_payment3){payments.push("Mobile payment")}
            formData.append("deliver_methods", payments)
        }
        axios.post('/api/user/updateProfile', formData)
        .then(resp => {
            alert(resp.data.message);
            this.props.history.push('/manage/account');
            window.location.reload();
        }).catch(err => {
            console.error(err)
            alert(err.response.data.message);
        })
    }


    onClose() {
        this.setState({upavatar: null})
      }

    onCrop(preview) {
      this.setState({upavatar: preview})
    }

    onBeforeFileLoad(elem) {
        if(elem.target.files[0].size > 5 * 1024 * 1024){ // 5 MB
          alert("File is too big!");
          elem.target.value = "";
        };
    }


    AccountSettings = () => {
        return(
            <Fragment>
                <p ref={this.refAccount} className={style['Account__container--flexcontainer-content__header']}>{lang.settings.account_settings.header_account}</p>
                <div className={style['Account__container--flexcontainer-content__container']}>
                    <form>
                        <div className={style['Account__container--flexcontainer-content__container--inputcontainer']}>
                            <p className={style['Account__container--flexcontainer-content__container--inputcontainer-label']}>{lang.settings.account_settings.sub_header}</p>
                            <div style={{position: 'relative'}}>
                                {this.props.context.user.email_verified
                                    ? <label htmlFor="emailField" className={style['Account__container--flexcontainer-content__container--inputcontainer-inlinelabelgreen']}>{lang.settings.account_settings.verified}</label>
                                    : <label htmlFor="emailField" className={style['Account__container--flexcontainer-content__container--inputcontainer-inlinelabelred']}>{lang.settings.account_settings.unverified}</label>
                                }
                                <input id="emailField" type="text" autoComplete="username" className={style['Account__container--flexcontainer-content__container--inputcontainer-input']} value={this.state.email} placeholder='john@doe.com' onChange={(e) => this.setState({email: e.target.value})}/>
                                <p className={style['Account__container--flexcontainer-content__container--inputcontainer-disclaimer']}>{lang.settings.account_settings.grey_text_trust}</p>

                            </div>
                        </div>
                        <div className={style['Account__container--flexcontainer-content__container--inputcontainer']}>
                            <p className={style['Account__container--flexcontainer-content__container--inputcontainer-label']}>{lang.settings.account_settings.password}</p>
                                <input type="password" autoComplete="new-password" placeholder='*******' className={style['Account__container--flexcontainer-content__container--inputcontainer-input']} value={this.state.password} onChange={(e) => this.setState({password: e.target.value})}/>
                        </div>
                </form>
                </div>
            </Fragment>
        )
    }

    ProfileSettings = () => {
        return(
            <Fragment>
                <p ref={this.refProfile} className={style['Account__container--flexcontainer-content__header']}>{lang.settings.account_settings.header_profile}</p>
                <div className={style['Account__container--flexcontainer-content__container']}>
                    <div className={style['Account__container--flexcontainer-content__container--inputcontainer']}>
                        <p className={style['Account__container--flexcontainer-content__container--inputcontainer-label']}>{lang.settings.profile.sub_header}</p>
                        <div className={style['Account__container--flexcontainer-content__container--inputcontainer-purposecontainer']}>
                            <div className={style['Account__container--flexcontainer-content__container--inputcontainer-purposecontainer__item']}>
                                <img src={purpose1} alt="Send money" className={style['Account__container--flexcontainer-content__container--inputcontainer-purposecontainer__item--image']}/>
                                <p className={style['Account__container--flexcontainer-content__container--inputcontainer-purposecontainer__item--header']}>{lang.settings.profile.purpose_send}</p>
                                <p className={style['Account__container--flexcontainer-content__container--inputcontainer-purposecontainer__item--text']}>{lang.settings.profile.text_send}</p>
                                <Checkbox
                                    defaultChecked={this.state.purpose1}
                                    onClick={(e) => this.handlePurpose(e)}
                                    spanstyle={{marginTop: '22px'}}
                                    name="purpose1"
                                />
                            </div>
                            <div className={style['Account__container--flexcontainer-content__container--inputcontainer-purposecontainer__item']}>
                                <img src={purpose2} alt="Receive money" className={style['Account__container--flexcontainer-content__container--inputcontainer-purposecontainer__item--image']}/>
                                <p className={style['Account__container--flexcontainer-content__container--inputcontainer-purposecontainer__item--header']}>{lang.settings.profile.purpose_receive}</p>
                                <p className={style['Account__container--flexcontainer-content__container--inputcontainer-purposecontainer__item--text']}>{lang.settings.profile.text_receive}</p>
                            <Checkbox
                                    defaultChecked={this.state.purpose2}
                                    onClick={(e) => this.handlePurpose(e)}
                                    spanstyle={{marginTop: '22px'}}
                                    name="purpose2"
                                />
                            </div>
                            <div className={style['Account__container--flexcontainer-content__container--inputcontainer-purposecontainer__item']}>
                                <img src={purpose3} alt="Choose agent" className={style['Account__container--flexcontainer-content__container--inputcontainer-purposecontainer__item--image']}/>
                                <p className={style['Account__container--flexcontainer-content__container--inputcontainer-purposecontainer__item--header']}>{lang.settings.profile.purpose_agent}</p>
                                <p className={style['Account__container--flexcontainer-content__container--inputcontainer-purposecontainer__item--text']}>{lang.settings.profile.text_agent}</p>
                            <Checkbox
                                    defaultChecked={this.state.purpose3}
                                    onClick={(e) => this.handlePurpose(e)}
                                    spanstyle={{marginTop: '22px'}}
                                    name="purpose3"
                                />
                            </div>
                        </div>
                    </div>
                    <div className={style['Account__container--flexcontainer-content__container--line']}/>
                    <div className={style['Account__container--flexcontainer-content__container--inputcontainer']}>
                        <p className={style['Account__container--flexcontainer-content__container--inputcontainer-label']}>{lang.settings.profile.sub_header_pic}</p>
                        <div className={style['Account__container--flexcontainer-content__container--inputcontainer-avatarcontainer']}>
                        {this.state.newpicstate
                            ?
                                <div className={style['Account__container--flexcontainer-content__container--inputcontainer-avatarcontainer__editorcontainer']}>
                                    <Avatar
                                              imageWidth={constants.avatarconf.width}
                                              imageHeight={constants.avatarconf.height}
                                              width={constants.avatarconf.editorWidth}
                                              height={constants.avatarconf.editorHeight}
                                              onCrop={this.onCrop}
                                              onClose={this.onClose}
                                              onBeforeFileLoad={this.onBeforeFileLoad}
                                              src={null}
                                        />
                                    {this.state.upavatar && (
                                        <div className={style['Account__container--flexcontainer-content__container--inputcontainer-avatarcontainer__editorcontainer--previewcontainer']}>
                                            <img src={this.state.upavatar} alt="Preview" className={style['Account__container--flexcontainer-content__container--inputcontainer-avatarcontainer__editorcontainer--previewcontainer-image']}/>
                                        </div>
                                    )}
                                </div>
                            :
                                <Fragment>
                                    <img src={this.state.upavatar} alt="Avatar" className={style['Account__container--flexcontainer-content__container--inputcontainer-avatarcontainer__avatar']} />
                                    <input type="button" onClick={() => this.setState({newpicstate: true})} value={lang.settings.profile.pic_change} className={style['Account__container--flexcontainer-content__container--inputcontainer-avatarcontainer__button']}/>
                                </Fragment>
                        }
                        </div>
                    </div>
                    <div className={style['Account__container--flexcontainer-content__container--inputcontainer']}>
                        <p className={style['Account__container--flexcontainer-content__container--inputcontainer-label']}>{lang.settings.profile.sub_header_first_name}</p>
                        <input type="text" disabled className={`${style['Account__container--flexcontainer-content__container--inputcontainer-input']} ${style['disabled']}`} value={this.state.firstname} placeholder='John' onChange={(e) => this.setState({firstname: e.target.value})}/>
                    </div>
                    <div className={style['Account__container--flexcontainer-content__container--inputcontainer']}>
                        <p className={style['Account__container--flexcontainer-content__container--inputcontainer-label']}>{lang.settings.profile.sub_header_last_name}</p>
                        <input type="text" disabled className={`${style['Account__container--flexcontainer-content__container--inputcontainer-input']} ${style['disabled']}`} value={this.state.lastnames} placeholder='Smith Doe' onChange={(e) => this.setState({lastnames: e.target.value})}/>
                        <p className={style['Account__container--flexcontainer-content__container--inputcontainer-disclaimer']}>{lang.settings.profile.grey_text_trust_name}</p>
                    </div>
                    <div className={style['Account__container--flexcontainer-content__container--searchcontainer']}>
                        <p className={style['Account__container--flexcontainer-content__container--searchcontainer-label']}>{lang.settings.profile.sub_header_address}</p>
                        <GooglePlacesAutocomplete
                            debounce={500}
                            selectProps={
                                {
                                    placeholder: this.state.address || lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.filler_address,
                                    onChange: (e) => this.geocodeAddress(e),
                                }
                            }
                        />
                        <p className={style['Account__container--flexcontainer-content__container--inputcontainer-disclaimer']}>{lang.settings.profile.grey_text_trust_address}</p>
                    </div>
                    <div className={style['Account__container--flexcontainer-content__container--inputcontainer']}>
                        <p className={style['Account__container--flexcontainer-content__container--inputcontainer-label']}>{lang.settings.profile.sub_header_phone}</p>
                        <select value={this.state.country_code} className={style['Account__container--flexcontainer-content__container--inputcontainer-phonecc']} onChange={(e) => this.setState({country_code: e.target.value})}>
                            {[...new Set(constants.countries.map(c => c.code))].sort(function(a,b){return a-b}).map((c,i) => <option key={i} value={c}>{`+${c}`}</option>)}
                        </select>
                        <input type="text" className={style['Account__container--flexcontainer-content__container--inputcontainer-phonenumber']} value={this.state.phonenumber} placeholder='12345678' onChange={(e) => this.setState({phonenumber: e.target.value})}/>
                        <p className={style['Account__container--flexcontainer-content__container--inputcontainer-disclaimer']}>{lang.settings.profile.grey_text_trust_phone}</p>
                    </div>
                </div>
            </Fragment>
        )
    }

    WalletSettings = () => {
        return(
            <Fragment>
                <p ref={this.refWallet} className={style['Account__container--flexcontainer-content__header']}>{lang.settings.wallet_settings.header}</p>
                <div className={style['Account__container--flexcontainer-content__container']}>
                    <div className={style['Account__container--flexcontainer-content__container--walletcontainer']}>
                        <div className={style['Account__container--flexcontainer-content__container--walletcontainer-left']}>
                            <img src={wallet} alt="Wallet" className={style['Account__container--flexcontainer-content__container--walletcontainer-left__icon']}/>
                            <div className={style['Account__container--flexcontainer-content__container--walletcontainer-left__container']}>
                                <p className={style['Account__container--flexcontainer-content__container--walletcontainer-left__container--header']}>{lang.settings.wallet_settings.sub_header}</p>
                                <input type="text" onChange={(e) => this.setState({ether_address: e.target.value})} className={style['Account__container--flexcontainer-content__container--walletcontainer-left__container--body']} value={this.state.ether_address}/>
                            </div>
                        </div>
{/*                        <div className={style['Account__container--flexcontainer-content__container--walletcontainer-right']}>
                        </div>*/}
                    </div>
                </div>
            </Fragment>
        )
    }

    // can't have fee percantages less than _min_% or greater than _max_%
    setFee = (e) => {
        const val = e.target.value
        if(val === ''){
            this.setState({deliver_exchangefee: ''})
        }
        else if(val < constants.min_fee){
            this.setState({deliver_exchangefee: constants.min_fee})
        }
        else if(val > (constants.max_fee_num)){
            this.setState({deliver_exchangefee: (constants.max_fee_num)})
        }
        else{
            this.setState({deliver_exchangefee: val})
        }
    }

    testChanges = () => {
        var original = this.state
        var copy = this.state.copy
        var t = false
        var skip = ['newpicstate']
        if (copy != undefined){
            for (const key in original){
                if(skip.includes(key)){
                    continue
                }
                if (original[key] != copy[key] && key != 'copy'){
                    t = true
                    break
                }
            }
        }
        return t
    }





    geocodeDeliver = async (e) => {
        var z = await geocodeByPlaceId(e.value.place_id)
        const len = z[0].address_components.length
        var street = ''
        var city = ''
        var country = ''
        if(Object.keys(z[0]).includes("plus_code") && z[0].plus_code.compound_code){
            var area = z[0].plus_code.compound_code.split(",")
            var city = area[0].split(" ").slice(1).join(" ")
            var country = area[1].substring(1)
        }
        var zip = ''
        for(var i = 0; i < len; i++){
            const ts = z[0].address_components[i].types
            const value = z[0].address_components[i].long_name
            if(ts.includes("route")){
                street = value
            }
            else if(ts.includes("postal_code")){
                zip = value
            }
            else if(!city && ts.includes("locality")){
                city = value
            }
            else if(!country && ts.includes("country")){
                country = value
            }
        }
        const lat = await z[0].geometry.location.lat()
        const long = await z[0].geometry.location.lng()
        this.setState({
            location: e.value.description,
            deliver_street: street,
            deliver_city: city,
            deliver_country: country,
            deliver_zip: zip,
            deliver_location: [lat, long]
        })
    }



    DeliverySettings = () => {
        return(
            <Fragment>
                <p ref={this.refDelivery} className={style['Account__container--flexcontainer-content__header']}>{lang.settings.delivery_settings.header}</p>
                <div className={style['Account__container--flexcontainer-content__container']}>
                    <div className={style['Account__container--flexcontainer-content__container--inputcontainer']}>
                        <p className={style['Account__container--flexcontainer-content__container--inputcontainer-label']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.how_will_you}</p>
                        {/* Payment boxes */}
                        <div className={style['Account__container--flexcontainer-content__container--inputcontainer-purposecontainer']}>
                            {/* In person */}
                            <div className={style['Account__container--flexcontainer-content__container--inputcontainer-purposecontainer__item']}>
                                <div className={style['Account__container--flexcontainer-content__container--inputcontainer-purposecontainer__item--imagecontainer']}>
                                    <img src={inPerson} alt="Send money" className={style['Account__container--flexcontainer-content__container--inputcontainer-purposecontainer__item--imagecontainer-image']}/>
                                </div>
                                <p className={style['Account__container--flexcontainer-content__container--inputcontainer-purposecontainer__item--header']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.deliver_cash_header}</p>
                                <p className={style['Account__container--flexcontainer-content__container--inputcontainer-purposecontainer__item--text']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.deliver_cash}</p>
                                <Checkbox
                                    spanstyle={{marginTop: '22px'}}
                                    defaultChecked={this.state.deliver_payment1}
                                    onClick={(e) => this.handlePayment(e)}
                                    name="deliver_payment1"
                                />
                            </div>

                            {/*Bank transfer*/}
                            <div className={style['Account__container--flexcontainer-content__container--inputcontainer-purposecontainer__item']}>
                                <div className={style['Account__container--flexcontainer-content__container--inputcontainer-purposecontainer__item--imagecontainer']}>
                                    <img src={bankTransfer} alt="Receive money" className={style['Account__container--flexcontainer-content__container--inputcontainer-purposecontainer__item--imagecontainer-image']}/>
                                </div>
                                <p className={style['Account__container--flexcontainer-content__container--inputcontainer-purposecontainer__item--header']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.deliver_bank_header}</p>
                                <p className={style['Account__container--flexcontainer-content__container--inputcontainer-purposecontainer__item--text']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.deliver_bank}</p>
                                <Checkbox
                                    spanstyle={{marginTop: '22px'}}
                                    defaultChecked={this.state.deliver_payment2}
                                    onClick={(e) => this.handlePayment(e)}
                                    name="deliver_payment2"
                                />
                            </div>

                            {/* Mobile payment */}
                            <div className={style['Account__container--flexcontainer-content__container--inputcontainer-purposecontainer__item']}>
                                <div className={style['Account__container--flexcontainer-content__container--inputcontainer-purposecontainer__item--imagecontainer']}>
                                    <img src={mobilePayment} alt="Agent" className={style['Account__container--flexcontainer-content__container--inputcontainer-purposecontainer__item--imagecontainer-image']}/>
                                </div>
                                <p className={style['Account__container--flexcontainer-content__container--inputcontainer-purposecontainer__item--header']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.deliver_mobile_header}</p>
                                <p className={style['Account__container--flexcontainer-content__container--inputcontainer-purposecontainer__item--text']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.deliver_mobile}</p>
                                <Checkbox
                                    spanstyle={{marginTop: '22px'}}
                                    defaultChecked={this.state.deliver_payment3}
                                    onClick={(e) => this.handlePayment(e)}
                                    name="deliver_payment3"
                                />
                            </div>
                        </div>
                    </div>

                    { this.state.deliver_payment3 && (
                        <Fragment>
                            {/* Preferred mobile payment solutions */}
                            <div className={style['Account__container--flexcontainer-content__container--inputcontainer2']}>
                                <p className={style['Account__container--flexcontainer-content__container--inputcontainer2-label']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.preferred_header}</p>
                                <input type="text" className={`${style['Account__container--flexcontainer-content__container--inputcontainer2-input']}`} value={typeof(this.state.deliver_mobile_applications) === 'object' ? this.state.deliver_mobile_applications.join(", ") : this.state.deliver_mobile_applications} placeholder={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.preferred_filler} onChange={(e) => this.setState({deliver_mobile_applications: e.target.value})}/>
                                <p className={style['Account__container--flexcontainer-content__container--inputcontainer2-disclaimer']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.preferred_subtext}</p>
                            </div>
                        </Fragment>
                    )}
                    <div className={style['Account__container--flexcontainer-content__container--inputcontainer3']}>
                        <p className={style['Account__container--flexcontainer-content__container--inputcontainer3-label']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.profile_header}</p>
                        <textarea type="text" onChange={e => this.setState({deliver_profiletext: e.target.value})} value={this.state.deliver_profiletext} placeholder={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.profile_filler} className={style['Account__container--flexcontainer-content__container--inputcontainer3-textarea']}/>
                    </div>

                    <p className={style['Account__container--flexcontainer-content__container--delivercontainer-header']}>{lang.settings.delivery_settings.sub_header_where}</p>
                    <div className={style['Account__container--flexcontainer-content__container--flexcontainer']}>
                        <div className={style['Account__container--flexcontainer-content__container--flexcontainer-left']}>
                            <div className={style['Account__container--flexcontainer-content__container--flexcontainer-left__searchcontainer']}>
                                <Zoom width="17.49px" height="17.53px" viewBox="0 0 17.5 17.5" className={style['Account__container--flexcontainer-content__container--flexcontainer-left__searchcontainer--inline']}/>
                                <GooglePlacesAutocomplete
                                    debounce={500}
                                    selectProps={
                                        {
                                            placeholder: this.state.location || lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.filler_address,
                                            onChange: (e) => this.geocodeDeliver(e),
                                        }
                                    }
                                />
                            </div>
                            {this.state.deliver_payment1 && (
                                <div className={style['Account__container--flexcontainer-content__container--flexcontainer-left__inputcontainer']}>
                                    <div className={style['Account__container--flexcontainer-content__container--flexcontainer-left__inputcontainer--flexcontainer']}>
                                        <p className={style['Account__container--flexcontainer-content__container--flexcontainer-left__inputcontainer--flexcontainer-header']}>{lang.settings.delivery_settings.sub_header_where_radius}</p>
                                        <p className={style['Account__container--flexcontainer-content__container--flexcontainer-left__inputcontainer--flexcontainer-header']}>{this.state.deliver_radius} km</p>
                                     </div>
                                    <div ref={this.sliderRef} className={style['Account__container--flexcontainer-content__container--flexcontainer-left__inputcontainer--slidecontainer']}>
                                        <input type="range" min="1" max="250" value={this.state.deliver_radius} onChange={(e) => this.updateVal(e.target.value)} className={style['Account__container--flexcontainer-content__container--flexcontainer-left__inputcontainer--slidecontainer-slider']} />
                                        <div className={style['Account__container--flexcontainer-content__container--flexcontainer-left__inputcontainer--slidecontainer-trail']} style={{width: `${this.getRangeVal()}px`}} />
                                    </div>
                                </div>
                            )}
                        </div>
{/*                        <div className={style['Account__container--flexcontainer-content__container--flexcontainer-right']}>
                            <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcROXqKYm0xxjikUnXsfSCCvHYG4QIpb__16KXc5nCXjw8EFezHc"} alt="map" className={style['Account__container--flexcontainer-content__container--flexcontainer-right__map']}/>
                        </div>*/}
                    </div>

                    <div className={style['Account__container--flexcontainer-content__container--delivercontainer']}>
                        <p className={style['Account__container--flexcontainer-content__container--delivercontainer-header']}>{lang.settings.delivery_settings.sub_header_when}</p>
                        <div className={style['Account__container--flexcontainer-content__container--delivercontainer-container']}>
                            <table className={style['Account__container--flexcontainer-content__container--delivercontainer-container__table']}>
                                <thead>
                                    <tr>
                                        <td></td>
                                        <td>{lang.settings.delivery_settings.from}</td>
                                        <td>{lang.settings.delivery_settings.to}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{lang.settings.delivery_settings.monday}</td>
                                        <td><input type="text" value={this.state.deliver_starttime_monday} placeholder="09:00" onChange={(e) => this.setState({deliver_starttime_monday: e.target.value})}/></td>
                                        <td><input type="text" value={this.state.deliver_endtime_monday} placeholder="16:00" onChange={(e) => this.setState({deliver_endtime_monday: e.target.value})}/></td>
                                    </tr>
                                    <tr>
                                        <td>{lang.settings.delivery_settings.tuesday}</td>
                                        <td><input type="text" value={this.state.deliver_starttime_tuesday} placeholder="09:00" onChange={(e) => this.setState({deliver_starttime_tuesday: e.target.value})}/></td>
                                        <td><input type="text" value={this.state.deliver_endtime_tuesday} placeholder="16:00" onChange={(e) => this.setState({deliver_endtime_tuesday: e.target.value})}/></td>
                                    </tr>
                                    <tr>
                                        <td>{lang.settings.delivery_settings.wednesday}</td>
                                        <td><input type="text" value={this.state.deliver_starttime_wednesday} placeholder="09:00" onChange={(e) => this.setState({deliver_starttime_wednesday: e.target.value})}/></td>
                                        <td><input type="text" value={this.state.deliver_endtime_wednesday} placeholder="16:00" onChange={(e) => this.setState({deliver_endtime_wednesday: e.target.value})}/></td>
                                    </tr>
                                    <tr>
                                        <td>{lang.settings.delivery_settings.thursday}</td>
                                        <td><input type="text" value={this.state.deliver_starttime_thursday} placeholder="09:00" onChange={(e) => this.setState({deliver_starttime_thursday: e.target.value})}/></td>
                                        <td><input type="text" value={this.state.deliver_endtime_thursday} placeholder="16:00" onChange={(e) => this.setState({deliver_endtime_thursday: e.target.value})}/></td>
                                    </tr>
                                    <tr>
                                        <td>{lang.settings.delivery_settings.friday}</td>
                                        <td><input type="text" value={this.state.deliver_starttime_friday} placeholder="09:00" onChange={(e) => this.setState({deliver_starttime_friday: e.target.value})}/></td>
                                        <td><input type="text" value={this.state.deliver_endtime_friday} placeholder="16:00" onChange={(e) => this.setState({deliver_endtime_friday: e.target.value})}/></td>
                                    </tr>
                                    <tr>
                                        <td>{lang.settings.delivery_settings.saturday}</td>
                                        <td><input type="text" value={this.state.deliver_starttime_saturday} placeholder="09:00" onChange={(e) => this.setState({deliver_starttime_saturday: e.target.value})}/></td>
                                        <td><input type="   text" value={this.state.deliver_endtime_saturday} placeholder="16:00" onChange={(e) => this.setState({deliver_endtime_saturday: e.target.value})}/></td>
                                    </tr>
                                    <tr>
                                        <td>{lang.settings.delivery_settings.sunday}</td>
                                        <td><input type="text" value={this.state.deliver_starttime_sunday} placeholder="09:00" onChange={(e) => this.setState({deliver_starttime_sunday: e.target.value})}/></td>
                                        <td><input type="text" value={this.state.deliver_endtime_sunday} placeholder="16:00" onChange={(e) => this.setState({deliver_endtime_sunday: e.target.value})}/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>


                    <div className={style['Account__container--flexcontainer-content__container--currencycontainer']}>
                        <div>
                            <p className={style['Account__container--flexcontainer-content__container--currencycontainer-label']}>{lang.settings.delivery_settings.currency}</p>
                            <select value={this.state.deliver_currency} className={style['Account__container--flexcontainer-content__container--currencycontainer-input']} onChange={(e) => this.setState({deliver_currency: e.target.value})}>
                                <option key={0} value="0" disabled>Select Currency</option>
                                {constants.countries.map((c,i) => <option key={i} value={c.curr}>{`${c.curr}`}</option>)}
                            </select>
                        </div>
                        <div>
                            <p className={style['Account__container--flexcontainer-content__container--currencycontainer-label']}>{lang.settings.delivery_settings.minimum}</p>
                            <input type="number" className={style['Account__container--flexcontainer-content__container--currencycontainer-input']} placeholder="100" value={this.state.deliver_amount_min} onChange={(e) => this.setState({deliver_amount_min: e.target.value})}/>
                        </div>
                        <div>
                            <p className={style['Account__container--flexcontainer-content__container--currencycontainer-label']}>{lang.settings.delivery_settings.maximum}</p>
                            <input type="number" className={style['Account__container--flexcontainer-content__container--currencycontainer-input']} placeholder="2000" value={this.state.deliver_amount_max} onChange={(e) => this.setState({deliver_amount_max: e.target.value})}/>
                        </div>
                    </div>
                    <div className={style['Account__container--flexcontainer-content__container--inputcontainer']}>
                        <p className={style['Account__container--flexcontainer-content__container--inputcontainer-label']}>{lang.settings.delivery_settings.set_fee}</p>
                        <div style={{position: 'relative', width: '130px'}}>
                            <label htmlFor="feeField" className={style['Account__container--flexcontainer-content__container--inputcontainer-inlinelabel']}>%</label>
                            <input id="feeField" type="number" value={this.state.deliver_exchangefee} placeholder="2" className={style['Account__container--flexcontainer-content__container--inputcontainer-input']} style={{width: '156px'}} onChange={this.setFee} />
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

	render(){
        return(
        	<div className={style['Account']}>
                <div className={style['Account__container']}>
                    <p className={style['Account__container--header']}>{lang.settings.account_settings.master_header}</p>
                    <div className={style['Account__container--flexcontainer']}>
                        <div className={style['Account__container--flexcontainer-menu']}>
                            <ul className={style['Account__container--flexcontainer-menu__list']}>
                                <li className={style['Account__container--flexcontainer-menu__list--item']}><Link to="#" onClick={(e) => this.handleOnClick(e, this.refAccount)}>{lang.settings.account_settings.header_account}</Link></li>
                                <li className={style['Account__container--flexcontainer-menu__list--item']}><Link to="#" onClick={(e) => this.handleOnClick(e, this.refProfile)}>{lang.settings.account_settings.header_profile}</Link></li>
                                {this.props.context.user.complete && (
                                    <Fragment>
                                        <li className={style['Account__container--flexcontainer-menu__list--item']}><Link to="#" onClick={(e) => this.handleOnClick(e, this.refWallet)}>{lang.settings.account_settings.header_wallet}</Link></li>
                                        {(Object.keys(this.props.context.user).length > 0 && this.props.context.user.dafnir_purposes.includes(2)) && (
                                            <li className={style['Account__container--flexcontainer-menu__list--item']}><Link to="#" onClick={(e) => this.handleOnClick(e, this.refDelivery)}>{lang.settings.account_settings.header_delivery}</Link></li>
                                        )}
                                    </Fragment>
                                )}
                            </ul>
                            <input type="button" onClick={() => this.submit()} className={`${this.testChanges() ? style['Account__container--flexcontainer-menu__button'] : style['Account__container--flexcontainer-menu__button']+' '+style['disabled']}`} value={lang.settings.account_settings.save_button}/>
                        </div>
                        <div className={style['Account__container--flexcontainer-content']}>
                            <this.AccountSettings/>
                            <this.ProfileSettings/>
                                {this.props.context.user.complete && (
                                    <Fragment>
                                        <this.WalletSettings/>
                                        {this.props.context.isLoggedIn &&
                                            this.state.purpose3 && (<this.DeliverySettings/>)
                                        }
                                    </Fragment>
                                )}
                        </div>
                    </div>
                </div>
        	</div>
        )
    }
}

export default React.forwardRef((props, ref) => (
  <UserContext.Consumer>
    {(user, setStatus, setUser, isLoggedIn) => <ManageAccount {...props} context={user} isLoggedIn={isLoggedIn} ref={ref} />}
  </UserContext.Consumer>
));