import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class NotFound extends Component {
    render(){
        return(
        	<div style={{margin: 'auto', marginTop: '20px', textAlign: 'center', maxWidth: '700px', backgroundColor: 'white', borderRadius: '6px', padding: '20px'}}>
				<h2 style={{fontSize: '18px'}}>Seems like you're lost, traveller. <Link to="/" style={{color: '#FF4057'}}>Click here</Link> to get back on the road</h2>
        	</div>
     		)
    }
}
export default NotFound;
