import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AdminNav from '../AdminNav/AdminNav';
import BN from 'bignumber.js';
import style from '../../Admin.module.scss';
import { constants } from 'consts/utils';
import Checkbox from 'components/Forms/Checkbox/Checkbox';
import iconZoom from 'img/icons/icon-zoom.png';
import purpose1 from 'img/illustrations/fly-wallet.png'
import purpose2 from 'img/illustrations/choose-agent.png'
import purpose3 from 'img/illustrations/hand-over.png';
import Modal from 'react-modal';

function GetRole(props){
    var items = []
    for (const p of props.purposes){
        switch(p){
            case 0:
                items.push(<p key={p} className={`${style['User__container--column-header']}`}>Sending money</p>)
                break;
            case 1:
                items.push(<p key={p} className={`${style['User__container--column-header']}`}>Receiving money</p>)
                break;
            case 2:
                items.push(<p key={p} className={`${style['User__container--column-header']}`}>Delivering money</p>)
                break;
        }
    }
    return items
}


class Users extends Component {
    constructor(){
        super()
        this.state = {
            users: [],
            verified: 'no',
            complete: 'yes',
            search: '',
            filter_sender: true,
            filter_recipient: true,
            filter_agent: true,
            from: '',
            to: '',
            jurisdiction: '',
            beneficiary: '',
            nationality: '',
            idModal: false,
            idpic: null

        }
    }
    componentDidMount(){
        axios.get('/api/admin/users')
        .then(response => {
            for (var user of response.data){
                user.unfolded = false
            }
            this.setState({ users: response.data })
        })
        .catch(err => {
        })
    }


    toggleUser = async e => {
        var copy = this.state.users
        var user = copy[e.target.getAttribute("data-index")]
        user.unfolded = !user.unfolded
        this.setState({users: copy})
    }

    isValidDate = (d) => {
      return d instanceof Date && !isNaN(d);
    }

    formatCreationDate = (date) => {
        var d = new Date(date)
        if(!this.isValidDate(d)){
            return ""
        }
        return d.getDate() + "-" + (d.getMonth()+1) + "-" + d.getFullYear()
    }

    setDate = (strdate, state) => {
        var x = new Date(strdate)
        if(!this.isValidDate(x)){
            alert("Not a valid date")
            return strdate
        } else{
            this.setState({[state]: strdate})
        }
    }


    setComment = (e, comment, userid) => {
        e.preventDefault()
        if(!comment || !userid){
            alert("No comment could be found or the user id could not be found")
            return
        }
        axios.post('/api/admin/set_comment/', {id: userid, comment: comment})
        .then(resp => {
            alert(resp.data.message);
        }).catch(err => {
            if(err.response && err.response.data && err.response.data.message){
                alert(err.response.data.message);
            } else{
                alert(err)
                console.error(err)
            }
        })
    }

    verify_user(e, id){
        axios.post("/api/admin/verify_user", {id: id})
        .then(resp => {
            const arrayCopy = this.state.users.filter((u) => u._id !== id);
            this.setState({users: arrayCopy})
        })
        .catch(err => {
            if(err.response && err.response.data && err.response.data.message){
                alert(err.response.data.message);
            } else{
                alert(err)
                console.error(err)
            }
        })
    }

    getSending = (u) => {
        var items = []
        var ks = Object.keys(u)
        for (const k of ks){
            var kks = Object.keys(u[k])
            for (const kk of kks){
                const val = u[k][kk]
                items.push(<p key={kk} className={`${style['User__container--column-header']}`}>{new BN(val).toFixed(2)} {k} to {kk}</p>)
            }
        }
        return items
    }
    getReceiving = (u) => {
        var items = []
        var ks = Object.keys(u)
        for (const k of ks){
            var kks = Object.keys(u[k])
            for (const kk of kks){
                const val = u[k][kk]
                items.push(<p key={kk} className={`${style['User__container--column-header']}`}>{new BN(val).toFixed(2)} {kk} from {k}</p>)
            }
        }
        return items
    }

    getDelivering = (u) => {
        var items = []
        var ks = Object.keys(u)
        for (const k of ks){
            var kks = Object.keys(u[k])
            for (const kk of kks){
                const val = u[k][kk]
                items.push(<p key={kk} className={`${style['User__container--column-header']}`}>{new BN(val).toFixed(2)} {kk} to {k}</p>)
            }
        }
        return items
    }

    setRisk = (usind, risk) => {
        var users = this.state.users
        var user = users[usind]
        if(!usind || !risk){
            alert("No risk was specified or the user could not be found")
            return
        }
        axios.post('/api/admin/set_risk/', {id: user._id, risk: risk})
        .then(resp => {
            user.risk = risk
            users[usind] = user
            this.setState({users: users})
            alert(resp.data.message)
        }).catch(err => {
            if(err.response && err.response.data && err.response.data.message){
                alert(err.response.data.message)
            } else{
                alert(err)
                console.error(err)
            }
        })
    }

    getBeneficiary = (bs) => {
        var items = []
        for(const b of bs){
            items.push(<p key={b} className={`${style['User__container--column-header']}`}>{b}</p>)
        }
        return items
    }

    viewIdModal = (id) => {
        if(id){
            this.setState({idModal: true, idpic: id})
        } else{
            alert("No id was found")
        }
    }

    setNationality = (user, e) => {
        e.preventDefault()
        if(!user.nationality || !user._id){
            alert("Didn't specify a nationality, or the user id could not be found")
            return
        }
        axios.post('/api/admin/set_nationality/', {id: user._id, nationality: user.nationality})
        .then(resp => {
            alert(resp.data.message)
        }).catch(err => {
            if(err.response && err.response.data && err.response.data.message){
                alert(err.response.data.message)
            } else{
                alert(err)
                console.error(err)
            }
        })
    }

    MakeUsers = () => {
        var items = []

        var userCount = 0
        for(var i = 0; i < this.state.users.length; i++){
            const user = this.state.users[i]

            if(
                (this.state.verified === 'yes' && !user.verified) ||
                (this.state.verified === 'no' && user.verified)
            ){
                continue
            }

            if(
                (this.state.complete === 'yes' && !user.complete) ||
                (this.state.complete === 'no' && user.complete)
            ){
                continue
            }

            // Date filter
            var user_created = new Date(user.createdAt)
            if(this.state.from){
                var search_created = new Date(this.state.from)
                if(user_created <= search_created.getTime()){
                    continue
                }
            }

            if(this.state.to){
                var search_created = new Date(this.state.to)
                if(user_created > search_created.getTime()){
                    continue
                }
            }

            // Country filters
            if(this.state.jurisdiction){
                if(!user.jurisdiction.includes(this.state.jurisdiction)){
                    continue
                }
            }
            if(this.state.beneficiary){
                if(!user.beneficiary.includes(this.state.beneficiary)){
                    continue
                }
            }
            if(this.state.nationality){
                if(user.nationality !== this.state.nationality){
                    continue
                }
            }


            if(
                (this.state.filter_sender && user.dafnir_purposes.includes(0)) ||
                (this.state.filter_recipient && user.dafnir_purposes.includes(1)) ||
                (this.state.filter_agent && user.dafnir_purposes.includes(2))
            ){

            } else{
                if(!this.state.filter_sender && !this.state.filter_recipient && !this.state.filter_agent && user.dafnir_purposes.length == 0){
                } else{
                    continue
                }
            }

            // Search filters
            if(this.state.search){
                const search = this.state.search.toLowerCase()
                var found = false
                var ks = Object.keys(user)
                for (const k of ks){
                    if (k == 'unfolded'){
                        continue
                    }
                    var val = user[k]
                    if(val){
                        const t = typeof(val)
                        if(t == "string"){
                            var lval = val.toLowerCase()
                            if(lval.includes(search)){
                                found = true
                                break
                            }
                        } else if(t == 'object'){
                            if (Array.isArray(val)){
                                for (const v of val){
                                    const lval = v.toString().toLowerCase()
                                    if(lval.includes(search)){
                                        found = true
                                        break
                                    }
                                }
                            }
                        } else if(t == 'number'){
                            const lval = val.toString()
                            if(lval.includes(search)){
                                found = true
                                break
                            }
                        } else{
                        }
                    }
                }
                if(!found){
                    continue
                }
            }
            userCount += 1
            items.push(
                    <div key={i} className={style['orderContainer']}>
                        <Link to={`#`} data-index={i} onClick={(e) => this.toggleUser(e)} className={`${style['orderIndex']}`}>{userCount}.</Link>
                        {user.unfolded ?
                            /*Unfolded */
                            <div className={`${style['Uuser']}`}>
                                <div className={`${style['Uuser__usercontainer']}`}>
                                    <Link to={`#`} data-index={i} onClick={(e) => this.toggleUser(e)} className={`${style['Uuser__usercontainer--userid']}`}>{user._id}</Link>
                                </div>


                                <div className={`${style['Uuser__container']}`}>
                                    <p className={`${style['Uuser__container--header']}`}>First name</p>
                                    <div className={`${style['Uuser__container--subcontainer']}`}>
                                        <p className={`${style['Uuser__container--subcontainer-field']}`}>{user.firstname}</p>
                                        <input type="button" className={`${style['Uuser__container--subcontainer-button']}`} onClick={this.viewIdModal.bind(this, user.passport)} value="View ID"/>
                                    </div>
                                </div>
                                <p className={`${style['Uuser__container--label']}`}></p>


                                <div className={`${style['Uuser__container']}`}>
                                    <p className={`${style['Uuser__container--header']}`}>Last name</p>
                                    <div className={`${style['Uuser__container--subcontainer']}`}>
                                        <p className={`${style['Uuser__container--subcontainer-field']}`}>{user.lastnames}</p>
                                        <input type="button" className={`${style['Uuser__container--subcontainer-button']}`} onClick={this.viewIdModal.bind(this, user.passport)} value="View ID"/>
                                    </div>
                                </div>
                                <p className={`${style['Uuser__container--label']}`}></p>



                                <div className={`${style['Uuser__container']}`}>
                                    <p className={`${style['Uuser__container--header']}`}>National ID number</p>
                                    <div className={`${style['Uuser__container--subcontainer']}`}>
                                        <p className={`${style['Uuser__container--subcontainer-field']}`}>{user.ssn}</p>
                                        <input type="button" className={`${style['Uuser__container--subcontainer-button']}`} onClick={this.viewIdModal.bind(this, user.passport)} value="View ID"/>
                                    </div>
                                </div>
                                <p className={`${style['Uuser__container--label']}`}></p>

                                <form>
                                    <div className={`${style['Uuser__container']}`}>
                                        <p className={`${style['Uuser__container--header']}`}>Nationality</p>
                                        <div className={`${style['Uuser__container--subcontainer']}`}>
                                            <input type="text" className={`${style['Uuser__container--subcontainer-input']}`} onChange={(e) => user.nationality = e.target.value} defaultValue={user.nationality || ''}/>
                                            <input type="button" className={`${style['Uuser__container--subcontainer-button']}`} onClick={this.viewIdModal.bind(this, user.passport)} value="View ID"/>
                                        </div>
                                        <input type="submit" value="Save" className={`${style['Uuser__container--bottom-button']}`} onClick={this.setNationality.bind(this, user)}/>
                                    </div>
                                    <p className={`${style['Uuser__container--label']}`}></p>
                                </form>


                                <div className={`${style['Uuser__container']}`}>
                                    <p className={`${style['Uuser__container--header']}`}>Address</p>
                                    <div className={`${style['Uuser__container--subcontainer']}`}>
                                        <p className={`${style['Uuser__container--subcontainer-field']}`}>{`${user.street || ''} ${user.no ? user.no +", " : ''} ${user.zip  || ''} ${user.city ? user.city + ", " : ''} ${user.country  || ''}`}</p>
                                        <input type="button" className={`${style['Uuser__container--subcontainer-button']}`} onClick={this.viewIdModal.bind(this, user.passport)} value="View ID"/>
                                    </div>
                                </div>
                                <p className={`${style['Uuser__container--label']}`}></p>


                                <div className={`${style['Uuser__container']}`}>
                                    <p className={`${style['Uuser__container--header']}`}>Profile picture</p>
                                    <div className={`${style['Uuser__container--subcontainer2']}`}>
                                        <img src={`/api/user/avatar/${user.avatar}`} className={`${style['Uuser__container--subcontainer-profilepicture']}`}/>
                                        <input type="button" className={`${style['Uuser__container--subcontainer-button']}`} onClick={this.viewIdModal.bind(this, user.passport)} value="View ID"/>
                                    </div>
                                </div>
                                <p className={`${style['Uuser__container--label']}`}></p>



                                <div className={`${style['Uuser__container']}`}>
                                    <p className={`${style['Uuser__container--header']}`}>E-mail</p>
                                    <div className={`${style['Uuser__container--subcontainer']}`}>
                                        <p className={`${style['Uuser__container--subcontainer-field']}`}>{`${user.email || ''}`}</p>
                                    </div>
                                </div>
                                <p className={`${style['Uuser__container--label']}`}></p>



                                <div className={`${style['Uuser__container']}`}>
                                    <p className={`${style['Uuser__container--header']}`}>Phone</p>
                                    <div className={`${style['Uuser__container--subcontainer']}`}>
                                        <p className={`${style['Uuser__container--subcontainer-field']}`}>{`+${user.country_code || ''} ${user.phonenumber || ''}`}</p>
                                    </div>
                                </div>
                                <p className={`${style['Uuser__container--label']}`}></p>


                                <div className={`${style['Uuser__container']}`}>
                                    <p className={`${style['Uuser__container--header']}`}>Ether wallet address</p>
                                    <div className={`${style['Uuser__container--subcontainer']}`}>
                                        <p className={`${style['Uuser__container--subcontainer-field']}`}>{`${user.ether_address || ''}`}</p>
                                    </div>
                                </div>
                                <p className={`${style['Uuser__container--label']}`}></p>


                                <div className={`${style['Uuser__container']}`}>
                                    <p className={`${style['Uuser__container--header']}`}>Purpose</p>
                                    <div className={`${style['Uuser__container--purposecontainer']}`}>
                                        <div className={style['Uuser__container--purposecontainer-item']}>
                                            <img src={purpose1} alt="Send money" className={style['Uuser__container--purposecontainer-item__image']}/>
                                            <p className={style['Uuser__container--purposecontainer-item__header']}>{"Send money"}</p>
                                            <p className={style['Uuser__container--purposecontainer-item__text']}>{"Exchanging my Dai to cash"}</p>
                                            <Checkbox
                                                defaultChecked={user.dafnir_purposes.includes(0)}
                                                spanstyle={{marginTop: '22px', backgroundColor: 'grey'}}
                                                name="purpose1"
                                                disabled
                                            />
                                        </div>
                                        <div className={style['Uuser__container--purposecontainer-item']}>
                                            <img src={purpose2} alt="Receive money" className={style['Uuser__container--purposecontainer-item__image']}/>
                                            <p className={style['Uuser__container--purposecontainer-item__header']}>{"Receive money"}</p>
                                            <p className={style['Uuser__container--purposecontainer-item__text']}>{"Get cash delivered"}</p>
                                        <Checkbox
                                                defaultChecked={user.dafnir_purposes.includes(1)}
                                                spanstyle={{marginTop: '22px', backgroundColor: 'grey'}}
                                                name="purpose2"
                                                disabled
                                            />
                                        </div>
                                        <div className={style['Uuser__container--purposecontainer-item']}>
                                            <img src={purpose3} alt="Choose agent" className={style['Uuser__container--purposecontainer-item__image']}/>
                                            <p className={style['Uuser__container--purposecontainer-item__header']}>{"Agent"}</p>
                                            <p className={style['Uuser__container--purposecontainer-item__text']}>{"Make money delivering cash"}</p>
                                        <Checkbox
                                                defaultChecked={user.dafnir_purposes.includes(2)}
                                                spanstyle={{marginTop: '22px', backgroundColor: 'grey'}}
                                                name="purpose3"
                                                disabled
                                            />
                                        </div>

                                    </div>
                                </div>


                                <div className={`${style['Uuser__container']}`}>
                                    <p className={`${style['Uuser__container--header']}`}>Account creation date</p>
                                    <div className={`${style['Uuser__container--subcontainer']}`}>
                                        <p className={`${style['Uuser__container--subcontainer-field']}`}>{`${this.formatCreationDate(user.createdAt)}`}</p>
                                    </div>
                                </div>



                                <div className={`${style['Uuser__container']}`}>
                                    <p className={`${style['Uuser__container--header']}`}>First associated IP address</p>
                                    <div className={`${style['Uuser__container--subcontainer']}`}>
                                        <p className={`${style['Uuser__container--subcontainer-field']}`}>{`${user.ip_list.length > 0 ? user.ip_list[0] : 'No IP found'}`}</p>
                                    </div>
                                </div>


                                <div className={`${style['Uuser__container']}`}>
                                    <p className={`${style['Uuser__container--header']}`}>Latest completed transaction</p>
                                    <div className={`${style['Uuser__container--subcontainer']}`}>
                                        <p className={`${style['Uuser__container--subcontainer-field']}`}>{`${user.last_transaction_date ? this.formatCreationDate(user.last_transaction_date) : 'No transactions found'}`}</p>
                                        {user.last_transaction_id && (
                                            <input type="button" className={`${style['Uuser__container--subcontainer-button']}`} onClick={() => window.location.href=`/admin/orders/${user.last_transaction_id}`} value="View transaction"/>
                                        )}
                                    </div>
                                </div>


                                <div className={`${style['Uuser__container']}`}>
                                    <p className={`${style['Uuser__container--header']}`}>Dafnir comments about this user</p>
                                    <form>
                                        <div className={`${style['Uuser__container--subcontainer2']}`}>
                                            <textarea rows="8" cols="100"  onChange={(e) => user.comment = e.target.value} className={`${style['Uuser__container--subcontainer2-comment']}`} defaultValue={user.comment || user.dafnir_comment}/>
                                        </div>
                                        <div className={`${style['Uuser__container--bottom']}`}>
                                            {(user.dafnir_comment && user.dafnir_last_comment_editor) && (
                                                <p className={`${style['Uuser__container--bottom-label']}`}>Last edited by {user.dafnir_last_comment_editor}. {this.formatCreationDate(user.dafnir_last_comment_timestamp)}</p>
                                            )}
                                            <input type="submit" value="Save" onClick={(e) => this.setComment(e, user.comment, user._id)} className={`${style['Uuser__container--bottom-button']}`}/>
                                        </div>
                                    </form>
                                </div>

                                <div className={`${style['Uuser__container']}`}>
                                    <p className={`${style['Uuser__container--header']}`}>Customer risk</p>
                                    <div className={`${style['Uuser__container--purposecontainer']}`}>
                                        <div className={style['Uuser__container--purposecontainer-item']}>
                                            <p className={style['Uuser__container--purposecontainer-item__header']}>{"Low risk"}</p>
                                            <p className={style['Uuser__container--purposecontainer-item__text']}>{"Low due dilligence and low monitoring apply."}</p>
                                            <Checkbox
                                                onChange={this.setRisk.bind(this, i, "Low")}
                                                checked={user.risk === 'Low'}
                                                spanstyle={{marginTop: '22px'}}
                                                name="lowrisk"
                                            />
                                        </div>
                                        <div className={style['Uuser__container--purposecontainer-item']}>
                                            <p className={style['Uuser__container--purposecontainer-item__header']}>{"Normal risk"}</p>
                                            <p className={style['Uuser__container--purposecontainer-item__text']}>{"Due dilligence and transaction monitoring apply."}</p>
                                        <Checkbox
                                                onChange={this.setRisk.bind(this, i, "Normal")}
                                                checked={user.risk === 'Normal'}
                                                spanstyle={{marginTop: '22px'}}
                                                name="normalrisk"
                                            />
                                        </div>
                                        <div className={style['Uuser__container--purposecontainer-item']}>
                                            <p className={style['Uuser__container--purposecontainer-item__header']}>{"High risk"}</p>
                                            <p className={style['Uuser__container--purposecontainer-item__text']}>{"Enhanced due dilligence and enhanced transaction monitoring apply."}</p>
                                        <Checkbox
                                                key={i}
                                                onChange={this.setRisk.bind(this, i, "High")}
                                                checked={user.risk === 'High'}
                                                spanstyle={{marginTop: '22px'}}
                                                name="highrisk"
                                            />
                                        </div>

                                    </div>
                                </div>

                                <div className={`${style['Uuser__bottomcontainer']}`}>
                                    {!user.verified && (
                                        <div className={`${style['Uuser__bottomcontainer--container']}`}>
                                            <input type="button" className={`${style['Uuser__bottomcontainer--container-redbutton']}`} onClick={(e) => this.verify_user(e, user._id)} value="Verify user"/>
                                        </div>
                                    )}
                                    <div className={`${style['Uuser__bottomcontainer--container']}`}>
                                        <input type="button" className={`${style['Uuser__bottomcontainer--container-button']}`} onClick={(e) => window.location.href=`/admin/orders/${user._id}`} value="View transactions"/>
                                    </div>
                                </div>








                            </div>
                        :

                            // Folded
                            <div className={`${style['User']}`}>
                                <div className={`${style['User__container']}`}>
                                    <div className={`${style['User__container--column']}`}>
                                        <p className={`${style['User__container--column-title']}`}>Basics</p>
                                        <p className={`${style['User__container--column-header']}`}>Full name</p>
                                        {(user.firstname && user.lastnames)
                                            ? <p className={`${style['User__container--column-bodygreen']}`}>{constants.make_name(user.firstname, user.lastnames)}</p>
                                            : <p className={`${style['User__container--column-bodyred']}`}><i>Profile is not complete</i></p>
                                        }
                                        <p className={`${style['User__container--column-header']}`}>E-mail</p>
                                        {user.email
                                            ? <p className={`${style['User__container--column-bodygreen']}`}>{user.email}</p>
                                            : <p className={`${style['User__container--column-bodyred']}`}><i>Profile is not complete</i></p>
                                        }
                                    </div>
                                    <div className={`${style['User__container--column']}`}>
                                        <p className={`${style['User__container--column-title']}`}>AML/CFT risk</p>
                                        <p className={`${style['User__container--column-header']}`}>{user.risk ? user.risk + ' risk' : 'Risk not specified'}</p>
                                        <p className={`${style['User__container--column-bodygreen']}`}>{user.risk_modified_date ? `Assessed ${this.formatCreationDate(user.risk_modified_date)}` : ''}</p>
                                    </div>
                                    <div className={`${style['User__container--column']}`}>
                                        <p className={`${style['User__container--column-title']}`}>Other details</p>
                                        <p className={`${style['User__container--column-title2']}`}>Role</p>
                                        <GetRole purposes={user.dafnir_purposes}/>
                                    </div>
                                    <div className={`${style['User__container--column']}`}>
                                        <Link to={`#`} data-index={i} onClick={(e) => this.toggleUser(e)} className={`${style['User__container--column-userid']}`}>{user._id}</Link>
                                        <p className={`${style['User__container--column-title2']}`}>Jurisdiction</p>
                                        {user.jurisdiction && this.getBeneficiary(user.jurisdiction)}
                                        <p className={`${style['User__container--column-title2']}`}>Beneficiary country</p>
                                        {user.beneficiary && this.getBeneficiary(user.beneficiary)}

                                        <p className={`${style['User__container--column-title2']}`}>Sending volume 12m</p>
                                        {user.sending && this.getSending(user.sending)}

                                        <p className={`${style['User__container--column-title2']}`}>Receiving volume 12m</p>
                                        {user.receiving && this.getReceiving(user.receiving)}

                                        <p className={`${style['User__container--column-title2']}`}>Delivering volume 12m</p>
                                        {user.delivering && this.getDelivering(user.delivering)}
                                    </div>
                                </div>

                            </div>
                    }
                    </div>
            )
        }
        return items
    }


    render(){
        return(
            <Fragment>
                <Modal
                      isOpen={this.state.idModal}
                      onRequestClose={() => this.setState({idModal: false})}
                      className={`videoModal`}
                      style={{overlay: {zIndex: 10, margin: 'auto'}}}
                >
                    <img className={style['id']} src={`/api/admin/image/${this.state.idpic}`} />
                </Modal>
{/*                <Modal
                      isOpen={this.state.docModal}
                      onRequestClose={() => this.setState({docModal: false})}
                      className={`videoModal`}
                      style={{overlay: {zIndex: 10, margin: 'auto'}}}
                >
                    lmao2
                </Modal>*/}
                <AdminNav/>
                <div className={`${style['Admin']}`}>
                    <div className={`${style['Admin__container']}`}>
                        <div className={`${style['Admin__container--centercontainer']}`}>
                            <h2 className={`${style['Admin__container--header']}`}>Users</h2>
                            <div className={`${style['Admin__container--filters']}`}>
                                <div className={`${style['Admin__container--filters-fullflex']}`}>
                                    <div className={`${style['Admin__container--filters-fullflex']}`}>
                                        <div className={`${style['Admin__container--filters-fullflex__search']}`}>
                                            <span className={`${style['Admin__container--filters-fullflex__search--icon']}`}><img src={iconZoom}/></span>
                                            <input type="text" placeholder="Search" className={`${style['Admin__container--filters-fullflex__search--input']}`} value={this.state.search} onChange={(e) => this.setState({search: e.target.value})} />
                                        </div>
                                        <div className={`${style['Admin__container--filters-fullflex__statuscontainer']}`}>
                                            <div className={`${style['Admin__container--filters-fullflex__statuscontainer--item']}`}>
                                                <Checkbox
                                                    extendspanclass={`${style['Admin__container--filters-fullflex__statuscontainer--item-checkspan']}`}
                                                    extendinputclass={`${style['Admin__container--filters-fullflex__statuscontainer--item-checkinput']}`}
                                                    defaultChecked={this.state.filter_sender}
                                                    onClick={() => this.setState({filter_sender: !this.state.filter_sender})}
                                                />
                                                <p className={`${style['Admin__container--filters-fullflex__statuscontainer--item-text']}`}>Sender</p>
                                            </div>
                                            <div className={`${style['Admin__container--filters-fullflex__statuscontainer--item']}`}>
                                                <Checkbox
                                                    extendspanclass={`${style['Admin__container--filters-fullflex__statuscontainer--item-checkspan']}`}
                                                    extendinputclass={`${style['Admin__container--filters-fullflex__statuscontainer--item-checkinput']}`}
                                                    defaultChecked={this.state.filter_recipient}
                                                    onClick={() => this.setState({filter_recipient: !this.state.filter_recipient})}
                                                />
                                                <p className={`${style['Admin__container--filters-fullflex__statuscontainer--item-text']}`}>Recipient</p>
                                            </div>
                                            <div className={`${style['Admin__container--filters-fullflex__statuscontainer--item']}`}>
                                                <Checkbox
                                                    extendspanclass={`${style['Admin__container--filters-fullflex__statuscontainer--item-checkspan']}`}
                                                    extendinputclass={`${style['Admin__container--filters-fullflex__statuscontainer--item-checkinput']}`}
                                                    defaultChecked={this.state.filter_agent}
                                                    onClick={() => this.setState({filter_agent: !this.state.filter_agent})}
                                                />
                                                <p className={`${style['Admin__container--filters-fullflex__statuscontainer--item-text']}`}>Agent</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${style['Admin__container--filters-smallflex2']}`}>
                                    <p className={`${style['Admin__container--filters-smallflex2__label']}`}>Created between</p>
                                    <input type="date" placeholder="mm-dd-yyyy" className={`${style['Admin__container--filters-smallflex2__input']}`} onChange={(e) => this.setDate(e.target.value,'from')} value={this.state.from}/>
                                    <p className={`${style['Admin__container--filters-smallflex2__label2']}`}>and</p>
                                    <input type="date" placeholder="mm-dd-yyyy" className={`${style['Admin__container--filters-smallflex2__input']}`} onChange={(e) => this.setDate(e.target.value, 'to')} value={this.state.to}/>
                                </div>

                                <div className={`${style['Admin__container--filters-smallflex2']}`}>
                                    <p className={`${style['Admin__container--filters-smallflex2__label']}`}>Jurisdiction</p>
                                    <select value={this.state.jurisdiction} className={style['Admin__container--filters-smallflex2__select']} onChange={(e) => this.setState({jurisdiction: e.target.value})}>
                                        <option key={0} value="">All</option>
                                        {[...new Set(constants.countries.map(c => c.country))].sort(function(a,b){return a-b}).map((c,i) => <option key={i} value={c}>{`${c}`}</option>)}
                                    </select>
                                </div>
                                <div className={`${style['Admin__container--filters-smallflex2']}`}>
                                    <p className={`${style['Admin__container--filters-smallflex2__label']}`}>Beneficiary country</p>
                                    <select value={this.state.beneficiary} className={style['Admin__container--filters-smallflex2__select']} onChange={(e) => this.setState({beneficiary: e.target.value})}>
                                        <option key={0} value="">All</option>
                                        {[...new Set(constants.countries.map(c => c.country))].sort(function(a,b){return a-b}).map((c,i) => <option key={i} value={c}>{`${c}`}</option>)}
                                    </select>
                                </div>
                                <div className={`${style['Admin__container--filters-smallflex2']}`}>
                                    <p className={`${style['Admin__container--filters-smallflex2__label']}`}>Nationality</p>
                                    <select value={this.state.nationality} className={style['Admin__container--filters-smallflex2__select']} onChange={(e) => this.setState({nationality: e.target.value})}>
                                        <option key={0} value="">All</option>
                                        {[...new Set(constants.countries.map(c => c.country))].sort(function(a,b){return a-b}).map((c,i) => <option key={i} value={c}>{`${c}`}</option>)}
                                    </select>
                                </div>
                            </div>
{                            <ul className={`${style['Admin__container--centercontainer-filtermenu']}`}>
                                {/*<Link to="#" className={`${style['Admin__container--centercontainer-filtermenu__link']}`} onClick={() => this.setState({activemenuitem: 'failed'})}><li className={`${this.state.activemenuitem === 'failed' && style['activetransaction']}`}>Show disabled users</li></Link>*/}
                                {/*<Link to="#" className={`${style['Admin__container--centercontainer-filtermenu__link']}`} onClick={() => this.setState({activemenuitem: 'cancelled'})}><li className={`${this.state.activemenuitem === 'cancelled' && style['activetransaction']}`}>Show blocked users</li></Link>*/}
                                {this.state.complete === 'all'
                                    ? <Link to="#" className={`${style['Admin__container--centercontainer-filtermenu__link']}`} onClick={() => this.setState({complete: 'yes'})}><li>No "completed" filter</li></Link>
                                    : this.state.complete === 'yes'
                                        ? <Link to="#" className={`${style['Admin__container--centercontainer-filtermenu__link']}`} onClick={() => this.setState({complete: 'no'})}><li>Showing complete profiles</li></Link>
                                        : <Link to="#" className={`${style['Admin__container--centercontainer-filtermenu__link']}`} onClick={() => this.setState({complete: 'all'})}><li>Showing incomplete profiles</li></Link>
                                }
                                {this.state.verified === 'all'
                                    ? <Link to="#" className={`${style['Admin__container--centercontainer-filtermenu__link']}`} onClick={() => this.setState({verified: 'yes'})}><li>No "verified" filter</li></Link>
                                    : this.state.verified === 'yes'
                                        ? <Link to="#" className={`${style['Admin__container--centercontainer-filtermenu__link']}`} onClick={() => this.setState({verified: 'no'})}><li>Showing verified users</li></Link>
                                        : <Link to="#" className={`${style['Admin__container--centercontainer-filtermenu__link']}`} onClick={() => this.setState({verified: 'all'})}><li>Showing unverified users</li></Link>
                                }
                            </ul>}
                            <this.MakeUsers/>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default Users;
