import React, { Component, Fragment } from 'react';
import { constants } from 'consts/utils';
var lang = constants.getTranslations
class MakeStars extends Component {
    constructor(props){
        super(props)
    }

    Make_stars = (props) => {
        if(!props) return <Fragment>{lang.orderflow_myself_agent_details_before_accept.view_agent_details_sidebar.no_ratings}</Fragment>
        if(!props.ratings) return <Fragment>{lang.orderflow_myself_agent_details_before_accept.view_agent_details_sidebar.no_ratings}</Fragment>
        var ratings = props.ratings
        if(typeof(props.ratings) === "number"){
            ratings = [props.ratings]
        }
        var total = 0
        var len = ratings.length
        for(var i = 0; i < len; i++){
            total+=ratings[i]
        }
        const avg = total/len
        var stars = [];
        for(var i = 1; i <= 5; i++){
            if(avg >= i){
                stars.push(<span key={i} className={'star on'}></span>)
            } else{
                if(avg >= (i-0.5)){
                    stars.push(<span key={i} className={'star half'}></span>)
                } else{
                    stars.push(<span key={i} className={'star'}></span>)
                }
            }
        }
        return(
            len == 0
                ? <div style={this.props.containerstyle}>{lang.orderflow_myself_agent_details_before_accept.view_agent_details_sidebar.no_ratings}</div>
                : <div style={this.props.containerstyle}>{stars}</div>
        )
    }

    render(){
        return(
            <this.Make_stars ratings={this.props.ratings}/>
        )
    }
}

export default MakeStars;