import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import iconGuarantee from 'img/icons/Icon-guarantee.png'
import style from '../../../Onboarding.module.scss'
import { inject, observer } from 'mobx-react';
import { constants } from 'consts/utils';
var lang = constants.getTranslations

export default @inject('extendedKYCStore') @observer class Step7 extends Component{

    constructor(props){
        super(props)
    }

    next = () => {
        if(this.props.extendedKYCStore.deliver_payment3 && !this.props.extendedKYCStore.deliver_mobile_applications){
            alert("Please write at least one payment solution")
            return;
        }
        if(this.props.extendedKYCStore.deliver_profiletext){
            this.props.extendedKYCStore.step = 8
        } else{
            alert("Please write an advertisement for your service")
        }
    }

    render(){
        return(
            <Fragment>
                <div className={style['Onboarding']}>
                    <div className={style['Onboarding__container']}>
                        <p className={style['Onboarding__container--header3']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.deliver}</p>
                        {/*Søren: Denne tekst nævner både mobile payment og bank transfer, men det skal vel være forskellige tekster ud fra hvad man har valgt? Martin, jeg har gjort den generel - advertisement er nu agnostic for den valgte service. Men der mangler en besked om at tjenester skal adskilles med komma, under linjen hvor agenten skriver, ligesom denne: <p className={style['Account__container--flexcontainer-content__container--inputcontainer2-disclaimer']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.preferred_subtext}</p> Vil du tilpasse, så den virker, tak.*/}
                        <p className={style['Onboarding__container--subheader2']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.agent_settings}</p>

                        {this.props.extendedKYCStore.deliver_payment3 && (
                            <div className={style['Onboarding__container--inputcontainer']}>
                                <p className={style['Onboarding__container--inputcontainer-label']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.preferred_header}</p>
                                <input type="text" onChange={e => this.props.extendedKYCStore.deliver_mobile_applications = e.target.value} value={this.props.extendedKYCStore.deliver_mobile_applications} placeholder={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.preferred_filler} className={style['Onboarding__container--inputcontainer-input']}/>
                                <p className={style['Onboarding__container--inputcontainer-description']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.preferred_subtext}</p>
                            </div>
                        )}
                        <div className={style['Onboarding__container--inputcontainer']}>
                            <p className={style['Onboarding__container--inputcontainer-label']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.profile_header}</p>
                            <textarea type="text" onChange={e => this.props.extendedKYCStore.deliver_profiletext = e.target.value} value={this.props.extendedKYCStore.deliver_profiletext} placeholder={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.profile_filler} className={style['Onboarding__container--inputcontainer-textarea']}/>
                        </div>
                        <div className={style['Onboarding__container--buttoncontainer']}>
                            <input type="button" onClick={() => this.next()} className={style['Onboarding__container--buttoncontainer-button']} value={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.continue}/>
                            <Link to="#" onClick={() => this.props.extendedKYCStore.step = 6} className={style['Onboarding__container--buttoncontainer-link']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.previous}</Link>
                        </div>
                        <div className={style['Onboarding__container--textcontainer']}>
                            <img src={iconGuarantee} className={style['Onboarding__container--textcontainer-icon']} alt="Secure" />
                            <p className={style['Onboarding__container--textcontainer-text']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.storage}</p>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}