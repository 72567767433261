import React, { Component, Fragment } from 'react';
import { Router as Router, Route, Switch } from 'react-router-dom';
import LandingPage from './containers/LandingPage/LandingPage';
import BecomeAnAgent from './containers/BecomeAnAgent/BecomeAnAgent';
import Onboarding from './containers/Onboarding/Onboarding';
import KYC from './containers/Onboarding/KYC';
import EmailAssets from './containers/EmailAssets/EmailAssets';
import Order from './containers/Order/Order';
import MyOrders from './containers/MyOrders/MyOrders';
import SignedUp from './containers/Onboarding/SignedUp';
import MyTasks from './containers/MyTasks/MyTasks';
import PublicTasks from './containers/PublicTasks/PublicTasks';
import ManageAccount from './containers/ManageAccount/ManageAccount';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import NotFound from './components/NotFound/NotFound';
import UnauthorizedRoute from './services/unauthorizedRoute';
import AuthorizedRoute from './services/authorizedRoute';
import AdminRoute from './services/adminRoute';
import FillOrder from './containers/FillOrder/FillOrder';
import VerifyNewEmail from './containers/ConfirmEmail/VerifyNewEmail';
import ReviewRecipient from './containers/Review/Recipient.js';
import ReviewAgent from './containers/Review/Agent.js';
import ReviewResponse from './containers/Review/Response.js';
// import Support from './containers/Support/Support';
import ForgotPassword from './containers/Support/ForgotPassword/ForgotPassword';
import ResetPassword from './containers/Support/ResetPassword/ResetPassword';
import Admin from './containers/Admin/Admin';
import AdminUsers from './containers/Admin/LocalComponents/Users/Users';
import AdminOrders from './containers/Admin/LocalComponents/Orders/Orders';
import AdminStats from './containers/Admin/LocalComponents/Stats/Stats';
import AdminEmails from './containers/Admin/LocalComponents/Emails/Emails';
import ConfirmRegistration from './containers/ConfirmRegistration/ConfirmRegistration';

import { createBrowserHistory } from 'history';

const history = createBrowserHistory();


class RouterComponent extends Component {

  SmallnavRoutes(){
                return(
                    <Switch>
                      <Route path={['/orders/my', '/orders/tasks', '/orders/public', '/manage/account', '/admin']}>
                        <Fragment>
                          <Navbar style={{paddingTop: '19px', paddingBottom: '19px'}}/>
                          <Switch>
                        {/*Orders */}
                            <AuthorizedRoute exact path="/orders/my" component={MyOrders}/>
                            <AuthorizedRoute exact path="/orders/tasks" component={MyTasks}/>
                            <AuthorizedRoute exact path="/orders/public" component={PublicTasks}/>
                        {/* Account */}
                            <AuthorizedRoute exact path="/manage/account" component={ManageAccount}/>
                        {/* admin */}
                            <AdminRoute exact path="/admin" component={Admin}/>
                            <AdminRoute exact path="/admin/users" component={AdminUsers}/>
                            <AdminRoute exact path="/admin/orders/:search?" component={AdminOrders}/>
                            <AdminRoute exact path="/admin/stats" component={AdminStats}/>
                            <AdminRoute exact path="/admin/emails" component={AdminEmails}/>
                          </Switch>
                          <Footer/>
                        </Fragment>
                      </Route>
                    </Switch>
              )
  }

  BignavRoutes(){
            return(
                  <Switch>
                      <Route exact path={[
                          '/',
                          '/agent',
                          '/orders/new',
                          '/orders/token/:token',
                          '/orders/review/agent/:token',
                          '/orders/review/recipient/:token',
                          '/orders/review/respond/:token',
                          '/orders/confirm/:token',
                          '/signedup',
                          '/onboarding/:token',
                          '/kyc',
                          '/verify_new_email/:token',
                          '/support/forgotpassword',
                          '/support/resetpassword/:token',
                          ]}>
                        <Fragment>
                          <Navbar/>
                          <Switch>
                        {/* Landing pages */}
                            <Route exact path="/agent" component={BecomeAnAgent}/>
                        {/*Orders */}
                            <Route exact path="/orders/new" component={Order}/>
                            <Route exact path="/orders/token/:token" component={FillOrder}/>
                            <Route exact path="/orders/review/agent/:token" component={ReviewAgent}/>
                            <Route exact path="/orders/review/recipient/:token" component={ReviewRecipient}/>
                            <Route exact path="/orders/review/respond/:token" component={ReviewResponse}/>
                            <Route exact path="/orders/confirm/:token" component={ConfirmRegistration}/>
                        {/* Account */}
                            <UnauthorizedRoute exact path="/signedup" component={SignedUp}/>
                            <UnauthorizedRoute exact path="/onboarding/:token" component={Onboarding}/>
                            <AuthorizedRoute exact path="/kyc/" component={KYC}/>
                            <Route exact path="/verify_new_email/:token" render={VerifyNewEmail.render}/>
                        {/* Support */}
                            {/*<Route exact path="/support" render={Support.render}/>*/}
                            <UnauthorizedRoute exact path="/support/forgotpassword" component={ForgotPassword}/>
                            <UnauthorizedRoute exact path="/support/resetpassword/:token" component={ResetPassword}/>
                          </Switch>
                          <Route exact path="/" component={LandingPage}/>
                        </Fragment>
                        <Footer/>
                      </Route>
                  </Switch>
            )
  }


    render(){
        return (
            <Router history={history}>
                <Fragment>
                    <this.SmallnavRoutes />
                    <this.BignavRoutes/>
                    <Route exact path="/emailassets/:path" component={EmailAssets}/>
                </Fragment>
            </Router>
        );
    };
};

export default RouterComponent;