import React from "react";
import {UserContext} from '../services/userContext';
import { Route, Redirect } from "react-router-dom";

export default ({ component: C, props: cProps, ...rest }) =>
  <UserContext.Consumer>
    {
      ({isLoggedIn, requesting})=>
        <Route
          {...rest}
          render={props =>
            isLoggedIn
              ? <C {...props} {...cProps} />
              : requesting
                ? (
                  <center><i>Checking login status...</i></center>
                )
                : (
                  <Redirect
                    to={`/`}
                  />
                )
}
        />
    }
  </UserContext.Consumer>