import React, { Component, Fragment } from 'react';
import style from './Welcome.module.scss';
import { withRouter, Link} from 'react-router-dom';
import iconWallet from 'img/icons/icon-wallet.png'
import passport from 'img/icons/passport.png'
import iconGuarantee from 'img/icons/Icon-guarantee.png'
import headerHandover from 'img/illustrations/header-handover.png'
import { IdSidebar } from 'components/Sidebars/Sidebars';
import { HowToStoreDaiSidebar } from 'components/Sidebars/Sidebars';
import { constants } from 'consts/utils';
var lang = constants.getTranslations

class Welcome extends Component {
    constructor(props){
        super(props)
        this.state = {
            idSidebar: false,
            howToStoreDaiSidebar: false
        }
    }

    continue = () => {
        this.props.history.push('/kyc')
        this.props.handler("isWelcomeOpen")
    }

    handler = (state) => {
        this.setState({[state]: false})
    }

    render(){
        return(
            <Fragment>
            <IdSidebar handler={this.handler} isOpen={this.state.idSidebar}/>
            <HowToStoreDaiSidebar handler={this.handler} isOpen={this.state.howToStoreDaiSidebar}/>
            <div className={`${style['Welcome']}`}>
                <img src={headerHandover} alt="Header" className={`${style['Welcome__topimg']}`}/>
                <div className={`${style['Welcome__container']}`}>
                    <p className={`${style['Welcome__container--topheader']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.welcome} {this.props.name}!</p>
                    <p className={`${style['Welcome__container--subheader']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.welcome_support}</p>
                    <hr className={`${style['Welcome__container--line']}`}/>
                    <p className={`${style['Welcome__container--bodyheader']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.docs}</p>
                    <div className={`${style['Welcome__container--row']}`}>
                        <div className={`${style['Welcome__container--row-column']}`}>
                            <img src={passport} alt="Passport" className={`${style['Welcome__container--row-column__icon']}`}/>
                        </div>
                        <div className={`${style['Welcome__container--row-column']}`}>
                            <p className={`${style['Welcome__container--row-column__header']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.passport}</p>
                            <ul className={`${style['Welcome__container--row-column__list']}`}>
                                <Link to="#" onClick={() => this.setState({idSidebar: true})}><li className={style['Welcome__container--row-column__list--item']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.passport_link}</li></Link>
                            </ul>
                        </div>
                    </div>
                    <div className={`${style['Welcome__container--row']}`}>
                        <div className={`${style['Welcome__container--row-column']}`}>
                            <img src={iconWallet} alt="Ether wallet" className={`${style['Welcome__container--row-column__icon']}`}/>
                        </div>
                        <div className={`${style['Welcome__container--row-column']}`}>
                            <p className={`${style['Welcome__container--row-column__header']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.wallet}</p>
                            <ul className={`${style['Welcome__container--row-column__list']}`}>
                                <Link to="#" onClick={() => this.setState({howToStoreDaiSidebar: true})}><li className={style['Welcome__container--row-column__list--item']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.wallet_link}</li></Link>
                            </ul>
                        </div>
                    </div>
                    <hr className={`${style['Welcome__container--line']}`}/>
                    <div className={style['Welcome__container--buttoncontainer']}>
                        <input type="button" onClick={() => this.continue()} className={style['Welcome__container--buttoncontainer-button']} value={`${this.props.testAgent.dafnir_purposes.includes(2) ? lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.continue_steps1 : lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.continue_steps2}`}/>

                    </div>
                    <div className={style['Welcome__container--textcontainer']}>
                        <img src={iconGuarantee} className={style['Welcome__container--textcontainer-icon']} alt="Secure" />
                        <p className={style['Welcome__container--textcontainer-text']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.storage}</p>
                    </div>
                    <div className={style['Welcome__container--buttoncontainer']}>
                        <Link to="#" onClick={() => this.props.handler("isWelcomeOpen")} className={style['Welcome__container--buttoncontainer-link']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.later}</Link>
                    </div>
                </div>
            </div>
        </Fragment>
        )
    }
}

export default withRouter(Welcome)