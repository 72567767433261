import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import style from './ConfirmRegistration.module.scss';
import axios from 'axios';
import { ReactComponent as RoundCheckmark } from 'img/icons/icon-checkmark-round.react.svg'
import Checkbox from 'components/Forms/Checkbox/Checkbox.js';
import Spinner from 'components/Spinner/Spinner'
import { constants } from 'consts/utils';

var lang = constants.getTranslations


class ConfirmRegistration extends Component {

    constructor(){
        super()
        this.state = {
            method: '', // Mobile payment // bank
            method2: '', // Bank transfer or Mobile payment
            terms: false,
            releasing: false,
            complete: false,
            errormsg: null
        }
    }

    componentDidMount(){
        // Get info from token, and check if it exists
        axios.get('/api/order/confirmtoken', {
            params:
                {
                    token: this.props.match.params.token
                }
        }).then(resp => {
            this.setState({
                method: resp.data.method === 'Bank transfer' ? 'bank' : resp.data.mobile_application,
                method2: resp.data.method === 'Bank transfer' ? 'bank transfer' : resp.data.mobile_application
            })
        }).catch(err => {
            this.setState({errormsg: err.response.data.message})
        })
    }

    confirm = () => {
        if(this.state.releasing){
            return
        }

        this.setState({releasing: true})

        if(!this.state.terms){
            alert("You have to accept the terms before confirming the transfer")
            return
        }

        axios.post('/api/order/confirmtransfer', {token: this.props.match.params.token})
        .then(resp => {
            this.setState({complete: true, releasing: false})
        })
        .catch(err => {
            this.setState({releasing: false})
            try{
                alert(err.response.data.message)
            } catch(e){
                alert(err)
            }
        })
    }

    render(){
        return(
            <div className={`${style['Confirm']}`}>
                <div className={`${style['Confirm__container']}`}>
                    <p className={`${style['Confirm__container--header']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.confirmation_header}</p>
                    <div className={`${style['Confirm__container--line']}`}/>
                    {this.state.errormsg
                        ? <p className={style['Confirm__container--error']}><b>Error: </b>{this.state.errormsg}</p>
                        :
                            this.state.complete
                                ?
                                    <div className={`${style['Confirm__container--completecontainer']}`}>
                                        <div className={`${style['Confirm__container--completecontainer-bigiconcontainer']}`}>
                                            <RoundCheckmark width="91.86px" height="84.8px" viewBox="0 0 70 65" className={`${style['Confirm__container--completecontainer-bigiconcontainer__bigicon']}`}/>
                                        </div>
                                        <p className={`${style['Confirm__container--completecontainer-text']}`}>{`The order has been completed. Thanks for using Dafnir`}</p>
                                    </div>
                                :
                                    this.state.releasing
                                        ? <Spinner text={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.spinning}/>
                                        :
                                            <Fragment>
                                                <p className={`${style['Confirm__container--text']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.confirmation_text} {this.state.method} {lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.confirmation_text_two}</p>
                                                <div className={`${style['Confirm__container--flexcontainer']}`}>
                                                    <Checkbox
                                                        extendspanclass={style['Confirm__container--flexcontainer-checkmark']}
                                                        defaultChecked={this.state.terms}
                                                        onClick={(e) => this.setState({terms: !this.state.terms})}
                                                    />
                                                    <p className={`${style['Confirm__container--flexcontainer-text']}`}>{lang.sign_up.accept_terms} <Link to="#" className={style['link']}>{lang.sign_up.terms_of_business}</Link> {lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.for_using} {this.state.method2}.</p>
                                                </div>
                                                <div className={style['Confirm__container--buttoncontainer']}>
                                                    <input type="button" onClick={() => this.confirm()} className={style['Confirm__container--buttoncontainer-button']} value={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.confirm_button}/>
                                                </div>
                                                <div className={style['Confirm__container--warningcontainer']}>
                                                    <p className={style['Confirm__container--warningcontainer-header']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.confirm_attention}</p>
                                                    <p className={style['Confirm__container--warningcontainer-body']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.confirm_attention_text} {this.state.method} {lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.account}</p>
                                                </div>
                                            </Fragment>

                    }
                </div>
            </div>
         )
    }
}
export default ConfirmRegistration;
