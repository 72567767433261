import React, { Component, Fragment } from 'react';
import ordstyle from '../../Order.module.scss';
import style from './Step3.module.scss';
import ReactFlagsSelect from 'react-flags-select'
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import Checkbox from 'components/Forms/Checkbox/Checkbox.js';
import dai from 'img/icons/Dai/Logos/PNGs/Mark_Dai.png'
import defaultprofile from 'img/icons/defaultprofile.png';
import wallet from 'img/icons/icon-wallet.png';
import {UserContext} from 'services/userContext';
import { constants } from 'consts/utils';
import { TermsBusinessSidebar } from 'components/Sidebars/Sidebars';

var lang = constants.getTranslations

@inject('orderStore')
@observer
class Step3 extends Component {

    constructor(props){
        super(props)
        this.state = {
            termsBusinessSidebar: false
        }
    }

    handler = (state) => {
        this.setState({[state]: false})
    }

    render() {
        return (
            <Fragment>
                <TermsBusinessSidebar handler={this.handler} isOpen={this.state.termsBusinessSidebar}/>
                <div className={style['Step3']}>
                    <div className={ordstyle['Order__container']}>
                        <p className={ordstyle['Order__container--header']}>{lang.orderflow_myself_review_details_of_your_transfer.white_box.header}</p>
                        <div className={style['Step3__container']}>
                            <div className={style['Step3__container--row']}>
                                <b className={style['Step3__container--row-label']}>{lang.orderflow_myself_review_details_of_your_transfer.white_box.transfer_details.subheader}</b>
                                <a href="#" onClick={e => this.props.orderStore.step = 1} className={style['Step3__container--row-edit']}>{lang.orderflow_myself_review_details_of_your_transfer.white_box.transfer_details.edit_button}</a>
                            </div>
                            <div className={style['Step3__container--row']}>
                                <p className={style['Step3__container--row-label']+" "+style['Step3__container--row-biglabel']}>{lang.orderflow_myself_review_details_of_your_transfer.white_box.transfer_details.you_will_receive}</p>
                                <div className={style['Step3__container--row-valuecontainer']}>
                                    <ReactFlagsSelect
                                        className={'only-flag'}
                                        selected={constants.shorthand_curr_to_flag[this.props.orderStore.receive_currency]}
                                        showSelectedLabel={false}
                                        disabled={true}
                                        placeholder=""
                                        selectedSize={22}
                                        optionsSize={22}
                                     />
                                    <p className={style['Step3__container--row-valuecontainer__amount']+" "+style['Step3__container--row-valuecontainer__big']}>{parseFloat(this.props.orderStore.total_minus_fee_local).toFixed(2)}</p>
                                    <p className={style['Step3__container--row-valuecontainer__currency']}>{this.props.orderStore.receive_currency}</p>
                                </div>
                            </div>
                            <div className={style['Step3__container--row']}>
                                <p className={style['Step3__container--row-label']}>{lang.orderflow_myself_review_details_of_your_transfer.white_box.transfer_details.total_fees}</p>
                                <div className={style['Step3__container--row-valuecontainer']}>
                                    <p className={style['Step3__container--row-valuecontainer__amount']}>{this.props.orderStore.get_deposit_fee.toFixed(2)}</p>
                                    <p className={style['Step3__container--row-valuecontainer__currency']}>{this.props.orderStore.send_currency}</p>
                                </div>
                            </div>
                            <div className={style['Step3__container--row']}>
                                <p className={style['Step3__container--row-disclaimerlabel']}>{lang.orderflow_myself_review_details_of_your_transfer.white_box.transfer_details.deposit_explainer}</p>
                            </div>
                            <div className={style['Step3__container--row']}>
                                <p className={style['Step3__container--row-label']}>{lang.orderflow_myself_review_details_of_your_transfer.white_box.transfer_details.amount_converted}</p>
                                <div className={style['Step3__container--row-valuecontainer']}>
                                    <p className={style['Step3__container--row-valuecontainer__amount']}>{this.props.orderStore.total_minus_fee.toFixed(2)}</p>
                                    <p className={style['Step3__container--row-valuecontainer__currency']}>{this.props.orderStore.send_currency}</p>
                                </div>
                            </div>
                            <div className={style['Step3__container--row']}>
                                <p className={style['Step3__container--row-label']}>{lang.orderflow_myself_review_details_of_your_transfer.white_box.transfer_details.exchange_rate}</p>
                                <div className={style['Step3__container--row-valuecontainer']}>
                                    <p className={style['Step3__container--row-valuecontainer__amount']}>{this.props.orderStore.exchange_rate.toFixed(2)}</p>
                                    <p className={style['Step3__container--row-valuecontainer__currency']}>{this.props.orderStore.send_currency}</p>
                                </div>
                            </div>
                        </div>
                        <div className={style['Step3__container']}>
                            <div className={style['Step3__container--row']}>
                                <p className={style['Step3__container--row-label']+" "+style['Step3__container--row-biglabel']}>{lang.orderflow_myself_review_details_of_your_transfer.white_box.transfer_details.you_will_pay}</p>
                                <div className={style['Step3__container--row-valuecontainer']}>
                                    <img src={dai} alt="Dai" className={style['Step3__container--row-valuecontainer__dai']}/>
                                    <p className={style['Step3__container--row-valuecontainer__amount']+" "+style['Step3__container--row-valuecontainer__big']}>{parseFloat(this.props.orderStore.send_amount).toFixed(2)}</p>
                                    <p className={style['Step3__container--row-valuecontainer__currency']}>{this.props.orderStore.send_currency}</p>
                                </div>
                            </div>
                        </div>
                        <div className={style['Step3__container']}>
                            <div className={style['Step3__container--row']}>
                                <b className={style['Step3__container--row-label']}>{lang.orderflow_myself_review_details_of_your_transfer.white_box.recipient.subheader}</b>
                                <a href="#" onClick={e => this.props.orderStore.step = 2} className={style['Step3__container--row-edit']}>{lang.orderflow_myself_review_details_of_your_transfer.white_box.edit_button}</a>
                            </div>
                            <div className={style['Step3__container--row']}>
                                <div className={style['Step3__container--row-recipient']}>
                                    <img src={this.props.orderStore.recipient === 'self' ? (this.props.context.user.avatar ? '/api/user/image/avatar' : defaultprofile) : defaultprofile} className={style['Step3__container--row-recipient__img']} alt={this.props.orderStore.recipient == 'other' ? lang.orderflow_myself_review_details_of_your_transfer.white_box.recipient.who_the_recipient_is.other : lang.orderflow_myself_review_details_of_your_transfer.white_box.recipient.who_the_recipient_is.myself}/>
                                    <p className={style['Step3__container--row-recipient__text']}>{this.props.orderStore.recipient == 'other' ? this.props.orderStore.receiver_email : lang.orderflow_myself_review_details_of_your_transfer.white_box.recipient.who_the_recipient_is.myself}</p>
                                </div>
                            </div>
                        </div>
                        <div className={style['Step3__container']}>
                            <div className={style['Step3__container--row']}>
                                <b className={style['Step3__container--row-label']}>{lang.orderflow_myself_review_details_of_your_transfer.white_box.ether_wallet.subheader}</b>
                                <Link to="/manage/account" target="_blank" rel="noopener noreferrer" className={style['Step3__container--row-edit']}>{lang.orderflow_myself_review_details_of_your_transfer.white_box.edit_button}</Link>
                            </div>
                            <div className={style['Step3__container--row']}>
                                <div className={style['Step3__container--row-metamask']}>
                                    <img src={wallet} className={style['Step3__container--row-metamask__img']} alt={"MetaMask"}/>
                                    <div className={style['Step3__container--row-metamask__container']}>
                                        <p className={style['Step3__container--row-metamask__container--header']}>MetaMask</p>
                                        <p className={style['Step3__container--row-metamask__container--address']}>{this.props.orderStore.ether_address}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={style['Step3__terms']}>
                            <div className={style['Step3__terms--container']}>
                                <Checkbox
                                    defaultChecked={this.props.orderStore.terms}
                                    onClick={e => this.props.orderStore.terms = !this.props.orderStore.terms}
                                />
                                <p className={style['Step3__terms--container-text']}>{lang.orderflow_myself_review_details_of_your_transfer.white_box.confirm_terms.term_text} <Link to="#" onClick={() => this.setState({termsBusinessSidebar: true})} className={style['Step3__terms--container-text__link']}>{lang.orderflow_myself_review_details_of_your_transfer.white_box.confirm_terms.link_text}</Link></p>
                            </div>
                        </div>
                        <div className={ordstyle['Order__nextcontainer']}>
                            <input type="button" onClick={e => this.props.orderStore.terms ? this.props.orderStore.step = 4 : alert("Please accept the terms before continuing")} className={ordstyle['Order__nextcontainer--button']} value={lang.orderflow_myself_review_details_of_your_transfer.white_box.below_confirm.red_button}/>
                            <div>
                                <Link to="#" onClick={e => this.props.orderStore.recipient == 'other' ? this.props.orderStore.step = '2a' : this.props.orderStore.step = 2} className={ordstyle['Order__nextcontainer--prev']}>{lang.orderflow_myself_review_details_of_your_transfer.white_box.below_confirm.previous_step_link}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default React.forwardRef((props, ref) => (
  <UserContext.Consumer>
    {user => <Step3 {...props} context={user} ref={ref} />}
  </UserContext.Consumer>
));