import React, { Component } from 'react';
import style from './OnboardingComplete.module.scss';
import iconClock from 'img/icons/icon-clock.png'
import headerStare from 'img/illustrations/header-stare.png'
import { constants } from 'consts/utils';
var lang = constants.getTranslations

import {UserContext} from '../../services/userContext';
import defaultprofile from 'img/icons/defaultprofile.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from '@fortawesome/free-solid-svg-icons';

class OnboardingComplete extends Component {
    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className={`${style['OnboardingComplete']}`}>
                <div className={`${style['OnboardingComplete__top']}`}>
                    <img src={headerStare} alt="Header" className={`${style['OnboardingComplete__top--img']}`}/>
                    <div className={`${style['OnboardingComplete__top--avatarcontainer']}`}>
                        <img src={this.props.context.user.avatar ? '/api/user/image/avatar' : defaultprofile} alt="Avatar" className={`${style['OnboardingComplete__top--avatarcontainer-avatar']}`}/>
                        <div className={`${style['OnboardingComplete__top--avatarcontainer-checkcontainer']}`}>
                            <FontAwesomeIcon icon={faCheck} className={`${style['OnboardingComplete__top--avatarcontainer-checkcontainer__check']}`} />
                        </div>
                    </div>
                </div>
                <div className={`${style['OnboardingComplete__container']}`}>
                    <p className={`${style['OnboardingComplete__container--topheader']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.done}</p>
                    <p className={`${style['OnboardingComplete__container--subheader']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.done_support}</p>
                    <p className={`${style['OnboardingComplete__container--bodyheader']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.promise}</p>
                    <div className={`${style['OnboardingComplete__container--row']}`}>
                        <div className={`${style['OnboardingComplete__container--row-column']}`}>
                            <img src={iconClock} alt="clock" className={`${style['OnboardingComplete__container--row-column__icon']}`}/>
                        </div>
                        <div className={`${style['OnboardingComplete__container--row-column']}`}>
                            <p className={`${style['OnboardingComplete__container--row-column__header']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.hrs}</p>
                        </div>
                    </div>
                    <p className={`${style['OnboardingComplete__container--text']}`}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.when_ready}</p>
                    <div className={style['OnboardingComplete__container--buttoncontainer']}>
                        <input type="button" onClick={() => this.props.handler("isOnboardingOpen")} className={style['OnboardingComplete__container--buttoncontainer-button']} value={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.close}/>

                    </div>
                </div>
            </div>
        )
    }
}

export default React.forwardRef((props, ref) => (
  <UserContext.Consumer>
    {(user, setStatus, setUser, isLoggedIn) => <OnboardingComplete {...props} context={user} ref={ref} />}
  </UserContext.Consumer>
));