import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import Step1 from './localComponents/Onboarding/Step1/Step1';
import Step2 from './localComponents/Onboarding/Step2/Step2';
import Step3 from './localComponents/Onboarding/Step3/Step3';

export default @inject('basicKYCStore') @observer class Onboarding extends Component{

    constructor(props){
        super(props)
    }

    ShowStep = () => {
        switch (this.props.basicKYCStore.step) {
            case 1: return(<Step1 />) // Email verified
            case 2: return(<Step2 />) // First name, last name, phone, pep
            case 3: return(<Step3 />) // Purpose of dafnir
            default: return(<Step1 />)
        }
    }
    render(){
        return(
            <Fragment>
                <this.ShowStep/>
            </Fragment>
        )

    }
}