import React, { Component, Fragment } from 'react';
import axios from 'axios';
import {UserContext} from '../../services/userContext';
import { ReactComponent as Zoom } from 'img/icons/icon-zoom.react.svg'
import BN from 'bignumber.js';
import profile from 'img/icons/defaultprofile.png';
import handover from 'img/illustrations/hand-over@3x.png';
import pin from 'img/icons/icon-mappin.png';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import Checkbox from 'components/Forms/Checkbox/Checkbox.js';
import style from './PublicTasks.module.scss';
import { PublicDetailsSidebar, PublicChooseMethodSidebar } from 'components/Sidebars/Sidebars';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import transfer from 'img/icons/icon-transfer.png';
import defaultprofile from 'img/icons/defaultprofile.png';
import dai from 'img/icons/Dai/Logos/PNGs/Mark_Dai.png'
import ReactFlagsSelect from 'react-flags-select'
import MakeStars from 'components/MakeStars/MakeStars.js';
import { constants } from 'consts/utils';
var lang = constants.getTranslations

class PublicTasks extends Component {
    constructor(props, context){
        super(props);
        this.state = {
            orders: [],
            street: '',
            city: '',
            zip: '',
            country: '',
            long: '',
            lat: '',
            filter_inPerson: true,
            filter_mobilePayment: true,
            filter_bankTransfer: true,
            publicsidebar: false,
            publicsidebar2: false,
            chosen_order: {},
            chosen_index: -1
        };
        this.handler = this.handler.bind(this);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    componentDidMount(){
        if(Object.keys(this.props.context.user).length === 0){
            location.reload()
        }
    }


    fetchOrders = async () => {
        try {
            const response = await axios.get('/api/order/list/', {
                params:
                    {
                        country: this.state.country,
                        lat: this.state.lat,
                        long: this.state.long,
                        cash: this.state.filter_inPerson,
                        bank: this.state.filter_bankTransfer,
                        other: this.state.filter_mobilePayment
                    }
            })
            this.setState({ orders: response.data });
        } catch (err) {
            console.error(err)
        }
    }

    test_purpose_deliverer(user){
        if(user.dafnir_purposes){
            return user.dafnir_purposes.includes(2) && user.deliver_exchangefee != null;
        } else{
            return false
        }
    }

    updateFilter = async (filter) => {
        await this.setState({
            [filter]: !this.state[filter],
        })

        this.fetchOrders()
    }


    next(){
        this.setState({publicsidebar: false, publicsidebar2: true})
    }
    previous(){
        this.setState({publicsidebar: true, publicsidebar2: false})
    }

    handler(state){
        this.setState({[state]: false})
    }


    request = (method) => {
        axios.post('/api/order/request', {id: this.state.chosen_order.id, deliver_method: method})
        .then(resp => {
            var orders = this.state.orders
            orders[this.state.chosen_index].requested = true
            this.setState({order: orders, publicsidebar: false, publicsidebar2: false, chosen_order: {}, chosen_index: -1})
        }).catch(err => {
            try{
                alert(err.response.data.message);
            } catch(e){
                alert(err)
            }
        })
    }


    openSidebar = (order, index) => {
        this.setState({publicsidebar: true, chosen_order: order, chosen_index: index})
    }


    MakeOrders = () => {
        var items = []
        for(const [index, order] of this.state.orders.entries()){
            const send_amount = new BN(order.send.amount["$numberDecimal"]).toFixed(2)
            const receive = constants.get_agent_payment(order.receive.amount["$numberDecimal"], order.max_fee, this.props.context.user.deliver_exchangefee)
            const reward = constants.get_agent_reward(order.receive.amount["$numberDecimal"], this.props.context.user.deliver_exchangefee)
            items.push(
                <div key={index} className={`${style['Order']}`}>
                    <div className={`${style['Order__top']}`}>
                        <div key={index} className={`${style['Order__top--container']}`}>
                            <img src={order.avatar ? `/api/${order.avatar.prefix}/avatar/${order.avatar.avatar}` : defaultprofile} alt="Avatar" className={`${style['Order__top--container-avatar']}`}/>
                            <div className={`${style['Order__top--container-inner']}`}>
                                <p className={`${style['Order__top--container-inner__header']}`}>{lang.deliverflow_private_order_my_tasks.main_window.active_orders.deliver_to_fat_grey}</p>
                                <MakeStars ratings={order.ratings.map(r => r.rating)}/>
                            </div>
                        </div>
                        <div className={`${style['Order__top--container2']}`}>
                            <p className={`${style['Order__top--container2-header']}`}>{lang.deliverflow_public_order_public_tasks.main_window.cash_to_deliver_fat_grey}</p>
                            <div className={`${style['Order__top--container2-inner']}`}>
                                <ReactFlagsSelect
                                    className={`${style['Order__top--container2-inner__flag']} only-flag`}
                                    selected={constants.shorthand_curr_to_flag[order.send.currency]}
                                    showSelectedLabel={false}
                                    placeholder=""
                                    disabled={true}
                                    selectedSize={16}
                                 />
                                <p className={`${style['Order__top--container2-inner__amount']}`}>{`${send_amount}`}</p>
                                <p className={`${style['Order__top--container2-inner__currency']}`}>{`${order.send.currency}`}</p>
                            </div>
                        </div>
                        <div className={`${style['Order__top--container2']}`}>
                            <p className={`${style['Order__top--container2-header']}`}>{lang.deliverflow_public_order_public_tasks.main_window.receive_fat_grey}</p>
                            <div className={`${style['Order__top--container2-inner']}`}>
                                <img src={dai} alt="DAI" className={`${style['Order__top--container2-inner__icon']}`}/>
                                <p className={`${style['Order__top--container2-inner__amount']}`}>{`${receive}`}</p>
                                <p className={`${style['Order__top--container2-inner__currency']}`}>{`${order.receive.currency}`}</p>
                            </div>
                        </div>
                        <div className={`${style['Order__top--container2']}`}>
                            <p className={`${style['Order__top--container2-header']}`}>{lang.deliverflow_public_order_public_tasks.main_window.you_earn_fat_grey} ({this.props.context.user.deliver_exchangefee}%)</p>
                            <div className={`${style['Order__top--container2-inner']}`}>
                                <img src={dai} alt="DAI" className={`${style['Order__top--container2-inner__icon']}`}/>
                                <p className={`${style['Order__top--container2-inner__amount']}`}>{`${reward}`}</p>
                                <p className={`${style['Order__top--container2-inner__currency']}`}>{`${order.receive.currency}`}</p>
                            </div>
                        </div>
                        <div className={`${style['Order__top--container']}`}>
                            {order.requested
                                ?
                                    <div className={`${style['Order__top--container-requested']}`}>
                                        <p className={`${style['Order__top--container-requested__text']}`}>{lang.deliverflow_public_order_public_tasks.main_window.requested_white_button}</p>
                                        <FontAwesomeIcon icon={faArrowRight} className={`${style['Order__top--container-requested__arrow']}`} />
                                    </div>
                                : <input type="button" value={lang.deliverflow_public_order_public_tasks.main_window.request_red_button} onClick={() => this.openSidebar(order, index)} className={`${style['Order__top--container-button']}`}/>
                            }
                        </div>
                    </div>
                    <div className={`${style['Order__bottom']}`}>
                        <div className={`${style['Order__bottom--left']}`}>
                            <div className={`${style['Order__bottom--left-methodcontainer']}`}>
                                <img src={transfer} className={`${style['Order__bottom--left-methodcontainer__img']}`} />
                                <p className={`${style['Order__bottom--left-methodcontainer__text']}`}>{`${constants.makeMethods(order.payment_methods, order.mobile_method)}`}</p>
                            </div>
                            {order.payment_methods.includes("In person") && (
                                <div className={`${style['Order__bottom--left-container']}`}>
                                    <img src={pin} alt="map pin" className={`${style['Order__bottom--left-container__img']}`}/>
                                    <p className={`${style['Order__bottom--left-container__text']}`}>{`Near ${order.location.city}, ${order.location.country}`}</p>
                                </div>
                            )}
                        </div>

                    </div>
                </div>
            )
        }
        return (
            <Fragment>
                {items}
            </Fragment>
        )
    }

    geocodeLocation = async (e) => {
        var z = await geocodeByPlaceId(e.value.place_id)
        const len = z[0].address_components.length
        var street = ''
        var city = ''
        var country = ''
        if(Object.keys(z[0]).includes("plus_code") && z[0].plus_code.compound_code){
            var area = z[0].plus_code.compound_code.split(",")
            var city = area[0].split(" ").slice(1).join(" ")
            var country = area[1].substring(1)
        }
        var zip = ''
        const lat = await z[0].geometry.location.lat()
        const long = await z[0].geometry.location.lng()
        for(var i = 0; i < len; i++){
            const ts = z[0].address_components[i].types
            const value = z[0].address_components[i].long_name
            if(ts.includes("route")){
                street = value
            }
            else if(ts.includes("postal_code")){
                zip = value
            }
            else if(!city && ts.includes("locality")){
                city = value
            }
            else if(!country && ts.includes("country")){
                country = value
            }
        }
        this.setState({
            street: street,
            city: city,
            zip: zip,
            country: country,
            long: long,
            lat: lat
        })
        this.fetchOrders()
    }


    Emptystate = () => {
        return(
            <div className={style['EmptyState']}>
                <div className={style['EmptyState__container']}>
                    <img src={handover} alt="No agents" className={style['EmptyState__container--img']}/>
                    <p className={style['EmptyState__container--header']}>{lang.deliverflow_public_order_public_tasks.main_window.no_tasks}</p>
                    <p className={style['EmptyState__container--subheader']}>{lang.deliverflow_public_order_public_tasks.main_window.different_location}</p>
                </div>
            </div>
        )
    }

	render(){
        return(
            <Fragment>
                <PublicDetailsSidebar
                    handler={this.handler}
                    next={this.next}
                    isOpen={this.state.publicsidebar}
                    order={this.state.chosen_order}
                />
                <PublicChooseMethodSidebar
                    handler={this.handler}
                    previous={this.previous}
                    isOpen={this.state.publicsidebar2}
                    order={this.state.chosen_order}
                    request={this.request}
                />
            	<div className={style['PublicTasks']}>
                    <div className={style['PublicTasks__container']}>
                        <h1 className={style['PublicTasks__container--header']}>{lang.deliverflow_public_order_public_tasks.main_window.header}</h1>
                        {this.props.context.user.complete ?
                            <Fragment>
                            {this.props.context.user.verified ?
                                <Fragment>
                                {this.test_purpose_deliverer(this.props.context.user) ?
                                    (
                                        <Fragment>
                                            <div className={style['PublicTasks__container--settingscontainer']}>
                                                <div className={style['PublicTasks__container--settingscontainer-searchcontainer']}>
                                                    <Zoom width="17.49px" height="17.53px" viewBox="0 0 17.5 17.5" className={`${style['PublicTasks__container--settingscontainer-searchcontainer__icon']}`}/>
                                                    <GooglePlacesAutocomplete
                                                        debounce={500}
                                                        selectProps={
                                                            {
                                                                placeholder: lang.kys_kyr_kya.customer_due_diligence.kyc_1_of_2.filler_address,
                                                                onChange: (e) => this.geocodeLocation(e)
                                                            }
                                                        }
                                                    />
                                                </div>
                                                <div className={style['PublicTasks__container--settingscontainer-filtercontainer']}>
                                                    <div className={style['PublicTasks__container--settingscontainer-filtercontainer__container']}>
                                                        <Checkbox
                                                            extendinputclass={style['PublicTasks__container--settingscontainer-filtercontainer__container--checkboxinput']}
                                                            extendspanclass={style['PublicTasks__container--settingscontainer-filtercontainer__container--checkboxspan']}
                                                            defaultChecked={this.state.filter_inPerson}
                                                            onClick={() => this.updateFilter('filter_inPerson')}
                                                        />
                                                        <p className={style['PublicTasks__container--settingscontainer-filtercontainer__container--text']}>In person</p>
                                                    </div>
                                                    <div className={style['PublicTasks__container--settingscontainer-filtercontainer__container']}>
                                                        <Checkbox
                                                            extendinputclass={style['PublicTasks__container--settingscontainer-filtercontainer__container--checkboxinput']}
                                                            extendspanclass={style['PublicTasks__container--settingscontainer-filtercontainer__container--checkboxspan']}
                                                            defaultChecked={this.state.filter_bankTransfer}
                                                            onClick={() => this.updateFilter('filter_bankTransfer')}
                                                        />
                                                        <p className={style['PublicTasks__container--settingscontainer-filtercontainer__container--text']}>Bank transfer</p>
                                                    </div>
                                                    <div className={style['PublicTasks__container--settingscontainer-filtercontainer__container']}>
                                                        <Checkbox
                                                            extendinputclass={style['PublicTasks__container--settingscontainer-filtercontainer__container--checkboxinput']}
                                                            extendspanclass={style['PublicTasks__container--settingscontainer-filtercontainer__container--checkboxspan']}
                                                            defaultChecked={this.state.filter_mobilePayment}
                                                            onClick={() => this.updateFilter('filter_mobilePayment')}
                                                        />
                                                        <p className={style['PublicTasks__container--settingscontainer-filtercontainer__container--text']}>Mobile payment</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.country
                                                ?
                                                    this.state.orders.length > 0
                                                        ?
                                                            <Fragment>
                                                                <p className={style['PublicTasks__container--results']}>{this.state.orders.length} {this.state.orders.length > 1 ? lang.deliverflow_public_order_public_tasks.main_window.tasks_near : lang.deliverflow_public_order_public_tasks.main_window.task_near} '{this.state.street ? `${this.state.street}, ` : ''}{this.state.city ? `${this.state.city}, ` : ''}{this.state.country ? `${this.state.country}` : ''}'</p>
                                                                <this.MakeOrders/>
                                                            </Fragment>
                                                        : <this.Emptystate/>
                                                : <Fragment/>
                                            }
                                        </Fragment>
                                    )
                                    : <center><h2><i>{lang.deliverflow_public_order_public_tasks.main_window.purpose}</i></h2></center>

                                }
                                </Fragment>
                            : <center><h2><i>{lang.deliverflow_public_order_public_tasks.main_window.verify}</i></h2></center>
                        }
                            </Fragment>
                    : <center><h2><i>{lang.deliverflow_public_order_public_tasks.main_window.complete}</i></h2></center>
                    }

                    </div>
                </div>
            </Fragment>
        )
    }
}

export default React.forwardRef((props, ref) => (
  <UserContext.Consumer>
    {user => <PublicTasks {...props} context={user} ref={ref} />}
  </UserContext.Consumer>
));