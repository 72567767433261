import React, { Component, Fragment } from 'react';
import style from './Step1.module.scss';
import ordstyle from '../../Order.module.scss';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { constants } from 'consts/utils';
import axios from 'axios';
import ReactFlagsSelect from 'react-flags-select';
import who from 'img/icons/who.png';
import dai from 'img/icons/Dai/Logos/PNGs/Mark_Dai.png'
var lang = constants.getTranslations

@inject('orderStore')
@observer
class Step1 extends Component {
    constructor(props){
        super(props)
        this.state = {
            receive: this.props.orderStore.receive_amount || '',
            send: this.props.orderStore.send_amount || '',
        }
    }


    componentDidMount(){
        if((Object.keys(this.props.orderStore.exchange_rates).length === 0 && this.props.orderStore.exchange_rates.constructor === Object)){
            axios.get('/api/fiat/all')
            .then(
                  response => {
                      this.props.orderStore.exchange_rates = response.data
                      this.set_receive_currency("AR")
            })
            .catch(err => {
            })
        }
    }

    set_receive_currency = (country) => {
        var c = constants.currflags.find(a => a.flagname === country)
        var cc = c.curr
        this.props.orderStore.local_usd = this.props.orderStore.exchange_rates[cc]
        this.props.orderStore.receive_currency = cc
        this.props.orderStore.exchange_rate = +(this.props.orderStore.dai_usd * this.props.orderStore.local_usd)
        this.props.orderStore.receive_amount = +(this.props.orderStore.exchange_rate * (this.props.orderStore.send_amount - this.props.orderStore.get_deposit_fee))
        this.setState({receive: this.props.orderStore.receive_amount > 0 ? this.props.orderStore.receive_amount.toFixed(2) : ''})
    }

    set_send_amount = (e) => {
        if(e.target.value){
            this.props.orderStore.send_amount = parseFloat(e.target.value)
            this.props.orderStore.receive_amount = +(this.props.orderStore.send_amount * this.props.orderStore.exchange_rate)
            this.setState({receive: this.props.orderStore.total_minus_fee_local.toFixed(2), send: this.props.orderStore.send_amount})
        } else{
            this.setState({receive: "", send: ""})

        }
    }

    set_receive_amount = (e) => {
        this.props.orderStore.receive_amount = parseFloat(e.target.value)
        this.props.orderStore.send_amount = +(this.props.orderStore.receive_amount / ((1 - this.props.orderStore.deposit_fee)*this.props.orderStore.exchange_rate))
        this.setState({receive: e.target.value, send: this.props.orderStore.send_amount.toFixed(2)})
    }

    next = (e) => {
        e.preventDefault()
        if(!this.props.orderStore.send_amount){
           alert("Not a valid send amount")
           return
        }
        if(!this.props.orderStore.receive_amount){
           alert("Not a valid receive amount")
           return
        }
        if(this.props.orderStore.send_amount <= 0 || this.props.orderStore.receive_amount <= 0){
            alert('Amounts have to be greater than 0')
            return
        }
        if(this.props.orderStore.send_amount < constants.min_amount){
            alert(`The order amount has to be greater or equal to ${constants.min_amount} DAI`)
            return
        }
        this.props.orderStore.step = 2

    }

    render() {
        return(
            <div className={`${style['Step1']}`}>
                <div className={`${ordstyle['Order__container']}`}>
                    <p className={`${ordstyle['Order__container--header']}`}>{lang.orderflow_amount.white_box.header}</p>
                    <form>
                        <div className={style['Step1__container--inputcontainer']}>
                            <div className={style['Step1__container--inputcontainer-container']}>
                                <label className={style['Step1__container--inputcontainer-container__label']}>{lang.orderflow_amount.white_box.get_label}</label>
                                <input className={style['Step1__container--inputcontainer-container__input']} type='number' value={this.state.receive} onChange={(e) => this.set_receive_amount(e)} placeholder='0.00' />
                            </div>
                            <ReactFlagsSelect
                                searchable={true}
                                 className={'default-flag'}
                                 onSelect={(e) => this.set_receive_currency(e)}
                                 countries={constants.country_labels}
                                 customLabels={constants.shorthand}
                                 selected={constants.shorthand_curr_to_flag[this.props.orderStore.receive_currency]}
                                 selectedSize={22.31}
                                 optionsSize={14}
                             />
                        </div>
                        <div className={style['Step1__container--bulletcontainer']}>
                            <div className={style['Step1__container--bulletcontainer-flexcontainer']}>
                                <div>
                                    <p className={style['Step1__container--bulletcontainer-flexcontainer__amount']}>{(this.props.orderStore.get_deposit_fee - this.props.orderStore.get_matching_fee).toFixed(2)} Dai</p>
                                    <p className={style['Step1__container--bulletcontainer-flexcontainer__amount']}>{this.props.orderStore.get_matching_fee.toFixed(2)} Dai</p>
                                </div>
                                <div>
                                    <p className={style['Step1__container--bulletcontainer-flexcontainer__info']}>Max {constants.max_fee_num}% {lang.orderflow_amount.white_box.delivery_fee} ({lang.orderflow_amount.white_box.calculated_later})</p>
                                    <p className={style['Step1__container--bulletcontainer-flexcontainer__info']}>{constants.matching_fee*100}% {lang.orderflow_amount.white_box.matching_fee}</p>
                                </div>
                            </div>
                            <div className={style['Step1__container--bulletcontainer-line']}/>
                            <div className={style['Step1__container--bulletcontainer-flexcontainer']}>
                                <div>
                                    <p className={style['Step1__container--bulletcontainer-flexcontainer__amount']}>{this.props.orderStore.total_minus_fee.toFixed(2)} Dai</p>
                                    <p className={style['Step1__container--bulletcontainer-flexcontainer__amount']}>{this.props.orderStore.exchange_rate.toFixed(2)} Dai</p>
                                </div>
                                <div>
                                    <p className={style['Step1__container--bulletcontainer-flexcontainer__info']}>{lang.orderflow_amount.white_box.converted_amount}</p>
                                    <p className={style['Step1__container--bulletcontainer-flexcontainer__info']}>{lang.orderflow_amount.white_box.exchange_rate}</p>
                                </div>
                            </div>
                        </div>
                        <div className={style['Step1__container--inputcontainer']}>
                            <div className={style['Step1__container--inputcontainer-container']}>
                                <label className={style['Step1__container--inputcontainer-container__label']}>{lang.orderflow_amount.white_box.pay_label}</label>
                                <input className={style['Step1__container--inputcontainer-container__input']} type='number' value={this.state.send} onChange={(e) => this.set_send_amount(e)} placeholder='0.00' />
                            </div>
                            <div className={style['Step1__container--inputcontainer-token__container']}>
                                <img className={style['Step1__container--inputcontainer-token__container--img']} src={dai} alt='DAI'/>
                                <p className={style['Step1__container--inputcontainer-token__container--currency']}>Dai</p>
                            </div>
                        </div>
                        <div className={ordstyle['Order__nextcontainer']}>
                            <input type="submit" onClick={(e) => this.next(e)} className={ordstyle['Order__nextcontainer--button']} value={lang.orderflow_amount.white_box.continue_button}/>
                            <div>
                                <Link to="/" className={ordstyle['Order__nextcontainer--prev']}>{lang.orderflow_amount.white_box.previous_step_link}</Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
export default Step1