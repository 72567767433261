import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import iconGuarantee from 'img/icons/Icon-guarantee.png'
import style from '../../../Onboarding.module.scss'
import { constants } from 'consts/utils';
import { inject, observer } from 'mobx-react';
var lang = constants.getTranslations

export default @inject('extendedKYCStore') @observer class Step10 extends Component{

    constructor(props){
        super(props)
    }

    next = (e) => {
        e.preventDefault()
        if(!this.props.extendedKYCStore.deliver_currency){
            alert("Please choose a currency you wish to deliver")
            return;
        }
        if(!this.props.extendedKYCStore.deliver_amount_min){
            alert("Please write a minimum amount")
            return;
        }
        if(!this.props.extendedKYCStore.deliver_amount_max){
            alert("Please write a minimum amount")
            return;
        }
        this.props.extendedKYCStore.step = 11
    }

    render(){
        return(
            <Fragment>
                <div className={style['Onboarding']}>
                    <div className={style['Onboarding__container']}>
                        <p className={style['Onboarding__container--header3']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.currency}</p>
                        <p className={style['Onboarding__container--subheader']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.currency_support}</p>
                        <form>
                            <div className={style['Onboarding__container--inputcontainer']}>
                                <table className={style['Onboarding__container--inputcontainer-table']}>
                                    <thead>
                                        <tr>
                                            <td>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.choise}</td>
                                            <td>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.min}</td>
                                            <td>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.max}</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <select value={this.props.extendedKYCStore.deliver_currency} onChange={(e) => this.props.extendedKYCStore.deliver_currency = e.target.value}>
                                                    {constants.countries.map((c,i) => <option key={i} value={c.curr}>{`${c.curr}`}</option>)}
                                                </select>
                                            </td>
                                            <td><input type="text" value={this.props.extendedKYCStore.deliver_amount_min} placeholder="Min" onChange={(e) => this.props.extendedKYCStore.deliver_amount_min = parseFloat(e.target.value)}/></td>
                                            <td><input type="text" value={this.props.extendedKYCStore.deliver_amount_max} placeholder="Max" onChange={(e) => this.props.extendedKYCStore.deliver_amount_max = parseFloat(e.target.value)}/></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={style['Onboarding__container--buttoncontainer']}>
                                <input type="submit" onClick={(e) => this.next(e)} className={style['Onboarding__container--buttoncontainer-button']} value={lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.continue}/>
                                <Link to="#" onClick={() => this.props.extendedKYCStore.step = 9} className={style['Onboarding__container--buttoncontainer-link']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.previous}</Link>
                            </div>
                        </form>
                        <div className={style['Onboarding__container--textcontainer']}>
                            <img src={iconGuarantee} className={style['Onboarding__container--textcontainer-icon']} alt="Secure" />
                            <p className={style['Onboarding__container--textcontainer-text']}>{lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.storage}</p>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}