import React, { Component } from 'react';
import axios from 'axios';
import {UserContext} from 'services/userContext';
import style from './ForgotPassword.module.scss';

export default class ForgotPassword extends Component {

    constructor(){
        super();
        this.state = {
            email: ''
        };
    }

    resetPassword = (e) => {
        e.preventDefault()
        axios.post('/api/user/forgotpassword', {
            email: this.state.email
        })
        .then(async resp => {
            alert("An email with instructions has been sent to your email address")
            this.props.history.push('/')
        })
        .catch(err => {
            alert(err.response.data.message)
        })
    }

    render(){
        return(
            <div className={`${style['ForgotPassword']}`}>
                <p className={`${style['ForgotPassword__text']}`}>Forgot your password? Simply enter your email and we'll send an email to help you on your way</p>
                <div className={`${style['ForgotPassword__formcontainer']}`}>
                    <form>
                        <input type="text" value={this.state.email} onChange={(i) => this.setState({email: i.target.value})} className={`${style['ForgotPassword__formcontainer--input']}`} placeholder="Email" autoFocus />
                        <input type="submit" value={`Reset password`} onClick={this.resetPassword} className={style['ForgotPassword__formcontainer--submit']} />
                    </form>
                </div>
            </div>
        )
    }
}