import en from 'translations/en.json';
import es from 'translations/es.json';
import BN from 'bignumber.js';

function range(start, end) {
    return (new Array(end - start + 1)).fill(undefined).map((_, i) => i + start);
}

function metamask_installed() {
   if (typeof web3 !== 'undefined'){
      return true
   }
   else{
      return false
   }
}

function metamask_unlocked(cb) {
    web3.eth.getAccounts(function(err, accounts){
      if (err != null) {
         return cb(false)
      }
      else if (accounts.length === 0) {
         return cb(false)
      }
      else {
         return cb(true)
      }
   })
}

function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}

/*    if(methodscopy.includes("Mobile payment")){
        const index = methodscopy.indexOf("Mobile payment")
        methodscopy.splice(index, 1)
        ret = methodscopy.join(", ")
        if(ret != ""){
            ret = ret+", "
        }
        if(typeof(mobile_applications) === 'object'){
            ret = ret+mobile_applications.join(", ")
        }
    } else{
*/

function makeMethods(methods, mobile_applications){
    if(!methods){
        return ""
    }
    var ret = ""
    if(Array.isArray(methods)){
        var methodscopy = methods.slice(0);
        if(methodscopy.includes("Mobile payment")){
            const index = methodscopy.indexOf("Mobile payment")
            methodscopy.splice(index, 1)
            ret = methodscopy.map(m => translateMethod(m)).join(", ")
            if(Array.isArray(mobile_applications)){
                if (ret != "" && mobile_applications.length > 0){
                    ret = ret+", "
                }
                ret = ret+mobile_applications.join(", ")
            } else{
                if(typeof(mobile_applications) === 'object' && mobile_applications != null){
                    var ks = Object.keys(mobile_applications)
                    if(ks.length > 0 && ks.includes('mobile_application')){
                        if (ret != ""){
                            ret = ret+", "
                        }
                        ret = ret+mobile_applications.mobile_application
                    } else{
                        if (ret != ""){
                            ret = ret+", "
                        }
                        ret = ret+""
                    }
                } else{
                    if(typeof(mobile_applications) === 'string'){
                        if (ret != ""){
                            ret = ret+", "
                        }
                        ret = ret+mobile_applications
                    }else{
                        if (ret != ""){
                            ret = ret+", "
                        }
                        ret = ret+translateMethod("Mobile payment")
                    }
                }
            }
        } else{
            ret = methodscopy.map(m => translateMethod(m)).join(", ")
        }
    } else{
        if(methods === "Mobile payment"){
            if(typeof(mobile_applications) === 'object' && mobile_applications.mobile_application){
                ret = mobile_applications.mobile_application || translateMethod(null)
            } else{
                if(mobile_applications){
                    ret = mobile_applications
                } else{
                    ret = translateMethod("Mobile payment")
                }
            }
        } else{
            ret = translateMethod(methods)
        }
    }
    return ret
}


function isMobile() {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
};




function translateStatus(status){
    const lang = getLang()
    var ret;
    switch(status){
        case 'Publicly available':
            ret = lang.my_orders.main_window.public_tasks.public_available
            break;
        case 'Privately available':
            ret = lang.my_orders.main_window.public_tasks.private_available
            break;
        case 'Publicly taken':
            ret = lang.my_orders.main_window.public_tasks.public_taken
            break;
        case 'Privately taken':
            ret = lang.my_orders.main_window.public_tasks.private_taken
            break;
        case 'Transfer registered':
            ret = lang.orderflow_recipient_overview_one_of_two_no_account.white_box.registered
            break;
        case 'Publicly completed':
            ret = lang.orderflow_recipient_overview_one_of_two_no_account.white_box.publicly_completed
            break;
        case 'Privately completed':
            ret = lang.orderflow_recipient_overview_one_of_two_no_account.white_box.privately_completed
            break;
        case 'Missing info':
            ret = lang.orderflow_recipient_overview_one_of_two_no_account.white_box.missing_agent
            break;
        case 'Requested':
            ret = lang.orderflow_recipient_overview_one_of_two_no_account.white_box.status_agent_request
            break;
        case 'Accepted':
            ret = lang.orderflow_recipient_overview_one_of_two_no_account.white_box.accepted
            break;
        case 'Cancelled':
            ret = lang.orderflow_recipient_overview_one_of_two_no_account.white_box.cancelled
            break;
        case 'Failed':
            ret = lang.orderflow_recipient_overview_one_of_two_no_account.white_box.failed
            break;
        case 'Solved':
            ret = lang.orderflow_recipient_overview_one_of_two_no_account.white_box.solved
            break;
        case 'Frozen':
            ret = 'Frozen'
            break;
        case 'Refunded':
            ret = 'Refunded'
            break;
        default:
            ret = ''
            break;
    }
    return ret;
}

function translateMethod(method){
    const lang = getLang()
    var ret;
    switch(method){
        case 'In person':
            ret = lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.receive_cash_header
            break;
        case 'Mobile payment':
            ret = lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.receive_other_header
            break;
        case 'Bank transfer':
            ret = lang.kys_kyr_kya.customer_due_diligence.kyc_2_of_2.receive_transfer_header
            break;
        case 'none':
            ret = 'All chosen agents declined to accept this order'
            break;
        case null:
            ret = 'No payment method found';
            break;
        default:
            ret = '';
            break;
    }
    return ret
}

const currflags = [
    {flagname: 'AE', curr: 'AED'},
    {flagname: 'AF', curr: 'AFN'},
    {flagname: 'AL', curr: 'ALL'},
    {flagname: 'AM', curr: 'AMD'},
    {flagname: 'AN', curr: 'ANG'}, // Custom
    {flagname: 'AO', curr: 'OAO'},
    {flagname: 'AR', curr: 'ARS'},
    {flagname: 'AU', curr: 'AUD'},
    {flagname: 'AW', curr: 'AWG'},
    {flagname: 'AZ', curr: 'AZN'},
    {flagname: 'BA', curr: 'BAM'},
    {flagname: 'BB', curr: 'BBD'},
    {flagname: 'BD', curr: 'BDT'},
    {flagname: 'BG', curr: 'BGN'},
    {flagname: 'BH', curr: 'BHD'},
    {flagname: 'BI', curr: 'BIF'},
    {flagname: 'BJ', curr: 'CFA'},
    {flagname: 'BM', curr: 'BMD'},
    {flagname: 'BN', curr: 'BND'},
    {flagname: 'BO', curr: 'BOB'},
    {flagname: 'BR', curr: 'BRL'},
    {flagname: 'BS', curr: 'BSD'},
    {flagname: 'BT', curr: 'BTN'},
    {flagname: 'BW', curr: 'BWP'},
    {flagname: 'BY', curr: 'BYR'},
    {flagname: 'BZ', curr: 'BZD'},
    {flagname: 'CA', curr: 'CAD'},
    {flagname: 'CD', curr: 'CDF'},
    {flagname: 'CH', curr: 'CHE'},
    {flagname: 'CL', curr: 'CLP'},
    {flagname: 'CN', curr: 'CNY'},
    {flagname: 'CO', curr: 'COP'},
    {flagname: 'CR', curr: 'CRC'},
    {flagname: 'CU', curr: 'CUC'},
    {flagname: 'CV', curr: 'CVE'},
    {flagname: 'CZ', curr: 'CZK'},
    {flagname: 'DJ', curr: 'DJF'},
    {flagname: 'DK', curr: 'DKK'},
    {flagname: 'DO', curr: 'DOP'},
    {flagname: 'DZ', curr: 'DZD'},
    {flagname: 'EG', curr: 'EGP'},
    {flagname: 'ER', curr: 'ERN'},
    {flagname: 'EU', curr: 'EUR'}, // Custom
    {flagname: 'ET', curr: 'ETB'},
    {flagname: 'FJ', curr: 'FJD'},
    {flagname: 'FK', curr: 'FKP'},
    {flagname: 'GB', curr: 'GBP'},
    {flagname: 'GE', curr: 'GEL'},
    {flagname: 'GH', curr: 'GHS'},
    {flagname: 'GI', curr: 'GIP'},
    {flagname: 'GM', curr: 'GMD'},
    {flagname: 'GN', curr: 'GNF'},
    {flagname: 'GT', curr: 'GTQ'},
    {flagname: 'GY', curr: 'GYD'},
    {flagname: 'HK', curr: 'HKD'},
    {flagname: 'HN', curr: 'HNL'},
    {flagname: 'HR', curr: 'HRK'},
    {flagname: 'HT', curr: 'HTG'},
    {flagname: 'HU', curr: 'HUF'},
    {flagname: 'ID', curr: 'IDR'},
    {flagname: 'IL', curr: 'ILS'},
    {flagname: 'IN', curr: 'INR'},
    {flagname: 'IQ', curr: 'IQD'},
    {flagname: 'IR', curr: 'IRR'},
    {flagname: 'JM', curr: 'JMD'},
    {flagname: 'JO', curr: 'JOD'},
    {flagname: 'JP', curr: 'JPY'},
    {flagname: 'KE', curr: 'KES'},
    {flagname: 'KG', curr: 'KGS'},
    {flagname: 'KH', curr: 'KHR'},
    {flagname: 'KM', curr: 'KMF'},
    {flagname: 'KP', curr: 'KPW'},
    {flagname: 'KR', curr: 'KRW'},
    {flagname: 'KW', curr: 'KWD'},
    {flagname: 'KY', curr: 'KYD'},
    {flagname: 'KZ', curr: 'KZT'},
    {flagname: 'LA', curr: 'LAK'},
    {flagname: 'LB', curr: 'LBP'},
    {flagname: 'LI', curr: 'CHF'},
    {flagname: 'LK', curr: 'LKR'},
    {flagname: 'LR', curr: 'LRD'},
    {flagname: 'LS', curr: 'LSL'},
    {flagname: 'LY', curr: 'LYD'},
    {flagname: 'MG', curr: 'MGA'},
    {flagname: 'MK', curr: 'MKD'},
    {flagname: 'MD', curr: 'MDL'},
    {flagname: 'MM', curr: 'MMK'},
    {flagname: 'MA', curr: 'MAD'},
    {flagname: 'MN', curr: 'MNT'},
    {flagname: 'MO', curr: 'MOP'},
    {flagname: 'MR', curr: 'MEO'},
    {flagname: 'MU', curr: 'MUR'},
    {flagname: 'MV', curr: 'MVR'},
    {flagname: 'MW', curr: 'MWK'},
    {flagname: 'MX', curr: 'MXN'},
    {flagname: 'MY', curr: 'MYR'},
    {flagname: 'MZ', curr: 'MZN'},
    {flagname: 'NA', curr: 'NAD'},
    {flagname: 'NG', curr: 'NGN'},
    {flagname: 'NI', curr: 'NIO'},
    {flagname: 'NO', curr: 'NOK'},
    {flagname: 'NP', curr: 'NPR'},
    {flagname: 'NZ', curr: 'NZD'},
    {flagname: 'OM', curr: 'OMR'},
    {flagname: 'PA', curr: 'PAB'},
    {flagname: 'PE', curr: 'PEN'},
    {flagname: 'PG', curr: 'PGK'},
    {flagname: 'PH', curr: 'PHP'},
    {flagname: 'PK', curr: 'PKR'},
    {flagname: 'PL', curr: 'PLM'},
    {flagname: 'PS', curr: 'NIS'},
    {flagname: 'PY', curr: 'PYG'},
    {flagname: 'QA', curr: 'QAR'},
    {flagname: 'RO', curr: 'RON'},
    {flagname: 'RS', curr: 'RSD'},
    {flagname: 'RU', curr: 'RUB'},
    {flagname: 'RW', curr: 'RWF'},
    {flagname: 'SA', curr: 'SAR'},
    {flagname: 'SB', curr: 'SBD'},
    {flagname: 'SC', curr: 'SCR'},
    {flagname: 'SD', curr: 'SDG'},
    {flagname: 'SS', curr: 'SSP'},
    {flagname: 'SE', curr: 'SEK'},
    {flagname: 'SG', curr: 'SGD'},
    {flagname: 'SH', curr: 'SHP'},
    {flagname: 'SL', curr: 'SLL'},
    {flagname: 'SO', curr: 'SOS'},
    {flagname: 'SR', curr: 'SRD'},
    {flagname: 'ST', curr: 'STD'},
    {flagname: 'SV', curr: 'SVC'},
    {flagname: 'SY', curr: 'SYP'},
    {flagname: 'SZ', curr: 'SZL'},
    {flagname: 'TH', curr: 'THB'},
    {flagname: 'TJ', curr: 'TJS'},
    {flagname: 'TM', curr: 'TMT'},
    {flagname: 'TN', curr: 'TND'},
    {flagname: 'TO', curr: 'TOP'},
    {flagname: 'TR', curr: 'TRY'},
    {flagname: 'TT', curr: 'TTD'},
    {flagname: 'TW', curr: 'TWD'},
    {flagname: 'TZ', curr: 'TZS'},
    {flagname: 'UA', curr: 'UAH'},
    {flagname: 'UG', curr: 'UGX'},
    {flagname: 'UY', curr: 'UYU'},
    {flagname: 'UZ', curr: 'UZS'},
    {flagname: 'US', curr: 'USD'},
    {flagname: 'VE', curr: 'VES'},
    {flagname: 'VN', curr: 'VND'},
    {flagname: 'VU', curr: 'VUV'},
    {flagname: 'WS', curr: 'WST'},
    {flagname: 'YE', curr: 'YER'},
    {flagname: 'ZA', curr: 'ZAR'},
    {flagname: 'ZM', curr: 'ZMW'},
    {flagname: 'ZW', curr: 'ZWL'},
    {flagname: 'XB', curr: 'XAF'}, // Custom
    {flagname: 'XA', curr: 'XCD'}, // Xa is a placeholder for unknown. Can't have multiple of the same flagnames, same with Xb, Xc
    {flagname: 'UN', curr: 'XOF'}, // Custom
    {flagname: 'XC', curr: 'XPF'} // Custom
]


const avatarconf = {
    width: 98,
    height: 98,
    editorWidth: 98,
    editorHeight: 98

}
/* flagnames: https://github.com/wiredmax/react-flags/blob/master/src/flags.json5
 * currs: https://openexchangerates.org/api/currencies.json
*/
const countries = [
        {country: 'Andorra', flagname: 'AD', curr: 'EUR', code: 376},
        {country: 'United Arab Emirates', flagname: 'AE', curr: 'AED', code: 971},
        {country: 'Afghanistan', flagname: 'AF', curr: 'AFN', code: 93},
        {country: 'Antigua and Barbuda', flagname: 'AG', curr: 'XCD', code: 1268},
        {country: 'Anguilla', flagname: 'AI', curr: 'XCD', code: 1264},
        {country: 'Albania', flagname: 'AL', curr: 'ALL', code: 355},
        // {country: 'Antarctica', flagname: 'AQ', curr: 'AAD', 672},
        {country: 'Armenia', flagname: 'AM', curr: 'AMD', code: 374},
        {country: 'Netherlands Antilles', flagname: 'AN', curr: 'ANG', code: 599},
        {country: 'Angola', flagname: 'AO', curr: 'OAO', code: 244},
        {country: 'Argentina', flagname: 'AR', curr: 'ARS', code: 54},
        {country: 'American Samoa', flagname: 'AS', curr: 'USD', code: 1684},
        {country: 'Austria', flagname: 'AT', curr: 'EUR', code: 43},
        {country: 'Australia', flagname: 'AU', curr: 'AUD', code: 61},
        {country: 'Aruba', flagname: 'AW', curr: 'AWG', code: 297},
        {country: 'Åland Islands', flagname: 'AX', curr: 'EUR', code: 358},
        {country: 'Azerbaijan', flagname: 'AZ', curr: 'AZN', code: 994},
        {country: 'Bosnia and Herzegovina', flagname: 'BA', curr: 'BAM', code: 387},
        {country: 'Barbados', flagname: 'BB', curr: 'BBD', code: 1246},
        {country: 'Bangladesh', flagname: 'BD', curr: 'BDT', code: 880},
        {country: 'Belgium', flagname: 'BE', curr: 'EUR', code: 32},
        {country: 'Burkina Faso', flagname: 'BF', curr: 'XOF', code: 226},
        {country: 'Bulgaria', flagname: 'BG', curr: 'BGN', code: 359},
        {country: 'Bahrain', flagname: 'BH', curr: 'BHD', code: 973},
        {country: 'Burundi', flagname: 'BI', curr: 'BIF', code: 257},
        {country: 'Benin', flagname: 'BJ', curr: 'CFA', code: 229},
        {country: 'Saint Barthélemy', flagname: 'BL', curr: 'EUR', code: 590},
        {country: 'Bermuda', flagname: 'BM', curr: 'BMD', code: 1441},
        {country: 'Brunei Darussalam', flagname: 'BN', curr: 'BND', code: 674},
        {country: 'Bolivia', flagname: 'BO', curr: 'BOB', code: 591},
        {country: 'Brazil', flagname: 'BR', curr: 'BRL', code: 55},
        {country: 'Bahamas', flagname: 'BS', curr: 'BSD', code: 1242},
        {country: 'Bhutan', flagname: 'BT', curr: 'BTN', code: 975},
        {country: 'Bouvet Island', flagname: 'BV', curr: 'NOK', code: 55},
        {country: 'Botswana', flagname: 'BW', curr: 'BWP', code: 267},
        {country: 'Belarus', flagname: 'BY', curr: 'BYR', code: 375},
        {country: 'Belize', flagname: 'BZ', curr: 'BZD', code: 591},
        {country: 'Canada', flagname: 'CA', curr: 'CAD', code: 1},
        {country: 'Cocos (Keeling) Islands', flagname: 'CC', curr: 'AUD', code: 61},
        {country: 'Congo, the Democratic Republic of the', flagname: 'CD', curr: 'CDF', code: 243},
        {country: 'Central African Republic', flagname: 'CF', curr: 'XAF', code: 236},
        {country: 'Congo', flagname: 'CG', curr: 'XAF', code: 242},
        {country: `Côte d'Ivoire`, flagname: 'CI', curr: 'XOF', code: 225},
        {country: 'Curaçao', flagname: 'CW', curr: 'ANG', code: 599},
        {country: 'Switzerland', flagname: 'CH', curr: 'CHE', code: 41},
        {country: 'Cook Islands', flagname: 'CK', curr: 'NZD', code: 682},
        {country: 'Chile', flagname: 'CL', curr: 'CLP', code: 56},
        {country: 'Cameroon', flagname: 'CM', curr: 'XAF', code: 237},
        {country: 'China', flagname: 'CN', curr: 'CNY', code: 86},
        {country: 'Colombia', flagname: 'CO', curr: 'COP', code: 57},
        {country: 'Costa Rica', flagname: 'CR', curr: 'CRC', code: 506},
        {country: 'Cuba', flagname: 'CU', curr: 'CUC', code: 53},
        {country: 'Cape Verde', flagname: 'CV', curr: 'CVE', code: 383},
        {country: 'Christmas Island', flagname: 'CX', curr: 'AUD', code: 61},
        {country: 'Cyprus', flagname: 'CY', curr: 'EUR', code: 357},
        {country: 'Czech Republic', flagname: 'CZ', curr: 'CZK', code: 420},
        {country: 'Germany', flagname: 'DE', curr: 'EUR', code: 49},
        {country: 'Djibouti', flagname: 'DJ', curr: 'DJF', code: 253},
        {country: 'Denmark', flagname: 'DK', curr: 'DKK', code: 45},
        {country: 'Dominica', flagname: 'DM', curr: 'XCD', code: 1767},
        {country: 'Dominican Republic', flagname: 'DO', curr: 'DOP', code: 1809},
        {country: 'Algeria', flagname: 'DZ', curr: 'DZD', code: 213},
        {country: 'Ecuador', flagname: 'EC', curr: 'USD', code: 593},
        {country: 'Estonia', flagname: 'EE', curr: 'EUR', code: 372},
        {country: 'Egypt', flagname: 'EG', curr: 'EGP', code: 20},
        {country: 'Western Sahara', flagname: 'EH', curr: 'MAD', code: 212},
        {country: 'Eritrea', flagname: 'ER', curr: 'ERN', code: 291},
        {country: 'Spain', flagname: 'ES', curr: 'EUR', code: 34},
        {country: 'Ethiopia', flagname: 'ET', curr: 'ETB', code: 251},
        {country: 'Finland', flagname: 'FI', curr: 'EUR', code: 358},
        {country: 'Fiji', flagname: 'FJ', curr: 'FJD', code: 679},
        {country: 'Falkland Islands (Malvinas)', flagname: 'FK', curr: 'FKP', code: 500},
        {country: 'Micronesia, Federated States of', flagname: 'FM', curr: 'USD', code: 691},
        {country: 'Faroe Islands', flagname: 'FO', curr: 'DKK', code: 298},
        {country: 'France', flagname: 'FR', curr: 'EUR', code: 33},
        {country: 'Gabon', flagname: 'GA', curr: 'XAF', code: 241},
        {country: 'Great Britain', flagname: 'GB', curr: 'GBP', code: 44},
        {country: 'Grenada', flagname: 'GD', curr: 'XCD', code: 1473},
        {country: 'Georgia', flagname: 'GE', curr: 'GEL', code: 995},
        {country: 'French Guiana', flagname: 'GF', curr: 'EUR', code: 689},
        {country: 'Guernsey', flagname: 'GG', curr: 'GBP', code: 441481},
        {country: 'Ghana', flagname: 'GH', curr: 'GHS', code: 233},
        {country: 'Gibraltar', flagname: 'GI', curr: 'GIP', code: 350},
        {country: 'Greenland', flagname: 'GL', curr: 'DKK', code: 299},
        {country: 'Gambia', flagname: 'GM', curr: 'GMD', code: 220},
        {country: 'Guinea', flagname: 'GN', curr: 'GNF', code: 224},
        {country: 'Guadeloupe', flagname: 'GP', curr: 'EUR', code: 590},
        {country: 'Equatorial Guinea', flagname: 'GQ', curr: 'XAF', code: 240},
        {country: 'Greece', flagname: 'GR', curr: 'EUR', code: 30},
        {country: 'Guatemala', flagname: 'GT', curr: 'GTQ', code: 502},
        {country: 'Guam', flagname: 'GU', curr: 'USD', code: 1671},
        {country: 'Guinea-Bissau', flagname: 'GW', curr: 'XOF', code: 245},
        {country: 'Guyana', flagname: 'GY', curr: 'GYD', code: 592},
        {country: 'Hong Kong', flagname: 'HK', curr: 'HKD', code: 852},
        {country: 'Honduras', flagname: 'HN', curr: 'HNL', code: 504},
        {country: 'Croatia', flagname: 'HR', curr: 'HRK', code: 385},
        {country: 'Haiti', flagname: 'HT', curr: 'HTG', code: 509},
        {country: 'Hungary', flagname: 'HU', curr: 'HUF', code: 36},
        {country: 'Indonesia', flagname: 'ID', curr: 'IDR', code: 62},
        {country: 'Ireland', flagname: 'IE', curr: 'EUR', code: 353},
        {country: 'Israel', flagname: 'IL', curr: 'ILS', code: 972},
        {country: 'Isle of Man', flagname: 'IM', curr: 'GBP', code: 441624},
        {country: 'India', flagname: 'IN', curr: 'INR', code: 91},
        {country: 'British Indian Ocean Territory', flagname: 'IO', curr: 'USD', code: 246},
        {country: 'Iraq', flagname: 'IQ', curr: 'IQD', code: 964},
        {country: 'Iran', flagname: 'IR', curr: 'IRR', code: 98},
        {country: 'Island', flagname: 'IS', curr: 'EUR', code: 354},
        {country: 'Italy', flagname: 'IT', curr: 'EUR', code: 39},
        {country: 'Jersey', flagname: 'JE', curr: 'GBP', code: 441534},
        {country: 'Jamaica', flagname: 'JM', curr: 'JMD', code: 1876},
        {country: 'Jordan', flagname: 'JO', curr: 'JOD', code: 962},
        {country: 'Japan', flagname: 'JP', curr: 'JPY', code: 81},
        {country: 'Kenya', flagname: 'KE', curr: 'KES', code: 254},
        {country: 'Kyrgyzstan', flagname: 'KG', curr: 'KGS', code: 996},
        {country: 'Cambodia', flagname: 'KH', curr: 'KHR', code: 855},
        {country: 'Kiribati', flagname: 'KI', curr: 'AUD', code: 686},
        {country: 'Comoros', flagname: 'KM', curr: 'KMF', code: 269},
        {country: 'Saint Kitts and Nevis', flagname: 'KN', curr: 'XCD', code: 1869},
        {country: 'North Korea', flagname: 'KP', curr: 'KPW', code: 850},
        {country: 'South Korea', flagname: 'KR', curr: 'KRW', code: 82},
        {country: 'Kuwait', flagname: 'KW', curr: 'KWD', code: 965},
        {country: 'Cayman Islands', flagname: 'KY', curr: 'KYD', code: 1345},
        {country: 'Kazakhstan', flagname: 'KZ', curr: 'KZT', code: 7},
        {country: 'Laos', flagname: 'LA', curr: 'LAK', code: 856},
        {country: 'Lebanon', flagname: 'LB', curr: 'LBP', code: 961},
        {country: 'Saint Lucia', flagname: 'LC', curr: 'XCD', code: 1758},
        {country: 'Liechtenstein', flagname: 'LI', curr: 'CHF', code: 423},
        {country: 'Sri Lanka', flagname: 'LK', curr: 'LKR', code: 94},
        {country: 'Liberia', flagname: 'LR', curr: 'LRD', code: 231},
        {country: 'Lesotho', flagname: 'LS', curr: 'LSL', code: 266},
        {country: 'Lithuania', flagname: 'LT', curr: 'EUR', code: 370},
        {country: 'Luxembourg', flagname: 'LU', curr: 'EUR', code: 352},
        {country: 'Latvia', flagname: 'LV', curr: 'EUR', code: 371},
        {country: 'Libya', flagname: 'LY', curr: 'LYD', code: 218},
        {country: 'Madagascar', flagname: 'MG', curr: 'MGA', code: 261},
        {country: 'Marshall Islands', flagname: 'MH', curr: 'USD', code: 692},
        {country: 'Macedonia', flagname: 'MK', curr: 'MKD', code: 389},
        {country: 'Mali', flagname: 'ML', curr: 'XOF', code: 223},
        {country: 'Malta', flagname: 'MT', curr: 'EUR', code: 356},
        {country: 'Moldova', flagname: 'MD', curr: 'MDL', code: 373},
        {country: 'Myanmar', flagname: 'MM', curr: 'MMK', code: 95},
        {country: 'Monaco', flagname: 'MC', curr: 'EUR', code: 377},
        {country: 'Montenegro', flagname: 'ME', curr: 'EUR', code: 382},
        {country: 'Morocco', flagname: 'MA', curr: 'MAD', code: 212},
        {country: 'Mongolia', flagname: 'MN', curr: 'MNT', code: 976},
        {country: 'Macao', flagname: 'MO', curr: 'MOP', code: 853},
        {country: 'Martinique', flagname: 'MQ', curr: 'EUR', code: 596},
        {country: 'Mauritania', flagname: 'MR', curr: 'MEO', code: 222},
        {country: 'Montserrat', flagname: 'MS', curr: 'XCD', code: 1664},
        {country: 'Mauritius', flagname: 'MU', curr: 'MUR', code: 230},
        {country: 'Maldives', flagname: 'MV', curr: 'MVR', code: 960},
        {country: 'Malawi', flagname: 'MW', curr: 'MWK', code: 265},
        {country: 'Mexico', flagname: 'MX', curr: 'MXN', code: 52},
        {country: 'Malaysia', flagname: 'MY', curr: 'MYR', code: 60},
        {country: 'Mozambique', flagname: 'MZ', curr: 'MZN', code: 258},
        {country: 'Namibia', flagname: 'NA', curr: 'NAD', code: 264},
        {country: 'New Caledonia', flagname: 'NC', curr: 'XPF', code: 687},
        {country: 'Niger', flagname: 'NE', curr: 'XOF', code: 227},
        {country: 'Nigeria', flagname: 'NG', curr: 'NGN', code: 234},
        {country: 'Nicaragua', flagname: 'NI', curr: 'NIO', code: 505},
        {country: 'Netherlands', flagname: 'NL', curr: 'EUR', code: 31},
        {country: 'Northern Mariana Islands', flagname: 'MP', curr: 'USD', code: 1670},
        {country: 'Norway', flagname: 'NO', curr: 'NOK', code: 47},
        {country: 'Nepal', flagname: 'NP', curr: 'NPR', code: 977},
        {country: 'Nauru', flagname: 'NR', curr: 'AUD', code: 674},
        {country: 'Niue', flagname: 'NU', curr: 'NZD', code: 683},
        {country: 'New Zealand', flagname: 'NZ', curr: 'NZD', code: 64},
        {country: 'Oman', flagname: 'OM', curr: 'OMR', code: 968},
        {country: 'Panama', flagname: 'PA', curr: 'PAB', code: 507},
        {country: 'Peru', flagname: 'PE', curr: 'PEN', code: 51},
        {country: 'French Polynesia', flagname: 'PF', curr: 'XPF', code: 689},
        {country: 'Papua New Guinea', flagname: 'PG', curr: 'PGK', code: 675},
        {country: 'Philippines', flagname: 'PH', curr: 'PHP', code: 63},
        {country: 'Pakistan', flagname: 'PK', curr: 'PKR', code: 92},
        {country: 'Poland', flagname: 'PL', curr: 'PLM', code: 48},
        {country: 'Saint Pierre and Miquelon', flagname: 'PM', curr: 'EUR', code: 508},
        {country: 'Pitcairn', flagname: 'PN', curr: 'NZD', code: 64},
        {country: 'Puerto Rico', flagname: 'PR', curr: 'USD', code: 1787},
        {country: 'Palestine', flagname: 'PS', curr: 'NIS', code: 970},
        {country: 'Portugal', flagname: 'PT', curr: 'EUR', code: 351},
        {country: 'Palau', flagname: 'PW', curr: 'USD', code: 680},
        {country: 'Paraguay', flagname: 'PY', curr: 'PYG', code: 595},
        {country: 'Qatar', flagname: 'QA', curr: 'QAR', code: 974},
        {country: 'Réunion', flagname: 'RE', curr: 'EUR', code: 262},
        {country: 'Romania', flagname: 'RO', curr: 'RON', code: 40},
        {country: 'Serbia', flagname: 'RS', curr: 'RSD', code: 381},
        {country: 'Russia', flagname: 'RU', curr: 'RUB', code: 7},
        {country: 'Rwanda', flagname: 'RW', curr: 'RWF', code: 250},
        // {country: 'Saint Martin', flagname: 'MF', curr: '', code: 590},
        {country: 'Saudi Arabia', flagname: 'SA', curr: 'SAR', code: 966},
        {country: 'Solomon Islands', flagname: 'SB', curr: 'SBD', code: 677},
        {country: 'Seychelles', flagname: 'SC', curr: 'SCR', code: 248},
        {country: 'Sudan', flagname: 'SD', curr: 'SDG', code: 249},
        {country: 'South Sudan', flagname: 'SS', curr: 'SSP', code: 211},
        {country: 'Sweden', flagname: 'SE', curr: 'SEK', code: 46},
        {country: 'Singapore', flagname: 'SG', curr: 'SGD', code: 65},
        {country: 'Saint Helena', flagname: 'SH', curr: 'SHP', code: 290},
        {country: 'Slovenia', flagname: 'SI', curr: 'EUR', code: 386},
        {country: 'Svalbard and Jan Mayen', flagname: 'SJ', curr: 'NOK', code: 47},
        {country: 'Slovakia', flagname: 'SK', curr: 'EUR', code: 421},
        {country: 'Sierra Leone', flagname: 'SL', curr: 'SLL', code: 232},
        {country: 'San Marino', flagname: 'SM', curr: 'EUR', code: 378},
        {country: 'Senegal', flagname: 'SN', curr: 'XOF', code: 221},
        {country: 'Somalia', flagname: 'SO', curr: 'SOS', code: 252},
        {country: 'Suriname', flagname: 'SR', curr: 'SRD', code: 597},
        {country: 'Sao Tome and Principe', flagname: 'ST', curr: 'STD', code: 239},
        {country: 'El Salvador', flagname: 'SV', curr: 'SVC', code: 503},
        {country: 'Sint Maarten (Dutch part)', flagname: 'SX', curr: 'ANG', code: 1721},
        {country: 'Syria', flagname: 'SY', curr: 'SYP', code: 963},
        {country: 'Swaziland', flagname: 'SZ', curr: 'SZL', code: 268},
        {country: 'Turks and Caicos Islands', flagname: 'TC', curr: 'USD', code: 1649},
        {country: 'Chad', flagname: 'TD', curr: 'XAF', code: 235},
        {country: 'French Southern Territories', flagname: 'TF', curr: 'EUR', code: 689},
        {country: 'Togo', flagname: 'TG', curr: 'XOF', code: 228},
        {country: 'Thailand', flagname: 'TH', curr: 'THB', code: 66},
        {country: 'Tajikistan', flagname: 'TJ', curr: 'TJS', code: 992},
        {country: 'Tokelau', flagname: 'TK', curr: 'NZD', code: 690},
        {country: 'Timor-Leste', flagname: 'TL', curr: 'USD', code: 670},
        {country: 'Turkmenistan', flagname: 'TM', curr: 'TMT', code: 993},
        {country: 'Tunisia', flagname: 'TN', curr: 'TND', code: 216},
        {country: 'Tonga', flagname: 'TO', curr: 'TOP', code: 676},
        {country: 'Turkey', flagname: 'TR', curr: 'TRY', code: 90},
        {country: 'Trinidad and Tobago', flagname: 'TT', curr: 'TTD', code: 1868},
        {country: 'Tuvalu', flagname: 'TV', curr: 'AUD', code: 688},
        {country: 'Taiwan', flagname: 'TW', curr: 'TWD', code: 886},
        {country: 'Tanzania', flagname: 'TZ', curr: 'TZS', code: 255},
        {country: 'Ukraine', flagname: 'UA', curr: 'UAH', code: 380},
        {country: 'Uganda', flagname: 'UG', curr: 'UGX', code: 256},
        {country: 'USA', flagname: 'US', curr: 'USD', code: 1},
        {country: 'Uruguay', flagname: 'UY', curr: 'UYU', code: 598},
        {country: 'Uzbekistan', flagname: 'UZ', curr: 'UZS', code: 998},
        {country: 'Vatican', flagname: 'VA', curr: 'EUR', code: 379},
        {country: 'Saint Vincent and the Grenadines', flagname: 'VC', curr: 'XCD', code: 1784},
        {country: 'Venezuela', flagname: 'VE', curr: 'VES', code: 58},
        {country: 'Virgin Islands, British', flagname: 'VG', curr: 'USD', code: 1284},
        {country: 'Virgin Islands, U.S.', flagname: 'VI', curr: 'USD', code: 1340},
        {country: 'Vietnam', flagname: 'VN', curr: 'VND', code: 84},
        {country: 'Vanuatu', flagname: 'VU', curr: 'VUV', code: 678},
        {country: 'Wallis and Futuna Islands', flagname: 'WF', curr: 'XPF', code: 681},
        {country: 'Kosovo', flagname: 'XK', curr: 'EUR', code: 383},
        {country: 'Samoa', flagname: 'WS', curr: 'WST', code: 685},
        {country: 'Yemen', flagname: 'YE', curr: 'YER', code: 967},
        {country: 'Mayotte', flagname: 'YT', curr: 'EUR', code: 262},
        {country: 'South Africa', flagname: 'ZA', curr: 'ZAR', code: 27},
        {country: 'Zambia', flagname: 'ZM', curr: 'ZMW', code: 260},
        {country: 'Zimbabwe', flagname: 'ZW', curr: 'ZWL', code: 263}
    ]

const country_labels = currflags.map(a => a.flagname)
const shorthand = {}
for(var i = 0; i < currflags.length; i++){
    shorthand[currflags[i].flagname] = currflags[i].curr
}
const shorthand_curr_to_flag = {}
for(var i = 0; i < currflags.length; i++){
    shorthand_curr_to_flag[currflags[i].curr] = currflags[i].flagname
}

const deposit_fee = 8
const matching_fee = 2
const max_fee_num = deposit_fee - matching_fee

function setCookie(cvalue) {
  var d = new Date();
  d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000)); // 365 days
  var expires = "expires="+d.toUTCString();
  document.cookie = "language" + "=" + cvalue + ";" + expires + ";path=/";
  window.location.reload(false);
}

function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function make_name(firstname, lastnames){
    return (firstname && lastnames) ? `${firstname} ${lastnames.split(" ")[0][0]}.` : ''
}

// Which languages
function getLanguage(){
    var lang;
    var language = getCookie("language");
    if(!language){
        var browserlang = window.navigator.language || window.navigator.userLanguage
        if(browserlang.length >= 2){
            if(browserlang === 'es' || browserlang.substr(0, 2) === 'es'){
                lang = 'Spanish'
            } else{
                lang = 'English'
            }
        } else{
            lang = 'Spanish'
        }
        setCookie(lang)
    } else{
        lang = language
    }
    return lang
}


// Language set
function getLang() {
    var lang;
    var language = getCookie("language");
    if(!language){
        var browserlang = window.navigator.language || window.navigator.userLanguage
        if(browserlang.length >= 2){
            if(browserlang === 'es' || browserlang.substr(0, 2) === 'es'){
                lang = es
            } else{
                lang = en
            }
        } else{
            lang = es
        }
        setCookie(lang)
    } else{
        switch(language){
             case "English":
                 lang = en;
                 break;
             case "Spanish":
                 lang = es;
                 break;
             default:
                 lang = en;
                 break;
        }
    }
    return lang
}

// deposit - deposit*(max_fee - delivery_fee)
// 10 - 10*(0.08 - 0.04) = 9.6
function get_agent_payment(deposit, max_fee, delivery_fee){
    const sent = new BN(deposit)
    try{
        return sent.minus(sent.multipliedBy((new BN(max_fee)).minus((new BN(delivery_fee)).dividedBy(new BN(100))))).toFixed(2)
    } catch(e){
        console.error(e)
        return null
    }
}

// deposit * (delivery_fee/100)
function get_agent_reward(deposit, delivery_fee, divide = true){
    const sent = new BN(deposit)
    const d_fee = new BN(delivery_fee)
    const hundred = new BN(100)
    try{
        var value = null
        if(divide){
            value = sent.multipliedBy(d_fee.dividedBy(hundred)).toFixed(2)
        } else{
            value = sent.multipliedBy(d_fee).toFixed(2)
        }
        return value
    } catch(e){
        console.error(e)
        return null
    }
}

function get_receive_amount(deposit, exchange_rate, max_fee){
    const sent = new BN(deposit)
    const e_r = new BN(exchange_rate)
    const m_fee = new BN(max_fee)
    try{
        var value = sent.multipliedBy(e_r) // value = deposit * exchange_rate
        value = value.minus(value.multipliedBy(m_fee)) // value - value*max_fee
        return value.toFixed(2)
    } catch(e){
        console.error(e)
        return null
    }
}




export const constants = {
    countries: countries,
    currflags: currflags,
    days: (range(1, 31)),
    year: ((new Date()).getFullYear()),
    months: [
        {month: "January"},
        {month: "February"},
        {month: "March"},
        {month: "April"},
        {month: "May"},
        {month: "June"},
        {month: "July"},
        {month: "August"},
        {month: "September"},
        {month: "October"},
        {month: "November"},
        {month: "December"}
    ],
    metamask_installed: metamask_installed,
    metamask_unlocked: metamask_unlocked,

    country_labels: country_labels,
    shorthand: shorthand,
    shorthand_curr_to_flag: shorthand_curr_to_flag,

    min_amount: 0.5, // minimum 0.5 DAI
    max_amount: 5000, // maximum 5000 DAI

    matching_fee: matching_fee/100, // 2 percent
    delivery_fee: 0,
    min_fee: 0,
    max_fee_num: max_fee_num,
    max_fee: max_fee_num/100,
    deposit_fee: deposit_fee/100,
    isMobile: isMobile,
    getLang: getCookie("language"),
    getLanguage: getLanguage(),
    getTranslations: getLang(),
    setLang: setCookie,
    get_agent_payment: get_agent_payment,
    get_agent_reward: get_agent_reward,
    get_receive_amount: get_receive_amount,
    translateMethod: translateMethod,
    translateStatus: translateStatus,
    makeMethods: makeMethods,
    dataURLtoFile: dataURLtoFile,
    avatarconf: avatarconf,
    make_name: make_name
}